import React, { useState, useEffect } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import axios from 'axios';
import { loading } from '../../utils/api';
import { useForm } from 'react-hook-form';
import ModalErrores from '../../componentes/ModalErrores'
import { useAlert } from 'react-alert';

const CargarArchivoExcel = (props) => {
    const {getValues} = useForm({});
    const actionBase = {
        meta: '',
        file: '',
        remove: ''
    }
    const [action, setAction] = useState(actionBase)
    const [modalErrores, setModalErrores] = useState(false)
    const [errores, setErrores] = useState([])
    const alert = useAlert();

    const reiniciaDatos = () => {
        action.remove()
        setAction(actionBase)
    }
    useEffect(
        () => {
            if (action.file !== ""){
                guardar_excel()
            }
        },
        [action]
    )
    const handleChangeStatus = ({ meta, file, remove }, status) => {

        if (status === 'done') {
            setAction({
                meta: meta,
                file: file,
                remove: remove
            })

        } else if (status === 'preparing') {
        } else if (status === 'error_upload') {
            alert.show('Error al cargar archivo', { type: 'error' })
        }
    }
    async function guardar_excel() {
        let formData = new FormData();
        formData.append("file", action.file);
        formData.append("nombre", getValues().nombre_documento);
        formData.append("fecha",props.fecha);

        loading(true);
        alert.show('Procesando archivo, espere unos momentos')
        const token = localStorage.getItem('token');
        const ruta = axios.post(`${process.env.REACT_APP_API_URL}api/carga_excel_movimientos`, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`} });
        ruta.then(respuesta => {
            props.retorno(respuesta.data.data);
            reiniciaDatos()
            loading(false);
            setModalErrores(false)
            alert.show(respuesta.data.msg, { type: 'success' })
        }).catch((error) => {
            if (error.response.status === 500) {
                alert.show(error.response.data.msg, { type: 'error' });
                if (error.response.data.errores){
                    setModalErrores(true)
                    setErrores(error.response.data.errores)
                }
            }
            action.remove()
            loading(false);
        });
    }
    return (
        <>
            <Dropzone
                onChangeStatus={handleChangeStatus}
                inputContent="Click para cargar documento"
                maxFiles={1}
                multiple={false}
                accept=".csv,.xls,.xlsm,.xlsx"
            />
            <ModalErrores
                show = {modalErrores}
                errores = {errores}
                close={() => {
                    setModalErrores(false)
                }}
            />
        </>
    )
}

export default CargarArchivoExcel