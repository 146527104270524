import React, {useState, useContext, useEffect, useRef} from 'react';
import {useAlert} from 'react-alert';
import Layout from './Layout.js';
import IndicadoresFinancieros from './IndicadoresFinancieros.js';
import ResumenDeMargenes from './ResumenDeMargenes.js';
// import { Breadcrumb } from 'react-bootstrap';
import { ApiBDL } from '../../utils/api';
import {Breadcrumb, Button, Row, Col, ButtonGroup, Form, InputGroup} from 'react-bootstrap';

function ReporteBdl(props) {

    const alert = useAlert();
    const [predio, setPredio] = useState({});
    const [predioId, setPredioId] = useState(props.match.params.predio_id);
    const [token, setToken] = useState('');
    const [periodos, setPeriodos] = useState([]);
    const [periodoId, setPeriodoId] = useState('');
    const cmbxPeriodos = useRef(null);
    const listaPeriodos= periodos.map((p) =>
        <option key={p.id.toString()} value={p.id}>
            {p.numero}: ({p.inicio} {p.cierre})
        </option>
    );

    useEffect(
        () => {
            let search = props.location.search.slice(1);
            if (search !== '' && token === '') {
                let search2 = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
                setToken(search2.token)
            }

            if (token !== '') {
                obtenerPredio();
                if (predioId) {
                    getPeriodos(predioId);
                }
            }

        },
        [token]
    )

    /**
     * Cargar información de la grilla
     */
    async function obtenerPredio() {
        let resultado = await ApiBDL(`/api/bdl/predio/${predioId}`, null, {}, true, 'get', token);
        if (resultado && resultado.status === 200) {
            setPredio(resultado.data);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });;
        }
    }

    async function getPeriodos(predio) {
        let resultado = await ApiBDL(`api/bdl/balance/periodos/${predio}`, null, {}, true, 'get', token);
        if (resultado && resultado.status === 200) {
            setPeriodos(resultado.data.datos);
            setPeriodoId(resultado.data.datos[0].id);
        } else {
            alert.show('Error al cargar periodos', { type: 'error' });
        }
    }

    function handleChange(e) {
        setPeriodoId(e.target.value)
    }


    return (
        <Layout>
            {token !== '' &&
                <>
                    {predio.id &&
                        <>
                            <h4>{predio.nombre}</h4>
                            <Col md={12}>
                                <Form.Group as={Row} controlId="select_periodo">
                                        <InputGroup size="sm">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="text" size="sm">Periodo</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control ref={cmbxPeriodos} as="select" onChange={handleChange}>
                                                {listaPeriodos}
                                            </Form.Control>
                                        </InputGroup>
                                </Form.Group>
                            </Col>
                            <Breadcrumb style={{marginTop: '10px'}}>
                                <Breadcrumb.Item active>Indicadores financieros</Breadcrumb.Item>
                            </Breadcrumb>
                            <IndicadoresFinancieros predioId={predio.id} periodoId={periodoId} token={token}/>
                            <Breadcrumb style={{ marginTop: '10px' }}>
                                <Breadcrumb.Item active>Resumen de margenes</Breadcrumb.Item>
                            </Breadcrumb>
                            <ResumenDeMargenes predio={predio} periodoId={periodoId} token={token}/>
                        </>
                    }
                </>
            }
        </Layout>
    );
}

export default ReporteBdl;