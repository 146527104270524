import React from 'react';
import {BaseStylesPdf} from '../../../componentes/pdf/BaseStylesPdf';
import {BaseLinkPdf} from '../../../componentes/pdf/BaseLinkPdf';
import {Table, TableHeader, TableBody, TableCell, DataTableCell} from '@david.kucsai/react-pdf-table';
import {separadorDeMiles} from '../../../componentes/grid/utils';

export function PresupuestoCajaPdf(props) {
    const styles = BaseStylesPdf();
    const tablaPresupuestoCaja =(
        <Table data={props.rows}>
            <TableHeader textAlign={"center"}>
                {/* el "weighting" idealmente debe sumar 1 para que quede todo ordenado */}
                <TableCell style={styles.tableHeader} weighting={0.07}>Cuenta</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.24}>Descripcion</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.15}>Ingresos</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.15}>Egresos</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.34}>Mes</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.05}>IVA</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.2}>Comentario</TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell style={styles.textCenter} weighting={0.07} getContent={(r) => r.codigo_cuenta}/>
                <DataTableCell style={styles.textLeft} weighting={0.24} getContent={(r) => r.descripcion}/>
                <DataTableCell style={styles.textRigh} weighting={0.15} getContent={(r) => separadorDeMiles(r.ingresos)}/>
                <DataTableCell style={styles.textRigh} weighting={0.15} getContent={(r) => separadorDeMiles(r.egresos)}/>
                <DataTableCell style={styles.textLeft} weighting={0.34} getContent={(r) => r.codigo_mes}/>
                <DataTableCell style={styles.textCenter} weighting={0.05} getContent={(r) => r.texto_iva}/>
                <DataTableCell style={styles.textLeft} weighting={0.2} getContent={(r) => r.comentario}/>
            </TableBody>
        </Table>)

    let blob = BaseLinkPdf({...props, body:tablaPresupuestoCaja, orientation:"landscape"})

    return blob
}