import React from 'react';
import MenuAdmin from './menu/MenuAdmin.js';
import {Container, Row, Col, Breadcrumb} from 'react-bootstrap';


function LayoutAdmin(props) {

    return (
        <Container fluid={true}>
            <Row>
                <Col>
                    <MenuAdmin />
                </Col>
            </Row>
            <Row style={{paddingTop:'10px'}}>
                <Col>
                    <Breadcrumb>
                        {/* <Breadcrumb.Item as={Link} to='/'>Home</Breadcrumb.Item> */}
                        <Breadcrumb.Item active>{props.title}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>{props.children}</Col>          
            </Row>
            <footer>
                <hr />
                <a href="/">CONGA: Sistema de contabilidad y gestión Agropecuaria. Desarrollado por AltaVenta SpA</a>
            </footer>
        </Container>
    );
}

export default LayoutAdmin;