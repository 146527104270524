import React, {useState, useEffect, useContext, useRef} from 'react';
import {Form, Row, Col, Table, Button} from 'react-bootstrap';
import {Api} from '../../utils/api';
import UserContext from '../../UserContext';
import { useAlert } from 'react-alert';
import { useForm, ErrorMessage } from 'react-hook-form';

function FormPresupuestoRoles(props) {
    const alert = useAlert();           // Para las notificaciones
    const contexto = useContext(UserContext);
    const { register, handleSubmit, errors, setValue, watch } = useForm({
        defaultValues: {
            actividad_codigo: '',
            roles:[],
            superficies:[]
        }
    });
    const [rows, setRows] = useState([]);
    const [roles, setRoles] = useState([]);
    const listaRoles = roles.map((p) =>
        <option key={p.id.toString()} value={p.id} rol={p.rol}>
            {p.rol}
        </option>
    );
    const [superficieTotal, setSuperficieTotal] = useState(0);
    const cmbxRoles = useRef(null);

    function changeCmbxRoles(e) {
        //getDatos(e.target.value);
        //setPresupuestoSeleccionadoId(cmbxPresupuestos.current.value);
        // agregar rol a la grilla
        console.log(cmbxRoles.current.selectedOptions[0].getAttribute('rol'));
        let seAgrega = true;
        rows.forEach(row => {
            if (e.target.value === row.id) {
                seAgrega = false;
            }
        });
        if (seAgrega && cmbxRoles.current.selectedOptions[0].getAttribute('rol')!==null) {
            setRows([...rows, {id:e.target.value,rol:cmbxRoles.current.selectedOptions[0].getAttribute('rol'),superficie:''}])
        }
    }

    useEffect(
        () => {
            if (props.tabSeleccionado === 'formulario_roles') { // Viene un presupuesto id
                getPresupuesto(contexto.predio.predio_id, props.presupuestoid);
                obtenerRoles();
            }
        },
        [props.tabSeleccionado]
    )

    useEffect(
        () => {
            if (props.roles_presupuesto!=="[]" && props.roles_presupuesto!==undefined) {
                let newRows = JSON.parse(props.roles_presupuesto);
                setRows(newRows);
                let newSuperficieTotal = 0;
                newRows.forEach(row => {
                    newSuperficieTotal+=parseFloat(row.superficie);
                });
                setSuperficieTotal(newSuperficieTotal);
            }
        },
        [props.roles_presupuesto]
    )

    /**
     * Busca un presupuesto para editar
     *
     * @param {integer} predioId
     * @param {string} presupuesto_id
     */
    async function getPresupuesto(predioId, presupuestoId) {
        let resultado = await Api(`api/presupuesto/${presupuestoId}?predio_id=${predioId}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            let actividad = resultado.data.datos.actividad;
            setValue([
                {actividad_codigo: actividad.codigo},
                {roles: resultado.data.datos.roles},
                {superficies:resultado.data.datos.superficies}
            ]);
        } else {
            alert.show('Seleccione Rol')
        }
    }

    async function obtenerRoles() {
        let resultado = await Api(`/api/administracion/predios/${contexto.predio.predio_id}/roles`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setRoles(resultado.data.datos);
        } else {
            setRoles([]);
        }
    }

    function inputsSuperficies(){
        let filas = [];
        rows.forEach(row => {
            let fila = (
                <tr key={"tr_"+row.id}>
                    <td>{row.rol}</td>
                    <td>
                        <Form.Control
                            id={"superficie_"+row.id}
                            name={"superficie_"+row.id}
                            type="number"
                            placeholder="Superficie utilizada"
                            size="sm"
                            defaultValue={row.superficie || ''}
                            onChange={(e) => actualizaSuperficie(row.id,e.target.value)}
                            ref={props.register({
                                required: "Por favor, ingrese la superficie utilizada",
                            })}
                            isInvalid={props.errors["superficie_"+row.id]}
                        /><ErrorMessage errors={props.errors} name={"superficie_"+row.id} as="div" className="invalid-feedback" /></td>
                    <td>
                        <Button variant="danger" onClick={(e) => quitarFila(row.id)}>X</Button></td>
                </tr>
            )
            filas.push(fila)
        });
        filas.push(
            <tr key="total">
                    <td>Total</td>
                    <td>{superficieTotal}</td>
                    <td></td>
                </tr>
        )
        return filas;
    }

    function actualizaSuperficie(id,superficie){
        let newSuperficieTotal = 0;
        rows.forEach(row => {
            if (row.id === id) {
                row.superficie = superficie;
            }
            newSuperficieTotal+=parseFloat(row.superficie);
        });
        props.setValue("roles",JSON.stringify(rows));
        setSuperficieTotal(newSuperficieTotal);
    }

    function quitarFila(id){
        let newRows = [];
        let newSuperficieTotal = 0;
        rows.forEach(row => {
            if (row.id!=id) {
                newRows.push(row);
            }
            newSuperficieTotal+=parseFloat(row.superficie);
        });
        setRows(newRows);
        props.setValue("roles",JSON.stringify(newRows));
        setSuperficieTotal(newSuperficieTotal);
    }

    return (
        <>
            <Form.Group as={Row} controlId="cmbxRoles">
                <Form.Label column sm="2">Roles</Form.Label>
                <Col sm="10">
                    <Form.Control ref={cmbxRoles} as="select" size="sm" onChange={changeCmbxRoles}>
                        <option key={null} value={null} className="placeholder">Seleccionar rol...</option>
                        {listaRoles}
                    </Form.Control>
                </Col>
            </Form.Group>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Rol</th>
                        <th>Superficie</th>
                        <th>Quitar</th>
                    </tr>
                </thead>
                <tbody>
                    {inputsSuperficies()}
                </tbody>
            </Table>
        </>
    );
}

export default FormPresupuestoRoles;