import React from 'react';
import { Navbar, Nav, NavDropdown, Form, Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';


function MenuConga(props) {
    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand href="#home">
                CONGA
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Navbar>
    );
}

export default withRouter(MenuConga);