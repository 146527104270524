import React from 'react';
import {BaseStylesPdf} from '../../../componentes/pdf/BaseStylesPdf';
import {BaseLinkPdf} from '../../../componentes/pdf/BaseLinkPdf';
import {Table, TableHeader, TableBody, TableCell, DataTableCell} from '@david.kucsai/react-pdf-table';
import {separadorDeMiles} from '../../../componentes/grid/utils';

export function EstructuraCostosPdf(props) {
    const styles = BaseStylesPdf();
    const tablaEstructuraCostos =(
        <>
        <Table data={props.rows}>
            <TableHeader textAlign={"center"}>
                {/* el "weighting" idealmente debe sumar 1 para que quede todo ordenado */}
                <TableCell style={styles.tableHeader} weighting={0.1}>Cuenta</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.4}>Descripcion</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.1}>Datos</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.15}>Total</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.15}>Total/ha</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.1}>%</TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell style={styles.textCenter} weighting={0.1} getContent={(r) => r.codigo}/>
                <DataTableCell style={styles.textLeft} weighting={0.4} getContent={(r) => r.nombre}/>
                <DataTableCell style={styles.textCenter} weighting={0.1} getContent={(r) => r.datos}/>
                <DataTableCell style={styles.textRigh} weighting={0.15} getContent={(r) => separadorDeMiles(r.total)}/>
                <DataTableCell style={styles.textRigh} weighting={0.15} getContent={(r) => separadorDeMiles(r.total_ha)}/>
                <DataTableCell style={styles.textCenter} weighting={0.1} getContent={(r) => r.porcentaje}/>
            </TableBody>
        </Table>
        <Table data={props.rowsTotales}>
            <TableHeader textAlign={"center"}>
                <TableCell weighting={0.6} style={{...styles.tableHeader,...{ padding: '2px 7px'}}}></TableCell>
                <TableCell weighting={0.15} style={styles.tableHeader}>Total</TableCell>
                <TableCell weighting={0.15} style={styles.tableHeader}>Total/ha</TableCell>
                <TableCell weighting={0.1} style={styles.tableHeader}></TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell style={{...styles.textRigh,...{ padding: '2px 7px'}}} weighting={0.6} getContent={(r) => r.nombre}/>
                <DataTableCell style={styles.textRigh} weighting={0.15} getContent={(r) => separadorDeMiles(r.total)}/>
                <DataTableCell style={styles.textRigh} weighting={0.15} getContent={(r) => separadorDeMiles(r.total_ha)}/>
                <DataTableCell style={styles.textRigh} weighting={0.1} getContent={(r) => ""}/>
            </TableBody>
        </Table>
        </>)

    let blob = BaseLinkPdf({...props, body:tablaEstructuraCostos, orientation:"landscape"})

    return blob
}