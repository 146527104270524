import React from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import { FaWindowClose, FaTrashAlt } from 'react-icons/fa';
import ReactHtmlParser from 'react-html-parser';

function ModalEliminar(props) {

    const handleClose = () => props.setShow(false);

    return (
        <Modal show={props.show} onHide={handleClose} autoFocus={true} enforceFocus={true}>
            <Modal.Header closeButton>
                <Modal.Title>{props.titulo}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {props.children &&
                    <>
                        {ReactHtmlParser(props.children)}
                    </>
                }
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={handleClose}>
                    <FaWindowClose /> Cerrar
                </Button>
                <Button variant="danger" size="sm" onClick={props.confirmar}>
                    <FaTrashAlt /> {props.texto_aceptar ? props.texto_aceptar : 'Eliminar'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalEliminar;