import React, { useEffect, useContext } from 'react';
import { Modal, Button, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { useForm, ErrorMessage } from 'react-hook-form';
import { useAlert } from 'react-alert';
import { Api, ObjectToFormdata } from '../../utils/api';
import UserContext from '../../UserContext';
import Fecha from '../../componentes/Fecha';
import { deltaPeriodo, tienePermisos } from '../../utils/Functions';

export async function getPeriodo(periodoId) {
    let resultado = await Api('api/periodo/' + periodoId, null, {}, true);
    return resultado.data.periodo;
}

export function FormPeriodo(props) {
    const alert = useAlert();           // Para las notificaciones
    const contexto = useContext(UserContext);
    const { periodo, ...rest } = props;
    const { watch, register, handleSubmit, errors, setValue } = useForm();

    const onSubmit = data => {
        let fechaCierre = watch("cierre").split("/");
        data = {
            ...data,
            cierre: `${fechaCierre[2]}-${fechaCierre[1]}-${fechaCierre[0]}`
        }
        enviarFormulario(data);
    }

    async function enviarFormulario(data) {
        data = ObjectToFormdata(data);
        let resultado = await Api('api/periodo/guardar', data, { 'Content-Type': 'application/json' }, true, 'post');
        if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            props.funcionRetorno(data);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            //props.funcionRetorno(data);
        }
    }

    useEffect(
        () => {
            if (periodo) {
                setValue([
                    { id: periodo.id },
                    { numero: periodo.numero },
                    { inicio: periodo.inicio },
                    { cierre: periodo.cierre },
                    { abierto: periodo.abierto },
                    { superficie_util: periodo.superficie_util },
                    { mano_obra_permanente: periodo.mano_obra_permanente }
                ]);
            }
        },
        [periodo]
    )

    function onChangeInicio(fecha) {
        let fechaCierre = deltaPeriodo(fecha, 1);
        setValue([
            { cierre: `${fechaCierre.getDate()}/${fechaCierre.getMonth() + 1}/${fechaCierre.getFullYear()}` }
        ])
    }


    return (
        <Modal {...rest} backdrop="static" keyboard={false} size="lg">
            <Modal.Header>
                <Modal.Title>Periodo</Modal.Title>
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>

                    <Form.Control type="hidden" name="id" defaultValue={periodo.id} ref={register({})}></Form.Control>
                    <Form.Control type="hidden" name="predio_id" defaultValue={contexto.predio.predio_id} ref={register({})}></Form.Control>
                    <Form.Group as={Row}>
                        <Form.Label column sm="2">
                            Numero:
                    </Form.Label>
                        <Col sm="4">
                            <Form.Control type="number" name="numero" placeholder="numero"
                                defaultValue={periodo.numero}
                                ref={register({
                                    required: "Por favor, ingrese un numero válido"
                                })}
                                isInvalid={errors.numero} size="sm" disabled />
                            <ErrorMessage errors={errors} name="numero" as="div" className="invalid-feedback" />
                        </Col>
                        <Form.Label column sm="2">
                            Abierto:
                    </Form.Label>
                        <Col sm="4">
                            <Form.Check
                                type="checkbox"
                                id="ab_id"
                                name="abierto"
                                label="Sí"
                                defaultChecked={periodo.abierto}
                                value={true}
                                size="sm"
                                ref={register()}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm="2">
                            Inicio:
                    </Form.Label>
                        <Col sm="4" id="cont-fecha-inicio">
                            <Fecha
                                name='inicio'
                                register={register}
                                errors={errors}
                                dateFormat='dd/MM/yyyy' // dd/MM/Y h:mm aa - dd/MM/Y - MM/Y
                                formatoMesAno={true}
                                dateFormatOculto='y-m-d' // y-m-d h:i:s
                                inicial={periodo.inicio}
                                onChange={onChangeInicio} />
                        </Col>
                        <Form.Label column sm="2">
                            Cierre:
                    </Form.Label>
                        <Col sm="4">
                            <Form.Control type="text" name="cierre" placeholder="Cierre"
                                defaultValue={periodo.cierre}
                                ref={register({})}
                                isInvalid={errors.cierre} size="sm" disabled />
                            <ErrorMessage errors={errors} name="cierre" as="div" className="invalid-feedback" />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm="2">
                            Superficie Silvoagropecuaria:
                    </Form.Label>
                        <Col sm="4">
                            <Form.Control type="number" name="superficie_util" placeholder="Superficie Silvoagropecuaria"
                                defaultValue={periodo.superficie_util}
                                ref={register({
                                    required: "Por favor, ingrese un valor",
                                    min: { value: 0.1, message: "Debe ser mayor a 0" }
                                })}
                                isInvalid={errors.superficie_util} size="sm" />
                            <ErrorMessage errors={errors} name="superficie_util" as="div" className="invalid-feedback" />
                        </Col>
                        <Form.Label column sm="2">
                            Mano Obra Permanente:
                    </Form.Label>
                        <Col sm="4">
                            <Form.Control type="text" name="mano_obra_permanente" placeholder="Mano Obra Permanente"
                                defaultValue={periodo.mano_obra_permanente}
                                ref={register({
                                    required: "Por favor, ingrese un valor",
                                    min: { value: 0, message: "Mínimo 0" }
                                })}
                                isInvalid={errors.mano_obra_permanente} size="sm" />
                            <ErrorMessage errors={errors} name="mano_obra_permanente" as="div" className="invalid-feedback" />
                        </Col>
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>Cerrar</Button>
                    {tienePermisos('editar',contexto.predio.perfil) &&
                        <Button type="submit" variant="primary">Guardar</Button>
                    }

                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export function FormEliminarPeriodo(props) {

    const alert = useAlert();           // Para las notificaciones
    const { periodo, funcionRetorno, ...rest } = props;


    async function eliminarPeriodo(data) {
        //data = ObjectToFormdata(data);
        let resultado = await Api('api/periodo/eliminar/' + data.id, null, {}, true, 'delete');
        if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            props.funcionRetorno(data);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            //props.funcionRetorno(data);
        }
    }

    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Eliminar periodo
            </Modal.Header>
            <Modal.Body>
                <Col sm="12">
                    ¿Está seguro de eliminar el periodo de inicio en {periodo.inicio}?
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.onHide}>
                    Cerrar
                    </Button>
                <Button variant="danger" size="sm"
                    onClick={(e) => eliminarPeriodo(periodo)}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}