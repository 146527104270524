import React from "react";
import {FaCheck} from 'react-icons/fa';
import {IoMdClose} from "react-icons/io";


export function separadorDeMiles(value) {
    var num = Math.round(value);
    num = num.toString().replace(/\./g,'');
    if(!isNaN(num)){
        num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
        num = num.split('').reverse().join('').replace(/^[\.]/,'');
        return num.indexOf("-.")!=-1 ? num.replace("-.","-") : num;
    } else {
        return value;
    }
};

/**
 * Si viene un numero agrega separador de miles
 * En caso contrario devuelve vacio, util para los reportes
 * @param {number} value
 */
export function separadorDeMilesOrVacio(value) {
    if (typeof value === 'number') {
        var num = Math.round(value);
        num = num.toString().replace(/\./g, '');
        if (!isNaN(num)) {
            num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
            num = num.split('').reverse().join('').replace(/^[\.]/, '');
            return num.indexOf("-.") != -1 ? num.replace("-.", "-") : num;
        } else {
            return value;
        }
    } else {
        return value
    }
};

export function roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
}

export function separadorDeMilesDecimales(value) {
    var num = roundToTwo(value);
    let numSeparado = num.toString().split('.');
    num = numSeparado[0].replace(/\./g,'');
    if(!isNaN(num)){
        num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
        num = num.split('').reverse().join('').replace(/^[\.]/,'');
        let numFinal = num.indexOf("-.")!=-1 ? num.replace("-.","-") : num;
        return numSeparado[1] ? numFinal+"."+numSeparado[1] : numFinal
    } else {
        return value;
    }
};

/**
 * Agrega puntos y guión a un rut con dv incluido
 *
 * @export
 * @param {*} rut
 * @returns
 */
export function formateaRut(rut) {
	rut = limpiarRut(rut);
	if (typeof rut === 'undefined') {
		rut = '';
	}
	if (rut) {
		var actual = rut.replace(/^0+/, "");
		if (actual !== '' && actual.length > 1) {
			var sinPuntos = actual.replace(/\./g, "");
			var actualLimpio = sinPuntos.replace(/-/g, "");
			var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
			var rutPuntos = "";
			var i = 0;
			var j = 1;
			for (i = inicio.length - 1; i >= 0; i--) {
				var letra = inicio.charAt(i);
				rutPuntos = letra + rutPuntos;
				if (j % 3 == 0 && j <= inicio.length - 1) {
					rutPuntos = "." + rutPuntos;
				}
				j++;
			}
			var dv = actualLimpio.substring(actualLimpio.length - 1);
			rutPuntos = rutPuntos + "-" + dv;
		} else {
			return actual;
		}
		return rutPuntos;
	} else {
		return '';
	}
}

/**
 * Quita puntos y guión de un rut, mantiene el dv
 *
 * @export
 * @param {*} valor
 * @returns
 */
export function limpiarRut(valor) {
	if (valor) {
		valor = valor.replace(/\./g, '');
		valor = valor.replace(/\-/g, '');
		return valor;
	} else {
		return '';
	}
}

/**
 * Calcula dv de un rut sin puntos ni guión
 *
 * @export
 * @param {*} rut
 * @returns String
 */
export function digitoVerificador(rut){
    var M=0,S=1;
    for(;rut;rut=Math.floor(rut/10))
    S=(S+rut%10*(9-M++%6))%11;
    return (S?S-1:'k').toString();
}

/**
 * Para completar los ruts que vienen de db: sin puntos ni dv
 *
 * @export
 * @param {String} rut
 * @returns String rut
 */
export function completaRut(rut){
    rut = rut.trim();
    rut += digitoVerificador(rut);
    rut = formateaRut(rut);
    return rut;
}


export const RutFormatter = ({ value }) => {
    if (value) {
        if(value.length > 1) {
            return <p> {completaRut(value)} </p>;
        } else {
            return <p>{value}</p>;
        }
    } else {
        return <p>{value}</p>;
    }
};

export const PercentFormatter = ({ value }) => {
    if (value !== null && value !== ''){
        return <p style={{textAlign: 'center'}}> {value.toString().replace('.', ',')} % </p>;
    } else {
        return <p style={{textAlign: 'center'}}> </p>;
    }
};


export const NumberFormatter = ({ value }) => {
    if (value !== null && value !== ''){
        if (value < 0) {
            return <p style={{textAlign: 'right', color: 'red'}}> {separadorDeMiles(value)} </p>;
        } else {
            return <p style={{textAlign: 'right'}}> {separadorDeMiles(value)} </p>;
        }
    } else {
        return <p style={{textAlign: 'right'}}> {value} </p>;
    }
};

export const DecimalFormatter = ({ value }) => {
    if (value !== null && value !== ''){
        if (value < 0) {
            return <p style={{textAlign: 'right', color: 'red'}}> {separadorDeMilesDecimales(value)} </p>;
        } else {
            return <p style={{textAlign: 'right'}}> {separadorDeMilesDecimales(value)} </p>;
        }
    } else {
        return <p style={{textAlign: 'right'}}> {value} </p>;
    }
};

export const PercentageFormatter = ({ value }) => {
    if (value !== null && value !== ''){
        if (value < 0) {
            return <p style={{textAlign: 'right', color: 'red'}}> {separadorDeMilesDecimales(value)} %</p>;
        } else {
            return <p style={{textAlign: 'right'}}> {separadorDeMilesDecimales(value)} %</p>;
        }
    } else {
        return <p style={{textAlign: 'right'}}> {value} </p>;
    }
};

/**
 * Retorna un icono
 * true: icono ticket, false: una x
 * @param {Boolean} param0
 */
export const CheckFormatter = ({ value }) => {
    return <div style={{ 'textAlign': 'center' }}>
                {(value ? <FaCheck /> : <IoMdClose />)}
            </div>
};


export const DateFormatter = ({ value }) => {
    value = value.replace(/-/g, '/');
    let date = new Date(value);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return <p>{("00" + day).slice(-2)}-{("00" + month).slice(-2)}-{year}</p>;
};

export const DateFormatter2 = ({ value }) => {
    if (value != null){
        value = value.replace(/-/g, '/');
        let date = new Date(value);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return <p>{("00" + day).slice(-2)}-{("00" + month).slice(-2)}-{year}</p>
    } else{
        return <p></p>
    }
};

export const DateFormatterField = (value) => {
    value = value.replace(/-/g, '/');
    let date = new Date(value);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return ("00" + day).slice(-2) + '-' +  ("00" + month).slice(-2) + '-' + year;
};


export const LongTextFormatter = ({ value }) => {
    return <p style={{whiteSpace:'pre-wrap', lineHeight: 1.2}}> {value} </p>
};

/**
 * Formato para el código de actividad de las grillas
 * Si es menor a 4 digitos agrega ceros a la izquierda
 *
 * @param {integer} rut
 * @param {integer} rut
 * @returns string
 */
export const codigoActividad = ({ value }) => {
    value = zeroFill(value, 4);
    return <p style={{ textAlign: 'left' }}>{value}</p>;
};

/**
 * Agregar 0 a la izquierda del número, el limite de ceros es width
 *
 * @param {integer} rut
 * @param {integer} rut
 * @returns string
 */
export const zeroFill = (number, width) => {
    width -= number.toString().length;
    if (width > 0) {
        return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number;
    }
    return number + ""; // siempre devuelve tipo cadena
}

export const RightAlign = ({ value }) => {
    return <p style={{textAlign: 'right'}}> {value} </p>;
};


export const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
    const comparer = (a, b) => {
        if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
        } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
        }
    };
    return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
};


export const EmptyRowsView = () => {
    const message = "No hay información";
    return (
      <div
        style={{ textAlign: "center", backgroundColor: "#ddd", padding: "100px" }}
      >
        <img src={window.location.origin + '/logo192.png'} alt={message} />
        <h3>{message}</h3>
      </div>
    );
};

export function capitalizeWords(str){
    return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}

export const RelFormatter = ({ value }) => {
    let relacion = ""
    switch(value) {
        case 'A':
            relacion = 'Cliente y Proveedor';
            break;
        case 'P':
            relacion = 'Proveedor';
            break;
        case 'C':
            relacion = 'Cliente';
            break;
        default:
            relacion = "No definido";
            break;
    }
    return relacion;
};

export const VigFormatter = ({ value }) => {
    return ((value === true) ? 'Vigente' : 'No Vigente');
}
