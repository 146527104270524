import React, {useEffect, useState, useContext} from 'react';
import UserContext from '../../UserContext';
import {useParams} from "react-router-dom";
import {getPredio} from '../../modulos/administracion/FormularioPredio';
import { FormularioCooperados } from '../../modulos/administracion/FormularioCooperativa';

import Layout from '../../componentes/Layout';

function Cooperados(props){
    const contexto = useContext(UserContext);
	const [cooperadoForm, setCooperadoForm] = useState(
		{
			"id":null,
			"nombre_socio": "",
			"predio_asociado":"",
			"cuotas_participacion":0
		});
	const cooperativaId = contexto.predio ? contexto.predio.predio_id : null;

    useEffect(
        () => {
            if (cooperativaId!==null) {
                getPredio(cooperativaId).then(function(predio){
                    setCooperadoForm(predio);
                })
            }
        },
        [cooperativaId]
    )


	return (
		<>
			<Layout title={
				<>
				    Cooperados
				</>
			}>
		
            <FormularioCooperados
                cooperativa={cooperadoForm}
				show={true}
				tabSeleccionado={'permisos_acceso'}
            />
		</Layout>
		</>
	)
}

export default Cooperados;