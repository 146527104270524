import React, { useEffect, useContext } from 'react';
import { Modal, Button, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { useForm, ErrorMessage } from 'react-hook-form';
import { useAlert } from 'react-alert';
import { Api, ObjectToFormdata } from '../../utils/api';
import { tienePermisos } from '../../utils/Functions'
import UserContext from '../../UserContext';
import SelectorComuna from '../../componentes/SelectorComuna';
import { completaRut, limpiarRut, digitoVerificador, capitalizeWords } from '../../componentes/grid/utils';

export async function getEntidad(entidadId) {
    let resultado = await Api('api/entidad/' + entidadId, null, {}, true);
    return resultado.data.entidad;
}

export function FormEntidad(props) {
    const alert = useAlert();           // Para las notificaciones
    const contexto = useContext(UserContext);
    const { entidad, ...rest } = props;
    const { register, handleSubmit, errors, setValue } = useForm();

    const onSubmit = data => {
        enviarFormulario(data);
    }

    async function enviarFormulario(data) {
        data = {
            ...data,
            rut: limpiarRut(data.rut.slice(0, -1)),
            comuna: capitalizeWords(data.comuna.toLowerCase())
        }
        data = ObjectToFormdata(data);
        let resultado = await Api('api/entidad/guardar', data, { 'Content-Type': 'application/json' }, true, 'post');
        if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            props.funcionRetorno(resultado);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            props.funcionRetorno(resultado);
            //props.funcionRetorno(data);
        }
    }

    useEffect(
        () => {
            if (entidad) {
                setValue([
                    { id: entidad.id },
                    { rut: entidad.rut },
                    { rol_unico_pecuario: entidad.rol_unico_pecuario },
                    { razon_social: entidad.razon_social },
                    { giro: entidad.giro },
                    { comuna: entidad.comuna },
                    { direccion: entidad.direccion },
                    { direccion_despacho: entidad.direccion_despacho },
                    { cuenta_corriente: entidad.cuenta_corriente },
                    { relacion: entidad.relacion },
                    { habilitada: entidad.habilitada },
                    { contacto: entidad.contacto }
                ]);
            }
        },
        [entidad]
    )

    /**
     * Valida el dv que viene en un rut con puntos, guión y dv
     *
     * @param {*} rut
     * @returns
     */
    function validaDV(rut) {
        let dv = rut.charAt(rut.length - 1);
        let rutLimpio = limpiarRut(rut).slice(0, -1);
        if (dv.toLowerCase() === digitoVerificador(rutLimpio)) {
            return true;
        }
        else return false;
    }


    return (
        <Modal {...rest} backdrop="static" keyboard={false} size="lg">
            <Modal.Header>
                <Modal.Title>Entidad (Cliente Proveedor)</Modal.Title>
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>

                    <Form.Control type="hidden" name="id" defaultValue={entidad.id} ref={register({})}></Form.Control>
                    <Form.Control type="hidden" name="predio_id" defaultValue={contexto.predio.predio_id} ref={register({})}></Form.Control>
                    <Form.Group as={Row}>
                        <Form.Label column sm="2">
                            RUT:
                    </Form.Label>
                        <Col sm="4">
                            <Form.Control type="text" name="rut" placeholder="Rut con puntos y con guión"
                                defaultValue={completaRut(entidad.rut)}
                                ref={register({
                                    required: "Por favor, ingrese un rut válido",
                                    pattern: { value: /^\d{1,2}\.\d{3}\.\d{3}[-][0-9kK]{1}$/, message: "Ingrese su rut con puntos y con guión" },
                                    validate: (value) => validaDV(value) || "El rut no es válido"
                                })}
                                isInvalid={errors.rut} size="sm" />
                            <ErrorMessage errors={errors} name="rut" as="div" className="invalid-feedback" />
                        </Col>
                        <Form.Label column sm="2">
                            RUP:
                    </Form.Label>
                        <Col sm="4">
                            <Form.Control type="text" name="rol_unico_pecuario" placeholder="Rol único pecuario"
                                defaultValue={entidad.rol_unico_pecuario}
                                ref={register({})}
                                isInvalid={errors.rol_unico_pecuario} size="sm" />
                            <ErrorMessage errors={errors} name="rol_unico_pecuario" as="div" className="invalid-feedback" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm="2">
                            Razón Social:
                    </Form.Label>
                        <Col sm="4">
                            <Form.Control type="text" name="razon_social" placeholder="Razón social"
                                defaultValue={entidad.razon_social}
                                ref={register({ required: "Por favor, ingrese la razón social" })}
                                isInvalid={errors.razon_social} size="sm" />
                            <ErrorMessage errors={errors} name="razon_social" as="div" className="invalid-feedback" />
                        </Col>
                        <Form.Label column sm="2">
                            Giro:
                    </Form.Label>
                        <Col sm="4">
                            <Form.Control type="text" name="giro" placeholder="Giro"
                                defaultValue={entidad.giro}
                                ref={register({})} />
                            <ErrorMessage errors={errors} name="giro" as="div" className="invalid-feedback" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="4">
                        <Form.Label column sm="2">
                            Comuna:
                    </Form.Label>
                        <Col sm="10">
                            <SelectorComuna
                                id='1'
                                name='comuna'
                                register={register}
                                errors={errors}
                                inicial={entidad.comuna} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm="2">
                            Dirección:
                    </Form.Label>
                        <Col sm="4">
                            <Form.Control type="text" name="direccion" placeholder="Dirección comercial"
                                defaultValue={entidad.direccion}
                                ref={register({})} />
                            <ErrorMessage errors={errors} name="direccion" as="div" className="invalid-feedback" />
                        </Col>
                        <Form.Label column sm="2">
                            Despacho:
                    </Form.Label>
                        <Col sm="4">
                            <Form.Control type="text" name="direccion_despacho" placeholder="Dirección de despacho"
                                defaultValue={entidad.direccion_despacho}
                                ref={register({})} />
                            <ErrorMessage errors={errors} name="direccion_despacho" as="div" className="invalid-feedback" />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column sm="2">
                            Cta Corriente:
                    </Form.Label>
                        <Col sm="10">
                            <Form.Control type="number" name="cuenta_corriente" placeholder="Cuenta Corriente"
                                defaultValue={entidad.cuenta_corriente}
                                ref={register({})} />
                            <ErrorMessage errors={errors} name="cuenta_corriente" as="div" className="invalid-feedback" />
                        </Col>
                    </Form.Group>


                    <Form.Group as={Row} controlId="7">
                        <Form.Label column sm="2">
                            Relación:
                    </Form.Label>
                        <Col sm="4">
                            <Form.Check name='relacion' inline type='checkbox' label={`Cliente`}
                                id={`cliente_id`} value="C" defaultChecked={entidad.relacion === "C" || entidad.relacion === "A"}
                                ref={register({})} />
                            <Form.Check name='relacion' inline type='checkbox' label={`Proveedor`}
                                id={`proveedor_id`} value="P" defaultChecked={entidad.relacion === "P" || entidad.relacion === "A"}
                                ref={register({})} />
                        </Col>
                        <Form.Label column sm="2">
                            Habilitada:
                    </Form.Label>
                        <Col sm="4">
                            <Form.Check
                                type="checkbox"
                                id="hab_id"
                                name="habilitada"
                                label="Sí"
                                defaultChecked={entidad.habilitada}
                                value={true}
                                size="sm"
                                ref={register()}
                            />
                        </Col>
                    </Form.Group>

                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Contactos:</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="textarea" aria-label="With textarea"
                            name="contacto" ref={register()} defaultValue={entidad.contacto} />
                    </InputGroup>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>Cerrar</Button>
                    {tienePermisos('editar',contexto.predio.perfil) &&
                        <Button type="submit" variant="primary">Guardar</Button>
                    }

                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export function FormEliminarEntidad(props) {

    const alert = useAlert();           // Para las notificaciones
    const { entidad, funcionRetorno, ...rest } = props;


    async function eliminarEntidad(data) {
        //data = ObjectToFormdata(data);
        let resultado = await Api('api/entidad/eliminar/' + data.id, null, {}, true, 'delete');
        if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            props.funcionRetorno(data);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            //props.funcionRetorno(data);
        }
    }

    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Eliminar entidad
            </Modal.Header>
            <Modal.Body>
                <Col sm="12">
                    ¿Está seguro de eliminar a {entidad.razon_social}?
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.onHide}>
                    Cerrar
                    </Button>
                <Button variant="danger" size="sm"
                    onClick={(e) => eliminarEntidad(entidad)}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}