import React, { useState, useEffect} from 'react';
import { Form, Button, Col, Modal, Row} from 'react-bootstrap';
import { useForm, ErrorMessage } from 'react-hook-form'
import { useAlert } from 'react-alert';
import {Api} from '../../utils/api';
import { FaSave, FaWindowClose } from 'react-icons/fa';
import SelectorCategoriaActividad from '../../componentes/SelectorCategoriaActividad';

function FormularioActividadBase(props) {
    const alert = useAlert();           // Para las notificaciones
    const { actividadbase, ...rest } = props;
    const { register, handleSubmit, watch, errors, setValue } = useForm({
        defaultValues: {
            categoria_actividad_id: '',
            nombre: '',
            codigo: '',
            habilitada: 'SI'
        }
    });
    const inicial = {
        codigo_minimo: '',
        codigo_maximo: ''
    }
    const [global, setGlobal] = useState(inicial);

    useEffect(
        () => {
            if (actividadbase) {
                setValue([
                    {categoria_actividad_id: actividadbase.categoria_id},
                    {nombre: actividadbase.nombre},
                    {codigo: actividadbase.codigo},
                    {habilitada: (actividadbase.habilitada ? 'SI' : 'NO')}
                ]);
            } else {
                setGlobal(inicial);
            }
        },
        [actividadbase]
    )
    /**
     * Cuando el formulario está validado
     *
     * @param {*} data
     * @returns
     */
    async function guardar(data) {
        if (actividadbase === '') { // Crear
            let resultado = await Api('api/actividad/base', data, { 'Content-Type': 'application/json' }, true, 'post');
            if (resultado && resultado.status === 200) { // Presupuesto creado
                alert.show(resultado.data.msg, { type: 'success' });
                cerrarModal();
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
            }
        } else { // Actualizar
            let resultado = await Api(`api/actividad/base/${actividadbase.id}`, data, { 'Content-Type': 'application/json' }, true, 'put');
            if (resultado && resultado.status === 200) { // Presupuesto creado
                alert.show(resultado.data.msg, { type: 'success' });
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
            }
        }
    }

    /**
     * Retorna la categoria actividad seleccionada, actualizamos codigo_minimo y codigo_maximo
     *
     * @param {Object} data
     */
    function returnSelectorCategoriaCuenta(categoria) {
        if (categoria.length > 0) {
            setGlobal({
                ...global,
                codigo_minimo: categoria[0].codigo_minimo,
                codigo_maximo: categoria[0].codigo_maximo
            });
        } else {
            setGlobal({
                ...global,
                codigo_minimo: '',
                codigo_maximo: ''
            })
        }
    }

    function cerrarModal() {
        setGlobal({
            ...global,
            codigo_minimo: '',
            codigo_maximo: ''
        });
        props.onHide();
    }

    return (
        <Modal {...rest} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>{actividadbase ? 'Editar' : 'Agregar'} Actividad</Modal.Title>
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit(guardar)}>
                <Modal.Body>
                    <Row>
                        <Form.Label column sm="3"> Categoría: </Form.Label>
                        <Col sm="9">
                            <SelectorCategoriaActividad
                                id='selectorCategoriaActividad'
                                name="categoria_actividad_id"
                                register={register}
                                errors={errors}
                                inicial={watch('categoria_actividad_id')}
                                funcionRetorno={returnSelectorCategoriaCuenta}
                                actividadesUtilizadas={true}
                                placeholder=""
                                disabled={actividadbase ? true : false}
                                desactivarBotonBorrar={actividadbase ? true : false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="3">Rangos</Form.Label>
                        <Col sm="9">
                            {global.codigo_minimo} - {global.codigo_maximo}
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="3">Nombre</Form.Label>
                        <Col sm="9">
                            <Form.Control
                                name="nombre"
                                type="text"
                                placeholder=""
                                size="sm"
                                ref={register({
                                    required: "Por favor, ingrese el nombre"
                                })}
                                isInvalid={errors.nombre} />
                            <ErrorMessage errors={errors} name="nombre" as="div" className="invalid-feedback" />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="3">Código</Form.Label>
                        <Col sm="9">
                            <Form.Control
                                name="codigo"
                                type="number"
                                placeholder=""
                                size="sm"
                                ref={register({
                                    required: "Por favor, ingrese el código"
                                })}
                                isInvalid={errors.codigo} />
                                <ErrorMessage errors={errors} name="codigo" as="div" className="invalid-feedback" />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="3">Habilitada:</Form.Label>
                        <Col sm="9">
                            <Form.Check name='habilitada' inline type='radio' label={`Sí`} id={`habilitada_si`}
                                value={'SI'} ref={register({ required: true })} />
                            <Form.Check name='habilitada' inline type='radio' label={`No`} id={`habilitada_no`}
                                value={'NO'} ref={register({ required: true })} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={cerrarModal}><FaWindowClose /> Cerrar</Button>
                    <Button type="submit" size="sm" variant="success"><FaSave /> guardar</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default FormularioActividadBase;