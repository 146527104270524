import React, { useState, useEffect, useContext } from 'react';
import BaseGrid from '../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import GridMenu from '../../componentes/grid/GridMenu';
import LayoutAdmin from '../../componentes/LayoutAdmin';
import { LongTextFormatter, sortRows, EmptyRowsView, DateFormatter, PercentFormatter, DateFormatter2 } from '../../componentes/grid/utils';
import { Api } from '../../utils/api';
import UserContext from '../../UserContext';
import { Button, Row, Col, ButtonGroup } from 'react-bootstrap';
import DefaultButtons from '../../componentes/grid/DefaultButtons'
import Buscador from '../../componentes/Buscador';
import { useAlert } from 'react-alert';
import { ModalFormularioImpuesto, getImpuesto, FormularioEliminarImpuesto } from '../../modulos/administracion/FormularioImpuesto';

function Impuestos(props) {

    const {ContextMenuTrigger} = Menu;
    const [textoBuscar, setTextoBuscar] = useState('');
	const alert = useAlert();			// Para las notificaciones

	const columns = [
		{ key: 'nombre', name: 'Nombre', formatter: LongTextFormatter, sortable: true },
		{ key: 'tasa', name: 'Tasa', formatter: PercentFormatter },
		{ key: 'fecha_inicio', name: 'Fecha Inicio Vigencia', formatter: DateFormatter },
		{ key: 'fecha_cierre', name: 'Fecha Termino Vigencia', formatter: DateFormatter2 }
    ];
	const [rows, setRows] = useState([]);
	const contexto = useContext(UserContext);

	const [activarModalAgregarImpuesto, setActivarModalAgregarImpuesto] = useState(false);			// Modal para agregar impuesto
	const [activarModalEliminarImpuesto, setactivarModalEliminarImpuesto] = useState(false);			// Modal para eliminar impuesto
    const [impuestoForm, setImpuestoForm] = useState(
		{
			"id":"",
			"nombre": "",
			"tasa":"",
			"fecha_inicio":"",
			"fecha_cierre":"",
		});

	useEffect(
		() => {
			cargarImpuestos();
		},
		[contexto]
	)

	/**
	 * Buscamos y cargamos todos los impuestos en la grilla
	 */
	async function cargarImpuestos() {

		let resultado = await Api('api/administracion/impuestos', null, {}, true);
		if (resultado && resultado.status === 200) {
			trasformarAPorcentaje(resultado.data.datos);
			setRows(resultado.data.datos);
		} else {
			alert.show('Error al cargar impuestos', { type: 'error' });
			setRows([]);
		}
    }

    /**
     * Función para búsqueda específica de impuestos, actualmente deshabilitada
     *
     * @param {String} texto
     */
    async function retornoBusqueda(texto) {
		// let resultado = await Api(`api/administracion/unidades?texto=${texto}`, null, {}, true);
		// if (resultado && resultado.status === 200) {
		// 	setRows(resultado.data.datos);
		// } else {
        //     setRows([])
		// 	alert.show('Error al cargar usuarios', { type: 'error' });
		// }
		// console.log("Función Retorno de búsqueda");
	}

	function trasformarAPorcentaje(datos){
		for (let i = 0; i < datos.length; i++) {
			datos[i].tasa = Math.round(datos[i].tasa * 100);
		}
		return datos
	}

	/**
	 * Acción para desplegar el modal agregar impuesto
	 */
	function nuevoDato() {
		setImpuestoForm({
			"id":"",
			"nombre": "",
			"tasa":"",
			"fecha_inicio":"",
			"fecha_cierre":"",
		});
		setActivarModalAgregarImpuesto(true);
	}

	/**
	 * Evento para cerrar el modal agregar impuesto
	 */
	function cerrarModalAgregar() {
		cargarImpuestos();
		setActivarModalAgregarImpuesto(false);
	}

	/**
	 * Evento para cerrar el modal eliminar impuesto
	 */
	function cerrarModalEliminar() {
		cargarImpuestos();
		setactivarModalEliminarImpuesto(false);
	}


	/**
	 * Permite enviar el formulario para crear una nuevo impuesto
	 */
	async function agregarImpuesto(data) {
		cerrarModalAgregar();
	}

	/**
	 * Cuando queremos editar un impuesto, desde el context menú
	 *
	 * @param {integer} rowIdx
	 */
	const editRow = rowIdx => {
		new Promise(function(resolve, reject){
			getImpuesto(rows[rowIdx].id).then(function(impuesto){
				setImpuestoForm(impuesto);
				setActivarModalAgregarImpuesto(true);
			})
		})
	};


	/**
	 * Cuando queremos eliminar un impuesto, desde el context menú
	 *
	 * @param {*} rowIdx
	 */
	const deleteRow = rowIdx => {
		new Promise(function(resolve, reject){
			getImpuesto(rows[rowIdx].id).then(function(impuesto){
				setImpuestoForm(impuesto);
				setactivarModalEliminarImpuesto(true);
			})
		})
	};


	return (
		<LayoutAdmin title="Impuestos">
			<Row style={{paddingBottom:'10px'}}>
                <Col>
                    {/* <Buscador retornoBusqueda={retornoBusqueda} busqueda={textoBuscar} placeholder='Búsqueda de impuestos...'/> */}
                </Col>
				<Col>
					<ButtonGroup className="float-right">
						<Button variant="success" size="sm" onClick={nuevoDato}>Agregar Impuesto</Button>
						<DefaultButtons />
					</ButtonGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<div>
						<BaseGrid
							columns={columns}
							rowGetter={i => rows[i]}
							rowsCount={rows.length}
							minHeight={400}
							contextMenu={
								<GridMenu
									id="pac_1"
									options = {[
										{texto: 'Editar',
										funcion: (e, { rowIdx }) => editRow(rowIdx)},
										{texto: 'Eliminar',
										funcion: (e, { rowIdx }) => deleteRow(rowIdx)}
									]}
								/>
							}
							RowsContainer={ContextMenuTrigger}
							onGridSort={(sortColumn, sortDirection) =>
								setRows(sortRows(rows, sortColumn, sortDirection))
							}
							emptyRowsView={EmptyRowsView}/>
					</div>
				</Col>
			</Row>
			<ModalFormularioImpuesto
				show={activarModalAgregarImpuesto}
				onHide={cerrarModalAgregar}
				impuesto={impuestoForm}
				funcionRetorno={function(data){agregarImpuesto(data)}}
				size="md"
			/>
			<FormularioEliminarImpuesto
				show={activarModalEliminarImpuesto}
				onHide={cerrarModalEliminar}
				impuesto={impuestoForm}
				funcionRetorno={function(data){cerrarModalEliminar()}}
				size="md"
			/>
		</LayoutAdmin>
	);
}

export default Impuestos;