import React, {useState, useEffect, useContext} from 'react';
import {useAlert} from 'react-alert';
import BaseGrid from '../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import GridMenu from '../../componentes/grid/GridMenu';
import Layout from '../../componentes/Layout';
import {NumberFormatter, LongTextFormatter, sortRows, EmptyRowsView} from '../../componentes/grid/utils';
import {Api} from '../../utils/api';
import UserContext from '../../UserContext';
import { FormPresupuestoCaja, GetPresupuestoCaja, FormularioEliminarPresupuestoCaja} from './FormPresupuestoCaja';
import {Button, Row, Col, ButtonGroup} from 'react-bootstrap';
import DefaultButtons from '../../componentes/grid/DefaultButtons'
import { FaPlus } from 'react-icons/fa';
import { exportar, downloadBlob,tienePermisos, getDMY, fechaHoy } from '../../utils/Functions';
import {PresupuestoCajaPdf} from './pdf/PresupuestoCajaPdf';


function PresupuestoCaja(props) {

    const {ContextMenuTrigger} = Menu
    const alert = useAlert();			// Para las notificaciones
    const [showForm, setShowForm] = useState(false);
    const [presupuesto, setPresupuesto] = useState(null);
    const [showEliminar, setShowEliminar] = useState(false);  // Modal para eliminar presupuesto caja
    const [fechaExportar] = useState(fechaHoy());

    const columns = [
        {key: 'codigo_cuenta', name: 'Cuenta', sortable: true},
        { key: 'descripcion', name: 'Descripción', width: 300, formatter: LongTextFormatter},
        {key: 'ingresos', name: 'Ingresos', formatter: NumberFormatter},
        {key: 'egresos', name: 'Egresos', formatter: NumberFormatter},
        { key: 'codigo_mes', name: 'Mes', width: 250, formatter: LongTextFormatter},
        {key: 'texto_iva', name: 'IVA'},
        {key: 'comentario', name: 'Comentario', sortable: true},
     ];

    const [rows, setRows] = useState([]);

    const contexto = useContext(UserContext);

    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                obtenerDatos(contexto.predio.predio_id);
            }
        },
        [contexto]
    )

    async function obtenerDatos(predio) {

        let resultado = await Api(`api/presupuesto/caja/${predio}?periodo_id=${contexto.predio.periodo.id}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
        } else {
            alert.show('Error al cargar datos', { type: 'error' });
            setRows([]);
        }
    }

    function cerrarModalPresupuesto() {
        setShowForm(false)
        obtenerDatos(contexto.predio.predio_id);
    }

    function nuevoDato() {
        setPresupuesto(null);
        setShowForm(true);
    }

    const editRow = rowIdx => {
        new Promise(function(resolve, reject){
			GetPresupuestoCaja(contexto.predio.predio_id,rows[rowIdx].id).then(function(presupuesto){
				setPresupuesto(presupuesto);
				setShowForm(true);
			})
		})
    };

    const deleteRow = rowIdx => {
        new Promise(function(resolve, reject){
			GetPresupuestoCaja(contexto.predio.predio_id,rows[rowIdx].id).then(function(presupuesto){
				setPresupuesto(presupuesto);
				setShowEliminar(true);
			})
		})
    };

    function cerrarModalEliminar() {
		obtenerDatos(contexto.predio.predio_id);
		setShowEliminar(false);
    }

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        exportar(`api/exportar/presupuesto/caja?predio_id=${contexto.predio.predio_id}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf(){
        let props = {
            columns:columns,
            rows:rows,
            titulo:"Presupuesto de Caja",
            bajada:contexto.predio.nombre_predio,
            //subtitulo:"Tabla"
        }
        alert.show('La descarga comenzará en breve', { type: 'success' });
        new Promise(function(resolve, reject){
            PresupuestoCajaPdf(props).then(
                function(blob){
                    downloadBlob(blob,`Presupuesto_Caja_${contexto.predio.nombre_predio.replace(/ /g, '_')}_${getDMY(fechaExportar)}.pdf`);
                },function(blob){
                    alert.show('Error al exportar', { type: 'error' })
            })
        })
    }

    return (
        <Layout title="Presupuesto de Caja">
            <Row style={{paddingBottom:'10px'}}>
                <Col>
                    <ButtonGroup className="float-right">
                        {tienePermisos('editar',contexto.predio.perfil) &&
                        <Button
                            variant="success"
                            size="sm"
                            onClick={nuevoDato}><FaPlus/> Agregar dato</Button>
                        }
                        <DefaultButtons
                            exportar={exportarExcel}/>
                        <DefaultButtons
                            imprimir={exportarPdf}/>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <BaseGrid
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            minHeight={400}
                            contextMenu={
                                <GridMenu
                                    id="pac_1"
                                    options={ [
                                        {texto:"Editar",
                                        funcion: (e, { rowIdx }) => editRow(rowIdx)},
                                        {texto:"Eliminar",
                                        funcion: (e, { rowIdx }) => deleteRow(rowIdx)}
                                    ]}
                                />
                            }
                            RowsContainer={ContextMenuTrigger}
                            onGridSort={(sortColumn, sortDirection) =>
                                setRows(sortRows(rows, sortColumn, sortDirection))
                            }
                            emptyRowsView={EmptyRowsView}/>
                    </div>
                </Col>
            </Row>

            <FormPresupuestoCaja
                show={showForm}
                //datoId={datoId}
                presupuesto={presupuesto}
                onHide={cerrarModalPresupuesto} />

            <FormularioEliminarPresupuestoCaja
				show={showEliminar}
				onHide={cerrarModalEliminar}
				presupuesto={presupuesto}
				funcionRetorno={function(data){cerrarModalEliminar()}}
				size="md"
			/>
        </Layout>
    );
}

export default PresupuestoCaja;