import React from 'react';
import {BaseStylesPdf} from '../../componentes/pdf/BaseStylesPdf';
import {BaseLinkPdf} from '../../componentes/pdf/BaseLinkPdf';
import {Table, TableHeader, TableBody, TableCell, DataTableCell} from '@david.kucsai/react-pdf-table';
import { separadorDeMilesOrVacio} from '../../componentes/grid/utils';

export function IndicadoresPdf(props) {
    const styles = BaseStylesPdf();
    var tablaPresupuestoActividad = '';
    try {
        tablaPresupuestoActividad =(
            <>
            <Table data={props.rows}>
                <TableHeader textAlign={"center"}>
                    {/* el "weighting" idealmente debe sumar 1 para que quede todo ordenado */}
                    <TableCell style={styles.tableHeader} weighting={0.406}>Indicador</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.2}>Presupuesto</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.2}>Fin Ejercicio</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.2}>Desviación</TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{ textAlign: "left" }} weighting={0.4} getContent={(r) => r.indicador}/>
                    <DataTableCell style={{ textAlign: "right", fontSize: 11 }} weighting={0.2} getContent={(r) => separadorDeMilesOrVacio(r.presupuesto)}/>
                    <DataTableCell style={{ textAlign: "right", fontSize: 11 }} weighting={0.2} getContent={(r) => separadorDeMilesOrVacio(r.ejercicio)}/>
                    <DataTableCell style={{ textAlign: "right", fontSize: 11 }} weighting={0.2} getContent={(r) => separadorDeMilesOrVacio(r.desviacion)}/>
                </TableBody>
            </Table>
            </>)
    } catch (error) {
        console.log("Error IndicadoresPdf", error);
    }

    let blob = BaseLinkPdf({...props, body:tablaPresupuestoActividad, orientation:"landscape"})

    return blob
}