import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { Api } from "../../utils/api";
import UserContext from "../../UserContext";
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell } from "recharts";
import { colorCuenta } from "../../componentes/chart/utils";

function ChartEstructuraIngresos(props) {
  const contexto = useContext(UserContext);
  const [data, setData] = useState([]);

  async function getReporte(predio) {
    let resultado = await Api(
      `api/reportes/presupuesto/estructura_ingresos/${predio}/${contexto.predio.periodo.id}`,
      null,
      {},
      true
    );
    if (resultado && resultado.status === 200) {
      resultado.data.datos.map((dato) => {
        return (dato.porcentaje = parseFloat(dato.porcentaje));
      });
      setData(resultado.data.datos);
    } else {
      setData([]);
      console.log("viene un error");
    }
  }

  useEffect(() => {
    if (contexto.predio.predio_id && contexto.predio.periodo) {
      getReporte(contexto.predio.predio_id);
    }
  }, [contexto]);

  return (
    <Modal {...props} backdrop="static" keyboard={false} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Gráfico Estructura Ingresos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ width: "100%", height: 350 }}>
          <ResponsiveContainer>
            <PieChart
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <Pie
                dataKey="porcentaje"
                nameKey="nombre"
                isAnimationActive={false}
                data={data}
                outerRadius={100}
                fill="#8884d8"
                label
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colorCuenta(entry.codigo)}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend layout="vertical" align="right" verticalAlign="top" />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default ChartEstructuraIngresos;