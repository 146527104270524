import React, {useState, useContext, useEffect, useRef} from 'react';
import {useAlert} from 'react-alert';
import BaseGrid from '../../componentes/grid/BaseGrid';
import UserContext from '../../UserContext';
import Layout from '../../componentes/Layout.js';
import { Menu } from "react-data-grid-addons";
// import GridMenu from '../../componentes/grid/GridMenu';
import {NumberFormatter, DecimalFormatter, LongTextFormatter, sortRows, EmptyRowsView} from '../../componentes/grid/utils';
import { FormBalanceInicial, GetBalanceInicial, FormularioEliminarBalanceInicial} from './FormBalanceInicial';
import DefaultButtons from '../../componentes/grid/DefaultButtons'
import {Button, Row, Col, ButtonGroup, Form, InputGroup} from 'react-bootstrap';
import {Api} from '../../utils/api';
import { FaLock, FaSearch} from 'react-icons/fa';
import { exportar, downloadBlob } from '../../utils/Functions';
import {BalanceInicialPdf} from './pdf/BalanceInicialPdf';
import InventarioBalance from './InventarioBalance';
import { fechaHoy, getDMY } from '../../utils/Functions';


function BalanceInicial(props) {

    const contexto = useContext(UserContext);
	const alert = useAlert();			// Para las notificaciones
    // const {ContextMenuTrigger} = Menu;
    const [showForm, setShowForm] = useState(false);
    const [showEliminar, setShowEliminar] = useState(false);  // Modal para eliminar balance
    const [balance, setBalance] = useState(null);
    const [periodos, setPeriodos] = useState([]);
    const [periodoid, setPeriodoId] = useState(null);
    const [cuenta, setCuenta] = useState({codigo_minimo:'',codigo_maximo:''});
    const [showInventarioBalance, setShowInventarioBalance] = useState(false);
    const [fechaExportar] = useState(fechaHoy());
    const listaPeriodos= periodos.map((p) =>
        <option key={p.id.toString()} value={p.id}>
            {p.numero}: ({p.inicio} {p.cierre})
        </option>
    );
    const cmbxPeriodos = useRef(null);

    const columns = [
        {key: 'codigo_cuenta', name: 'Categoría', sortable: true},
        {key: 'nombre_cuenta', name: 'Descripción', width: 250},
        {key: 'debito', name: 'Débito', formatter: NumberFormatter},
        {key: 'credito', name: 'Crédito', formatter: NumberFormatter},
        {key: 'cantidad', name: 'Cantidad', formatter: DecimalFormatter},
        {key: 'unidad', name: 'Unidad', formatter: NumberFormatter},
		{key: 'inventario', name: 'Inventario', width: 250, sortable: true, formatter: LongTextFormatter},
        {key: 'comentario', name: 'Comentario', width: 250,  formatter: LongTextFormatter},
     ];

     const columnsTotalCD = [
        {key: 'c1', name: '', sortable: true},
        {key: 'c2', name: '', width: 250},
        {key: 'total_debito', name: 'Total Débito', formatter: NumberFormatter},
        {key: 'total_credito', name: 'Total Crédito', formatter: NumberFormatter},
        {key: 'capital_propio_cd', name: 'Capital propio', formatter: DecimalFormatter},
        {key: 'c4', name: '', formatter: NumberFormatter},
		{key: 'c5', name: '', width: 250, sortable: true, formatter: LongTextFormatter},
        {key: 'c6', name: '', width: 250,  formatter: LongTextFormatter},

     ]
    const [rows, setRows] = useState([]);
    const [rowsTotales, setRowsTotales] = useState({})

    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                getPeriodos(contexto.predio.predio_id);
            }
        },
        [contexto]
    )

    async function getPeriodos(predio) {
        let resultado = await Api(`api/balance/periodos/${predio}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setPeriodos(resultado.data.datos);
            getDatos(contexto.predio.periodo.id);
            setPeriodoId(contexto.predio.periodo.id);
        } else {
            alert.show('Error al cargar periodos', { type: 'error' });
        }
    }

    function handleChange(e) {
        setPeriodoId(e.target.value)
        getDatos(e.target.value)
    }

    async function getDatos(periodo_id) {
        let resultado = await Api(`api/balance/${periodo_id}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
            setRowsTotales(resultado.data.total)
        } else {
            alert.show('Error al cargar balances', { type: 'error' });
            setRows([]);
            setRowsTotales({})
        }
    }

    // function nuevoDato() {
    //     setBalance(null);
    //     setShowForm(true);
    // }

    // const editRow = rowIdx => {
    //     //setDatoId(rows[rowIdx].id);

    //     new Promise(function(resolve, reject){
	// 		GetBalanceInicial(cmbxPeriodos.current.value,rows[rowIdx].id).then(function(balance){
	// 			setBalance(balance);
	// 			setShowForm(true);
	// 		})
	// 	})
    // };

    function cerrarModalBalanceInicial() {
        setShowForm(false)
		getDatos(cmbxPeriodos.current.value);
    }

    // const deleteRow = rowIdx => {
    //     new Promise(function(resolve, reject){
	// 		GetBalanceInicial(cmbxPeriodos.current.value,rows[rowIdx].id).then(function(balance){
	// 			setBalance(balance);
	// 			setShowEliminar(true);
	// 		})
	// 	})
    // };

    function cerrarModalEliminar() {
		getDatos(cmbxPeriodos.current.value);
		setShowEliminar(false);
    }

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        exportar(`api/exportar/balance/${periodoid}/${contexto.predio.predio_id}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf(){
        let props = {
            columns:columns,
            rows:rows,
            rowsTotales:rowsTotales,
            titulo:"Balance Inicial",
            bajada:contexto.predio.nombre_predio,
            subtitulo:cmbxPeriodos.current.selectedOptions[0].label
        }
        alert.show('La descarga comenzará en breve', { type: 'success' });
        new Promise(function(resolve, reject){
            BalanceInicialPdf(props).then(
                function(blob){
                    downloadBlob(blob,
                        `Balance_Inicial_${contexto.predio.nombre_predio.replace(/ /g, '_')}_periodo${props.subtitulo.replace(/:/g, '').replace(/ /g, '_').replace(/\(|\)/g, "")}_${getDMY(fechaExportar)}.pdf`);
                },function(blob){
                    alert.show('Error al exportar', { type: 'error' })
            })
        })
    }

    /**
     * Cada vez que se presiona la celda de una fila se ejecuta esta función
     * Importante: onRowClick se ejecuta antes que getCellActions
     * @param {integer} rowIdx
     */
    function onRowClick(rowIdx) {
        if (rowIdx !== -1) { // CUANDO PRESIONAN LOS TITULOS SE EJECUTA Y DEVUELVE -1, NO QUEREMOS QUE LANCE ERROR
            setCuenta({
                codigo_maximo:rows[rowIdx].codigo_maximo,
                codigo_cuenta:rows[rowIdx].codigo_cuenta
            });
        }
    }

    const codigoActions = [
        {
            icon: <span><FaSearch /></span>,
            callback: () => {
                setShowInventarioBalance(true);
            }
        },
      ];

    /**
     * Cada vez que se presiona una celda se ejecuta esta función
     * Primero comprobamos que la celda seleccionada sea de la columna codigo_cuenta y el codigo <> 100000
     * En ese caso se muestra el icono de lupa(codigoActions)
     * @param {object} column
     * @param {object} row
     */
    function getCellActions(column, row) {
        if (column.key === "codigo_cuenta") {
            const cellActions = {
                codigo_cuenta: codigoActions
            };
            if (row.codigo_cuenta !== 100000) {
                return cellActions[column.key];
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    return (
        <Layout title="Resumen Balance Inicial">
            <Form>
                <Form.Row style={{paddingBottom:'10px'}}>
                    <Col md={4}>
                        <Form.Group as={Row} controlId="select_periodo">
                            <Col sm="12">
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="text" size="sm">Periodo</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control ref={cmbxPeriodos} as="select" onChange={handleChange} value={periodoid} disabled>
                                        {listaPeriodos}
                                    </Form.Control>

                                </InputGroup>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col md={8}>
                        <ButtonGroup className="float-right">
                            {/*<Button
                                variant="success"
                                size="sm"
                            onClick={nuevoDato}><FaPlus/> Agregar dato</Button>*/}
                            <DefaultButtons exportar={exportarExcel}/>
                            <DefaultButtons imprimir={exportarPdf}/>
                        </ButtonGroup>
                    </Col>
                </Form.Row>
            </Form>
            <div>
                <BaseGrid
                    columns={columns}
                    getCellActions={getCellActions}
                    onRowClick={onRowClick}
                    rowGetter={i => rows[i]}
                    rowsCount={rows.length}
                    minHeight={400}
                    onGridSort={(sortColumn, sortDirection) =>
                        setRows(sortRows(rows, sortColumn, sortDirection))
                    }
                    emptyRowsView={EmptyRowsView}
                     />
            </div>
            <BaseGrid
                    columns={columnsTotalCD}
                    getCellActions={getCellActions}
                    onRowClick={onRowClick}
                    rowGetter={i => rowsTotales[i]}
                    rowsCount={rowsTotales.length}
                    minHeight={80}
                    onGridSort={(sortColumn, sortDirection) =>
                        setRowsTotales(sortRows(rowsTotales, sortColumn, sortDirection))
                    }
                    emptyRowsView={EmptyRowsView}
                    showCountRows={false} />

            <FormBalanceInicial
                show={showForm}
                //datoId={datoId}
                balance={balance}
                periodoid={periodoid}
                onHide={cerrarModalBalanceInicial} />

            <FormularioEliminarBalanceInicial
				show={showEliminar}
				onHide={cerrarModalEliminar}
                balance={balance}
                periodoid={periodoid}
				funcionRetorno={function(data){cerrarModalEliminar()}}
				size="md"
			/>
            <InventarioBalance
                cuenta={cuenta}
                show={showInventarioBalance}
                onHide={() => setShowInventarioBalance(false)} />

        </Layout>
    );
}

export default BalanceInicial;