import React, { useState, useContext, useEffect, useRef} from 'react';
import {Typeahead} from 'react-bootstrap-typeahead';
import { Button, InputGroup, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FaBalanceScale} from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import {Api} from '../utils/api';
import UserContext from '../UserContext';
import { ErrorMessage } from 'react-hook-form';

function SelectorUnidades(props) {
    const ref = useRef();
    const contexto = useContext(UserContext);
    const [unidades, setUnidades] = useState([]);
    const [unidad, setUnidadOculto] = useState('');

    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                obtenerUnidades();
            }
        },
        [contexto]
    )

    useEffect(
        () => {
            if (props.inicial) { // El props inicial es distinto de vacio '', tiene uno seleccionado(acordarse es el input oculto)
                // console.log('valor combobox: ', ref.current.getInstance().state.selected.length);
                // console.log('valor: ', ref.current.getInstance()._input.value);
                // console.log('unidades.length: ', unidades.length);
                // console.log('props.inicial: ', props.inicial);
                if (unidades.length === 0) { // No hay unidades cargadas, tenemos que ir a buscar la seleccionada y seleccionar el combobox
                    obtenerUnidad(props.inicial);
                } else { // Si hay unidades cargadas, solo seleccionamos la que esté
                    if (ref.current.getInstance().state.selected.length === 0) { // Solo cuando no hay un elemento seleccionado en el combobox, esto se da solo en el inicio, ref.current.getInstance()._input.value
                        unidades.forEach(unidad => {  // Buscamos dentro de las unidades la que tenemos que seleccionar
                            if (parseInt(unidad.id) === parseInt(props.inicial)) {
                                ref.current.getInstance()._updateSelected([unidad]); // Automáticamente llama cambiarSelector
                            }
                            // console.log(unidad);
                        });
                    }
                }
            }
        },
        [props.inicial]
    )

    async function obtenerUnidad(id) {
        let resultado = await Api(`api/catalogo/unidades?id=${id}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            if (resultado.data.datos) {
                ref.current.getInstance()._updateSelected([resultado.data.datos]); // Automáticamente llama cambiarSelector
            } else {
                alert.show('Error al cargar combobox unidades', { type: 'error' });
            }
        } else {
            setUnidades([]);
        }
    }

    async function obtenerUnidades() {
        let resultado = await Api(`api/catalogo/unidades`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setUnidades(resultado.data.datos);
        } else {
            setUnidades([]);
        }
    }


    /**
     * Cada vez que selecciona una opción en el combobox
     * También cuando borra un caracter o todo el contenido
     *
     * @param {*} selected
     */
    function cambiarSelector(selected) {
        if (props.retornar) {
            props.retornar(selected);
        }
        if (selected[0] !== undefined) {
            setUnidadOculto(selected[0].id);
        } else {
            setUnidadOculto('');
        }
    }

    return (
        <>
        <InputGroup size="sm">
            <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1"><FaBalanceScale /></InputGroup.Text>
            </InputGroup.Prepend>
            <div style={{ width: 'calc(100% - 64px)' }}>
                { props.register ?
                    <Typeahead
                        { ...props}
                        bsSize='small'
                        options={unidades}
                        highlightOnlyResult={true}
                        selectHintOnEnter={true}
                        flip={true}
                        ref={ref}
                        isInvalid={props.errors[props.name] ? true : false}
                        onChange={cambiarSelector}
                        // onInputChange={onInputChange}
                        labelKey={(option) => `${option.abreviacion}: ${option.nombre}`}
                    />
                :
                    <Typeahead
                        { ...props}
                        bsSize='small'
                        options={unidades}
                        highlightOnlyResult={true}
                        selectHintOnEnter={true}
                        flip={true}
                        ref={ref}
                        onChange={cambiarSelector}
                        labelKey={(option) => `${option.abreviacion}: ${option.nombre}`}
                    />
                }
            </div>
            <InputGroup.Append>
                <OverlayTrigger
                    placement='top'
                    overlay={<Tooltip>Limpiar opción</Tooltip>}>
                    <Button
                        variant="outline-secondary"
                        disabled={props.desactivarBotonBorrar ? true : false}
                        onClick={() => {
                            ref.current.getInstance().clear(); // solo limpia la casila de la unidad, no hace nada más
                            ref.current.getInstance()._updateSelected([]); // Este limpia la casilla y activa cambiarSelector
                            // setUnidadOculto('');
                            // if (props.retornar) {
                            //     ref.current.getInstance()._updateSelected([]);
                            //     props.retornar([]);
                            // }
                        }}>
                        <IoMdClose />
                    </Button>
                </OverlayTrigger>
                {/* <Button variant="outline-secondary"><FaPlusCircle /></Button> */}
            </InputGroup.Append>
        </InputGroup>
        {/* INPUT INVISIBLE */}
        {props.register &&
            <Form.Control
                type="text"
                name={props.name}
                value={unidad}
                hidden
                onChange={(e) => {}} // Este onChange no se ocupa, ya que nunca modificamos este valor directamente, solo está para que no lance error reactjs
                ref={props.register({
                    required: "Seleccione una opción"
                })}
                isInvalid={props.errors[props.name]}
            />
        }

        {props.errors &&
            <ErrorMessage errors={props.errors} name={props.name} as="div" className="invalid-feedback" />
        }
        </>
    );
}

export default SelectorUnidades;