import React, { useState, useEffect,useContext } from 'react';
import { Modal, Button, Row, Col, Form, Tabs, Tab } from 'react-bootstrap';
import { useForm, ErrorMessage } from 'react-hook-form'
import SelectorCuenta from '../../componentes/SelectorCuenta';
import SelectorUnidades from '../../componentes/SelectorUnidades';
import { Api, ObjectToFormdata } from '../../utils/api';
import { useAlert } from 'react-alert';
import { FaSave, FaWindowClose } from 'react-icons/fa';
import TablaVidaUtil from './TablaVidaUtil';
import {tienePermisos} from '../../utils/Functions'
import UserContext from '../../UserContext';
import SelectorPeriodo from '../../componentes/SelectorPeriodo'

export function FormDatoInventario(props) {
    const contexto = useContext(UserContext);
    const alert = useAlert();           // Para las notificaciones
    const { ...rest } = props
    const initState = {
        id: null
    };
    const [dato, setDato] = useState(initState);
    const [tabSeleccionado, setTabSeleccionado] = useState('formulario_inventario');

    // para la validacion y envio de formulario - react hook form
    const { register, handleSubmit, watch, errors, setValue } = useForm({
        defaultValues: {
            codigo_cuenta: '',
            item: '',
            fabricacion: '',
            cantidad: '',
            unidad: '',
            tipo: 'C',
            valor_unitario: '',
            monto_total: '',
            depreciacion: 'false',
            tiempo_depreciacion: '',
            valor_residual: '',
            comentario: ''
        }
    })

    const onSubmit = data => {
        data.predioId = props.predioId;
        enviarFormulario(data);
    }

    async function enviarFormulario(data) {
        data = ObjectToFormdata(data);
        if (props.inventario !== null) {
            let resultado = await Api(`api/inventario/${props.predioId}/dato_inventario/${props.inventario.id}`, data, { 'Content-Type': 'application/json' }, true, 'put');
            if (resultado && resultado.status === 200) {
                alert.show(resultado.data.msg, { type: 'success' });
                cerrarModal();
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
            }
        }
        else {
            let resultado = await Api(`api/inventario/${props.predioId}/dato_inventario`, data, { 'Content-Type': 'application/json' }, true, 'post');
            if (resultado && resultado.status === 200) {
                alert.show(resultado.data.msg, { type: 'success' });
                cerrarModal();
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
            }
        }

    }

    function initForm() {
        //getData();
        if (props.inventario != null) {
            setValue([
                { codigo_cuenta: props.inventario.codigo_cuenta },
                { item: props.inventario.item },
                { fabricacion: props.inventario.fabricacion },
                { cantidad: props.inventario.cantidad },
                { unidad: props.inventario.unidad_id },
                { tipo: props.inventario.tipo },
                { valor_unitario: props.inventario.valor_unitario },
                { monto_total: props.inventario.monto_total },
                { depreciacion: (props.inventario.depreciacion).toString() },
                { tiempo_depreciacion: props.inventario.tiempo_depreciacion },
                { valor_residual: props.inventario.valor_residual },
                {periodo_id:props.inventario.periodo_id},
                { comentario: props.inventario.comentario }
            ]);
            setDato(props.inventario)
        }
    }

    function closeForm() {
        setDato(initState);
    }

    function cerrarModal() {
        setDato(initState);
        props.onHide();
    }

    useEffect(
        () => {
            // Calcula el impuesto feria ganado
            if (watch('valor_unitario')) {
                setValue([
                    { monto_total: Math.round(watch('valor_unitario') * watch('cantidad')) }
                ]);
            } else {
                setValue([
                    { monto_total: 0 }
                ]);
            }

        },
        [watch('valor_unitario'), watch('cantidad')]
    )
    function returnSelectorActividad(data) {
    }
    /**
     * Luego de presionar un tab
     *
     * @param {*} e
     */
    function onSelect(e) {
        setTabSeleccionado(e);
    }

    return (
        <Modal {...rest} onShow={initForm} onHide={closeForm} size="lg" backdrop="static" keyboard={false}>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header>
                    <Modal.Title>Inventario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey="formulario_inventario" id="inventario_tabs" onSelect={onSelect}>
                        <Tab eventKey="formulario_inventario" title={`Formulario`}>
                            <Form.Control type="hidden" name="id" defaultValue={dato.id} ref={register({})}></Form.Control>
                            {/* cuenta, tipo (d,c), monto, unidad, cantidad*/}
                            <Row style={{ marginTop: '10px' }}>
                                <Form.Label column sm="3">
                                    Cuenta:
                            </Form.Label>
                                <Col sm="9">
                                    <SelectorCuenta
                                        id='1'
                                        codigoMin={"100001"}
                                        codigoMax={"599999"}
                                        name="codigo_cuenta"
                                        register={register}
                                        errors={errors}
                                        inicial={watch('codigo_cuenta')}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Form.Label column sm="3"> Periodo: </Form.Label>
                                <Col sm="9">
                                    <SelectorPeriodo
                                        id='1'
                                        name="periodo_id"
                                        register={register}
                                        errors={errors}
                                        inicial={watch('periodo_id')}
                                        inicial={props.inventario ? watch('periodo_id') : (contexto.predio ? (contexto.predio.periodo? contexto.predio.periodo.id:""): "")}
                                        placeholder="Seleccione el periodo"
                                        returnSelectorActividad={returnSelectorActividad}
                                        actividadesUtilizadas={true}
                                        desactivarBotonBorrar={props.presupuestoid ? true : false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Form.Label column sm="3">
                                    Nombre:
                            </Form.Label>
                                <Col sm="9">
                                    <Form.Control
                                        name="item"
                                        type="text"
                                        size="sm"
                                        placeholder="Item"
                                        ref={register({
                                            required: "Por favor, ingrese el item",
                                            min: { value: 3, message: "Largo minimo 3" },
                                            max: { value: 300, message: "Largo máximo 300" }
                                        })}
                                        isInvalid={errors.item} />
                                    <ErrorMessage errors={errors} name="item" as="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            <Row>
                                <Form.Label column sm="3">
                                    Cantidad:
                                </Form.Label>
                                <Col sm="9">
                                    <Form.Control
                                        name="cantidad"
                                        type="number"
                                        placeholder="Cantidad"
                                        size="sm"
                                        ref={register({
                                            required: "Por favor, ingrese la cantidad",
                                            min: { value: 0, message: "Por favor, ingrese una cantidad correcta" }
                                        })}
                                        isInvalid={errors.cantidad} />
                                    <ErrorMessage errors={errors} name="cantidad" as="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            <Row>
                                <Form.Label column sm="3"> Unidad: </Form.Label>
                                <Col sm="9">
                                    <SelectorUnidades
                                        id='2'
                                        name='unidad'
                                        register={register}
                                        errors={errors}
                                        inicial={watch('unidad')}
                                        //returnSelectorUnidades={returnSelectorUnidades}
                                        placeholder="Unidad en que se medirá el presupuesto"
                                    // disabled={props.presupuestoid ? true : false}
                                    // desactivarBotonBorrar={props.presupuestoid ? true : false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Form.Label column sm="3">
                                    Tipo:
                            </Form.Label>
                                <Col sm="9">
                                    <Form.Check name='tipo' inline type='radio' label={`Débito`} id={`ingreso_id`}
                                        value="D" ref={register({})} />
                                    <Form.Check name='tipo' inline type='radio' label={`Crédito`} id={`egreso_id}`}
                                        value="C" ref={register({})} />
                                </Col>
                            </Row>
                            <Row>
                                <Form.Label column sm="3"> Valor unitario: </Form.Label>
                                <Col sm="9">
                                    <Form.Control
                                        name="valor_unitario"
                                        type="number"
                                        placeholder="Valor unitario"
                                        size="sm"
                                        ref={register({
                                            required: "Por favor, ingrese el valor unitario",
                                            min: { value: 0, message: "Por favor, ingrese un valor correcto" }
                                        })}
                                        isInvalid={errors.valor_unitario}
                                    />
                                    <ErrorMessage errors={errors} name="valor_unitario" as="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            <Row>
                                <Form.Label column sm="3"> Valor total: </Form.Label>
                                <Col sm="9">
                                    <Form.Control
                                        name="monto_total"
                                        type="number"
                                        placeholder="Valor total"
                                        size="sm"
                                        disabled
                                        ref={register({})}
                                        isInvalid={errors.monto_total}
                                    />
                                    <ErrorMessage errors={errors} name="monto_total" as="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            <Row>
                                <Form.Label column sm="3">
                                    Depreciación:
                            </Form.Label>
                                <Col sm="9">
                                    <Form.Check name='depreciacion' inline type='radio' label={`Sí`} id={`ingreso_id`}
                                        value={true} ref={register({})} />
                                    <Form.Check name='depreciacion' inline type='radio' label={`No`} id={`egreso_id}`}
                                        value={false} ref={register({})} />
                                </Col>
                            </Row>
                            <Row>
                                <Form.Label column sm="3">
                                    Año fabricación: {watch('depreciacion')}
                                </Form.Label>
                                <Col sm="9">
                                    <Form.Control
                                        name="fabricacion"
                                        type="number"
                                        size="sm"
                                        placeholder="Año fabricación"
                                        ref={register({
                                            min: { value: 0, message: "Por favor, ingrese un año correcto" },
                                            required: (watch('depreciacion') === 'true' ? 'Campo obligatorio': false)
                                        })}
                                        isInvalid={errors.fabricacion} />
                                    <ErrorMessage errors={errors} name="fabricacion" as="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            <Row>
                                <Form.Label column sm="3">Vida útil: </Form.Label>
                                <Col sm="9">
                                    <Form.Control
                                        name="tiempo_depreciacion"
                                        type="number"
                                        placeholder="Vida útil"
                                        disabled={watch('depreciacion') === 'false'}
                                        size="sm"
                                        ref={register({
                                            //required: "Por favor, ingrese el tamaño del predio",
                                            min: { value: 0, message: "Por favor, ingrese un valor correcto" },
                                            required: (watch('depreciacion') === 'true' ? 'Campo obligatorio': false)
                                        })}
                                        isInvalid={errors.tiempo_depreciacion}
                                    />
                                    <ErrorMessage errors={errors} name="tiempo_depreciacion" as="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            <Row>
                                <Form.Label column sm="3"> Valor residual: </Form.Label>
                                <Col sm="9">
                                    <Form.Control
                                        name="valor_residual"
                                        type="number"
                                        placeholder="Valor residual"
                                        disabled={watch('depreciacion') === 'false'}
                                        size="sm"
                                        ref={register({
                                            //required: "Por favor, ingrese el tamaño del predio",
                                            min: { value: 0, message: "Por favor, ingrese un valor correcto" },
                                            required: (watch('depreciacion') === 'true' ? 'Campo obligatorio': false)
                                        })}
                                        isInvalid={errors.valor_residual}
                                    />
                                    <ErrorMessage errors={errors} name="valor_residual" as="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            <Row>
                                <Form.Label column sm="3">Comentario: </Form.Label>
                                <Col sm="9">
                                    <Form.Control
                                        as="textarea"
                                        name="comentario"
                                        rows="2"
                                        ref={register({})} />
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="tabla_vida_util" title={`Tabla Vida Útil`}>
                            <br></br>
                            <TablaVidaUtil></TablaVidaUtil>
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cerrarModal}><FaWindowClose /> Cerrar</Button>
                    {tienePermisos('editar',contexto.predio.perfil) &&
                        <Button variant="success" type="submit"><FaSave /> Guardar</Button>
                    }

                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export async function GetDatoInventario(predioId, inventarioId) {
    let resultado = await Api(`api/inventario/${predioId}/${inventarioId}`, null, {}, true);
    return resultado.data.dato;
}

export function FormularioEliminarDatoInventario(props) {
    const alert = useAlert();           // Para las notificaciones
    const { inventario, funcionRetorno, ...rest } = props;

    async function eliminarInventario(data) {
        let resultado = await Api(`api/inventario/${props.predioId}/dato_inventario/` + data.id, null, {}, true, 'delete');
        if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            props.funcionRetorno(data);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
        }
    }

    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Eliminar inventario
            </Modal.Header>
            <Modal.Body>
                <Col sm="12">
                    ¿Está seguro de eliminar el inventario {inventario ? inventario.item : ""}?
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.onHide}>
                    Cerrar
                    </Button>
                <Button variant="danger" size="sm"
                    onClick={(e) => eliminarInventario(inventario)}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}