import React, {useState, useContext, useEffect, useRef} from 'react';
import BaseGrid from '../../componentes/grid/BaseGrid';
import UserContext from '../../UserContext';
import Layout from '../../componentes/Layout.js';
import {NumberFormatter, LongTextFormatter, sortRows} from '../../componentes/grid/utils';
import DefaultButtons from '../../componentes/grid/DefaultButtons'
import {Button, Row, Col, ButtonGroup, Form, InputGroup} from 'react-bootstrap';
import {Api} from '../../utils/api';
import {FaPen, FaPlus, FaTrashAlt } from 'react-icons/fa';
import FormPresupuesto from './FormPresupuesto';
import FormDatoPresupuesto from './FormDatoPresupuesto';
import { useAlert } from 'react-alert';
import GridMenu from '../../componentes/grid/GridMenu';
import { Menu } from "react-data-grid-addons";
import ModalEliminar from '../../componentes/ModalEliminar';
import { exportar, downloadBlob, tienePermisos } from '../../utils/Functions';
import {PresupuestoActividadPdf} from './pdf/PresupuestoActividadPdf';
import { zeroFill } from '../../componentes/grid/utils';
import { fechaHoy, getDMY } from '../../utils/Functions';

function PresupuestoActividad(props) {
    const alert = useAlert();  // Para las notificaciones
    const contexto = useContext(UserContext);
    const [showFormPresupuesto, setShowFormPresupuesto] = useState(false);
    const [showFormDato, setShowFormDato] = useState(false);
    const [presupuestos, setPresupuestos] = useState([]);
    const [presupuestoId, setPresupuestoId] = useState('');
    const [presupuestoSeleccionadoId, setPresupuestoSeleccionadoId] = useState('');
    const [datoPresupuesto, setDatoPresupuesto] = useState('');
    const [fechaExportar] = useState(fechaHoy());
    const listaPresupuestos = presupuestos.map((p) =>
        <option key={p.id.toString()} value={p.id} comentario={p.comentario ? p.comentario : " "}>
            {zeroFill(p.codigo_actividad, 4)}:{p.actividad} ({p.cantidad} {p.unidad_abreviacion})
        </option>
    );
    const cmbxPresupuestos = useRef(null);

    const columns = [
        { key: 'codigo_cuenta', name: 'Cuenta', sortable: true, resizable: true },
        { key: 'nombre_cuenta', name: 'Descripción', width: 350, resizable: true },
        { key: 'codigo_mes', name: 'Mes', width: 250, formatter: LongTextFormatter, resizable: true },
        { key: 'unidad_abreviacion', name: 'Un.', resizable: true },
        { key: 'cantidad_unidad', name: 'Ctd / Un', formatter: NumberFormatter, resizable: true },
        { key: 'monto_unidad', name: '$ Unitario', formatter: NumberFormatter, resizable: true },
        { key: 'cantidad', name: 'Tamaño', formatter: NumberFormatter, resizable: true },
        { key: 'total_unidad', name: 'Total / Un', formatter: NumberFormatter, resizable: true },
        { key: 'ingresos', name: 'Ingreso', formatter: NumberFormatter, resizable: true },
        { key: 'egresos', name: 'Egreso', formatter: NumberFormatter, resizable: true },
    ];
    const {ContextMenuTrigger} = Menu;	// Context Menú Datagrid
    const [rows, setRows] = useState([]);

    // Modal eliminar dato presupuesto
    const [showEliminar, setShowEliminar] = useState(false);
    const [msgEliminar, setMsgEliminar] = useState('');

    // Modal eliminar presupuesto
    const [showEliminarPresupuesto, setShowEliminarPresupuesto] = useState(false);
    const [msgEliminarPresupuesto, setMsgEliminarPresupuesto] = useState('');

    /**
     * Buscamos y cargamos todos los presupuestos del predio
     * Luego cargamos todos los dato presupuestos del presupuesto seleccionado del combobox
     *
     * @param {*} predio
     */
    async function getPresupuestos(predio) {
        let resultado = await Api(`api/presupuestos/${predio}?periodo_id=${contexto.predio.periodo.id}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setPresupuestos(resultado.data.datos);  // Actualiza el combobox presupuestos
            /**
             * Como se ya se cargaron todos los presupuestos en el combobox con setPresupuestos()
             * si fuese el caso que se elimino el presupuesto, este ya no aparecerá en el combobox, por ende la variable cmbxPresupuestos.current.value
             * debería ahora tener automáticamente el valor del primer elemento visible en el combobox
             */
            setPresupuestoSeleccionadoId(cmbxPresupuestos.current.value);
            // Solo si tenemos un presupuestos seleccionado
            if (cmbxPresupuestos.current.value) {
                getDatos(cmbxPresupuestos.current.value);
            } else {
                setRows([]);
                setPresupuestos([]);
                alert.show('No tenemos presupuestos', { type: 'error' });
            }
        } else {
            setPresupuestos([]);
            setRows([]);
            alert.show('Error al obtener información del presupuesto', { type: 'error' });
        }
    }

    /**
     * Cuando cambia el combobox presupuestos
     *
     * @param {*} e
     */
    function changeCmbxPresupuesto(e) {
        getDatos(e.target.value);
        setPresupuestoSeleccionadoId(cmbxPresupuestos.current.value);
    }


    /**
     * Editar el combobox seleccionado
     */
    function editarCmbxPresupuesto() {
        setShowFormPresupuesto(true);
        setPresupuestoId(cmbxPresupuestos.current.value);
    }


    /**
     * Desplegamos modal eliminar junto con información relevante para que esté seguro antes de eliminar
     */
    async function eliminarCmbxPresupuesto() {
        let resultado = await Api(`api/presupuesto/${cmbxPresupuestos.current.value}/comprobar-eliminar`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setMsgEliminarPresupuesto(resultado.data.msg);
            setShowEliminarPresupuesto(true);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
        }
    }

    /**
     * Eliminar presupuesto, cerramos modal eliminar, cargamos todos los presupuestos del predio
     */
    async function funcionRetornoEliminarPresupuesto() {
        let resultado = await Api(`api/presupuesto/${cmbxPresupuestos.current.value}?predio_id=${contexto.predio.predio_id}`, null, {}, true, 'delete');
        if (resultado && resultado.status === 200) {
            setShowEliminarPresupuesto(false);
            getPresupuestos(contexto.predio.predio_id); // Cargamos todos los presupuestos del predio
            // cerrarModal(); //el modal ya está cerrado
            alert.show(resultado.data.msg, { type: 'success' });
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
        }
    }

    /**
     * Busca todos datos presupuestos del predio
     *
     * @param {*} presupuesto_id
     */
    async function getDatos(presupuesto_id) {
        let resultado = await Api(`api/presupuesto/${presupuesto_id}/dato_presupuesto`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
        } else {
            alert.show('Error al obtener información', { type: 'error' });
            setRows([]);
        }
    }

    /**
     * Agregar nuevo presupuesto
     */
    function nuevoPresupuesto() {
        setShowFormPresupuesto(true);
    }

    /**
     * Cuando cerramos el modal, actualizamos el combobox presupuestos, cargamos la información de la grilla, por si hay cambios
     */
    function cerrarModalPresupuesto() {
        setPresupuestoId('');   // No tenemos ningún presupuesto seleccionado, usado al crear/editar/presupuesto
        setShowFormPresupuesto(false);
        getPresupuestos(contexto.predio.predio_id); // Cargamos todos los presupuestos del predio
    }


    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                getPresupuestos(contexto.predio.predio_id);
            }
        },
        [contexto]
    )

    /**
     * Nuevo dato presupuesto
     */
    function nuevoDato() {
        setShowFormDato(true);
        // Para que el formulario este vacio cuando iniciamos, solo lo dejamos como doble refuerzo
        // ya que cuando cerramos o eliminamos ya está limpiando el modal datoPresupuesto
        setDatoPresupuesto('');
    }

    /**
     * Editar fila dato presupuesto
     *
     * @param {*} filaPosicion
     */
    function editRow(filaPosicion) {
        getDatoPrespuesto(rows[filaPosicion].id);
    }

    /**
     * Eliminar fila dato presupuesto
     *
     * @param {*} filaPosicion
     */
    function deleteRow(filaPosicion) {
        getDatoPrespuestoEliminar(rows[filaPosicion].id);
    }

    /**
     * Cargar información del presupuesto
     *
     * @param {*} id
     */
    async function getDatoPrespuesto(datoId) {
        let resultado = await Api(`api/presupuesto/${cmbxPresupuestos.current.value}/dato_presupuesto/${datoId}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setShowFormDato(true);
            setDatoPresupuesto(resultado.data.datos[0]);
        } else {
            alert.show('Error al obtener información', { type: 'error' });
        }
    }

    /**
     * Cargar información del presupuesto
     *
     * @param {*} id
     */
    async function getDatoPrespuestoEliminar(datoId) {
        let resultado = await Api(`api/presupuesto/${cmbxPresupuestos.current.value}/dato_presupuesto/${datoId}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            let data = resultado.data.datos[0]
            setShowEliminar(true);
            setDatoPresupuesto(resultado.data.datos[0]);
            setMsgEliminar(`¿Estás seguro de eliminar?<br><br><b>Cuenta: </b>${data.codigo_cuenta}<br><b>Descripción: </b>${data.nombre_cuenta}<br><b>Mes: </b>${data.codigo_mes} `);
        } else {
            alert.show('Error al obtener información', { type: 'error' });
        }
    }

    /**
     * Eliminar dato presupuesto, cerramos modal eliminar y actualizamos grilla
     */
    async function funcionRetornoEliminarDatoPresupuesto() {
        let resultado = await Api(`api/presupuesto/${cmbxPresupuestos.current.value}/dato_presupuesto/${datoPresupuesto.id}`, null, {}, true, 'delete');
        if (resultado && resultado.status === 200) {
            setShowEliminar(false);
            setDatoPresupuesto('');
            getDatos(cmbxPresupuestos.current.value);
            alert.show(resultado.data.msg, { type: 'success' });
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
        }
    }

    /**
     * Cuando cerramos el modal, actualizamos el combobox presupuestos, cargamos la información de la grilla, por si hay cambios
     */
    function cerrarModalDato() {
        setShowFormDato(false); // Cerramos el modal crear dato presupuesto
        setDatoPresupuesto(''); // Es el presupuesto seleccionado, debemos dejarlo vacio para cuando abramos el modal podamos crear uno nuevo
        getDatos(cmbxPresupuestos.current.value); // Debemos actualizar la grilla por si actualizaron algún dato
    }

    /**
    * Permite exportar la información de la grilla a excel
    */
    function exportarExcel() {
        exportar(`api/exportar/presupuesto/${contexto.predio.predio_id}/actividad?presupuesto_id=${cmbxPresupuestos.current.value}`, alert);
        //exportar(`api/exportar/presupuesto/actividad/${contexto.predio.predio_id}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf(){
        if (presupuestos.length > 0){
            let props = {
                columns:columns,
                rows:rows,
                titulo:"Presupuesto por Actividad",
                bajada:contexto.predio.nombre_predio,
                subtitulo:cmbxPresupuestos.current.selectedOptions[0].label,
                comentario:cmbxPresupuestos.current.selectedOptions[0].getAttribute('comentario')
            }
            alert.show('La descarga comenzará en breve', { type: 'success' });
            new Promise(function(resolve, reject){
                PresupuestoActividadPdf(props).then(
                    function(blob){
                        downloadBlob(blob,
                            `Presupuesto_Actividad_${contexto.predio.nombre_predio.replace(/ /g, '_')}_${cmbxPresupuestos.current.selectedOptions[0].label.replace(/ /g, '_').replace(/\(|\)/g, "")}_${getDMY(fechaExportar)}.pdf`);
                    },function(blob){
                        alert.show('Error al exportar', { type: 'error' })
                })
            })
        }else{
            alert.show('No se puede exportar, no existe presupuesto', { type: 'error' })
        }
    }

    return (
        <Layout title="Presupuesto por Actividad">
            <Form>
                <Form.Row style={{paddingBottom:'10px'}}>
                    <Col md={8}>
                        <Form.Group as={Row} controlId="select_presupuesto">
                            <Col sm="12">
                                <InputGroup>
                                    <InputGroup.Prepend>
                                    {tienePermisos('editar',contexto.predio.perfil) &&
                                        <Button
                                            variant="success"
                                            size="sm"
                                            onClick={nuevoPresupuesto}><FaPlus /> Presupuesto</Button>
                                    }
                                    </InputGroup.Prepend>
                                    <Form.Control ref={cmbxPresupuestos} as="select" size="sm" onChange={changeCmbxPresupuesto}>
                                        {listaPresupuestos}
                                    </Form.Control>
                                    <InputGroup.Append>
                                        {tienePermisos('editar',contexto.predio.perfil) &&
                                            <Button variant="warning" size="sm" onClick={editarCmbxPresupuesto}><FaPen /></Button>
                                        }
                                        {tienePermisos('eliminar',contexto.predio.perfil) &&
                                            <Button variant="danger" size="sm" onClick={eliminarCmbxPresupuesto}><FaTrashAlt /></Button>
                                        }
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <ButtonGroup className="float-right">
                            {tienePermisos('editar', contexto.predio.perfil) &&
                                <Button
                                    variant="success"
                                    size="sm"
                                    onClick={nuevoDato}><FaPlus /> Agregar dato</Button>
                            }
                            <DefaultButtons
                                exportar={exportarExcel}/>
                            <DefaultButtons
                                imprimir={exportarPdf}/>
                        </ButtonGroup>
                    </Col>
                </Form.Row>
            </Form>
            <div>
                <BaseGrid
                    columns={columns}
                    rowGetter={i => rows[i]}
                    rowsCount={rows.length}
                    minHeight={400}
                    contextMenu={
                        <GridMenu
                            id="pac_2"
                            options={ [
                                {texto:"Editar",
                                funcion: (e, { rowIdx }) => editRow(rowIdx)},
                                {texto:"Eliminar",
                                funcion: (e, { rowIdx }) => deleteRow(rowIdx)}
                            ]}
                        />
                    }
                    RowsContainer={ContextMenuTrigger}
                    onGridSort={(sortColumn, sortDirection) =>
                        setRows(sortRows(rows, sortColumn, sortDirection))
                    }
                />
            </div>

            <FormPresupuesto
                show={showFormPresupuesto}
                presupuestoid={presupuestoId}
                onHide={cerrarModalPresupuesto} />
            <FormDatoPresupuesto
                show={showFormDato}
                presupuestoseleccionadoid={presupuestoSeleccionadoId}
                presupuesto={datoPresupuesto}
                onHide={cerrarModalDato} />
            <ModalEliminar titulo="Eliminar" show={showEliminar} setShow={setShowEliminar} confirmar={funcionRetornoEliminarDatoPresupuesto}>
                {msgEliminar}
            </ModalEliminar>
            <ModalEliminar titulo="Eliminar" show={showEliminarPresupuesto} setShow={setShowEliminarPresupuesto} confirmar={funcionRetornoEliminarPresupuesto}>
                {msgEliminarPresupuesto}
            </ModalEliminar>
        </Layout>
    );
}

export default PresupuestoActividad;