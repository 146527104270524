import React, { useState, useEffect, useRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Button, InputGroup, Form } from 'react-bootstrap';
import { FaUserTie } from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import {Api} from '../../utils/api';
import { ErrorMessage } from 'react-hook-form';

function SelectorUsuariosPredio(props) {
    const refSelectorUsuarios= useRef();
    const [usuariosDisponibles, setUsuariosDisponibles] = useState([]);
    const [usuarioSeleccionado, setUsuarioSeleccionado] = useState('');

    useEffect(
        () => {
            if (props.predioid) {
                obtenerUsuarios(props.predioid);
            }
        },
        [props.predioid]
    )

    useEffect(
        () => {
            if (props.limpiaSelector===true){
                // index de la opcion seleccionada entre las opciones disponibles segun id de usuario
                //let removeIndex = usuariosDisponibles.map(function(item) { return item.id; }).indexOf(refSelectorUsuarios.current.getInstance().state.selected[0].id);
                //usuariosDisponibles.splice(removeIndex,1);
                //setUsuariosDisponibles([...usuariosDisponibles]);
                setUsuarioSeleccionado('');
                refSelectorUsuarios.current.getInstance().clear();
            }
        },
        [props.limpiaSelector]
    )

    /**
     * Cargamos la información de los usuariosDisponibles
     *
     * @param {string} [predioId]
     */
    async function obtenerUsuarios(predioId) {
        let resultado = await Api(`api/administracion/predios/${predioId}/usuarios_disponibles`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setUsuariosDisponibles(resultado.data.datos);
        } else {
            setUsuariosDisponibles([]);
        }
    }

    /**
     * Cada vez que cambia el selector usuariosDisponibles
     *
     * @param {*} selected
     */
    function cambiarSelector(selected) {
        if (selected[0] !== undefined) {
            setUsuarioSeleccionado(JSON.stringify(selected[0]));
            //
        } else {
            setUsuarioSeleccionado('');
        }
    }

    return (
        <>
            <InputGroup size="sm">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1"><FaUserTie /></InputGroup.Text>
                </InputGroup.Prepend>
                <div style={{ width: 'calc(100% - 64px)', height:"150%" }}>
                    <Typeahead
                        {...props}
                        bsSize='small'
                        options={usuariosDisponibles}
                        highlightOnlyResult={true}
                        selectHintOnEnter={true}
                        flip={true}
                        ref={refSelectorUsuarios}
                        onChange={cambiarSelector}
                        labelKey={(option) => `${option.username}: ${option.nombre_completo}`}
                    />
                </div>
                <InputGroup.Append>
                    <Button
                        variant="outline-secondary"
                        disabled={props.desactivarBotonBorrar ? true : false}
                        onClick={() => {
                            refSelectorUsuarios.current.getInstance().clear();
                            //setUsuariosOculto('');
                        }}>
                        <IoMdClose />
                    </Button>
                </InputGroup.Append>
            </InputGroup>
            {props.register &&
                <Form.Control
                    type="hidden"
                    name={props.name}
                    value={usuarioSeleccionado}
                    onChange={
                        (e) => {
                        }
                    }
                    ref={props.register({
                        required: "Seleccione una opción"
                    })}
                    isInvalid={props.errors[props.name]}
                />
            }

            {props.errors &&
                <ErrorMessage errors={props.errors} name={props.name} as="div" className="invalid-feedback" />
            }
        </>
    );
}

export default SelectorUsuariosPredio;