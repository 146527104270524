import React, {useState, useEffect, useRef} from 'react';
import { useHistory } from "react-router-dom";
import BaseGrid from '../../componentes/grid/BaseGrid'
import { Menu, Editors } from "react-data-grid-addons";
import GridMenu from '../../componentes/grid/GridMenu';
import { sortRows, EmptyRowsView } from '../../componentes/grid/utils';
import { Form, Button, Col, Modal, Row, ButtonGroup} from 'react-bootstrap';
import { useForm, ErrorMessage } from 'react-hook-form';
import { useAlert } from 'react-alert';
import {Api, ObjectToFormdata} from '../../utils/api';
import { FaSave, FaWindowClose, FaPlus } from 'react-icons/fa';
import DefaultButtons from '../../componentes/grid/DefaultButtons';


export function FormularioCooperativa(props){
    const { register, handleSubmit, errors, setValue } = useForm();
    const alert = useAlert();           // Para las notificaciones

    const {predio, funcionRetorno, ...rest} = props;

    const onSubmit = data => {
        enviarFormulario(data);
    }

    useEffect(
        () => {
            if (predio) {
                setValue([
                    {id:predio.id},
                    {nombre: predio.nombre},
                    {nombre_productor:predio.nombre_productor},
                    {total_cuotas:predio.total_cuotas},
                    {mes_inicio:predio.mes_inicio},
                    {tipo_cooperativa:predio.tipo_cooperativa}
                ]);
            }
        },
        [predio]
    )

    async function enviarFormulario(data){
        data = ObjectToFormdata(data);
        let resultado = await Api('api/administracion/cooperativas/guardar', data, { 'Content-Type': 'application/json' }, true, 'post');
		if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            if (props.funcionRetorno) {
                props.funcionRetorno(data);
            }
		} else {
            alert.show(resultado.data.msg, { type: 'error' });
            //props.funcionRetorno(data);
		}
    }

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Col sm="12">
                <Form.Row>
                    <Form.Control type="hidden" name="id" defaultValue={predio.id} ref={register({})}></Form.Control>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label>Nombre Cooperativa</Form.Label>
                        <Form.Control type="text" placeholder="Nombre Cooperativa..." name="nombre" defaultValue={predio.nombre}
                            ref={register({
                                required: "Por favor, ingrese el nombre del predio",
                                minLength: {value: 4,message: "Largo mínimo 4 caracteres"},
                                maxLength: {value: 100, message: "Largo máximmo 100"}
                            })}
                            isInvalid={errors.nombre} size="sm" />
                        <ErrorMessage errors={errors} name="nombre" as="div" className="invalid-feedback" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                        <Form.Label>Nombre Gerente</Form.Label>
                        <Form.Control type="text" placeholder="Nombre Gerente..." name="nombre_productor" defaultValue={predio.nombre_productor}
                            ref={register({
                                required: "Por favor, ingrese el nombre del gerente de la cooperativa",
                                minLength: {value: 4,message: "Largo mínimo 4 caracteres"},
                                maxLength: {value: 100, message: "Largo máximmo 100"}
                            })}
                            isInvalid={errors.nombre_productor} size="sm" />
                        <ErrorMessage errors={errors} name="nombre_productor" as="div" className="invalid-feedback" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                        <Form.Label>Cuotas Participación</Form.Label>
                        <Form.Control type="number" placeholder="Cuotas Participación..." name="total_cuotas"
                            defaultValue={predio.total_cuotas}
                            ref={register({
                                required: "Por favor, ingrese la cantidad de cuotas",
                                min: {value:0.1, message:"Debe ser mayor a 0"}
                            })}
                            isInvalid={errors.total_cuotas} size="sm" />
                        <ErrorMessage errors={errors} name="total_cuotas" as="div" className="invalid-feedback" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom06">
                        <Form.Label>Mes Inicio</Form.Label>
                        <Form.Control as="select" name="mes_inicio"
                            defaultValue={predio.mes_inicio}
                            ref={register({required: "Por favor, ingrese el mes de inicio"})}
                            isInvalid={errors.mes_inicio} size="sm">
                                <option key={"1"} value={"1"}>Enero</option>
                                <option key={"2"} value={"2"}>Febrero</option>
                                <option key={"3"} value={"3"}>Marzo</option>
                                <option key={"4"} value={"4"}>Abril</option>
                                <option key={"5"} value={"5"}>Mayo</option>
                                <option key={"6"} value={"6"}>Junio</option>
                                <option key={"7"} value={"7"}>Julio</option>
                                <option key={"8"} value={"8"}>Agosto</option>
                                <option key={"9"} value={"9"}>Septiembre</option>
                                <option key={"10"} value={"10"}>Octubre</option>
                                <option key={"11"} value={"11"}>Noviembre</option>
                                <option key={"12"} value={"12"}>Diciembre</option>
                        </Form.Control>
                        <ErrorMessage errors={errors} name="mes_inicio" as="div" className="invalid-feedback" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom07">
                        <Form.Label>Tipo</Form.Label>
                        <Form.Control as="select" name="tipo_cooperativa"
                            defaultValue={predio.tipo_cooperativa}
                            ref={register({required: "Por favor, ingrese el tipo de cooperativa"})}
                            isInvalid={errors.tipo_cooperativa} size="sm">
                                <option key={"1"} value={"Agrícola"}>Agrícola</option>
                                <option key={"2"} value={"Campesina"}>Campesina</option>
                        </Form.Control>
                        <ErrorMessage errors={errors} name="tipo_cooperativa" as="div" className="invalid-feedback" />
                    </Form.Group>
                </Form.Row>
            </Col>
            <Button type="submit" size="sm" variant="success" ref={props.fordwardRef} hidden><FaSave/> Guardar</Button>
        </Form>
    )
}

export function ModalFormularioCooperativa(props) {

    const refSubmitButton = useRef();
    const {predio, funcionRetorno, ...rest} = props;
    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Agregar cooperativa
            </Modal.Header>

            <Modal.Body>
                <FormularioCooperativa predio={predio} funcionRetorno={funcionRetorno} fordwardRef={refSubmitButton}></FormularioCooperativa>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.onHide}><FaWindowClose/> Cerrar</Button>
                <Button onClick={(e) => refSubmitButton.current.click()} size="sm" variant="success"><FaSave/> Guardar</Button>
            </Modal.Footer>

        </Modal>

    )
}


export function FormularioCooperados(props) {
    const alert = useAlert();
    const { register, handleSubmit, errors } = useForm()
    const {ContextMenuTrigger} = Menu;
    const [activarModalAgregarCooperado, setActivarModalCooperado] = useState(false);			// Modal para agregar cooperado
    const [cooperadoForm, setCooperadoForm] = useState(
		{
			"id":"",
			"nombre_socio": "",
			"predio_asociado":"",
            "cuotas_participacion":"",
            "tipo_socio":""
		});
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [cuotasAsignadas, setCuotasAsignadas] = useState(0);
    var history = useHistory();
    const {cooperativa, botonVolver} = props;

    useEffect(
        () => {
            if (cooperativa.id !== null) {
                initGrid()
            }
        },
        [cooperativa]
    )

    /** Para resolver problema cuando presionan el tab las colunas no se ven al 100%, luego de presionar el tab se cargan las columnas
     * ya que si no toma el ancho cuando está oculto el tab
     */
    useEffect(
        () => {
            if (props.tabSeleccionado === 'permisos_acceso') {
                setColumns([
                    { key: 'nombre_socio', name: 'Socio', sortable: true, width: 200 },
                    { key: 'predio_asociado', name: 'Predio', sortable: true },
                    { key: 'cuotas_participacion', name: 'Cuotas Participación', sortable: true, width: 200 },
                    { key: 'tipo_socio', name: 'Tipo', sortable: true, width: 200 },
                ]);
            } else {
                setColumns([]);
            }
        },
        [props.tabSeleccionado]
    )

    useEffect(
        () => {
            let cuotasAsignadasAux = 0;
            rows.forEach(row => {
                cuotasAsignadasAux += parseInt(row.cuotas_participacion);
            });
            setCuotasAsignadas(cuotasAsignadasAux);
        },
        [rows]
    )

    const agregaCooperado = data => {
        let nuevoUsuario = JSON.parse(data.usuario_predio);
        let indexNewRow = rows.map(function(item) { return item.username; }).indexOf(nuevoUsuario.username);
        if (indexNewRow > -1) {
            alert.show("El usuario ya está asociado a la cooperativa", { type: 'error' });
        }
        else{
            let newRow = {...nuevoUsuario, ...{perfil:data.perfil, usuario_id:nuevoUsuario.id}}
            setRows([...rows, ...[newRow]]);
        }
    }

    async function enviarGrilla(data){
        let rowsEnvio = [];
        let cuotas_cooperados = 0;
        if (data.length<5){
            alert.show("Está ingresando menos de 5 cooperados", { type: 'info' });
        }
        data.forEach(row => {
            rowsEnvio.push(JSON.stringify(row))
            cuotas_cooperados += parseInt(row.cuotas_participacion);
        });
        if (cuotas_cooperados > cooperativa.total_cuotas) {
            alert.show("Las cuotas de participación superan el total de cuotas de la cooperativa", { type: 'error' });
            return;
        }
        rowsEnvio = ObjectToFormdata(rowsEnvio);
        let resultado = await Api(`api/administracion/cooperativas/${cooperativa.id}/cooperados`, rowsEnvio, { 'Content-Type': 'application/json' }, true, 'put');
		if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            //props.funcionRetorno(data);
		} else {
            alert.show(resultado.data.msg, { type: 'error' });
            //props.funcionRetorno(data);
		}
    }

    async function initGrid(){
        if (cooperativa.id!==null) {
            let resultado = await Api(`api/administracion/cooperativas/${cooperativa.id}/cooperados`, null, {}, true);
            if (resultado && resultado.status === 200) {
                setRows(resultado.data.datos);
            } else {
                alert.show('Error al cargar cooperados asociados', { type: 'error' });
                setRows([]);
            }
        }
    }

    function onGridRowsUpdated({ fromRow, toRow, updated }) {
        const newRows = rows.slice();
        for (let i = fromRow; i <= toRow; i++) {
            newRows[i] = { ...newRows[i], ...updated };
        }
        setRows(newRows);
    };

    /**
	 * Cuando queremos editar un cooperado, desde el context menú
	 *
	 * @param {integer} rowIdx
	 */
	const editRow = rowIdx => {
            setCooperadoForm({
                id: rows[rowIdx].id,
                nombre_socio: rows[rowIdx].nombre_socio,
                predio_asociado: rows[rowIdx].predio_asociado,
                cuotas_participacion: rows[rowIdx].cuotas_participacion,
                tipo_socio: rows[rowIdx].tipo_socio,
                rowIdx: rowIdx
            });
            setActivarModalCooperado(true);
	};

    /**
	 * Cuando queremos elimininar un cooperado de la grilla
	 *
	 * @param {*} rowIdx
	 */
	const deleteRow = rowIdx => {
        rows.splice(rowIdx,1);
        setRows([...rows]);
    };

    /**
	 * Evento para cerrar el modal agregar cooperado
	 */
	function cerrarModalAgregar() {
		//initGrid();
		setActivarModalCooperado(false);
	}


	/**
	 * Permite enviar el formulario para crear un nuevo predio
	 */
	async function agregarCooperado(data) {
        cerrarModalAgregar();
        if (data.rowIdx>=0){
            rows[data.rowIdx].nombre_socio = data.nombre_socio;
            rows[data.rowIdx].predio_asociado = data.predio_asociado;
            rows[data.rowIdx].cuotas_participacion = data.cuotas_participacion;
            rows[data.rowIdx].tipo_socio = data.tipo_socio;
            setRows([...rows]);
        }
        else{
            let newRow = {...data}
            setRows([...rows, ...[newRow]]);
        }
    }
    
    /**
	 * Acción para desplegar modal agregar predio
	 */
	function nuevoDato() {
		setCooperadoForm({
			"id":"",
			"nombre_socio": "",
			"predio_asociado":"",
            "cuotas_participacion":"",
            "tipo_socio":""
		});
		setActivarModalCooperado(true);
	}


    return (
        <>
            Cooperados asociados a la cooperativa: <b>{cooperativa.nombre}</b><br/>
            Cuotas Participación: <b>{cooperativa.total_cuotas}</b> <br/>
            Cuotas asignadas: <b>{cuotasAsignadas}</b> <br/><br/>
            <Row>
                <Col>
                    <ButtonGroup className="float-right">
                        <Button variant="primary" size="sm" onClick={nuevoDato}>Agregar Cooperado</Button>
                        <DefaultButtons />
                    </ButtonGroup>
                </Col>
            </Row>

            <br></br>
            <Row>
                <Col>
                <div>
                    <BaseGrid
                        columns={columns}
                        rowGetter={i => rows[i]}
                        rowsCount={rows.length}
                        minHeight={250}
                        contextMenu={
                            <GridMenu
                                id="gridCooperados"
                                options={ [
                                    {texto:"Editar",
                                    funcion: (e, { rowIdx }) => editRow(rowIdx)},
                                    {texto:"Eliminar",
                                    funcion: (e, { rowIdx }) => deleteRow(rowIdx)}
                                ]}
                            />
                        }
                        RowsContainer={ContextMenuTrigger}
                        onGridSort={(sortColumn, sortDirection) =>
                            setRows(sortRows(rows, sortColumn, sortDirection))
                        }
                        emptyRowsView={EmptyRowsView}
                        enableCellSelect={true}
                        onGridRowsUpdated={(fromRow, toRow, updated) => onGridRowsUpdated(fromRow, toRow, updated)}/>
                </div>
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col>
                    <Button className="float-right" size="md" variant="success" onClick={() => {enviarGrilla(rows)} }><FaSave/> Guardar</Button>
                    { botonVolver===true && 
                        <Button onClick={() => history.push(`/administracion/cooperativas/listado`)} 
                            variant="light" size="md" className="borde-gris float-right"> Volver</Button>
                    }   
                </Col>
                
            </Row>
            <ModalFormularioCooperado
				show={activarModalAgregarCooperado}
				onHide={cerrarModalAgregar} 
				cooperado={cooperadoForm} 
				funcionRetorno={function(data){agregarCooperado(data)}}
				size="md"
			/>
        </>

    )
}

export function ModalFormularioCooperado(props) {
    const { register, handleSubmit, errors, setValue } = useForm();
    const refSubmitButton = useRef();
    const {cooperado, funcionRetorno, ...rest} = props;
    const onSubmit = data => {
        if (cooperado.rowIdx>=0) {
            data.rowIdx = cooperado.rowIdx;
        }
        funcionRetorno(data);
    }
    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Agregar cooperado
            </Modal.Header>

            <Modal.Body>
                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Col sm="12">
                        <Form.Row>
                            <Form.Control type="hidden" name="id" defaultValue={cooperado.id} ref={register({})}></Form.Control>
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>Nombre Socio</Form.Label>
                                <Form.Control type="text" placeholder="Nombre Socio..." name="nombre_socio" defaultValue={cooperado.nombre_socio}
                                    ref={register({
                                        required: "Por favor, ingrese el nombre del socio",
                                        minLength: {value: 4,message: "Largo mínimo 4 caracteres"},
                                        maxLength: {value: 100, message: "Largo máximmo 100"}
                                    })}
                                    isInvalid={errors.nombre_socio} size="sm" />
                                <ErrorMessage errors={errors} name="nombre_socio" as="div" className="invalid-feedback" />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom02">
                                <Form.Label>Predio Asociado</Form.Label>
                                <Form.Control type="text" placeholder="Predio Asociado..." name="predio_asociado" defaultValue={cooperado.predio_asociado}
                                    ref={register({})}
                                    isInvalid={errors.predio_asociado} size="sm" />
                                <ErrorMessage errors={errors} name="predio_asociado" as="div" className="invalid-feedback" />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                                <Form.Label>Cuotas Participación</Form.Label>
                                <Form.Control type="number" placeholder="Cuotas Participación..." name="cuotas_participacion"
                                    defaultValue={cooperado.cuotas_participacion}
                                    ref={register({
                                        required: "Por favor, ingrese la cantidad de cuotas",
                                        min: {value:0.1, message:"Debe ser mayor a 0"}
                                    })}
                                    isInvalid={errors.cuotas_participacion} size="sm" />
                                <ErrorMessage errors={errors} name="cuotas_participacion" as="div" className="invalid-feedback" />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom04">
                                <Form.Label>Tipo Socio</Form.Label>
                                <Form.Control as="select" name="tipo_socio"
                                    defaultValue={cooperado.tipo_socio}
                                    ref={register({})}
                                    isInvalid={errors.tipo_socio} size="sm">
                                        <option key={"1"} value={"Vendedor"}>Vendedor</option>
                                        <option key={"2"} value={"Comprador"}>Comprador</option>
                                        <option key={"3"} value={"Inversor"}>Inversor</option>
                                        <option key={"4"} value={"Vendedor y Comprador"}>Vendedor y Comprador</option>
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                    </Col>
                    <Button type="submit" size="sm" variant="success" ref={refSubmitButton} hidden><FaSave/> Guardar</Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.onHide}><FaWindowClose/> Cerrar</Button>
                <Button onClick={(e) => refSubmitButton.current.click()} size="sm" variant="success"><FaSave/> Guardar</Button>
            </Modal.Footer>

        </Modal>

    )
}