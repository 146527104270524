import React, { useState, useEffect, useContext } from 'react';
import { useAlert } from 'react-alert';
import BaseGrid from '../../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import Layout from '../../../componentes/Layout';
import { NumberFormatter, sortRows, EmptyRowsView, DateFormatter, RightAlign, zeroFill } from '../../../componentes/grid/utils';
import { Api } from '../../../utils/api';
import UserContext from '../../../UserContext';
import { Row, Col, Form, ButtonGroup } from 'react-bootstrap';
import Buscador from '../../../componentes/Buscador';
import Fecha from '../../../componentes/Fecha';
import { fechaHoy, getYMD, getDMY} from '../../../utils/Functions';
import SelectorActividad from '../../../componentes/SelectorActividad';
import SelectorCuenta from '../../../componentes/SelectorCuenta';
import { exportar, downloadBlob } from '../../../utils/Functions';
import DefaultButtons from '../../../componentes/grid/DefaultButtons';
import { LibroMayorPdf } from './pdf/LibroMayorPdf';

function LibroMayor(props) {

    const { ContextMenuTrigger } = Menu
    const alert = useAlert();

    const columns = [
        { key: 'documento_fecha', name: 'Fecha', width: 100, formatter: DateFormatter, sortable: true },
        { key: 'documento_folio', name: 'Folio', width: 80 },
        { key: 'glosa', name: 'Glosa' },
        { key: 'movimiento_cantidad', name: 'Cantidad', width: 100, formatter: NumberFormatter },
        { key: 'movimiento_unidad', name: 'Unidad', width: 100 },
        { key: 'precio_unidad', name: 'Precio/Unidad', width: 100, formatter: NumberFormatter },
        { key: 'debito', name: 'Débito', width: 100, formatter: NumberFormatter },
        { key: 'credito', name: 'Crédito', width: 100, formatter: NumberFormatter }
    ];
    const columnsTotales = [
        { key: 'titulo', name: '', formatter: RightAlign },
        { key: 'total_cantidad', name: 'Cantidad', width: 100, formatter: NumberFormatter },
        { key: 'unidad', name: 'Unidad', width: 100 },
        { key: 'total_precio_unidad', name: 'Precio/Unidad', width: 100, formatter: NumberFormatter },
        { key: 'total_debito', name: 'Débito', width: 100, formatter: NumberFormatter },
        { key: 'total_credito', name: 'Crédito', width: 100, formatter: NumberFormatter },
    ];

    const [rows, setRows] = useState([]);
    const [rowsTotales, setRowsTotales] = useState([]);
    const contexto = useContext(UserContext);
    const [codigoActividad, setCodigoActividad] = useState('');
    const [actividad, setActividad] = useState('');
    const [codigoCuenta, setCodigoCuenta] = useState('');
    const [cuenta, setCuenta] = useState('');
    const [fechaInicio, setFechaInicio] = useState(fechaHoy());
    const [fechaCierre, setFechaCierre] = useState(fechaHoy());
    const [textoBuscar, setTextoBuscar] = useState('');
    const [dataCuenta, setDataCuenta] = useState([]);
    const [codigoCuentaSeleccionar, setCodigoCuentaSeleccionar] = useState('');
    const [fechaExportar] = useState(fechaHoy());

    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                let inicio = (contexto.predio.periodo ? contexto.predio.periodo.inicio : '');
                let cierre = (contexto.predio.periodo ? contexto.predio.periodo.cierre : '');
                setDataCuenta([]);
                setCodigoActividad('');
                setActividad('');
                setCodigoCuentaSeleccionar('limpiar');
                setRows([]);
                setRowsTotales([]);

                setFechaInicio(inicio);
                setFechaCierre(cierre);
            }
        },
        [contexto]
    )

    /**
     * Búsqueda de los movimientos filtrados por actividad y cuenta
     *
     * @param {string} predio
     * @param {date} inicio
     * @param {date} cierre
     * @param {string} [texto='']
     * @param {string} [codigoCuenta='']
     * @param {string} [codigoActividad='']
     * @returns
     */
    async function obtenerDatos(predio, inicio, cierre, texto = '', codigoCuenta = '', codigoActividad = '') {
        if (codigoCuenta === '') {
            alert.show('Debe seleccionar una cuenta', { type: 'error' });
            return false;
        } else if (codigoActividad === '') {
            alert.show('Debe seleccionar una actividad', { type: 'error' });
            return false;
        }
        let resultado = await Api(`api/contabilidad/libro-mayor?predio_id=${predio}&inicio=${inicio}&cierre=${cierre}&texto=${texto}&codigo_cuenta=${codigoCuenta}&codigo_actividad=${codigoActividad}`);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
            setRowsTotales(resultado.data.totales);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            setRows([]);
            setRowsTotales([]);
        }
    }

    /**
     * Cuando escriben en el buscador
     *
     * @param {string} texto
     */
    async function retornoBusqueda(texto) {
        obtenerDatos(contexto.predio.predio_id, fechaInicio, fechaCierre, texto, codigoCuenta, codigoActividad);
        setTextoBuscar(texto);
    }

    /**
     * Cuando cambian la fecha inicio
     *
     * @param {date} e
     */
    function onChangeInicio(e) {
        let fecha = getYMD(e);
        setFechaInicio(fecha);
        obtenerDatos(contexto.predio.predio_id, fecha, fechaCierre, textoBuscar, codigoCuenta, codigoActividad);
    }

    /**
     * Cuando cambian la fecha cierre
     *
     * @param {date} e
     */
    function onChangeCierre(e) {
        let fecha = getYMD(e);
        setFechaCierre(fecha);
        obtenerDatos(contexto.predio.predio_id, fechaInicio, fecha, textoBuscar, codigoCuenta, codigoActividad);
    }

    /**
     * SE activa luego de que seleccionan un elemento en el selector cuenta
     *
     * @param {array} data
     */
    function retornarSelectorCuenta(data) {
        if (data.length > 0) {
            if (data[0].codigo !== codigoCuenta) {
                obtenerDatos(contexto.predio.predio_id, fechaInicio, fechaCierre, textoBuscar, data[0].codigo, codigoActividad);
                setCodigoCuenta(data[0].codigo);
                setCuenta(data[0]);
            }
        } else {
            setCodigoCuenta('');
            setCuenta('');
            setRows([]);
            setRowsTotales([]);
        }
    }

    /**
     * Se activa luego de que seleccionan un elemento en el selector actividad
     *
     * @param {array} data
     */
    function returnSelectorActividad(data) {
        if (data.length > 0) {
            if (data[0].codigo !== codigoActividad) {
                setCodigoActividad(data[0].codigo);
                setActividad(data[0]);
                cargarCuentasMovimiento(data[0].id);
            }
        } else {
            setDataCuenta([]);
            setCodigoActividad('');
            setActividad('');
            setCodigoCuentaSeleccionar('limpiar');
            setRows([]);
            setRowsTotales([]);
        }
    }

    /**
     * Búsqueda de todas las cuentas que han sido utilizadas en movimientos y que tienen la actividad seleccionada
     *
     * @param {string} texto
     */
    async function cargarCuentasMovimiento(actividad_id) {
        let resultado = await Api(`api/cuentas-movimiento?predio_id=${contexto.predio.predio_id}&actividad_id=${actividad_id}`);
        if (resultado && resultado.status === 200) {
            if (resultado.data.datos.length > 0) {
                setDataCuenta(resultado.data.datos);
                setCodigoCuentaSeleccionar(resultado.data.datos[0].codigo);
            } else {
                alert.show('No hay cuentas disponibles', { type: 'error' });
                setDataCuenta([]);
                setCodigoCuentaSeleccionar('limpiar');
            }
        } else {
            setDataCuenta([]);
            alert.show(resultado.data.mensaje, { type: 'error' });
        }
    }

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        if(codigoCuenta !=''){
            exportar(`api/exportar/contabilidad/libro-mayor?predio_id=${contexto.predio.predio_id}&inicio=${fechaInicio}&cierre=${fechaCierre}&texto=${textoBuscar}&codigo_cuenta=${codigoCuenta}&codigo_actividad=${codigoActividad}`, alert);
        }else{
            alert.show('Debe seleccionar los filtros', { type: 'error' })
        }
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf() {
        if (actividad.id && cuenta.id && fechaInicio && fechaCierre) {
            let props = {
                columns: columns,
                rows: rows,
                rowsTotales: rowsTotales,
                titulo: 'Libro Mayor',
                subtitulo: `Actividad: ${zeroFill(actividad.codigo, 4)} ${actividad.nombre}, Cuenta: ${cuenta.codigo} ${cuenta.nombre}, Inicio ${fechaInicio}, Cierra ${fechaCierre}`,
                bajada: contexto.predio.nombre_predio
            }
            const sub_formated = `actividad_${zeroFill(actividad.codigo, 4)}_${actividad.nombre.replace(/ /g, '_')}_cuenta_${cuenta.codigo}_${cuenta.nombre}_inicio_${getDMY(fechaInicio).replace(/ /g, '_').replaceAll('/', '-')}_fin_${getDMY(fechaCierre).replace(/ /g, '_').replaceAll('/', '-')}`
            alert.show('La descarga comenzará en breve', { type: 'success' });
            new Promise(function (resolve, reject) {
                LibroMayorPdf(props).then(
                    function (blob) {
                        downloadBlob(blob, `Libro_Mayor_${contexto.predio.nombre_predio.replace(/ /g, '_')}_${sub_formated}_${getDMY(fechaExportar)}.pdf`);
                    }, function (blob) {
                        alert.show('Error al exportar', { type: 'error' })
                    })
            })
        } else {
            alert.show('Debe seleccionar los filtros', { type: 'error' })
        }
    }

    return (
        <Layout title="Libro Mayor">
            <Row>
                <Col sm={12} style={{ paddingTop: '33px' }}>
                    <ButtonGroup className="float-right">
                        <DefaultButtons exportar={exportarExcel} />
                        <DefaultButtons imprimir={exportarPdf} />
                    </ButtonGroup>
                </Col>
            </Row>
            <Row style={{ marginBottom: '10px' }}>
                <Col sm={6} md={4} lg={2}>
                    <Form.Label>Búscador</Form.Label>
                    <Buscador retornoBusqueda={retornoBusqueda} busqueda={textoBuscar} placeholder='Glosa...' />
                </Col>
                <Col sm={6} md={4} lg={3}>
                    <Form.Label>Actividad</Form.Label>
                    <SelectorActividad
                        id='1'
                        name="actividad_codigo"
                        // register={register}
                        // errors={errors}
                        inicial_primero={true}
                        returnSelectorActividad={returnSelectorActividad}
                        solo_predio={true}
                        placeholder=""/>
                </Col>
                <Col sm={6} md={4} lg={3}>
                    <Form.Label>Cuenta</Form.Label>
                    <SelectorCuenta
                        id='1'
                        // codigoMin={"0"}
                        // codigoMax={"599999"}
                        name="codigo_cuenta"
                        // solo_predio={true}
                        no_cargar={true}
                        informacion={dataCuenta}
                        selecionar_valor={codigoCuentaSeleccionar}
                        retornar={retornarSelectorCuenta}
                        // inicial={codigoCuenta}
                    />
                </Col>
                <Col sm={6} md={4} lg={2} id="cont-fecha-desde">
                    <Form.Label>Desde</Form.Label>
                    <Fecha
                        name='inicio'
                        dateFormat='dd/MM/yyyy' // dd/MM/Y h:mm aa - dd/MM/Y - MM/Y
                        dateFormatOculto='y-m-d' // y-m-d h:i:s
                        inicial={contexto.predio.periodo ? contexto.predio.periodo.inicio : fechaHoy()}
                        onChange={onChangeInicio}
                        // disabled={true}
                        // desactivarBotonBorrar={true}
                        />
                </Col>
                <Col sm={6} md={4} lg={2} id="cont-fecha-hasta">
                    <Form.Label>Hasta</Form.Label>
                    <Fecha
                        name='cierre'
                        dateFormat='dd/MM/yyyy' // dd/MM/Y h:mm aa - dd/MM/Y - MM/Y
                        dateFormatOculto='y-m-d' // y-m-d h:i:s
                        inicial={contexto.predio.periodo ? contexto.predio.periodo.cierre : fechaHoy()}
                        onChange={onChangeCierre}
                        // disabled={true}
                        // desactivarBotonBorrar={true}
                         />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <BaseGrid
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            minHeight={400}
                            RowsContainer={ContextMenuTrigger}
                            onGridSort={(sortColumn, sortDirection) =>
                                setRows(sortRows(rows, sortColumn, sortDirection))
                            }
                            rowStyle={[{
                                columna: 'glosa',
                                valor: '--- SALDO INICIAL ---',
                                clases: 'titulo-grilla',
                                css: { color: 'black', fontWeight: '700' }
                            }]}
                            emptyRowsView={EmptyRowsView}
                            showCountRows={false} />
                    </div>
                    <div>
                        <BaseGrid
                            columns={columnsTotales}
                            rowGetter={i => rowsTotales[i]}
                            rowsCount={rowsTotales.length}
                            minHeight={141}
                            showCountRows={false}
                            rowStyle={[{
                                columna: 'agregar_titulo',
                                valor: '',
                                clases: '',
                                css: { color: 'black', fontWeight: '700' }
                            }]} />
                    </div>
                </Col>
            </Row>
        </Layout>
    );
}

export default LibroMayor;