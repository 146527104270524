import { StyleSheet } from '@react-pdf/renderer';

export function BaseStylesPdf(props) {

return (StyleSheet.create({
    // Base
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    page: {
      flexDirection: 'row',
      backgroundColor: '#FFFFFF'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    titulo: {
      fontSize: 24,
      textAlign: 'center'
    },
    bajada: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 40,
    },
    subtitulo: {
      fontSize: 14,
      margin: 4
    },
    comentario: {
      fontSize: 12,
      margin: 4
    },
    texto: {
      margin: 12,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman'
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    // Tablas
    tableHeader: {
        backgroundColor: "#F9F9F9",
        fontSize: 12,
        padding: '2px'
    },
    tableHeader10: {
        backgroundColor: "#F9F9F9",
        fontSize: 10,
        padding: '2px'
    },
    tableCellTexto:{
      textAlign:"center", fontSize:12
    },
    left14: {
      textAlign: "left", fontSize: 14
    },
    right14: {
      textAlign: "right", fontSize: 14
    },
    center14: {
      textAlign: "center", fontSize: 14
    },
    left13: {
      textAlign: "left", fontSize: 13
    },
    right13: {
      textAlign: "right", fontSize: 13
    },
    center13: {
      textAlign: "center", fontSize: 13
    },
    textLeft: {
      textAlign: "left", fontSize: 12, padding: '2px'
    },
    textRigh: {
      textAlign: "right", fontSize: 12, padding: '2px'
    },
    textCenter: {
      textAlign: "center", fontSize: 12, padding: '2px'
    },
    tableCellNum:{
      textAlign:"right", fontSize:12
    }
  }))
}