import React, { useState } from 'react'
import { Modal, Button, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
// import { CCol, CRow, CButton, CModal, CModalBody, CModalHeader, CModalFooter } from '@coreui/react-pro'
import { FaTimes, FaRegFileExcel} from 'react-icons/fa';
import CargarArchivoExcel from './CargarArchivoExcel'
// import FiltroAreaProtegidaTH from '../componentes/FiltroAreaProtegidaTH'
import Fecha from '../../componentes/Fecha';
import { fechaHoy, exportar } from '../../utils/Functions';
import { useForm } from 'react-hook-form';
import DefaultButtons from '../../componentes/grid/DefaultButtons'
import {useAlert} from 'react-alert';

function FormExcel(props){
    const [fecha, setFecha] = useState(fechaHoy);
    const alert = useAlert();			// Para las notificaciones

    function exportarPlantilla() {
        //exportar(`api/exportar/contabilidad/documentos?predio_id=${contexto.predio.predio_id}&texto=${textoBuscar}`, alert);
        exportar(`api/exportar/contabilidad/movimientos-plantilla?predio_id=${props.contexto.predio.predio_id}`, alert);
        console.log(props.contexto.predio.predio_id);
    }

    return (
        <>
            <Modal show={props.show} onHide={props.close} autoFocus={true} enforceFocus={true} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Instrucciones para cargar el archivo</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <ol>
                        <li>
                            <span>El nombre de cada columna debe ser <b>exactamente</b> el siguiente:</span>
                            <ul>
                                <li>Fecha, Folio, Número de Cheque, Tipo, Tipo Desc., Rut, Cod. Actividad, Actividad,
                                    Glosa, Cod. Cta. Provee, Cuenta Provee, Cod. Cta. Recibe, Cuenta Recibe, Unidad,
                                    Cantidad, Monto Total Neto, Impuestos registrados en la plataforma, Monto Total Bruto, Esp. Recup,
                                    Esp. No Recup.
                                </li>
                                <li>
                                    Recuerde que deberá existir una columna por cada impuesto registrado en la plataforma.
                                    Las columnas de impuestos se deberán ubicar entre "Monto Total Neto" y "Monto Total Bruto".
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span> Para obtener el formato del excel actualizado descargue la plantilla:
                                    <OverlayTrigger
                                        key={'excel'}
                                        placement={'top'}
                                        overlay={
                                            <Tooltip>
                                                 Descargar plantilla
                                            </Tooltip>
                                        }>
                                        <Button
                                            variant="outline-secondary"
                                            size="sm"
                                            onClick={exportarPlantilla}>
                                                <FaRegFileExcel />
                                                 Descargar Plantilla
                                        </Button>
                                    </OverlayTrigger>
                                    {/* <DefaultButtons exportar={exportarPlantilla}/> */}
                            </span>
                        </li>
                    </ol>

                    <Row>
                        <Col>
                            <CargarArchivoExcel
                                fecha={fecha}
                                retorno={(archivo) => {
                                    props.close()
                                }}
                            />
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Button color="light" onClick={props.close}>
                        <FaTimes /> Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default FormExcel