import React from "react";
import { Card, Button, Container, Row, Col, Form } from 'react-bootstrap';
import styles from '../styles/inicio.module.css'
import styles_image from './index.module.css'
import { Api } from '../../../utils/api'
import ReactPlayer from 'react-player'
import { useAlert } from 'react-alert';

function CardExample(props) {
  const alert = useAlert()
  const style_boton = {
    background: 'linear-gradient(90.21deg, #0D84A8 -7.85%, #20B099 121.12%)',
    borderRadius: 100,
    marginTop: 50,
    marginBottom: 30,
    width: 157,
    color: 'white'
  };

  const onSubmit = async (data, e) => {

    let resultado = await Api('api/email', data, {}, false, 'post');
    if (resultado && resultado.status === 200) {
      alert.show('Solicitud enviada con éxito', { type: 'success' });
    } else {
      console.log('viene un error');
    }
    e.target.reset()
  }

  return (
    <>
      <Container id={props.id} fluid>
        <Card className={styles_image.style_image}>
          <Row className="justify-content-md-center row center">
            <Col lg='4' >
              <Card className="border-0" style={{ backgroundColor: 'transparent' }}>
                <Card.Body >
                </Card.Body>
              </Card>
            </Col>
            <Col lg='4'>
              <Card className="border-0" style={{ backgroundColor: 'transparent', alignItems: 'center' }}>
                <Card.Body style={{ maxWidth: 500 }}>
                  <ReactPlayer
                    url={require('../video/inicio.mp4')}
                    height='100%'
                    width='100%'
                    controls='true'
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col lg='4'>
              <Card className="border-0" style={{ backgroundColor: 'transparent' }} >
                <Card.Body >
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-md-center" style={{ marginTop: 50, marginBottom: 50 }}>
            <Col xs lg="8" style={{ textAlign: 'center' }} className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card className="border-0" >
                <Card.Body >
                  <Card.Title className={styles.style_titulo_descriptivo}>Solicitar Cuenta</Card.Title>
                  <Card.Text className={styles.style_texto} >
                    Usted podrá planificar, controlar y evaluar permanentemente la empresa a partir de un presupuesto y de un control de gestión.
                  </Card.Text>
                  <Form onSubmit={props.handleSubmit(onSubmit)}>
                    <Row lg="2" md="1" sm="1" xs="1">
                      <Col>
                        <Form.Control name="nombre" placeholder="Nombre"
                          ref={
                            props.register({
                              required: { value: true, message: "Nombre obligatorio" }
                            })
                          }
                        />
                      </Col>
                      <Col className={styles.style_email}>
                        <Form.Control name="email" type="email" placeholder="Email"
                          ref={
                            props.register({
                              required: { value: true, message: "correo obligatorio" }
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ marginTop: 15, width: '100%' }}>
                        <Form.Control name="descripcion" as="textarea" placeholder="Descripción..."
                          ref={
                            props.register({
                              required: { value: true, message: "descripcion obligatorio" }
                            })
                          }
                        />
                      </Col>
                    </Row>

                    <Button variant="" style={style_boton} type="submit">Solicitar</Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
}

export default CardExample;