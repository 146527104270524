import React, { useState, useEffect, useContext } from 'react';
import { useAlert } from 'react-alert';
import BaseGrid from '../../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import Layout from '../../../componentes/Layout';
import { NumberFormatter, sortRows, EmptyRowsView, zeroFill } from '../../../componentes/grid/utils';
import { Api } from '../../../utils/api';
import UserContext from '../../../UserContext';
import { Row, Col, Form, ButtonGroup, Button } from 'react-bootstrap';
import Fecha from '../../../componentes/Fecha';
import { getYMD, fechaHoy, getDMY} from '../../../utils/Functions';
import SelectorActividad from '../../../componentes/SelectorActividad';
import { exportar, downloadBlob } from '../../../utils/Functions';
import DefaultButtons from '../../../componentes/grid/DefaultButtons';
import { GestionPorActividadPdf } from './pdf/GestionPorActividadPdf';
import ChartGestionPorActividad from './ChartGestionPorActividad';


function GestionPorActividad(props) {
    const contexto = useContext(UserContext);

    const { ContextMenuTrigger } = Menu
    const alert = useAlert();
    const [rows, setRows] = useState([]);
    const [rowsTotales, setRowsTotales] = useState([]);
    const [fechaExportar] = useState(fechaHoy());
    const [showChart, setShowChart] = useState(false);
    const columns = [
        { key: 'nombre', name: 'Cuenta'},
        { key: 'unidad', name: 'Unidad',  width: 70 },
        { key: 'cantidad_presupuesto', name: 'Cantidad', width: 100, formatter: NumberFormatter },
        { key: 'total_presupuesto', name: 'Valor', width: 100, formatter: NumberFormatter },
        { key: 'total_unidad_presupuesto', name: 'Val/Unidad', width: 100, formatter: NumberFormatter },
        { key: 'cantidad_operacion', name: 'Cantidad', width: 100, formatter: NumberFormatter },
        { key: 'gasto_operacion', name: 'Gastos', width: 100, formatter: NumberFormatter },
        { key: 'ingreso_operacion', name: 'Ingresos', width: 100, formatter: NumberFormatter },
        { key: 'total_unidad_operacion', name: 'Val/Unidad', width: 100, formatter: NumberFormatter },
        { key: 'desviacion', name: 'Desv.', width: 100, formatter: NumberFormatter }
    ];
    const titulos = [
        { key: 'titulo', name: '' },
        { key: 'unidad', name: 'Presupuesto', width: 300 },
        { key: 'cantidad', name: 'Operaciones', width: 500 },
    ];
    const tituloResultados = [
        { key: 'nombre', name: 'Cuenta'},
        { key: 'unidad', name: 'Unidad', width: 70},
        { key: 'cantidad_presupuesto', name: 'Cantidad', width: 100, formatter: NumberFormatter },
        { key: 'total_presupuesto', name: 'Valor', width: 100, formatter: NumberFormatter },
        { key: 'total_unidad_presupuesto', name: 'Val/Unidad', width: 100, formatter: NumberFormatter },
        { key: 'cantidad_operacion', name: 'Cantidad', width: 100, formatter: NumberFormatter },
        { key: 'total_gasto_operacion', name: 'Gastos', width: 100, formatter: NumberFormatter },
        { key: 'total_ingreso_operacion', name: 'Ingresos', width: 100, formatter: NumberFormatter },
        { key: 'total_unidad_operacion', name: 'Val/Unidad', width: 100, formatter: NumberFormatter },
        { key: 'desviacion', name: 'Desv.', width: 100, formatter: NumberFormatter }
    ];
    const [actividad, setActividad] = useState({
        id: '',
        codigo: '',
        nombre: ''
    });
    const [presupuesto, setPresupuesto] = useState({
        cantidad: '',
        unidad_abreviacion: '',
        unidad_nombre: ''
    });
    const [fecha, setFecha] = useState('');

    function chart() {
        setShowChart(true);
    }
    useEffect(
        () => {
            if (contexto.predio.predio_id, contexto.predio.periodo) {
                let inicio = (contexto.predio.periodo ? contexto.predio.periodo.inicio : '');
                let cierre = (contexto.predio.periodo ? contexto.predio.periodo.cierre : '');
                setFecha(cierre);
            }
        },
        [contexto]
    )

    /**
     * Cargar información de la grilla
     */
    async function obtenerDatos(actividad_id = actividad.id, fechaDirecto = fecha) {

        if (fechaDirecto === null || fechaDirecto === '') {
            return false;
        }
        if (actividad_id === '' || fechaDirecto === null) {
            return false;
        }
        let resultado = await Api(`api/contabilidad/gestion-por-actividad?predio_id=${contexto.predio.predio_id}&periodo_id=${contexto.predio.periodo.id}&actividad_id=${actividad_id}&fecha=${fechaDirecto}`);
        if (resultado && resultado.status === 200) {
            setRows(procesoDatos(resultado.data.datos));
            setRowsTotales(resultado.data.resultados);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            setRows([]);
            setRowsTotales([]);
        }
    }

    /**
     * Buscamos el presupuesto de la actividad y el predio seleccionado
     */
    async function obtenerPresupuesto(actividad_id = actividad.id) {
        let resultado = await Api(`api/presupuesto?predio_id=${contexto.predio.predio_id}&periodo_id=${contexto.predio.periodo.id}&actividad_id=${actividad_id}`);
        if (resultado && resultado.status === 200) {
            setPresupuesto(resultado.data);
            if (contexto.predio.periodo) {
                let inicio = (contexto.predio.periodo ? contexto.predio.periodo.inicio : '');
                let cierre = (contexto.predio.periodo ? contexto.predio.periodo.cierre : '');
                if (cierre) {
                    obtenerDatos(actividad_id, cierre);
                } else {
                    alert.show('El periodo no tiene fecha de cierre', { type: 'error' });
                }
            } else {
                alert.show('El presupuesto no tiene fecha hasta', { type: 'error' });
            }
        } else {
            setRows([]);
            setRowsTotales([]);
            alert.show(resultado.data.msg, { type: 'error' });
        }
    }

    /**
     * Crea una nueva columna combinando codigo y nombre de la cuenta
     *
     * @param {array} data
     */
    const procesoDatos = (data) => {
        data.forEach(dat => {
            dat.cuenta_codigo_nombre = `${dat.codigo_cuenta} ${dat.nombre_cuenta}`
        });
        return data;
    }

    /**
     * Se activa luego de que seleccionan un elemento en el selector actividad
     *
     * @param {array} data
     */
    function returnSelectorActividad(data) {
        if (data.length > 0) {
            obtenerPresupuesto(data[0].id);
            setActividad(data[0]);
        } else {
            setActividad({
                id: '',
                codigo: '',
                nombre: ''
            });
            setPresupuesto({
                cantidad: '',
                unidad_abreviacion: '',
                unidad_nombre: ''
            });
            setRows([]);
            setRowsTotales([]);
        }
    }

    /**
     * Cuando cambian la fecha
     *
     * @param {date} e
     */
    function onChangeInicio(e) {
        let fecha = getYMD(e);
        obtenerDatos(actividad.id, fecha);
        setFecha(fecha);
    }

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        exportar(`api/exportar/contabilidad/gestion-por-actividad?predio_id=${contexto.predio.predio_id}&periodo_id=${contexto.predio.periodo.id}&actividad_id=${actividad.id}&fecha=${fecha}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf() {
        if (actividad.id && presupuesto) {
            let props = {
                columns: columns,
                rows: rows,
                rowsTotales: rowsTotales,
                titulo: 'Gestión por Actividad',
                subtitulo: `Actividad: ${zeroFill(actividad.codigo,4)} - ${actividad.nombre}, ${presupuesto.cantidad} ${presupuesto.unidad_abreviacion}`,
                bajada: contexto.predio.nombre_predio
            }
            alert.show('La descarga comenzará en breve', { type: 'success' });
            const sub_formated = `actividad_${zeroFill(actividad.codigo,4)}_${actividad.nombre.replace(/ /g, '_')}_${presupuesto.cantidad}_${presupuesto.unidad_abreviacion}`
            new Promise(function (resolve, reject) {
                GestionPorActividadPdf(props).then(
                    function (blob) {
                        downloadBlob(blob, `Gestión_por_actividad_${contexto.predio.nombre_predio.replace(/ /g, '_')}_${sub_formated}_${getDMY(fechaExportar)}.pdf`);
                    }, function (blob) {
                        alert.show('Error al exportar', { type: 'error' })
                    })
            })
        } else {
            alert.show('Debe seleccionar una actividad', { type: 'error' })
        }
    }

    return (
        <Layout title="Gestión por Actividad">
            <Row style={{ marginBottom: '10px' }}>
                <Col sm={6} md={4} lg={5}>
                    <Form.Label>Actividad</Form.Label>
                    <SelectorActividad
                        id='1'
                        name="actividad_codigo"
                        // register={register}
                        // errors={errors}
                        inicial_primero={true}
                        returnSelectorActividad={returnSelectorActividad}
                        solo_predio={true}
                        desactivarBotonAgregar={true}
                        ocultarBotonAgregar={true}
                        placeholder="" />
                </Col>
                <Col sm={6} md={2} lg={2} style={{ paddingTop: '39px' }}>
                    {(presupuesto ? presupuesto.cantidad : '') + ' ' + (presupuesto ? presupuesto.unidad_abreviacion : '')}
                </Col>
                <Col sm={6} md={4} lg={3} id="cont-fecha-desde">
                    <Form.Label>Hasta</Form.Label>
                    <Fecha
                        name='inicio'
                        dateFormat='dd/MM/yyyy' // dd/MM/Y h:mm aa - dd/MM/Y - MM/Y
                        dateFormatOculto='y-m-d' // y-m-d h:i:s
                        inicial={fecha}
                        onChange={onChangeInicio}
                    // disabled={true}
                    // desactivarBotonBorrar={true}
                    />
                </Col>
                <Col sm={6} md={2} lg={2} style={{ paddingTop: '33px' }}>
                    <ButtonGroup className="float-right">
                        <Button
                            variant="info"
                            size="sm"
                            onClick={chart}>Gráfico</Button>
                        <DefaultButtons exportar={exportarExcel} />
                        <DefaultButtons imprimir={exportarPdf} />
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <BaseGrid
                            columns={titulos}
                            rowGetter={i => rowsTotales[i]}
                            rowsCount={1}
                            minHeight={35}
                            RowsContainer={ContextMenuTrigger}
                            emptyRowsView={EmptyRowsView}
                            showCountRows={false} />
                    </div>
                    <div>
                        <BaseGrid
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            minHeight={400}
                            RowsContainer={ContextMenuTrigger}
                            onGridSort={(sortColumn, sortDirection) =>
                                setRows(sortRows(rows, sortColumn, sortDirection))
                            }
                            rowStyle={[{
                                columna: 'nombre',
                                valor: 'Informe Gastos',
                                clases: 'titulo-grilla',
                                css: { color: 'black', fontWeight: '700' }
                            }, {
                                columna: 'nombre',
                                valor: 'Informe Ingresos',
                                clases: 'titulo-grilla',
                                css: { color: 'black', fontWeight: '700' }
                            }, {
                                columna: 'nombre',
                                valor: 'Diferencias de Inventario',
                                clases: 'titulo-grilla',
                                css: { color: 'black', fontWeight: '700' }
                            }]}
                            emptyRowsView={EmptyRowsView}
                            showCountRows={false} />
                    </div>
                    <div>
                        <BaseGrid
                            columns={tituloResultados}
                            rowGetter={i => rowsTotales[i]}
                            rowsCount={rowsTotales.length}
                            minHeight={177}
                            showCountRows={false}
                            rowStyle={[{
                                columna: 'nombre',
                                valor: '',
                                clases: '',
                                css: { color: 'black', fontWeight: '700' }
                            }]} />
                    </div>
                </Col>
            </Row>
            <ChartGestionPorActividad
                rows={rows}
                show={showChart}
                onHide={() => setShowChart(false)} />
        </Layout>
    );
}

export default GestionPorActividad;