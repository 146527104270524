import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import BaseGrid from '../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import GridMenu from '../../componentes/grid/GridMenu';
import LayoutAdmin from '../../componentes/LayoutAdmin';
import { LongTextFormatter, sortRows, EmptyRowsView, CheckFormatter } from '../../componentes/grid/utils';
import { Api } from '../../utils/api';
import UserContext from '../../UserContext';
import { Button, Row, Col, ButtonGroup } from 'react-bootstrap';
import DefaultButtons from '../../componentes/grid/DefaultButtons'
import Buscador from '../../componentes/Buscador';
import { useAlert } from 'react-alert';
import { ModalFormularioPredio, getPredio, FormularioEliminarPredio } from '../../modulos/administracion/FormularioPredio';


function Predios(props) {
	var history = useHistory();
	const { ContextMenuTrigger } = Menu;
	const [textoBuscar, setTextoBuscar] = useState('');
	const alert = useAlert();			// Para las notificaciones
	const columns = [
		{ key: 'nombre', name: 'Nombre', sortable: true, width: 200, },
		{ key: 'superficie_util', name: 'Superficie Silvoagropecuaria', formatter: LongTextFormatter, sortable: true, width: 210 },
		{ key: 'mano_obra_permanente', name: 'Mano Obra Permanente', sortable: true, width: 180 },
		{ key: 'rol_unico_pecuario', name: 'Rol Único Pecuario', sortable: true, width: 160 },
		{ key: 'nombre_productor', name: 'Nombre Productor', sortable: true, width: 350 },
		{ key: 'usuarios', name: 'Usuarios', sortable: true, formatter: LongTextFormatter, width: 500 },
		{ key: 'habilitada', name: 'Habilitado', formatter: CheckFormatter }
	];
	const [rows, setRows] = useState([]);
	const contexto = useContext(UserContext);
	const [activarModalAgregarPredio, setActivarModalPredio] = useState(false);			// Modal para agregar predio
	const [activarModalEliminarPredio, setActivarModalEliminarPredio] = useState(false);
	const [administradores, setAdministradores] = useState([])			// Modal para eliminar predio
	const [visualizadores, setVisualizadores] = useState([])			// Modal para eliminar predio
	const [predioForm, setPredioForm] = useState(
		{
			"id": "",
			"nombre": "",
			"superficie_util": 0,
			"mano_obra_permanente": 0,
			"rol_unico_pecuario": "",
			"nombre_productor": "",
			"mes_inicio": "1"
		});

	useEffect(
		() => {
			cargarPredios();

		},
		[contexto]
	)

	/**
	 * Buscamos y cargamos todos los predios en la grilla
	 */
	async function cargarPredios() {

		let resultado = await Api('api/administracion/predios', null, {}, true);
		if (resultado && resultado.status === 200) {
			setRows(resultado.data.datos);
		} else {
			alert.show('Error al cargar predios', { type: 'error' });
			setRows([]);
		}
	}

	/**
	 * Función para búsqueda específica de predios
	 *
	 * @param {String} texto
	 */
	async function retornoBusqueda(texto) {
		let resultado = await Api(`api/administracion/predios?texto=${texto}`, null, {}, true);
		if (resultado && resultado.status === 200) {
			setRows(resultado.data.datos);
		} else {
			setRows([])
			alert.show('Error al cargar usuarios', { type: 'error' });
		}
	}

	/**
	 * Acción para desplegar modal agregar predio
	 */
	function nuevoDato() {
		setPredioForm({
			"id": "",
			"nombre": "",
			"superficie_util": 0,
			"mano_obra_permanente": 0,
			"rol_unico_pecuario": "",
			"nombre_productor": "",
			"mes_inicio": "1",
		});
		setActivarModalPredio(true);
	}

	/**
	 * Evento para cerrar el modal agregar predio
	 */
	function cerrarModalAgregar() {
		cargarPredios();
		setActivarModalPredio(false);
		setAdministradores([])
		setVisualizadores([])
	}

	/**
	 * Evento para cerrar el modal eliminar predio
	 */
	function cerrarModalEliminar() {
		cargarPredios();
		setActivarModalEliminarPredio(false);
	}


	/**
	 * Permite enviar el formulario para crear un nuevo predio
	 */
	async function agregarPredio(data) {
		cerrarModalAgregar();
	}

	/**
	 * Cuando queremos editar un predio, desde el context menú
	 *
	 * @param {integer} rowIdx
	 */
	const editRow = rowIdx => {
		const administrador = rows[rowIdx].administrador
		const visualizador = rows[rowIdx].visualizador
		setAdministradores(administrador)
		setVisualizadores(visualizador)
		new Promise(function (resolve, reject) {
			getPredio(rows[rowIdx].id).then(function (predio) {
				setPredioForm(predio);
				setActivarModalPredio(true);
			})
		})
	};


	/**
	 * Cuando queremos elimininar un predio, desde el context menú
	 *
	 * @param {*} rowIdx
	 */
	const deleteRow = rowIdx => {
		new Promise(function (resolve, reject) {
			getPredio(rows[rowIdx].id).then(function (predio) {
				setPredioForm(predio);
				setActivarModalEliminarPredio(true);
			})
		})
	};

	/**
	 * Cuando queremos cambiar los permisos de acceso a un predio, desde el context menú
	 *
	 * @param {integer} rowIdx
	 */
	const permisosAcceso = rowIdx => {
		history.push(`/administracion/predios/permisos-acceso/${rows[rowIdx].id}`)
	};

	/**
	 * Cuando queremos cambiar los roles de un predio, desde el context menú
	 *
	 * @param {integer} rowIdx
	 */
	const rolesPredio = rowIdx => {
		history.push(`/administracion/predios/roles/${rows[rowIdx].id}`)
	};


	return (
		<LayoutAdmin title="Predios">
			<Row style={{ paddingBottom: '10px' }}>
				<Col>
					<Buscador retornoBusqueda={retornoBusqueda} busqueda={textoBuscar} placeholder='Búsqueda de predios...' />
				</Col>
				<Col>
					<ButtonGroup className="float-right">
						<Button variant="success" size="sm" onClick={nuevoDato}>Agregar Predio</Button>
						<DefaultButtons />
					</ButtonGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<div>
						<BaseGrid
							columns={columns}
							rowGetter={i => rows[i]}
							rowsCount={rows.length}
							minHeight={400}
							rowHeight={50}
							contextMenu={
								<GridMenu
									id="pac_1"
									options={[
										{
											texto: 'Editar',
											funcion: (e, { rowIdx }) => editRow(rowIdx)
										},
										{
											texto: 'Eliminar',
											funcion: (e, { rowIdx }) => deleteRow(rowIdx)
										},
										{
											texto: 'Permisos de acceso',
											funcion: (e, { rowIdx }) => permisosAcceso(rowIdx)
										},
										{
											texto: 'Roles del Predio',
											funcion: (e, { rowIdx }) => rolesPredio(rowIdx)
										}
									]}
								/>
							}
							RowsContainer={ContextMenuTrigger}
							onGridSort={(sortColumn, sortDirection) =>
								setRows(sortRows(rows, sortColumn, sortDirection))
							}
							emptyRowsView={EmptyRowsView} />
					</div>
				</Col>
			</Row>
			<ModalFormularioPredio
				show={activarModalAgregarPredio}
				onHide={cerrarModalAgregar}
				predio={predioForm}
				administradores={administradores}
				visualizadores={visualizadores}
				funcionRetorno={function (data) { agregarPredio(data) }}
				size="md"
			/>
			<FormularioEliminarPredio
				show={activarModalEliminarPredio}
				onHide={cerrarModalEliminar}
				predio={predioForm}
				funcionRetorno={function (data) { cerrarModalEliminar() }}
				size="md"
			/>
		</LayoutAdmin>
	);
}

export default Predios;