import React, {useState, useEffect, useContext, useRef} from 'react';
import {Form, Row, Col, Table, Button} from 'react-bootstrap';
import {Api} from '../../../utils/api';
import UserContext from '../../../UserContext';
import { useAlert } from 'react-alert';
import { useForm, ErrorMessage } from 'react-hook-form';
import { validaSize, exportar_archivo } from '../../../utils/Functions';
import { FaFileDownload } from 'react-icons/fa';

function FormAsistencia(props) {
    const alert = useAlert();           // Para las notificaciones
    const contexto = useContext(UserContext);
    
    const [rows, setRows] = useState([]);
    
    const [asistenciaTotal, setAsistenciaTotal] = useState(0);

    useEffect(
        () => {
            getCooperados(contexto.predio.predio_id, props.actaid);
        },
        [props.actaid]
    )


    async function getCooperados(predioId, actaId) {
        let resultado;
        if (actaId) {
            resultado = await Api(`api/contabilidad/cooperativas/${predioId}/cooperados/${actaId}`, null, {}, true);
        }
        else{
            resultado = await Api(`api/contabilidad/cooperativas/${predioId}/cooperados`, null, {}, true);
        }
        
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
            props.setValue("cooperados",JSON.stringify(rows));
            let newAsistenciaTotal = 0;
            resultado.data.datos.forEach(row => {
                if (row.asistencia === true) {
                    newAsistenciaTotal+=1;
                }
            });
            setAsistenciaTotal(newAsistenciaTotal);
        } else {
            setRows([]);
            props.setValue("cooperados",JSON.stringify(rows));
            alert.show(resultado.data.mensaje, { type: 'error' });
        }
    }


    function inputsAsistencia(){
        let filas = [];
        rows.forEach(row => {
            let fila = (
                <tr key={"tr_"+row.id}>
                    <td>{row.nombre_socio}</td>
                    <td>
                        <Form.Control
                            id={"asistencia_"+row.id}
                            name={"asistencia_"+row.id}
                            type="checkbox"
                            size="sm"
                            checked={row.asistencia}
                            onChange={(e) => actualizaAsistencia(row.id,e.target.checked)}
                            ref={props.register({})}
                            isInvalid={props.errors["asistencia_"+row.id]}
                        /><ErrorMessage errors={props.errors} name={"asistencia_"+row.id} as="div" className="invalid-feedback" /></td>
                </tr>
            )
            filas.push(fila)
        });
        filas.push(
            <tr key="total">
                <td>Total</td>
                <td>{asistenciaTotal}</td>
            </tr>
        )
        return filas;
    }

    function actualizaAsistencia(id,asistencia){
        let newAsistenciaTotal = 0;
        console.log(asistencia);
        rows.forEach(row => {
            if (row.id === id) {
                row.asistencia = asistencia;
            }
            if (row.asistencia === true) {
                newAsistenciaTotal+=1;
            }
        });
        props.setValue("cooperados",JSON.stringify(rows));
        setAsistenciaTotal(newAsistenciaTotal);
    }

    return (
        <>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Socio</th>
                        <th>Asistencia</th>
                    </tr>
                </thead>
                <tbody>
                    {inputsAsistencia()}
                </tbody>
            </Table>
            <Form.Group as={Col} md="12" controlId="validationCustom03">
                <Form.Label>Libro de asistencia firmada</Form.Label>
                <Form.Control type="file" accept='.doc, .docx, .pdf, .xls, .xlsx' name="file_asistencia"
                    //defaultValue={acta.archivo}
                    ref={props.register({
                        //required: (props.acta.id) ? "" : "Por favor, ingrese un archivo",
                        validate: (value) => validaSize(value) || "Tamaño máximo 5 Mb"
                    })}
                    isInvalid={props.errors.file} size="sm"
                    onChange={(e) => props.setArchB64Asistencia(e.target.files[0])}/>
                <ErrorMessage errors={props.errors} name="file_asistencia" as="div" className="invalid-feedback" />
            </Form.Group>
            { props.acta.archivo_asistencia &&
                <>
                    <Col md="12">
                        <p>Archivo guardado: {props.acta.archivo_asistencia}</p>
                        <Button size="sm" variant="primary" 
                            onClick={()=>{exportar_archivo('api/uploads/'+props.acta.archivo_asistencia, props.acta.archivo_asistencia);}}>
                                <FaFileDownload /> Descargar
                        </Button>
                    </Col>
                </>
            }
        </>
    );
}

export default FormAsistencia;