import React, {useState, useContext, useEffect} from 'react';
import BaseGrid from '../../componentes/grid/BaseGrid';
import UserContext from '../../UserContext';
import Layout from '../../componentes/Layout.js';
import {NumberFormatter, PercentFormatter, RightAlign} from '../../componentes/grid/utils';
import DefaultButtons from '../../componentes/grid/DefaultButtons'
import {Button, Row, Col, ButtonGroup, Form} from 'react-bootstrap';
import {Api} from '../../utils/api';
import ChartEstructuraCostos from './ChartEstructuraCostos';
import {FaSearch} from 'react-icons/fa';
import { Menu } from "react-data-grid-addons";
import "../../componentes/grid/GridMenu.css";
import EstructuraCostosDetalle from "./EstructuraCostosDetalle";
import { exportar, downloadBlob, getDMY, fechaHoy } from '../../utils/Functions';
import {EstructuraCostosPdf} from './pdf/EstructuraCostosPdf';
import { useAlert } from 'react-alert';

function EstructuraCostos(props) {
    const alert = useAlert();			// Para las notificaciones
    const contexto = useContext(UserContext);
    const [rows, setRows] = useState([]);
    const [rowsTotales, setRowsTotales] = useState([]);
    const [showChart, setShowChart] = useState(false);
    const {ContextMenu, MenuItem, ContextMenuTrigger} = Menu;
    const [showForm, setShowForm] = useState(false);
    const [categoria, setCategoria] = useState({id:70,nombre:'',codigo:''});
    const [fechaExportar] = useState(fechaHoy());

    const columns = [
        {key: 'codigo', name: 'Cuenta', sortable: true},
        {key: 'nombre', name: 'Descripción', width: 400},
        {key: 'datos', name: 'Datos', formatter: NumberFormatter},
        {key: 'total', name: 'Total', formatter: NumberFormatter},
        {key: 'total_ha', name: 'Total / ha', formatter: NumberFormatter},
        {key: 'porcentaje', name: '%', formatter: PercentFormatter},
     ];

     const columnsTotales = [
        {key: 'codigo', name: '', sortable: true},
        {key: 'nombre1', name: '', width: 400},
        {key: 'nombre', name: '', formatter: RightAlign},
        {key: 'total', name: 'Total', formatter: NumberFormatter},
        {key: 'total_ha', name: 'Total / ha', formatter: NumberFormatter},
        {key: '', name: ''},
     ];

    async function getReporte(predio) {
        let resultado = await Api(`api/reportes/presupuesto/estructura_costos/${predio}/${contexto.predio.periodo.id}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
            setRowsTotales(resultado.data.totales);
        } else {
            setRows([]);
            setRowsTotales([]);
            console.log('viene un error');
        }
    }

    function chart() {
        setShowChart(true);
    }

    useEffect(
        () => {
            if (contexto.predio.predio_id, contexto.predio.periodo) {
                getReporte(contexto.predio.predio_id);
            }
        },
        [contexto]
    )

    function onRowClick(rowIdx) {
        setCategoria({
            id:rows[rowIdx].categoria_id,
            nombre:rows[rowIdx].nombre,
            codigo:rows[rowIdx].codigo
        });
    }

    const codigoActions = [
        {
            icon: <span><FaSearch /></span>,
            callback: () => {
                setShowForm(true);
            }
        },
      ];
    function getCellActions(column, row) {
        const cellActions = {
            codigo: codigoActions
        };
        return row.datos > 1 ? cellActions[column.key] : null;
    }

    function DetailContextMenu({
        idx,
        id,
        rowIdx,
        onRowDetail
      }) {
        return (
          <ContextMenu id={id}>
            <MenuItem data={{ rowIdx, idx }} onClick={onRowDetail}>
              Ver Detalle
            </MenuItem>
          </ContextMenu>
        );
    }

    function onRowDetail(rowIdx, idx) {
        setCategoria({
            id:rows[rowIdx].categoria_id,
            nombre:rows[rowIdx].nombre,
            codigo:rows[rowIdx].codigo
        });
        setShowForm(true);
    }

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        exportar(`api/exportar/reportes/presupuesto/estructura_costos/${contexto.predio.predio_id}/${contexto.predio.periodo.id}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf(){
        let props = {
            columns:columns,
            rows:rows,
            columnsTotales: columnsTotales,
            rowsTotales: rowsTotales,
            titulo:"Estructura de Costos",
            bajada:contexto.predio.nombre_predio
        }
        alert.show('La descarga comenzará en breve', { type: 'success' });
        new Promise(function(resolve, reject){
            EstructuraCostosPdf(props).then(
                function(blob){
                    downloadBlob(blob,
                        `Estructura_Costos_${contexto.predio.nombre_predio.replace(/ /g, '_')}_${getDMY(fechaExportar)}.pdf`);
                },function(blob){
                    alert.show('Error al exportar', { type: 'error' })
            })
        })
    }

    return (
        <Layout title="Estructura de Costos">
            <Form>
                <Form.Row style={{paddingBottom:'10px'}}>
                    <Col md={8}>
                        <Form.Group as={Row} controlId="">

                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <ButtonGroup className="float-right">
                            <Button
                                variant="info"
                                size="sm"
                                onClick={chart}>Gráfico</Button>
                            <DefaultButtons exportar={exportarExcel}/>
                            <DefaultButtons imprimir={exportarPdf}/>
                        </ButtonGroup>
                    </Col>
                </Form.Row>
            </Form>
            <div>
                <BaseGrid
                    columns={columns}
                    rowGetter={i => rows[i]}
                    rowsCount={rows.length}
                    minHeight={350}
                    getCellActions={getCellActions}
                    onRowClick={onRowClick}
                    contextMenu={
                        <DetailContextMenu
                            id="estr_cto"
                            onRowDetail={(e, { rowIdx }) => onRowDetail(rowIdx)}
                        />
                    }
                    RowsContainer={ContextMenuTrigger}
                    showCountRows={false}/>
            </div>
            <div>
                <BaseGrid
                    columns={columnsTotales}
                    rowGetter={i => rowsTotales[i]}
                    rowsCount={rowsTotales.length}
                    minHeight={71}
                    showCountRows={false}/>
            </div>
            <ChartEstructuraCostos
                show={showChart}
                onHide={() => setShowChart(false)} />

            <EstructuraCostosDetalle
                categoria={categoria}
                show={showForm}
                onHide={() => setShowForm(false)} />
        </Layout>
    );
}

export default EstructuraCostos;