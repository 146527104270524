import React from 'react';
import {BaseStylesPdf} from '../../../componentes/pdf/BaseStylesPdf';
import {BaseLinkPdf} from '../../../componentes/pdf/BaseLinkPdf';
import {Table, TableHeader, TableBody, TableCell, DataTableCell} from '@david.kucsai/react-pdf-table';
import { separadorDeMiles, completaRut, DateFormatterField} from '../../../componentes/grid/utils';

export function DocumentosPdf2(props) {
    const styles = BaseStylesPdf();
    const tablaBalanceInicial = (
        <Table data={props.rows}>
            <TableHeader textAlign={"center"}>
                {/* el "weighting" idealmente debe sumar 1 para que quede todo ordenado */}
                <TableCell style={styles.tableHeader} weighting={0.12}>Fecha</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.09}>Folio</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.09}>Número de cheque</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.15}>Actividad</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.15}>Glosa</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.14}>Cuenta Provee</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.14}>Cuenta Recibe</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.07}>Unidad</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.1}>Cantidad</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.11}>Monto</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.08}>Esp.Recup</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.08}>Esp.No Recup</TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell style={styles.textCenter}weighting={0.12} getContent={(r) => DateFormatterField(r.fecha_documento)} />
                <DataTableCell style={styles.textRigh} weighting={0.09} getContent={(r) => r.documento_folio} />
                <DataTableCell style={styles.textRigh} weighting={0.09} getContent={(r) => r.numero_cheque} />
                <DataTableCell style={styles.textLeft} weighting={0.15} getContent={(r) => (r.nombre_actividad)} />
                <DataTableCell style={styles.textLeft} weighting={0.15} getContent={(r) => r.glosa} />
                <DataTableCell style={styles.textLeft} weighting={0.14} getContent={(r) => r.nombre_cuenta_provee} />
                <DataTableCell style={styles.textLeft} weighting={0.14} getContent={(r) => r.nombre_cuenta_recibe} />
                <DataTableCell style={styles.textCenter} weighting={0.07} getContent={(r) => r.unidad} />
                <DataTableCell style={styles.textCenter} weighting={0.1} getContent={(r) => r.cantidad} />
                <DataTableCell style={styles.textRigh} weighting={0.11} getContent={(r) => separadorDeMiles(r.monto_total_neto)} />
                <DataTableCell style={styles.textRigh} weighting={0.08} getContent={(r) => separadorDeMiles(r.especifico_recuperable)} />
                <DataTableCell style={styles.textRigh} weighting={0.08} getContent={(r) => separadorDeMiles(r.especifico_no_recuperable)} />
            </TableBody>
        </Table>)

    let blob = BaseLinkPdf({ ...props, body: tablaBalanceInicial, orientation: "landscape" })

    return blob
}