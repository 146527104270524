import React, {useContext, useEffect} from 'react';
import UserContext from '../UserContext'


function Logout(props) {

    const contexto = useContext(UserContext)

    useEffect(
        () => {
            localStorage.clear();
            contexto.setPredio({ predio_id: null, nombre_predio: null, periodo: null, cooperativa: null, perfil: null});
        },
        []
    )

    useEffect(
        () => {
            if (contexto.predio.perfil === null) {
                props.history.push('/login');
            }
        },
        [contexto]
    )

    return (
        <p>See you later!</p>
    );
}

export default Logout;