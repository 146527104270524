import React, {useState, useEffect} from 'react';
import BaseGrid from '../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import GridMenu from '../../componentes/grid/GridMenu';
import LayoutAdmin from '../../componentes/LayoutAdmin';
import {LongTextFormatter, sortRows, EmptyRowsView, CheckFormatter} from '../../componentes/grid/utils';
import {Api} from '../../utils/api';
import { Button, Row, Col, ButtonGroup} from 'react-bootstrap';
import DefaultButtons from '../../componentes/grid/DefaultButtons'
import Buscador from '../../componentes/Buscador';
import { useAlert } from 'react-alert';
import FormularioCuentaBase from './FormularioCuentaBase';
import ModalEliminar from '../../componentes/ModalEliminar';
import { exportar } from '../../utils/Functions';

function CuentasBase(props) {
    const {ContextMenuTrigger} = Menu;
    const [textoBuscar, setTextoBuscar] = useState('');
	const alert = useAlert();			// Para las notificaciones
    const columns = [
        { key: 'categoria', name: 'Categoría', sortable: true },
        { key: 'codigo', name: 'Código', width: 200, sortable: true },
        { key: 'nombre', name: 'Nombre', formatter: LongTextFormatter, sortable: true },
        { key: 'habilitada', name: 'Habilitada', width: 100, formatter: CheckFormatter }
    ];
	const [rows, setRows] = useState([]);
    const [activarModalAgregarCuenta, setActivarModalCuenta] = useState(false);			// Modal para agregar
    const [cuentaBase, setCuentaBase] = useState('');
    // Modal eliminar
    const [showEliminar, setShowEliminar] = useState(false);
    const [msgEliminar, setMsgEliminar] = useState('');

	useEffect(
		() => {
            cargarCuentasBase();
		},[]
	)

	/**
	 * Buscamos y cargamos todas las cuentas base
	 */
	async function cargarCuentasBase() {
        let resultado = await Api(`api/cuenta/base?texto=${textoBuscar}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setRows(actualizarDatos(resultado.data.datos));
        } else {
            alert.show('Error al cargar predios', { type: 'error' });
            setRows([]);
        }
    }

	/**
	 * Buscamos una cuenta base
	 */
    async function cargarCuentaBase(cuentaBaseId) {
        return await Api(`api/cuenta/base?id=${cuentaBaseId}`, null, {}, true);
    }

    /**
     * Función para búsqueda específica de predios
     *
     * @param {String} texto
     */
    async function retornoBusqueda(texto) {
        setTextoBuscar(texto);
        let resultado = await Api(`api/cuenta/base?texto=${texto}`, null, {}, true);
		if (resultado && resultado.status === 200) {
            setRows(actualizarDatos(resultado.data.datos));
		} else {
            setRows([])
			alert.show('Error en la búsqueda', { type: 'error' });
		}
    }

	/**
	 * Actualizar los registros antes de cargarlos a la grilla
	 *
	 * @param {array} datos
	 * @returns
	 */
    function actualizarDatos(datos) {
        datos.forEach(registro => {
            registro.categoria = `[${registro.categoria_codigo_minimo} - ${registro.categoria_codigo_maximo}] ${registro.categoria}`;
        });
        return datos;
    }

	/**
	 * Acción para desplegar modal agregar cuenta
	 */
	function nuevaCuenta() {
		setActivarModalCuenta(true);
	}

	/**
	 * Evento para cerrar la modal agregar predio
	 */
	function cerrarModalAgregar() {
        setActivarModalCuenta(false);
        cargarCuentasBase();
        setCuentaBase('');
	}

	/**
	 * Cuando queremos editar un predio, desde el context menú
	 *
	 * @param {integer} rowIdx
	 */
	const editRow = rowIdx => {
        new Promise(function (resolve, reject) {
            cargarCuentaBase(rows[rowIdx].id).then(function (resultado) {
                if (resultado && resultado.status === 200) {
                    setActivarModalCuenta(true);
                    setCuentaBase(resultado.data.datos.length === 1 ? resultado.data.datos[0] : '');
                } else {
                    alert.show('Error al cargar cuenta base', { type: 'error' });
                    setCuentaBase('');
                }
            })
        })
	};

	/**
	 * Cuando queremos elimininar una cuenta base, modal confirmación
	 *
	 * @param {*} rowIdx
	 */
	const deleteRow = rowIdx => {
        new Promise(function (resolve, reject) {
            cargarCuentaBase(rows[rowIdx].id).then(function (resultado) {
                if (resultado && resultado.status === 200) {
                    if (resultado.data.datos.length === 1) {
                        let data = resultado.data.datos[0];
                        setCuentaBase(data);
                        setShowEliminar(true);
                        setMsgEliminar('¿Estás seguro de eliminar?<br><br><b>Categoría: </b> ' + data.categoria + '<br><b>código: </b>' + data.codigo + '<br><b>Nombre: </b> ' + data.nombre);
                    } else {
                        alert.show('Este registro no éxiste', { type: 'error' });
                    }
                } else {
                    alert.show('Error al cargar cuenta base', { type: 'error' });
                }
            })
        })
    };

    /**
     * Eliminar cuenta base
     */
    async function retornoEliminarCuenta() {
        let resultado = await Api(`api/cuenta/base/${cuentaBase.id}`, null, {}, true, 'delete');
        if (resultado && resultado.status === 200) {
            setShowEliminar(false);
            setCuentaBase('');
            cargarCuentasBase();
            alert.show(resultado.data.msg, { type: 'success' });
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
        }
    }

    /**
    * Permite exportar la información de la grilla a excel
    */
    function exportarExcel() {
        exportar(`api/exportar/cuenta/base?texto=${textoBuscar}`, alert);
    }

	return (
		<LayoutAdmin title="Cuentas">
			<Row style={{paddingBottom:'10px'}}>
                <Col>
                    <Buscador retornoBusqueda={retornoBusqueda} busqueda={textoBuscar} placeholder='Búsqueda por código, nombre...'/>
                </Col>
				<Col>
					<ButtonGroup className="float-right">
						<Button variant="success" size="sm" onClick={nuevaCuenta}>Agregar Cuenta</Button>
                        <DefaultButtons exportar={exportarExcel} />
					</ButtonGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<div>
						<BaseGrid
							columns={columns}
							rowGetter={i => rows[i]}
							rowsCount={rows.length}
							minHeight={400}
							contextMenu={
								<GridMenu
									id="pac_1"
                                    options={ [
                                        {texto:"Editar",
                                        funcion: (e, { rowIdx }) => editRow(rowIdx)},
                                        {texto:"Eliminar",
                                        funcion: (e, { rowIdx }) => deleteRow(rowIdx)}
                                    ]}
								/>
							}
							RowsContainer={ContextMenuTrigger}
							onGridSort={(sortColumn, sortDirection) =>
								setRows(sortRows(rows, sortColumn, sortDirection))
							}
							emptyRowsView={EmptyRowsView}/>
					</div>
				</Col>
			</Row>
            <FormularioCuentaBase
				show={activarModalAgregarCuenta}
				onHide={cerrarModalAgregar}
                cuentabase={cuentaBase}
				size="lg"
			/>
            <ModalEliminar titulo="Eliminar" show={showEliminar} setShow={setShowEliminar} confirmar={retornoEliminarCuenta}>
                {msgEliminar}
            </ModalEliminar>
		</LayoutAdmin>
	);
}

export default CuentasBase;