import React from 'react';
import { BaseStylesPdf } from '../../../componentes/pdf/BaseStylesPdf';
import { BaseLinkPdf } from '../../../componentes/pdf/BaseLinkPdf';
import { Table, TableHeader, TableBody, TableCell, DataTableCell } from '@david.kucsai/react-pdf-table';
import { separadorDeMiles } from '../../../componentes/grid/utils';

export function InventarioPdf(props) {
    const styles = BaseStylesPdf();
    const tablaInventario = (
        <>
        <Table data={props.rows}>
            <TableHeader textAlign={"center"}>
                {/* el "weighting" idealmente debe sumar 1 para que quede todo ordenado */}
                <TableCell style={styles.tableHeader} weighting={0.255}>Cuenta</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.125}>Item</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.08}>Fabricación</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.07}>Ctd.</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.05}>Un.</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.05}>Tipo</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.15}>Valor Un.</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.13}>ValorTotal</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.05}>Años Dep.</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.1}>Valor Residual</TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell style={styles.textLeft} weighting={0.255} getContent={(r) => r.nombre_cuenta}/>
                <DataTableCell style={styles.textLeft} weighting={0.125} getContent={(r) => r.item}/>
                <DataTableCell style={styles.textRigh} weighting={0.08} getContent={(r) => separadorDeMiles(r.fabricacion)}/>
                <DataTableCell style={styles.textRigh} weighting={0.07} getContent={(r) => r.cantidad}/>
                <DataTableCell style={styles.textCenter} weighting={0.05} getContent={(r) => r.unidad_abreviacion}/>
                <DataTableCell style={styles.textCenter} weighting={0.05} getContent={(r) => r.tipo}/>
                <DataTableCell style={styles.textRigh} weighting={0.15} getContent={(r) => separadorDeMiles(r.valor_unitario)}/>
                <DataTableCell style={styles.textRigh} weighting={0.13} getContent={(r) => separadorDeMiles(r.monto_total)}/>
                <DataTableCell style={styles.textCenter} weighting={0.05} getContent={(r) => r.tiempo_depreciacion}/>
                <DataTableCell style={styles.textRigh} weighting={0.1} getContent={(r) => separadorDeMiles(r.valor_residual)}/>
            </TableBody>
        </Table>
        <Table data={props.rowsTotales}>
        <TableHeader textAlign={"center"}>
                <TableCell style={styles.tableHeader} weighting={0.255}></TableCell>
                <TableCell style={styles.tableHeader} weighting={0.125}></TableCell>
                <TableCell style={styles.tableHeader} weighting={0.08}></TableCell>
                <TableCell style={styles.tableHeader} weighting={0.07}></TableCell>
                <TableCell style={styles.tableHeader} weighting={0.05}></TableCell>
                <TableCell style={styles.tableHeader} weighting={0.05}></TableCell>
                <TableCell style={styles.tableHeader} weighting={0.15}></TableCell>
                <TableCell style={styles.tableHeader} weighting={0.13}>Total</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.05}></TableCell>
                <TableCell style={styles.tableHeader} weighting={0.1}>  </TableCell>
        </TableHeader>
        <TableBody>
                <DataTableCell style={styles.textLeft} weighting={0.255} getContent={(r) => r.c1}/>
                <DataTableCell style={styles.textLeft} weighting={0.125} getContent={(r) => r.c2}/>
                <DataTableCell style={styles.textRigh} weighting={0.08} getContent={(r) => (r.c3)}/>
                <DataTableCell style={styles.textRigh} weighting={0.07} getContent={(r) => r.c4}/>
                <DataTableCell style={styles.textCenter} weighting={0.05} getContent={(r) => r.c5}/>
                <DataTableCell style={styles.textCenter} weighting={0.05} getContent={(r) => r.c6}/>
                <DataTableCell style={styles.textRigh, {padding: '2px 2px'}} weighting={0.15} getContent={(r) => (r.nombre)}/>
                <DataTableCell style={styles.textRigh} weighting={0.13} getContent={(r) => separadorDeMiles(r.total)}/>
                <DataTableCell style={styles.textCenter} weighting={0.05} getContent={(r) => r.c9}/>
                <DataTableCell style={styles.textRigh} weighting={0.1} getContent={(r) => (r.c10)}/>
        </TableBody>
    </Table>
    </>)

    let blob = BaseLinkPdf({ ...props, body: tablaInventario, orientation: "landscape" })

    return blob
}