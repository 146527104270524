import React, { useEffect, useState } from 'react';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import {
    PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell
} from 'recharts';
import { colorCuenta } from '../../../componentes/chart/utils';

function ChartGestionPorActividad(props) {
    const [datosPorcentajesGastosPresupuesto, setDatosPorcentajesGastosPresupuesto] = useState([])
    const [datosPorcentajesGastosGestion, setDatosPorcentajesGastosGestion] = useState([])
    const [datosPorcentajesIngresosPresupuestos, setDatosPorcentajesIngresosPresupuestos] = useState([])
    const [datosPorcentajesIngresoGestion, setDatosPorcentajesIngresosGestion] = useState([])

    useEffect(
        () => {
            agrupaDatosGraficos(props.rows)
        },
        [props.rows]
    )

    /**
     * Permite agrupar los datos segun el "codigo_cuenta"
     *
     * @param {Array} rows
     */
    function agrupaDatosGraficos(rows) {
        //cuenta 600000
        let datosGraficos6 = rows; //agrupa en cuentas >600000 y <700000
        datosGraficos6 = datosGraficos6.filter(data => data.codigo !== "")
        datosGraficos6 = datosGraficos6.filter(data => data.codigo < 700000)

        //cuenta 700000
        let datosGraficos7 = rows; //agrupa en cuentas >700000 y <800000
        datosGraficos7 = datosGraficos7.filter(data => data.codigo !== "")
        datosGraficos7 = datosGraficos7.filter(data => data.codigo > 700000)

        let total_porcentaje_gastos_presupuesto = 0
        let total_porcentaje_gastos_gestion = 0
        let total_porcentaje_ingresos_presupuesto = 0
        let total_porcentaje_ingresos_gestion = 0

        let total_otros1 = 0
        let total_otros2 = 0
        let total_otros3 = 0
        let total_otros4 = 0

        let porcentaje_gastos_presupuesto = 0;
        let porcentaje_gastos_gestion = 0;
        let porcentaje_ingresos_presupuesto = 0;
        let porcentaje_ingresos_gestion = 0;

        datosGraficos7.forEach(data => {
            if (data.total_presupuesto != null && data.total_presupuesto !== "") {
                total_porcentaje_ingresos_presupuesto = parseFloat(total_porcentaje_ingresos_presupuesto) + parseFloat(data.total_presupuesto)
            }
            if (data.ingreso_operacion != null && data.ingreso_operacion !== "") {
                total_porcentaje_ingresos_gestion = parseFloat(total_porcentaje_ingresos_gestion) + parseFloat(data.ingreso_operacion)
            }
        })

        let datosPorcentajesIngresosPresupuestosArray = [];
        let datosPorcentajesIngresoGestionArray = [];
        datosGraficos7.forEach(data => {
            if (data.total_presupuesto != null && data.total_presupuesto !== "") {
                porcentaje_ingresos_presupuesto = ((data.total_presupuesto * 100) / total_porcentaje_ingresos_presupuesto)
                porcentaje_ingresos_presupuesto = parseFloat(porcentaje_ingresos_presupuesto.toFixed(2));

                if (porcentaje_ingresos_presupuesto < 1) {
                    total_otros3 = parseFloat(total_otros3) + parseFloat(porcentaje_ingresos_presupuesto)
                } else {
                    datosPorcentajesIngresosPresupuestosArray.push({
                        'porcentaje_ingresos_presupuesto': porcentaje_ingresos_presupuesto, 'cuenta_codigo_nombre': data.nombre, 'codigo_cuenta': data.codigo
                    })
                }
            }
            if (data.ingreso_operacion != null && data.ingreso_operacion !== "") {
                porcentaje_ingresos_gestion = (data.ingreso_operacion * 100) / total_porcentaje_ingresos_gestion
                porcentaje_ingresos_gestion = parseFloat(porcentaje_ingresos_gestion.toFixed(2))
                if (porcentaje_ingresos_gestion < 1) {
                    total_otros4 = parseFloat(total_otros4) + parseFloat(porcentaje_ingresos_gestion)
                } else {
                    datosPorcentajesIngresoGestionArray.push({
                        'porcentaje_ingresos_gestion': porcentaje_ingresos_gestion, 'cuenta_codigo_nombre': data.nombre, 'codigo_cuenta': data.codigo
                    })
                }
            }
        })

        datosGraficos6.forEach(data => {
            if ((data.codigo < 700000 && data.codigo > 600000) && (data.total_presupuesto != null && data.total_presupuesto !== "")) {
                total_porcentaje_gastos_presupuesto = parseFloat(total_porcentaje_gastos_presupuesto) + parseFloat(data.total_presupuesto)
            }
            if ((data.codigo < 700000 && data.codigo > 600000) && (data.gasto_operacion != null && data.gasto_operacion !== "")) {
                total_porcentaje_gastos_gestion = parseFloat(total_porcentaje_gastos_gestion) + parseFloat(data.gasto_operacion)
            }
        })
        let datosPorcentajesGastosPresupuestoArray = []
        let datosPorcentajesGastosGestionArray = []
        datosGraficos6.forEach(data => {
            if ((data.codigo < 700000 && data.codigo > 600000) && (data.total_presupuesto != null && data.total_presupuesto !== "")) {
                porcentaje_gastos_presupuesto = ((data.total_presupuesto * 100) / total_porcentaje_gastos_presupuesto)
                porcentaje_gastos_presupuesto = parseFloat(porcentaje_gastos_presupuesto.toFixed(2));
                if (porcentaje_gastos_presupuesto < 1) {
                    total_otros1 = total_otros1 + porcentaje_gastos_presupuesto
                } else {
                    datosPorcentajesGastosPresupuestoArray.push(
                        { 'porcentaje_presupuesto': porcentaje_gastos_presupuesto, 'cuenta_codigo_nombre': data.nombre, 'codigo_cuenta': data.codigo }
                    )
                }
            }
            if ((data.codigo < 700000 && data.codigo > 600000) && (data.gasto_operacion != null && data.gasto_operacion !== "")) {
                porcentaje_gastos_gestion = ((data.gasto_operacion * 100) / total_porcentaje_gastos_gestion)
                porcentaje_gastos_gestion = parseFloat(porcentaje_gastos_gestion.toFixed(2));
                if (porcentaje_gastos_gestion < 1) {
                    total_otros2 = total_otros2 + porcentaje_gastos_gestion
                } else {
                    datosPorcentajesGastosGestionArray.push(
                        { 'porcentaje_gastos': porcentaje_gastos_gestion, 'cuenta_codigo_nombre': data.nombre, 'codigo_cuenta': data.codigo }
                    )
                }
            }
        })
        //Categoria "Otros"
        total_otros1 = parseFloat(total_otros1.toFixed(2))
        total_otros2 = parseFloat(total_otros2.toFixed(2))
        total_otros3 = parseFloat(total_otros3.toFixed(2))
        total_otros4 = parseFloat(total_otros4.toFixed(2))

        if (total_otros1 > 0) {
            datosPorcentajesGastosPresupuestoArray.push({ 'porcentaje_presupuesto': total_otros1, 'cuenta_codigo_nombre': 'Otros', 'codigo_cuenta': 100000 })
        }
        if (total_otros2 > 0) {
            datosPorcentajesGastosGestionArray.push({ 'porcentaje_gastos': total_otros2, 'cuenta_codigo_nombre': 'Otros', 'codigo_cuenta': 100000 })
        }
        if (total_otros3 > 0) {
            datosPorcentajesIngresosPresupuestosArray.push({ 'porcentaje_ingresos_presupuesto': total_otros3, 'cuenta_codigo_nombre': 'Otros', 'codigo_cuenta': 100000 })
        }
        if (total_otros4 > 0) {
            datosPorcentajesIngresoGestionArray.push({ 'porcentaje_ingresos_gestion': total_otros4, 'cuenta_codigo_nombre': 'Otros', 'codigo_cuenta': 100000 })
        }
        setDatosPorcentajesGastosPresupuesto(datosPorcentajesGastosPresupuestoArray);
        setDatosPorcentajesGastosGestion(datosPorcentajesGastosGestionArray);
        setDatosPorcentajesIngresosPresupuestos(datosPorcentajesIngresosPresupuestosArray);
        setDatosPorcentajesIngresosGestion(datosPorcentajesIngresoGestionArray);
    }

    return (
        <Modal {...props} backdrop="static" keyboard={false} className="modal-xl">
            <Modal.Header>
            </Modal.Header>
            <Modal.Body>
                <Row style={{ borderBottom: '1px solid #ccc' }} >
                    <Col lg="6" style={{ borderRight: '1px solid #ccc' }}>
                        <h4 style={{ textAlign: 'center' }}>Gastos Presupuestados</h4>
                        <div style={{ width: '100%', height: 350 }}>
                            <ResponsiveContainer>
                                <PieChart
                                    margin={{
                                        top: 5, right: 30, left: 20, bottom: 5,
                                    }}>
                                    <Pie
                                        dataKey="porcentaje_presupuesto"
                                        nameKey="cuenta_codigo_nombre"
                                        isAnimationActive={false}
                                        data={datosPorcentajesGastosPresupuesto}
                                        outerRadius={100}
                                        label>
                                        {
                                            datosPorcentajesGastosPresupuesto.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colorCuenta(entry.codigo_cuenta)} />
                                            ))
                                        }
                                    </Pie>
                                    <Tooltip />
                                    <Legend layout='vertical' align='right' verticalAlign='top' width='200px' className="olaaa" />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                    <Col lg="6">
                        <h4 style={{ textAlign: 'center' }}>Gastos Gestión</h4>
                        <div style={{ width: '100%', height: 350 }}>
                            <ResponsiveContainer>
                                <PieChart
                                    margin={{
                                        top: 5, right: 30, left: 20, bottom: 5,
                                    }}>
                                    <Pie
                                        dataKey="porcentaje_gastos"
                                        nameKey="cuenta_codigo_nombre"
                                        isAnimationActive={false}
                                        data={datosPorcentajesGastosGestion}
                                        outerRadius={100}
                                        label>
                                        {
                                            datosPorcentajesGastosGestion.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colorCuenta(entry.codigo_cuenta)} />
                                            ))
                                        }
                                    </Pie>
                                    <Tooltip />
                                    <Legend layout='vertical' align='right' verticalAlign='top' width='200px' />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" style={{ borderRight: '1px solid #ccc' }}>
                        <h4 style={{ textAlign: 'center', marginTop: 20 }}>Ingresos Presupuestados</h4>
                        <div style={{ width: '100%', height: 350 }}>
                            <ResponsiveContainer>
                                <PieChart
                                    margin={{
                                        top: 5, right: 30, left: 20, bottom: 5,
                                    }}>
                                    <Pie
                                        dataKey="porcentaje_ingresos_presupuesto"
                                        nameKey="cuenta_codigo_nombre"
                                        isAnimationActive={false}
                                        data={datosPorcentajesIngresosPresupuestos}
                                        outerRadius={100}
                                        label>
                                        {
                                            datosPorcentajesIngresosPresupuestos.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colorCuenta(entry.codigo_cuenta)} />
                                            ))
                                        }
                                    </Pie>
                                    <Tooltip />
                                    <Legend layout='vertical' align='right' verticalAlign='top' width='200px' />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                    <Col lg="6">
                        <h4 style={{ textAlign: 'center', marginTop: 20 }}>Ingresos Gestión</h4>
                        <div style={{ width: '100%', height: 350 }}>
                            <ResponsiveContainer>
                                <PieChart
                                    margin={{
                                        top: 5, right: 30, left: 20, bottom: 5,
                                    }}>
                                    <Pie
                                        dataKey="porcentaje_ingresos_gestion"
                                        nameKey="cuenta_codigo_nombre"
                                        isAnimationActive={false}
                                        data={datosPorcentajesIngresoGestion}
                                        outerRadius={100}
                                        label>
                                        {
                                            datosPorcentajesIngresoGestion.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colorCuenta(entry.codigo_cuenta)} />
                                            ))
                                        }
                                    </Pie>
                                    <Tooltip />
                                    <Legend layout='vertical' align='right' verticalAlign='top' width='200px' />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ChartGestionPorActividad;