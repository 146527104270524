import React, {useState, useEffect, useContext} from 'react';
import {useAlert} from 'react-alert';
import Layout from '../../../componentes/Layout';
import {Api} from '../../../utils/api';
import UserContext from '../../../UserContext';
import {Button, Row, Col, Card, Form, Alert} from 'react-bootstrap';
import { FaCalculator } from 'react-icons/fa';
import ModalEliminar from '../../../componentes/ModalEliminar';

function CierreAnual(props) {
	const alert = useAlert();
	const contexto = useContext(UserContext);
	const [ipc, setIpc] = useState('');
	const [docRevalorizados, setDocRevalorizados] = useState(0);
	const [docRevalorizadosManual, setDocRevalorizadosManual] = useState(0);
	const [docRevalorizadosAuto, setDocRevalorizadosAuto] = useState(0);

	const [docDepreciados, setDocDepreciados] = useState(0);
	const [docDepreciadosManual, setDocDepreciadosManual] = useState(0);
	const [docDepreciadosAuto, setDocDepreciadosAuto] = useState(0);

	const [balanceInicial, setBalanceInicial] = useState(0);
	const [balanceInicialManual, setBalanceInicialManual] = useState(0);
	const [balanceInicialAuto, setBalanceInicialAuto] = useState(0);

	const [totales, setTotales] = useState({
		'periodo_siguiente': {},
		'total_presupuestos_auto': 0,
		'total_dato_presupuesto_auto': 0,
		'total_presupuestos_manual': 0,
		'total_dato_presupuesto_manual': 0,
		'total_presupuestos': 0,
		'total_dato_presupuestos': 0,
		'total_presupuesto_caja': 0,
		'total_presupuesto_caja_manual': 0,
		'total_presupuesto_caja_auto': 0
	})
    // Modal eliminar revalorización
    const [showEliminar, setShowEliminar] = useState(false);
    const [msgEliminar, setMsgEliminar] = useState('');
    // Modal eliminar depreciación
    const [showEliminarDepreciacion, setShowEliminarDepreciacion] = useState(false);
    const [msgEliminarDepreciacion, setMsgEliminarDepreciacion] = useState('');
    // Modal eliminar cierre
    const [showEliminarCierre, setShowEliminarCierre] = useState(false);
    const [msgEliminarCierre, setMsgEliminarCierre] = useState('');
    // Modal eliminar presupuestos
    const [showEliminarPresupuestos, setShowEliminarPresupuestos] = useState(false);
    const [msgEliminarPresupuestos, setMsgEliminarPresupuestos] = useState('');
	// Modal eliminar presupuestos caja
    const [showEliminarPresupuestoCaja, setShowEliminarPresupuestoCaja] = useState(false);
    const [msgEliminarPresupuestoCaja, setMsgEliminarPresupuestoCaja] = useState('');

	useEffect(
		() => {
			if (contexto.predio.predio_id) {
				obtenerDatos();
			}
		},
		[contexto]
	)

	async function obtenerDatos() {
		let resultado = await Api(`api/cierre/revalorizacion-info/${contexto.predio.predio_id}/${contexto.predio.periodo.numero}`, null, {}, true);
		if (resultado && resultado.status === 200) {
			setDocRevalorizados(resultado.data.doc_revalorizados_total);
			setDocRevalorizadosManual(resultado.data.doc_revalorizados_manual);
			setDocRevalorizadosAuto(resultado.data.doc_revalorizados_auto);
			setDocDepreciados(resultado.data.doc_depreciados_total);
			setDocDepreciadosManual(resultado.data.doc_depreciados_manual);
			setDocDepreciadosAuto(resultado.data.doc_depreciados_auto);
			setBalanceInicial(resultado.data.balance_inicial_total);
			setBalanceInicialManual(resultado.data.balance_inicial_manual);
			setBalanceInicialAuto(resultado.data.balance_inicial_auto);
			let info = resultado.data;
			setTotales({
				'periodo_siguiente': info.periodo_siguiente,
				'total_presupuestos_auto': info.total_presupuestos_auto,
				'total_dato_presupuesto_auto': info.total_dato_presupuesto_auto,
				'total_presupuestos_manual': info.total_presupuestos_manual,
				'total_dato_presupuesto_manual': info.total_dato_presupuesto_manual,
				'total_presupuestos': info.total_presupuestos,
				'total_dato_presupuestos': info.total_dato_presupuestos,
				'total_presupuesto_caja': info.total_presupuesto_caja,
				'total_presupuesto_caja_manual': info.total_presupuesto_caja_manual,
				'total_presupuesto_caja_auto': info.total_presupuesto_caja_auto
			})
		} else {
			alert.show(resultado.data.msg, { type: 'error' });
		}
	}

	async function revalorizar() {
		if (ipc) {
			let info = {
				predio_id: contexto.predio.predio_id,
				numero_periodo: contexto.predio.periodo.numero,
				ipc: parseFloat(ipc/100)
			}

			let resultado = await Api(`api/cierre/revalorizacion`, info, {}, true, 'post');
			if (resultado && resultado.status === 200) {
				alert.show(resultado.data.msg, { type: 'success' });
				obtenerDatos();
			} else {
				alert.show(resultado.data.msg, { type: 'error' });
			}
		} else {
			alert.show('Debe ingresar el IPC', { type: 'error' });
		}
	}

	async function depreciacion() {
		let info = {
			predio_id: contexto.predio.predio_id,
			numero_periodo: contexto.predio.periodo.numero
		}

		let resultado = await Api(`api/cierre/depreciacion`, info, {}, true, 'post');
		if (resultado && resultado.status === 200) {
			alert.show(resultado.data.msg, { type: 'success' });
			obtenerDatos();
		} else {
			alert.show(resultado.data.msg, { type: 'error' });
		}
	}

	async function cierre() {
		let info = {
			predio_id: contexto.predio.predio_id,
			numero_periodo: contexto.predio.periodo.numero,
			periodo_siguiente_id: totales.periodo_siguiente.id ? totales.periodo_siguiente.id : '' // si va vacio debe crear un nuevo periodo
		}

		let resultado = await Api(`api/cierre`, info, {}, true, 'post');
		if (resultado && resultado.status === 200) {
			alert.show(resultado.data.msg, { type: 'success' });
			obtenerDatos();
		} else {
			alert.show(resultado.data.msg, { type: 'error' });
		}
	}

	async function retornoEliminarRevalorizacion() {
		let info = {
			predio_id: contexto.predio.predio_id,
			numero_periodo: contexto.predio.periodo.numero
		}

		let resultado = await Api(`api/cierre/revalorizacion`, info, {}, true, 'delete');
		if (resultado && resultado.status === 200) {
			alert.show(resultado.data.msg, { type: 'success' });
			obtenerDatos();
			setShowEliminar(false);
		} else {
			alert.show(resultado.data.msg, { type: 'error' });
		}
	}

	async function retornoEliminarDepreciacion() {
		let info = {
			predio_id: contexto.predio.predio_id,
			numero_periodo: contexto.predio.periodo.numero
		}

		let resultado = await Api(`api/cierre/depreciacion`, info, {}, true, 'delete');
		if (resultado && resultado.status === 200) {
			alert.show(resultado.data.msg, { type: 'success' });
			obtenerDatos();
			setShowEliminarDepreciacion(false);
		} else {
			alert.show(resultado.data.msg, { type: 'error' });
		}
	}

	async function retornoEliminarCierre() {
		let info = {
			predio_id: contexto.predio.predio_id,
			periodo_id: totales.periodo_siguiente.id ? totales.periodo_siguiente.id : ''  // id del periodo siguiente a eliminar
		}

		let resultado = await Api(`api/cierre`, info, {}, true, 'delete');
		if (resultado && resultado.status === 200) {
			alert.show(resultado.data.msg, { type: 'success' });
			obtenerDatos();
			setShowEliminarCierre(false);
		} else {
			alert.show(resultado.data.msg, { type: 'error' });
		}
	}

	async function retornoEliminarPresupuestos() {
		let info = {
			predio_id: contexto.predio.predio_id,
			periodo_id: totales.periodo_siguiente.id ? totales.periodo_siguiente.id : ''  // id del periodo siguiente a eliminar
		}

		let resultado = await Api(`api/clonar-presupuestos`, info, {}, true, 'delete');
		if (resultado && resultado.status === 200) {
			alert.show(resultado.data.msg, { type: 'success' });
			obtenerDatos();
			setShowEliminarPresupuestos(false);
		} else {
			alert.show(resultado.data.msg, { type: 'error' });
		}
	}

	async function retornoEliminarPresupuestoCaja() {
		let info = {
			predio_id: contexto.predio.predio_id,
			periodo_id: totales.periodo_siguiente.id ? totales.periodo_siguiente.id : ''  // id del periodo siguiente a eliminar
		}

		let resultado = await Api(`api/clonar-presupuestos-caja`, info, {}, true, 'delete');
		if (resultado && resultado.status === 200) {
			alert.show(resultado.data.msg, { type: 'success' });
			obtenerDatos();
			setShowEliminarPresupuestoCaja(false);
		} else {
			alert.show(resultado.data.msg, { type: 'error' });
		}
	}

	return (
		<Layout title="Cierre Anual">
			<Row>
				<Col lg={12} className="mb-2">
					<Card>
						<Card.Header>1.- Calcular revalorizacion de activos.</Card.Header>
						<Card.Body>
							<Card.Text>
								Al ejecutar el proceso automático de revalorización este cambiará el valor de sus activos en base a la variación del IPC. Este Proceso tiene que efectuarse al final del ejercicio y antes de calcular las depreciaciones.
							</Card.Text>
							{docRevalorizados > 0 &&
								<Alert variant={'danger'}>
									Para este predio y periodo ya existe revalorización<br/>
									{docRevalorizadosManual > 0 &&
										<>Revalorización manual: {docRevalorizadosManual} - Debe eliminar manualmente estos documentos<br/> </>
									}
									Revalorización automática: {docRevalorizadosAuto}
									<Button style={{marginLeft: '10px'}} onClick={() => {
										setShowEliminar(true)
										setMsgEliminar('Este proceso eliminará todos los documentos de tipo revalorización que fueron creados automáticamente con este proceso.')
									}} size="sm" variant="danger">Eliminar revalorización automática</Button>
								</Alert>
							}
							<Row>
								<Col sm={12} lg={12}>
									<Form.Label>IPC acumulado de los últimos 12 meses antes del cierre del ejercicio (Ingrese el valor en porcentaje) <a href="https://www.sii.cl/valores_y_fechas/index_valores_y_fechas.html" target="_blank">Información IPC aquí</a></Form.Label>
								</Col>
								<Col sm={12} lg={3} className="mb-2">
									<Form.Control type="number" size="sm" onChange={(e) => {
										setIpc(e.target.value)
									}} value={ipc} required/>
								</Col>
							</Row>
							<Button onClick={revalorizar} variant="success" disabled={docRevalorizados > 0 ? true : false}><FaCalculator/> Revalorizar activos</Button>
						</Card.Body>
					</Card>
				</Col>
				<Col lg={12} className="mb-2">
					<Card>
						<Card.Header>2.- Calcular depreciaciones.</Card.Header>
						<Card.Body>
							<Card.Text>
								El proceso automático de depreciación calculará estas automáticamente en base a los factores para contrucciones y equipos ingresados.
							</Card.Text>
							{docDepreciados > 0 &&
								<Alert variant={'danger'}>
									Para este predio y periodo ya existe depreciación<br/>
									{docDepreciadosManual > 0 &&
										<>Depreciación manual: {docDepreciadosManual} - Debe eliminar manualmente estos documentos<br/> </>
									}
									Depreciación automática: {docDepreciadosAuto}
									<Button style={{marginLeft: '10px'}} onClick={() =>{
										setShowEliminarDepreciacion(true)
										setMsgEliminarDepreciacion('Este proceso eliminará todos los documentos de tipo depreciación que fueron creados automáticamente con este proceso. Si hubieran registros manuales en algún presupuesto se lo indicaremos.')
									}} size="sm" variant="danger">Eliminar depreciación automática</Button>
								</Alert>
							}
							{/* docDepreciados: {docDepreciados}<br/>
							docDepreciadosManual: {docDepreciadosManual}<br/>
							docDepreciadosAuto: {docDepreciadosAuto}<br/> */}
							<Button onClick={depreciacion} variant="success"
								disabled={((docRevalorizados > 0 && docDepreciados > 0) || (docRevalorizados === 0) ) ? true : false}>
								<FaCalculator/> Depreciar activos
							</Button>
						</Card.Body>
					</Card>
				</Col>
				<Col lg={12} className="mb-2">
					<Card>
						<Card.Header>3.- Cerrar ejercicio.</Card.Header>
						<Card.Body>
							<Card.Text>
							Después de haber calculado la revalorización y depreciación de activos, puede efectuar el cierre del ejercicio, el cual consiste en:<br/>
							- Cerrar ejercicio en curso.<br/>
							- Crear nuevo ejercicio, migrando plan de cuentas y actividades usado en el ejercicio anterior.<br/>
							- Calcular automáticamente Balance Inicial del nuevo ejercicio en curso.<br/>
							</Card.Text>
							{totales.periodo_siguiente.id &&
								<Alert variant={'primary'}>
									Periodo siguiente N°{totales.periodo_siguiente.numero}, Inicio: {totales.periodo_siguiente.inicio}, Cierre: {totales.periodo_siguiente.cierre}<br/>
								</Alert>
							}
							{balanceInicial > 0 &&
								<Alert variant={'danger'}>
									Para este predio y periodo N°{totales.periodo_siguiente.numero} ya existe un balance creado<br/>
									{balanceInicialManual > 0 &&
										<>Balances manuales: {balanceInicialManual} - Debe eliminar manualmente estos balances<br/> </>
									}
									Balances automáticos: {balanceInicialAuto}
									<Button style={{marginLeft: '10px'}} onClick={() => {
										setShowEliminarCierre(true)
										setMsgEliminarCierre('Este proceso eliminará todos balances que fueron creados automáticamente con este proceso.')
									}} size="sm" variant="danger">Eliminar balance automático del periodo N°{totales.periodo_siguiente.numero}</Button>
								</Alert>
							}
							{/* balanceInicial: {balanceInicial}<br/>
							balanceInicialManual: {balanceInicialManual}<br/>
							balanceInicialAuto: {balanceInicialAuto}<br/> */}

							{totales.total_presupuestos > 0 &&
								<Alert variant={'danger'}>
									Para este predio y periodo N°{totales.periodo_siguiente.numero} ya existen presupuestos y datos para el siguiente periodo<br/>
									{totales.total_presupuestos_manual > 0 &&
										<>Presupuestos manuales: {totales.total_presupuestos_manual} - Debe eliminar manualmente estos presupuestos<br/> </>
									}
									Presupuestos automáticos: {totales.total_presupuestos_auto}

									<Button style={{marginLeft: '10px'}} onClick={() => {
										setShowEliminarPresupuestos(true)
										setMsgEliminarPresupuestos('Este proceso eliminará todos los presupuestos y datos presupuestos que fueron creados automáticamente con este proceso.')
									}} size="sm" variant="danger">Eliminar presupuestos y datos automáticos del periodo N°{totales.periodo_siguiente.numero} </Button>
								</Alert>
							}
							{/* total_presupuestos_auto: {totales.total_presupuestos_auto}<br/>
							total_presupuestos_manual: {totales.total_presupuestos_manual} */}

							{totales.total_presupuesto_caja > 0 &&
								<Alert variant={'danger'}>
									Para este predio y periodo N°{totales.periodo_siguiente.numero} ya existen presupuestos caja<br/>
									{totales.total_presupuesto_caja_manual > 0 &&
										<>Presupuestos caja manuales: {totales.total_presupuesto_caja_manual} - Debe eliminar manualmente estos presupuestos<br/> </>
									}
									Presupuestos caja automáticos: {totales.total_presupuesto_caja_auto}

									<Button style={{marginLeft: '10px'}} onClick={() => {
										setShowEliminarPresupuestoCaja(true)
										setMsgEliminarPresupuestoCaja('Este proceso eliminará todos los presupuestos caja que fueron creados automáticamente con este proceso.')
									}} size="sm" variant="danger">Eliminar presupuestos caja automáticos del periodo N°{totales.periodo_siguiente.numero} </Button>
								</Alert>
							}
							{/* total_presupuestos_auto: {totales.total_presupuestos_auto}<br/>
							total_presupuestos_manual: {totales.total_presupuestos_manual} */}

							<Button onClick={cierre} variant="success"
								disabled={((balanceInicial > 0) || (docRevalorizados === 0) || (docDepreciados === 0) || (totales.total_presupuestos > 0) || (totales.total_presupuesto_caja > 0)) ? true : false}>
								<FaCalculator/> Cerrar ejercicio actual
							</Button>


						</Card.Body>
					</Card>
				</Col>
			</Row>
			<ModalEliminar titulo="Eliminar revalorización" show={showEliminar} setShow={setShowEliminar} confirmar={retornoEliminarRevalorizacion}>
                {msgEliminar}
            </ModalEliminar>
			<ModalEliminar titulo="Eliminar depreciación" show={showEliminarDepreciacion} setShow={setShowEliminarDepreciacion} confirmar={retornoEliminarDepreciacion}>
                {msgEliminarDepreciacion}
            </ModalEliminar>
			<ModalEliminar titulo="Eliminar balance" show={showEliminarCierre} setShow={setShowEliminarCierre} confirmar={retornoEliminarCierre}>
                {msgEliminarCierre}
            </ModalEliminar>
			<ModalEliminar titulo="Eliminar presupuestos" show={showEliminarPresupuestos} setShow={setShowEliminarPresupuestos} confirmar={retornoEliminarPresupuestos}>
                {msgEliminarPresupuestos}
            </ModalEliminar>
			<ModalEliminar titulo="Eliminar presupuestos caja" show={showEliminarPresupuestoCaja} setShow={setShowEliminarPresupuestoCaja} confirmar={retornoEliminarPresupuestoCaja}>
                {msgEliminarPresupuestoCaja}
            </ModalEliminar>
		</Layout>
	);
}

export default CierreAnual;