import React, {useEffect, useRef} from 'react';
import { Form, Button, Col, Modal, Row} from 'react-bootstrap';
import { useForm, ErrorMessage } from 'react-hook-form';
import { useAlert } from 'react-alert';
import {Api, ObjectToFormdata} from '../../utils/api';
import { FaSave, FaWindowClose, FaPlus } from 'react-icons/fa';
import Fecha from '../../componentes/Fecha';
import { deltaPeriodo } from '../../utils/Functions';

export function FormularioImpuesto(props){
    const { register, handleSubmit, errors, setValue } = useForm();
    const alert = useAlert();           // Para las notificaciones

    const {impuesto, funcionRetorno, ...rest} = props;

    const onSubmit = data => {
        enviarFormulario(data);
    }

    useEffect(
        () => {
            if (impuesto) {
                setValue([
                    {id:impuesto.id},
                    {nombre:impuesto.nombre},
                    {tasa:impuesto.tasa},
                    {fecha_inicio:impuesto.fecha_inicio},
                    {fecha_cierre:impuesto.fecha_cierre}
                ]);
            }
        },
        [impuesto]
    )

    async function enviarFormulario(data){
        if (data.id == ""){ // Crear
            let resultado = await Api(`api/administracion/impuestos`, data, { 'Content-Type': 'application/json' }, true, 'post');
            if (resultado && resultado.status === 200) {
                alert.show(resultado.data.msg, { type: 'success' });
                if (props.funcionRetorno) {
                    props.funcionRetorno(data);
                }
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
                // props.funcionRetorno(data);
            }
        } else{ // Actualizar
            let resultado = await Api(`api/administracion/impuestos/${data.id}`, data, { 'Content-Type': 'application/json' }, true, 'put');
            if (resultado && resultado.status === 200) {
                alert.show(resultado.data.msg, { type: 'success' });
                if (props.funcionRetorno) {
                    props.funcionRetorno(data);
                }
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
            }
        }
    }

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Col sm="12">
                <Form.Row>
                    <Form.Control type="hidden" name="id" defaultValue={impuesto.id} ref={register({})}></Form.Control>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label>Nombre Impuesto</Form.Label>
                        <Form.Control type="text" placeholder="Nombre" name="nombre" defaultValue={impuesto.nombre}
                            ref={register({
                                required: "ingrese el nombre de la impuesto",
                                minLength: {value: 2,message: "Largo mínimo 2 caracteres"},
                                maxLength: {value: 100, message: "Largo máximmo 100"}
                            })}
                            isInvalid={errors.nombre} size="sm" />
                        <ErrorMessage errors={errors} name="nombre" as="div" className="invalid-feedback" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                        <Form.Label>Tasa (%)</Form.Label>
                        <Form.Control type="text" placeholder="Tasa" name="tasa"
                            defaultValue={impuesto.tasa}
                            ref={register({
                                minLength: {value: 1,message: "Largo mínimo 1 caracter"},
                            })}
                            isInvalid={errors.tasa} size="sm" />
                        <ErrorMessage errors={errors} name="tasa" as="div" className="invalid-feedback" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>Fecha Inicio Vigencia</Form.Label>
                        <Fecha
                                name='inicio'
                                register={register}
                                errors={errors}
                                dateFormat='dd/MM/yyyy' // dd/MM/Y h:mm aa - dd/MM/Y - MM/Y
                                formatoMesAno={false}
                                dateFormatOculto='y-m-d' // y-m-d h:i:s
                                inicial={impuesto.fecha_inicio}
                        />
                        <ErrorMessage errors={errors} name="fecha_inicio" as="div" className="invalid-feedback" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                    <Form.Label>Fecha Termino Vigencia</Form.Label>
                        <Fecha
                            name='cierre'
                            register={register}
                            errors={errors}
                            dateFormat='dd/MM/yyyy' // dd/MM/Y h:mm aa - dd/MM/Y - MM/Y
                            formatoMesAno={false}
                            dateFormatOculto='y-m-d' // y-m-d h:i:s
                            inicial={impuesto.fecha_cierre}
                            vacio={true}
                        />
                        <ErrorMessage errors={errors} name="fecha_cierre" as="div" className="invalid-feedback" />
                    </Form.Group>
                </Form.Row>
            </Col>
            <Button type="submit" size="sm" variant="success" ref={props.fordwardRef} hidden><FaSave/> Guardar</Button>
        </Form>
    )
}

export function ModalFormularioImpuesto(props) {

    const refSubmitButton = useRef();
    const {impuesto, funcionRetorno, ...rest} = props;
    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Agregar impuesto
            </Modal.Header>

            <Modal.Body>
                <FormularioImpuesto impuesto={impuesto} funcionRetorno={funcionRetorno} fordwardRef={refSubmitButton}></FormularioImpuesto>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.onHide}><FaWindowClose/> Cerrar</Button>
                <Button onClick={(e) => refSubmitButton.current.click()} size="sm" variant="success"><FaSave/> Guardar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export async function getImpuesto(impuestoId){
    let resultado = await Api('api/administracion/impuestos/'+impuestoId, null, {}, true);
    return resultado.data.impuesto;
}

export function FormularioEliminarImpuesto(props) {

    const {impuesto, funcionRetorno, ...rest} = props;
    const alert = useAlert();           // Para las notificaciones

    async function eliminarImpuesto(data){
        //data = ObjectToFormdata(data);
        let resultado = await Api('api/administracion/impuestos/'+data.id,null,{},true,'delete');
		if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            props.funcionRetorno(data);
		} else {
            alert.show(resultado.data.msg, { type: 'error' });
            //props.funcionRetorno(data);
		}
    }

    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Eliminar Impuesto
            </Modal.Header>
            <Modal.Body>
                <Col sm="12">
                ¿Está seguro de eliminar el impuesto {impuesto.nombre}?
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.onHide}>
                    Cerrar
                    </Button>
                    <Button variant="danger" size="sm"
                        onClick={(e) => eliminarImpuesto(impuesto)}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
