import React, {useState, useEffect, useContext} from 'react';
import {Modal, Tabs, Tab} from 'react-bootstrap';
import {Api} from '../../../utils/api';
import UserContext from '../../../UserContext';
import {
    ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area
} from 'recharts';
import {NumberGraphicFormatter} from '../../../componentes/chart/utils';

function ChartMovimientosDeCaja(props) {

    const contexto = useContext(UserContext);
    const [meses, setMeses] = useState([]);
    const [data, setData] = useState([]);

    async function getReporte(predio, periodo) {

        let resultado = await Api(`api/contabilidad/movimientos-de-caja?predio_id=${predio}&periodo_id=${periodo}`);
        if (resultado && resultado.status === 200) {
            setMeses(resultado.data.meses);
            setData(resultado.data.grafico);
        } else {
            setData([]);
        }
    }

    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                getReporte(contexto.predio.predio_id, contexto.predio.periodo.id);
            }
        },
        [contexto]
    )

    return (
        <Modal { ...props}  backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                Gráfico Movimientos de Caja
            </Modal.Header>
            <Modal.Body>

            <Tabs defaultActiveKey="ingresos_egresos" id="chart_tab">
                <Tab eventKey="ingresos_egresos" title="Ingresos y Egresos">
                    <div style={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                            <LineChart
                                width={500}
                                height={350}
                                data={data}
                                margin={{
                                top: 20, right: 30, left: 20, bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="mes" />
                                <YAxis tickFormatter={value => NumberGraphicFormatter({value:value})} />
                                <Tooltip formatter={(value) => new Intl.NumberFormat('es').format(value)} />
                                <Legend />
                                <Line type="monotone" dataKey="ingreso" stroke="#00FF00" activeDot={{ r: 8 }} />
                                <Line type="monotone" dataKey="egreso" stroke="#FF0000" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </Tab>
                <Tab eventKey="presupuesto_caja" title="Movimientos de Caja">
                    <div style={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                            <AreaChart
                                width={500}
                                height={350}
                                data={data}
                                margin={{
                                top: 20, right: 30, left: 20, bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="mes" />
                                <YAxis tickFormatter={value => NumberGraphicFormatter({value:value})} />
                                <Tooltip formatter={(value) => new Intl.NumberFormat('es').format(value)} />
                                <Legend />
                                <Area type="monotone" dataKey="acumulado" stroke="#00FF00" fill="#00FF00" />
                                <Area type="monotone" dataKey="saldo_mensual" stroke="#0000FF" fill="#0000FF" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </Tab>
            </Tabs>
            </Modal.Body>
            <Modal.Footer>
                
            </Modal.Footer>
        </Modal>
    );
}

export default ChartMovimientosDeCaja;