import React, {useState} from 'react';
import { Button, Row, Col, Form, InputGroup} from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

/**
 * Este componente es un buscador que se activa cuando presionamos enter, hacemos click en el buscador o luego de 2 segundos de escribir
 *
 * @param {*} props
 * @returns Componente
 */
function Buscador(props) {

	const [valor, setvalor] = useState('');
	const [timer, setTimer] = useState(null);
	const WAIT_INTERVAL = 1000;
	const ENTER_KEY = 13;

	/**
	 * Evento cuando presionan botón buscar
	 */
	function onClick() {
		clearTimeout(timer); // Cuando es presionado el botón buscar, detenemos el setTimeOut si es que ya habia sido lanzado
		props.retornoBusqueda(valor); // LLamamos la función retorno del componente padre, por lo general es una llamada API
	}

	/**
	 * Cuando cambia el campo valor, actualizamos estado
	 *
	 * @param {*} e
	 */
	function onChange(e) {
		setvalor(e.target.value); // Lo unico que hace es actualizar estado
	}

	/**
	 * Cada vez que levantan el dedo de la tecla, detenemos el tiempo y luego de WAIT_INTERVAL segundos
	 * Se activa la función de búsqueda
	 *
	 * @param {*} e
	 */
	function onKeyUp(e) {
		clearTimeout(timer); // Cada vez que presionamos teclas detenemos la ejecución del setTimeOut si es que hay alguno andando
		if (e.keyCode === ENTER_KEY) { // Cuando la tecla presionada es enter, ejecutamos consulta
			// console.log('delay:  ' + valor);
			props.retornoBusqueda(valor);
		} else {
			setTimer(setTimeout(() => { // Si no es enter dejamos ejecutando setTimeOut por WAIT_INTERVAL segundos
				// console.log('delay:  ' + valor);
				props.retornoBusqueda(valor); // LLamamos la función retorno del componente padre, por lo general es una llamada API
			}, WAIT_INTERVAL));
		}
	}

	return (
		<Row>
			<Form.Group as={Col} md="12" controlId="busqueda-global">
				<InputGroup>
					<Form.Control type="text" size="sm" aria-describedby="inputGroupPrepend" onKeyUp={onKeyUp} onChange={onChange} value={valor} placeholder={props.placeholder} required/>
					<InputGroup.Append>
						<Button onClick={onClick} variant="light" size="sm" className="borde-gris"><FaSearch /> Buscar</Button>
					</InputGroup.Append>
				</InputGroup>
			</Form.Group>
		</Row>
	);
}

export default Buscador;