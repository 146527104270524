import React from 'react';
import {BaseStylesPdf} from '../../../../componentes/pdf/BaseStylesPdf';
import {BaseLinkPdf} from '../../../../componentes/pdf/BaseLinkPdf';
import {Table, TableHeader, TableBody, TableCell, DataTableCell} from '@david.kucsai/react-pdf-table';
import {separadorDeMiles} from '../../../../componentes/grid/utils';

export function ResumenDeMargenesPdf(props) {
    const styles = BaseStylesPdf();
    var tablaPresupuestoActividad = '';
    try {
        tablaPresupuestoActividad =(
            <>
            <Table data={props.rowsTotales}>
                <TableHeader textAlign={"center"}>
                    <TableCell style={styles.tableHeader} weighting={0.301}></TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.227}>PRESUPUESTO</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.427}>OPERACIONES</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.05}></TableCell>
                </TableHeader>
            </Table>
            <Table data={props.rows}>
                <TableHeader textAlign={"center"}>
                    <TableCell style={styles.tableHeader} weighting={0.2062}>Actividad</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Tamaño</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Margen</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.127}>Margen Unitario</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Gastos</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Ingresos</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Margen</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.123}>Margen Unitario</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.05}>Des(%)</TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{textAlign:"left"}} weighting={0.2} getContent={(r) => r.titulo}/>
                    <DataTableCell style={{textAlign:"left"}} weighting={0.098} getContent={(r) => r.tamano}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.098} getContent={(r) => separadorDeMiles(r.presupuesto_margen)}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.1245} getContent={(r) => separadorDeMiles(r.presupuesto_margen_unitario)}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.098} getContent={(r) => separadorDeMiles(r.operacion_gastos)}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.098} getContent={(r) => separadorDeMiles(r.operacion_ingresos)}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.098} getContent={(r) => separadorDeMiles(r.operacion_margen)}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.12} getContent={(r) => separadorDeMiles(r.operacion_margen_unitario)}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.05} getContent={(r) => separadorDeMiles(r.desviacion)}/>
                </TableBody>
            </Table>
            <Table data={props.rowsTotales}>
                <TableHeader textAlign={"center"}>
                    <TableCell style={styles.tableHeader} weighting={0.308}></TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.231}>Margen Presupuestado</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.098}></TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.098}></TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.225}>Margen Operacional</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.05}>Des(%)</TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{textAlign:"left"}} weighting={0.305} getContent={(r) => r.titulo}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.1} getContent={(r) => separadorDeMiles(r.margen_presupuestado)}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.13} getContent={(r) => ''}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.1} getContent={(r) => ''}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.101} getContent={(r) => ''}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.1} getContent={(r) => separadorDeMiles(r.margen_operacional)}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.124} getContent={(r) => ''}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.054} getContent={(r) => separadorDeMiles(r.desviacion)}/>
                </TableBody>
            </Table>
            </>)
    } catch (error) {
        console.log("Error ResumenDeMargenesPdf", error);
    }

    let blob = BaseLinkPdf({...props, body:tablaPresupuestoActividad, orientation:"landscape"})

    return blob
}