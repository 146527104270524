import React, { useState, useEffect, useRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Button, InputGroup, Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaCoins } from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import { Api } from '../utils/api';
import { ErrorMessage } from 'react-hook-form'
import { useAlert } from 'react-alert';

function SelectorTipoCategoriaCuenta(props) {
    const alert = useAlert();           // Para las notificaciones
    const refSelector = useRef(null);
    const [tipoCatetoriaCuenta, setTipoCategoriaCuentas] = useState([]);
    const [oculto, setOculto] = useState('')

    useEffect(
        () => {
            obtenerDatos();
        },
        []
    )

    useEffect(
        () => {
            if (props.inicial) { // Solo cuando es distinto a vacio
                if (tipoCatetoriaCuenta.length === 0) { // No hay tipoCatetoriaCuenta cargadas, tenemos que ir a buscar la seleccionada y seleccionar el combobox
                    obtenerDato(props.inicial);
                } else { // Si hay tipoCatetoriaCuenta cargadas, solo seleccionamos la que esté
                    if (refSelector.current.getInstance().state.selected.length === 0) { // Solo cuando no hay un elemento seleccionado en el combobox, esto se da solo en el inicio, ref.current.getInstance()._input.value
                        tipoCatetoriaCuenta.forEach(tipo => {  // Buscamos dentro de las tipoCatetoriaCuenta la que tenemos que seleccionar
                            if (parseInt(tipo.codigo) === parseInt(props.inicial)) {
                                refSelector.current.getInstance()._updateSelected([tipo]); // Automáticamente llama cambiarSelector
                            }
                        });
                    }
                }
            }
        },
        [props.inicial]
    )

    /**
     * Buscamos la información y actualizamos el valor por defecto del combobox
     *
     * @param {integer} predio
     */
    async function obtenerDato(id) {
        let resultado = await Api(`api/tipo-categoria-cuenta/${id}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            if (resultado.data.datos) {
                refSelector.current.getInstance()._updateSelected([resultado.data.datos]); // Automáticamente llama cambiaSelector
            } else {
                alert.show('Error al actualizar combobox tipo categoría cuenta', { type: 'error' });
            }
        } else {
            alert.show('Error al actualizar combobox tipo categoría cuenta', { type: 'error' });
        }
    }


    /**
     * Cargamos las tipoCatetoriaCuenta en el combobox
     *
     * @param {integer} predio
     */
    async function obtenerDatos() {
        let resultado = await Api(`api/tipo-categoria-cuenta`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setTipoCategoriaCuentas(resultado.data.datos);  // se obtiene la lista de tipoCatetoriaCuenta
        } else {
            setTipoCategoriaCuentas([]);
        }
    }

    /**
     * Cada vez que cambia el selector
     *
     * @param {array} selected
     */
    function cambiaSelector(selected) {
        if (props.retornar) {
            props.retornar(selected);
        }
        if (selected[0] !== undefined) {
            setOculto(selected[0].id);
        } else {
            setOculto('');
        }
    }

    return (
        <>
            <InputGroup size="sm">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1"><FaCoins /></InputGroup.Text>
                </InputGroup.Prepend>
                <div style={{ width: 'calc(100% - 62px)' }}>
                    <Typeahead
                        {...props}
                        maxResults={400}
                        bsSize='small'
                        options={tipoCatetoriaCuenta}
                        highlightOnlyResult={true}
                        selectHintOnEnter={true}
                        flip={true}
                        ref={refSelector}
                        isInvalid={props.errors ? (props.errors[props.name] ? true : false) : false}
                        onChange={cambiaSelector}
                        labelKey={(option) => `${option.nombre}`}
                    />
                </div>
                <InputGroup.Append>
                    <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Limpiar opción</Tooltip>}>
                        <Button
                            variant="outline-secondary"
                            disabled={props.desactivarBotonBorrar ? true : false}
                            onClick={() => {
                                refSelector.current.getInstance().clear(); // solo limpia la casila de la unidad, no hace nada más
                                refSelector.current.getInstance()._updateSelected([]); // Este limpia la casilla y activa cambiarSelector
                            }}>
                            <IoMdClose />
                        </Button>
                    </OverlayTrigger>
                </InputGroup.Append>
            </InputGroup>
            {props.register &&
                <Form.Group as={Row} controlId="6">
                    <Col sm="10">
                        <Form.Control
                            type="hidden"
                            name={props.name}
                            value={oculto}
                            onChange={
                                (e) => {
                                    setOculto(e.target.value)
                                }
                            }
                            ref={props.register({
                                required: "Por favor, seleccione una opción"
                            })}
                            isInvalid={props.errors[props.name]} />
                        <ErrorMessage errors={props.errors} name={props.name} as="div"
                            className="invalid-feedback" />
                    </Col>
                </Form.Group>
            }
        </>
    );
}

export default SelectorTipoCategoriaCuenta;