import React, {useState, useEffect, useContext} from 'react';
import {Modal, Button, InputGroup, Form, Row, Col, Tabs, Tab} from 'react-bootstrap';
import SelectorActividad from '../../componentes/SelectorActividad';
import SelectorPeriodo from '../../componentes/SelectorPeriodo'
import SelectorUnidades from '../../componentes/SelectorUnidades';
import FormPresupuestoRoles from './FormPresupuestoRoles';
import {Api} from '../../utils/api';
import UserContext from '../../UserContext';
import { useAlert } from 'react-alert';
import { useForm, ErrorMessage } from 'react-hook-form';
import { FaSave, FaWindowClose } from 'react-icons/fa';

function FormPresupuesto(props) {
    const alert = useAlert();           // Para las notificaciones
    const contexto = useContext(UserContext);
    const { register, handleSubmit, errors, setValue, watch, getValues } = useForm({
        defaultValues: {
            cantidad: '',
            actividad_codigo: '',
            unidad_id: '',
            comentario: '',
            roles:'[]'
        }
    });
    const [tabSeleccionado, setTabSeleccionado] = useState('formulario_presupuesto');

    /**
     * Luego de presionar un tab
     *
     * @param {*} e
     */
    function onSelect(e) {
        setTabSeleccionado(e);
    }

    useEffect(
        () => {
            if (props.presupuestoid) { // Viene un presupuesto id
                getPresupuesto(contexto.predio.predio_id, props.presupuestoid);
            }
        },
        [props.presupuestoid]
    )

    // Cuando el formulario está completo
    const formularioCompleto = async data => {
        if (props.presupuestoid === '') { // Crear el presupuesto
            data.predio_id = contexto.predio.predio_id;
            let resultado = await Api('api/presupuesto', data, { 'Content-Type': 'application/json' }, true, 'post');
            if (resultado && resultado.status === 200) { // Presupuesto creado
                alert.show(resultado.data.msg, { type: 'success' });
                cerrarModal();
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
            }
        } else { // Actualizamos el presupuesto
            data.predio_id = contexto.predio.predio_id;
            let resultado = await Api(`api/presupuesto/${props.presupuestoid}`, data, { 'Content-Type': 'application/json' }, true, 'put');
            if (resultado && resultado.status === 200) { // Presupuesto creado
                alert.show(resultado.data.msg, { type: 'success' });
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
            }
        }
    };

    /**
     * Busca un presupuesto para editar
     *
     * @param {integer} predioId
     * @param {string} presupuesto_id
     */
    async function getPresupuesto(predioId, presupuestoId) {
        let resultado = await Api(`api/presupuesto/${presupuestoId}?predio_id=${predioId}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            let actividad = resultado.data.datos.actividad;
            setValue([
                {cantidad: resultado.data.datos.cantidad},
                {actividad_codigo: actividad.codigo},
                {unidad_id: resultado.data.datos.unidad_id}, // key
                {periodo_id:resultado.data.datos.periodo_id},
                {comentario: resultado.data.datos.comentario},
                {roles:JSON.stringify(resultado.data.datos.roles)}
            ]);
        } else {
            alert.show(resultado.data.mensaje, { type: 'error' });
        }
    }

    /**
     * Función retorno cuando seleccionan un elemento del combobox selector actividad
     *
     * @param {array} data
     */
    function returnSelectorActividad(data) {
    }

    /**
     * Función retorno cuando seleccionan un elemento del combobox selector unidades
     *
     * @param {*} data
     */
    function returnSelectorUnidades(data) {
    }

    /**
     * Cuando cerramos la modal no limpiamos el formulario, ya que el formulario se pierde al igual que la modal del html base
     * entonces no es necesario limpiar, al abrir la modal de nuevo ocupa lo valores por defecto defaultValues
     */
    function cerrarModal() {
        props.onHide(); // Cerrar modal
    }

    return (
        <>
            <Modal show={props.show}  backdrop="static" keyboard={false}>
                <Form noValidate onSubmit={handleSubmit(formularioCompleto)}>
                    <Modal.Header>
                        <Modal.Title>Presupuesto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Tabs defaultActiveKey="formulario_presupuesto" id="presupuesto_tabs" onSelect={onSelect}>
                        <Tab eventKey="formulario_presupuesto" title={`Presupuesto`}>
                        <br></br>
                        <Form.Group as={Row} controlId="selectActividad">
                            <Form.Label column sm="2"> Actividad: </Form.Label>
                            <Col sm="10">
                                <SelectorActividad
                                    id='1'
                                    name="actividad_codigo"
                                    register={register}
                                    errors={errors}
                                    inicial={watch('actividad_codigo')}
                                    returnSelectorActividad={returnSelectorActividad}
                                    actividadesUtilizadas={true}
                                    placeholder="Actividad a presupuestar"
                                    // codigoMin="0"
                                    // codigoMax="8999"
                                    disabled={props.presupuestoid ? true : false}
                                    desactivarBotonBorrar={props.presupuestoid ? true : false}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="selectorPeriodo">
                            <Form.Label column sm="2"> Periodo: </Form.Label>
                            <Col sm="10">
                                <SelectorPeriodo
                                    id='1'
                                    name="periodo_id"
                                    register={register}
                                    errors={errors}
                                    inicial={props.presupuestoid ? watch('periodo_id') : (contexto.predio ? (contexto.predio.periodo? contexto.predio.periodo.id:""): "")}
                                    placeholder="Seleccione el periodo"
                                    returnSelectorActividad={returnSelectorActividad}
                                    actividadesUtilizadas={true}
                                    desactivarBotonBorrar={props.presupuestoid ? true : false}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="selectUnidad">
                            <Form.Label column sm="2"> Unidad: </Form.Label>
                            <Col sm="10">
                                <SelectorUnidades
                                    id='2'
                                    name='unidad_id'
                                    register={register}
                                    errors={errors}
                                    inicial={watch('unidad_id')}
                                    returnSelectorUnidades={returnSelectorUnidades}
                                    placeholder="Unidad en que se medirá el presupuesto"
                                    // disabled={props.presupuestoid ? true : false}
                                    // desactivarBotonBorrar={props.presupuestoid ? true : false}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formTam">
                            <Form.Label column sm="2"> Tamaño: </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name="cantidad"
                                    type="number"
                                    placeholder="Tamaño del presupuesto"
                                    size="sm"
                                    ref={register({
                                        required: "Por favor, ingrese el tamaño del predio",
                                        min: { value: 1, message: "mínimo 1" }
                                    })}
                                    isInvalid={errors.cantidad}
                                />
                                <ErrorMessage errors={errors} name="cantidad" as="div" className="invalid-feedback" />
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="textarea">
                            <Form.Label>Comentario:</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="comentario"
                                rows="2"
                                ref={register({})}/>
                        </Form.Group>
                        <Form.Control
                                as="input"
                                name="roles"
                                rows="2"
                                hidden
                                ref={register({})}/>
                        </Tab>
                        <Tab eventKey="formulario_roles" title={`Roles`}>
                            <br></br>
                            <FormPresupuestoRoles
                                show={true}
                                presupuestoid={props.presupuestoid}
                                tabSeleccionado={tabSeleccionado}
                                register={register}
                                setValue={setValue}
                                errors={errors}
                                roles_presupuesto={getValues("roles")} />
                        </Tab>
                    </Tabs>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" size="sm" onClick={cerrarModal}><FaWindowClose/> Cerrar</Button>
                        <Button type="submit" size="sm" variant="success"><FaSave/> Guardar</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default FormPresupuesto;