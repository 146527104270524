import React, { useState, useContext, useEffect, useRef} from 'react';
import {Typeahead} from 'react-bootstrap-typeahead';
import { Button, InputGroup, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import {Api} from '../utils/api';
import UserContext from '../UserContext';
import { ErrorMessage } from 'react-hook-form';
import { useAlert } from 'react-alert';


function SelectorPeriodo(props) {
    const alert = useAlert();           // Para las notificaciones
    const refSelectorPeriodo = useRef();
    const contexto = useContext(UserContext);
    const [periodos, setPeriodos] = useState([]);
    const [oculto, setOculto] = useState('');

    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                obtenerPeriodos(contexto.predio.predio_id);
            }
        },
        [contexto]
    )
    useEffect(
        () => {
            if (periodos.length === 0) { 
                if (props.inicial){
                    obtenerPeriodo(props.inicial);
                }
                
            } else { 
                if (refSelectorPeriodo.current.getInstance().state.selected.length === 0) { 
                    periodos.forEach(periodo => {  // Buscamos dentro de los periodos la que tenemos que seleccionar
                        if (parseInt(periodo.id) === parseInt(props.inicial)) {
                            refSelectorPeriodo.current.getInstance()._updateSelected([periodo]); // Automáticamente llama cambiarSelector
                        }
                    });
                }
            }
        },
        [props.inicial]
    )
    useEffect(
        () => {
            // Solo si queremos que el elemento seleccionado sea el primero que encuentre
            // Recordar que si se actualiza actividades se volvera a ejecutar esta acción siempre y cuando inicial_primero venga como props
            if (periodos.length!== 0 && props.inicial_primero) {
                refSelectorPeriodo.current.getInstance()._updateSelected([periodos[0]]);
            } else if (periodos.length === 0 && props.inicial_primero) {
                refSelectorPeriodo.current.getInstance().clear(); // solo limpia la casila de la unidad, no hace nada más
                refSelectorPeriodo.current.getInstance()._updateSelected([]); // Este limpia la casilla y activa cambiarSelector
            }
        },
        [periodos] // Solo se ejecutará cuando tengamos valores en actividades
    )
    /**
     * Buscamos una actividad y actualizamos el valor por defecto del combobox
     *
     * @param {integer} predio
     */
    async function obtenerPeriodo(periodo_id) { 
        let resultado = await Api(`/api/periodo/${periodo_id}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            if (resultado.data.periodo) {
                refSelectorPeriodo.current.getInstance()._updateSelected([resultado.data.periodo]); // Automáticamente llama cambiaSelectorActividad
            } else {
                alert.show('Error al actualizar combobox periodos as', { type: 'error' });
            }
        } else {
            alert.show('Error al actualizar combobox periodos', { type: 'error' });
        }
    }
    /**
     * Cargamos las actividades en el combobox
     *
     * @param {integer} predio
     */
    async function obtenerPeriodos(predio) {
        let resultado = '';
        resultado = await Api(`/api/periodo/listado/${predio}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setPeriodos(resultado.data.datos);
        } else {
            setPeriodos([]);
        }
    }
    /**
     * Cada vez que cambia el selector actividad
     *
     * @param {array} selected
     */
    function cambiaSelectorPeriodo(selected) {
        if (props.returnSelectorPeriodo) {
            props.returnSelectorPeriodo(selected);
        }
        if (selected[0] !== undefined) {
            setOculto(selected[0].id);
        } else {
            setOculto('');
        }
    }
    /**
     * Evento para cerrar la modal agregar actividad, si viene cuentaActividad, es porque se creo uno
     *
     * @param {Object} cuentaActividad
     */
    return (
        <>
        <InputGroup size="sm">
            <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1"><FaRegCalendarAlt /></InputGroup.Text>
            </InputGroup.Prepend>
            <div style={{ width: 'calc(100% - 64px)' }}>
                { props.register ?
                    <Typeahead
                        { ...props}
                        bsSize='small'
                        maxResults={400}
                        options={periodos}
                        highlightOnlyResult={true}
                        selectHintOnEnter={true}
                        flip={true}
                        ref={refSelectorPeriodo}
                        isInvalid={props.errors[props.name] ? true : false}
                        onChange={cambiaSelectorPeriodo}
                        labelKey={(option) => `${option.numero}: inicio:${option.inicio}, cierre: ${option.cierre}`}
                    />
                :
                    <Typeahead
                        { ...props}
                        bsSize='small'
                        options={periodos}
                        highlightOnlyResult={true}
                        selectHintOnEnter={true}
                        flip={true}
                        ref={refSelectorPeriodo}
                        onChange={cambiaSelectorPeriodo}
                        labelKey={(option) => `${option.numero}: inicio:${option.inicio}, cierre: ${option.cierre}`}
                    />
                }
            </div>
            <InputGroup.Append>
                    <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Limpiar opción</Tooltip>}>
                        <Button
                            variant="outline-secondary"
                            onClick={() => {
                                refSelectorPeriodo.current.getInstance().clear();
                                setOculto('');
                                if (props.returnSelectorPeriodo) {
                                    props.returnSelectorPeriodo([]);
                                }
                            }}>
                            <IoMdClose />
                        </Button>
                    </OverlayTrigger>
            </InputGroup.Append>
        </InputGroup>
        {/* INPUT INVISIBLE */}
        {props.register &&
            <Form.Control
                type="text"
                name={props.name}
                value={oculto}
                hidden
                onChange={ // Este onChange no se ocupa, ya que nunca modificamos este valor directamente, solo está para que no lance error reactjs
                    (e) => {
                        // setOculto(e.target.value)
                    }
                }
                ref={props.register({
                    required: "Seleccione una opción"
                })}
                isInvalid={props.errors[props.name]}
            />
        }

        {props.errors &&
            <ErrorMessage errors={props.errors} name={props.name} as="div" className="invalid-feedback" />
        }

        </>
    );
}
export default SelectorPeriodo;