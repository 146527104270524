import React, { useState } from 'react';
import { Api } from '../utils/api.js'
import { Alert, Button, Card, Row, Col, Image, Nav, Navbar, Form, Container } from 'react-bootstrap';
import styles from './inicio/styles/inicio.module.css'
import icono_conga from './inicio/images/Group.png'
import logo_uach from './inicio/images/logo_uach.png'
import logo_gob from './inicio/images/logo_gob.png'
import { useForm } from 'react-hook-form'
import { useAlert } from 'react-alert';

function Login(props) {
    const alert = useAlert()
    const { register, handleSubmit } = useForm({});
    const style_boton = {
        background: 'linear-gradient(90.21deg, #0D84A8 -7.85%, #20B099 121.12%)',
        borderRadius: 100,
        marginTop: 50,
        marginBottom: 30,
        width: 157,
        color: 'white'
    };
    const style_header = {
        minHeight: 50,
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        background: 'linear-gradient(178.18deg, #20B099 18.81%, #1C8AB7 108.17%, #1A74C8 158.3%)',
    };
    //const [redireccionar, setRedireccionar] = useState(false);
    const [notificacion, setNotificacion] = useState(false);
    const [usuario, setUsuario] = useState({
        username: '',
        password: '',
        access_token: ''
    });

    function onChange(event) {

        setUsuario({
            ...usuario,
            [event.target.name]: event.target.value
        });
    }

    async function validaUsuario(event) {

        var bodyFormData = new FormData();
        bodyFormData.set('username', usuario.username);
        bodyFormData.set('password', usuario.password);

        try {
            let resultado = await Api(
                'auth/login',
                bodyFormData,
                { 'Content-Type': 'multipart/form-data' },
                false,
                'post');

            if (resultado && resultado.status === 200) {
                localStorage.setItem('token', resultado.data.access_token);
                localStorage.setItem('usuario', resultado.data.usuario);
                setNotificacion(false);
                //setRedireccionar(true);
                props.history.push('/presupuesto/caja');
            } else {
                console.log("tenemos un error");
                // Determinar qué casos tratermos aquí
                console.log(resultado);
            }
        }
        catch (error) {
            // Todos los errores incluyendo auth 401.
            console.log(error);
            setNotificacion(true);
        }
    }

    async function testToken(event) {

        let resultado = await Api('auth/usuario/5');
        if (resultado && resultado.status === 200) {
            console.log(resultado);
        } else {
            console.log('viene un error');
        }
    }
    const onSubmit = async (data, e) => {

        let resultado = await Api('api/email', data, {}, false, 'post');
        if (resultado && resultado.status === 200) {
          alert.show('Solicitud enviada con éxito', { type: 'success' });
        } else {
          console.log('viene un error');
        }
        e.target.reset()
      }
    // useEffect(
    //     () => {
    //         //console.log("useEffect");
    //         // ocultamos el div de alertas
    //         // div_alert.current.style.display = 'none';
    //     },
    //     []
    // )

    return (
        <>
            {/* {redireccionar &&
        <Redirect to='/presupuesto/caja' />} */}
            <Row className="justify-content-md-center row center" style={style_header} >
                <Navbar expand="lg">
                    <Navbar.Brand ><Image src={icono_conga} />
                        <span className={styles.style_titulo1}>  Conga  </span>
                    </Navbar.Brand>
                    <Nav className="mx-auto">
                        <span className={styles.style_titulo}>CONTABILIDAD Y GESTIÓN <br /> SILVO-AGROPECUARIA</span>
                    </Nav>

                </Navbar>
            </Row>
            <Row className="justify-content-md-center" style={{ marginTop: 50, marginBottom: 50 }}>
                <Col lg="5" className="shadow-lg p-3 mb-5 bg-white">
                    <Card className="border-0" >
                        <Card.Body >
                            <Card.Title className={styles.style_titulo_descriptivo}>Login</Card.Title>
                            <Card.Text className={styles.style_texto} >
                                Ingrese con su cuenta
                             </Card.Text>
                            <form>
                                <div className="form-group">
                                    <label htmlFor="username" className={styles.style_texto2}>Nombre de usuario:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="username"
                                        name="username"
                                        value={usuario.username} onChange={onChange} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password" className={styles.style_texto2}>Contraseña:</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        name="password"
                                        value={usuario.password}
                                        onChange={onChange} />
                                </div>
                                <div className="form-group form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="remember" />
                                    <label
                                        className="form-check-label"
                                        htmlFor="remember"
                                        className={styles.style_texto2}>
                                        Recordar</label>
                                </div>
                                <Button className={styles.style_boton2} onClick={validaUsuario}>Ingresar</Button>
                                &nbsp;
                                <div className="form-group">
                                    {notificacion &&
                                        <Alert variant='warning'>
                                            Usuario o contraseña incorrectos!
                                        </Alert>
                                    }
                                </div>
                            </form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="5" style={{
                    textAlign: 'center', background: 'linear-gradient(178.18deg, #20B099 18.81%, #1C8AB7 108.17%, #1A74C8 158.3%)'
                }}
                    className="shadow-lg p-3 mb-5 bg-white rounded">
                    <Card className="border-0" style={{ background: 'linear-gradient(178.18deg, #20B099 18.81%, #1C8AB7 108.17%, #1A74C8 158.3%)' }} >
                        <Card.Body >
                            <Card.Title className={styles.style_titulo_descriptivo_login}>Solicitar Cuenta</Card.Title>
                            <Card.Text className={styles.style_texto_login} >
                                Usted podrá planificar, controlar y evaluar permanentemente la empresa a partir de un presupuesto y de un control de gestión.
                  </Card.Text>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row lg="1" md="1" sm="1" xs="1">
                                    <Col>
                                        <Form.Control name="nombre" placeholder="Nombre"
                                            ref={
                                                register({
                                                    required: { value: true, message: "Nombre obligatorio" }
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col className={styles.style_email} style={{ marginTop: 15 }}>
                                        <Form.Control name="email" type="email" placeholder="Email"
                                            ref={
                                                register({
                                                    required: { value: true, message: "correo obligatorio" }
                                                })
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ marginTop: 15, width: '100%' }}>
                                        <Form.Control name="descripcion" as="textarea" placeholder="Descripción..."
                                            ref={
                                                register({
                                                    required: { value: true, message: "descripcion obligatorio" }
                                                })
                                            }
                                        />
                                    </Col>
                                </Row>

                                <Button variant="" style={style_boton} type="submit">Solicitar</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <footer id="contacto" style={{ marginBottom: 50 }}>
                <div className="justify-content-md-center" style={{ textAlign: 'center', marginBottom: 50 }}>
                    <span className={styles.style_titulo_descriptivo}>Contacto</span>
                </div>
                <Row className="justify-content-md-center row " style={{ textAlign: 'center', marginBottom: 50 }} lg="3" md="1" sm="1" xs="1">
                    <Col className={styles.style_texto}>jlerdon@uach.cl</Col>
                    <Col className={styles.style_texto}>56-63-2221235</Col>
                    <Col className={styles.style_texto}>Universidad Austral de Chile</Col>
                </Row>
                <Container style={{ borderBottom: '1px solid #BFBFBF' }} fluid>
                </Container>
                <div className="justify-content-md-center" style={{ textAlign: 'center', marginTop: 21, marginBottom: 38 }}>
                    <span className={styles.style_titulo_footer} >Aplicación creada por</span>
                </div>
                <Row className="justify-content-md-center " style={{ textAlign: 'center' }} lg="3" md="1" sm="1" xs="1">
                    <Col >
                        <Image src={logo_uach} className={styles.style_img_footer1}></Image>
                    </Col>
                    <Col >
                        <div className={styles.autor_intelectual}>
                            <p className={styles.titulo}>Autor de la creación intelectual:</p>
                            <p>Ing. Agr. Dr. Juan Ricardo Lerdon Ferrada</p>
                            <p>Facultad de Ciencias Agrarias y Alimentarias</p>
                            <p>Instituto de economía Agraria</p>
                        </div>
                    </Col>
                </Row>
            </footer>
        </>
    );
}
export default Login;