import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form, Row, Col, Accordion, Card } from 'react-bootstrap';
import SelectorUnidades from '../../componentes/SelectorUnidades';
import { Api } from '../../utils/api';
import UserContext from '../../UserContext';
import { useAlert } from 'react-alert';
import { useForm, ErrorMessage } from 'react-hook-form';
import { FaSave, FaWindowClose } from 'react-icons/fa';
import SelectorCuenta from '../../componentes/SelectorCuenta';
import SelectorMeses from './SelectorMeses';
import { tienePermisos } from '../../utils/Functions';

function FormDatoPresupuesto(props) {
    const alert = useAlert();           // Para las notificaciones
    const contexto = useContext(UserContext);
    const { register, handleSubmit, errors, setValue, getValues, triggerValidation, reset, watch } = useForm({
        defaultValues: {
            codigo_cuenta: '',
            unidad_id: '',
            codigo_fecha: '',
            total1: '',
            tamano2: '',
            valor_unitario2: '',
            total2: '',
            cantidad_unidad3: '',
            tamano3: '',
            valor_unitario3: '',
            total3: '',
            iva: 'SI'
        }
    });
    const inicialForm = {
        opcion: 0
    }
    const [dataForm, setDataForm] = useState(inicialForm);
    const [finalizar, setFinalizar] = useState(false);

    useEffect(
        () => {
            if (props.presupuesto) { // Viene un presupuesto
                setFinalizar(false);
                let data = props.presupuesto;
                if (props.presupuesto.tipo_ingreso === 'a') {
                    setDataForm({
                        ...dataForm,
                        opcion: 0
                    })
                    setValue([
                        { codigo_cuenta: data.codigo_cuenta },
                        { unidad_id: data.unidad_id },
                        { codigo_fecha: data.codigo_fecha },
                        { total1: data.monto_total },
                        { tamano2: '' },
                        { valor_unitario2: '' },
                        { total2: '' },
                        { cantidad_unidad3: '' },
                        { tamano3: '' },
                        { valor_unitario3: '' },
                        { total3: '' },
                        { iva: data.iva ? 'SI' : 'NO' }
                    ]);
                } else if (props.presupuesto.tipo_ingreso === 'b') {
                    setDataForm({
                        ...dataForm,
                        opcion: 1
                    })
                    setValue([
                        { codigo_cuenta: data.codigo_cuenta },
                        { unidad_id: data.unidad_id },
                        { codigo_fecha: data.codigo_fecha },
                        { total1: '' },
                        { tamano2: data.cantidad },
                        { valor_unitario2: data.monto_unidad },
                        { total2: data.monto_total },
                        { cantidad_unidad3: '' },
                        { tamano3: '' },
                        { valor_unitario3: '' },
                        { total3: '' },
                        { iva: data.iva ? 'SI' : 'NO' }
                    ]);
                } if (props.presupuesto.tipo_ingreso === 'c') {
                    setDataForm({
                        ...dataForm,
                        opcion: 2
                    })
                    setValue([
                        { codigo_cuenta: data.codigo_cuenta },
                        { unidad_id: data.unidad_id },
                        { codigo_fecha: data.codigo_fecha },
                        { total1: '' },
                        { tamano2: '' },
                        { valor_unitario2: '' },
                        { total2: '' },
                        { cantidad_unidad3: data.cantidad_unidad },
                        { tamano3: data.cantidad },
                        { valor_unitario3: data.monto_unidad },
                        { total3: data.monto_total },
                        { iva: data.iva ? 'SI' : 'NO' },
                    ]);
                }
            } else { // Cuando viene vacio limpiamos la modal, dejamos activada la primera opción de los tabs
                setDataForm(inicialForm);
            }
        },
        [props.presupuesto]
    )

    /**
     * Cerrar modal editar/crear dato presupuesto
     */
    function cerrarModal() {
        setFinalizar(true); // Para que no se vuelva a ejecutar useEffect SelectorActividad
        props.onHide(); // Cerrar modal
    }

    /**
     * Cuando presionan sobre el tab(accordeon)
     * @param {object} e
     */
    function clickAccordion(e) {
        setDataForm({
            ...dataForm,
            opcion: e.target.id
        })
    }

    /**
     * Validar formularios
     */
    async function validar() {
        if (parseInt(dataForm.opcion) === parseInt(0)) { // Tab 1
            const tab1 = await triggerValidation(['unidad_id', 'codigo_cuenta', 'codigo_fecha', 'total1']);
            if (tab1) {
                guardar();
            }
        }
        if (parseInt(dataForm.opcion) === parseInt(1)) { // Tab 2
            const tab2 = await triggerValidation(['unidad_id', 'codigo_cuenta', 'codigo_fecha', 'tamano2', 'valor_unitario2', 'total2']);
            if (tab2) {
                guardar();
            }
        }
        if (parseInt(dataForm.opcion) === parseInt(2)) { // Tab 3
            const tab3 = await triggerValidation(['unidad_id', 'codigo_cuenta', 'codigo_fecha', 'cantidad_unidad3', 'tamano3', 'valor_unitario3', 'total3']);
            if (tab3) {
                guardar();
            }
        }
    }

    /**
     * Guardamos cambios
     */
    async function guardar() {
        let data = {
            codigo_cuenta: getValues().codigo_cuenta,
            codigo_fecha: getValues().codigo_fecha,
            iva: getValues().iva,
            unidad_id: getValues().unidad_id
        }
        if (parseInt(dataForm.opcion) === parseInt(0)) {
            data.total = parseInt(getValues().total1);
            data.cantidad_unidad = 0;
            data.tamano = 0;
            data.valor_unitario = 0;
        } else if (parseInt(dataForm.opcion) === parseInt(1)) {
            data.total = parseInt(getValues().total2);
            data.cantidad_unidad = 0;
            data.tamano = parseInt(getValues().tamano2);
            data.valor_unitario = parseInt(getValues().valor_unitario2);
        } else if (parseInt(dataForm.opcion) === parseInt(2)) {
            data.total = parseInt(getValues().total3);
            data.cantidad_unidad = parseInt(getValues().cantidad_unidad3);
            data.tamano = parseInt(getValues().tamano3);
            data.valor_unitario = parseInt(getValues().valor_unitario3);
        }
        switch (parseInt(dataForm.opcion)) {
            case 1:
                data.tipo_ingreso = 'b';
                break;
            case 2:
                data.tipo_ingreso = 'c';
                break;
            default:
                data.tipo_ingreso = 'a';
                break;
        }
        data.predio_id = contexto.predio.predio_id;

        if (props.presupuesto) { // Editar dato presupuesto
            let resultado = await Api(`api/presupuesto/${props.presupuestoseleccionadoid}/dato_presupuesto/${props.presupuesto.id}`, data, { 'Content-Type': 'application/json' }, true, 'put');
            if (resultado && resultado.status === 200) { // Presupuesto creado
                alert.show(resultado.data.msg, { type: 'success' });
                // cerrarModal();
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
            }
        } else { // Crear dato presupuesto
            let resultado = await Api(`api/presupuesto/${props.presupuestoseleccionadoid}/dato_presupuesto`, data, { 'Content-Type': 'application/json' }, true, 'post');
            if (resultado && resultado.status === 200) { // Presupuesto creado
                alert.show(resultado.data.msg, { type: 'success' });
                cerrarModal();
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
            }
        }
    }

    /**
     * Este proceso permite calcular el total dependiendo si es la opción 2 o 3
     *
     * @param {object} e
     */
    function calculartotal(e) {
        if (parseInt(dataForm.opcion) === parseInt(1)) { // Tab 2
            let tamano2 = (getValues().tamano2 ? getValues().tamano2 : 1);
            let valor_unitario2 = (getValues().valor_unitario2 ? getValues().valor_unitario2 : 1);
            let total2 = tamano2 * valor_unitario2;
            setValue('total2', total2);
        } else if (parseInt(dataForm.opcion) === parseInt(2)) { // Tab 3
            let cantidad_unidad3 = (getValues().cantidad_unidad3 ? getValues().cantidad_unidad3 : 1);
            let tamano3 = (getValues().tamano3 ? getValues().tamano3 : 1);
            let valor_unitario3 = (getValues().valor_unitario3 ? getValues().valor_unitario3 : 1);
            let total3 = cantidad_unidad3 * tamano3 * valor_unitario3;
            setValue('total3', total3);
        }
    }

    return (
        <>
            <Modal {...props} backdrop="static" keyboard={false} size="lg">
                <Form noValidate onSubmit={handleSubmit(validar)}>
                    <Modal.Header>
                        <Modal.Title>Dato Presupuesto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Form.Label column sm="2">Cuenta:</Form.Label>
                            <Col sm="10">
                                <SelectorCuenta
                                    id='1'
                                    register={register}
                                    errors={errors}
                                    codigoMin={"600000"}
                                    codigoMax={"800000"}
                                    name="codigo_cuenta"
                                    inicial={watch('codigo_cuenta')} />
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label column sm="2">Mes:</Form.Label>
                            <Col sm="10">
                                <SelectorMeses
                                    id="selector_m"
                                    name="codigo_fecha"
                                    register={register}
                                    errors={errors}
                                    finalizar={finalizar}
                                    inicial={watch('codigo_fecha')} />
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label column sm="2">IVA:</Form.Label>
                            <Col sm="10">
                                <Form.Check name='iva' inline type='radio' label={`Sí`} id={`iva_si`}
                                    value={'SI'} ref={register({ required: true })} />
                                <Form.Check name='iva' inline type='radio' label={`No`} id={`iva_no`}
                                    value={'NO'} ref={register({ required: true })} />
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label column sm="2"> Unidad: </Form.Label>
                            <Col sm="10">
                                <SelectorUnidades
                                    id='2'
                                    register={register}
                                    name="unidad_id"
                                    errors={errors}
                                    inicial={watch('unidad_id')}
                                    // returnSelectorUnidades={returnSelectorUnidades}
                                    placeholder="Unidad en que se medirá el presupuesto"
                                // disabled={props.presupuestoid ? true : false}
                                // desactivarBotonBorrar={props.presupuestoid ? true : false}
                                />
                            </Col>
                        </Row>
                        {/* defaultActiveKey="0" */}
                        <Accordion defaultActiveKey={String(dataForm.opcion)} activeKey={String(dataForm.opcion)} style={{marginTop:'10px'}}>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={clickAccordion} id="0">
                                    Total
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        Total: Cuando la información disponible corresponde al monto total de insumo o producto a utilizar ( no se dispone de información del detalle).
                                        <Row>
                                            <Form.Label column sm="3"> Total: </Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                    name="total1"
                                                    type="number"
                                                    placeholder="Total"
                                                    size="sm"
                                                    ref={register({
                                                        required: "Por favor, ingrese el total"
                                                    })}
                                                    isInvalid={errors.total1}/>
                                                <ErrorMessage errors={errors} name="total1" as="div" className="invalid-feedback" />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1" onClick={clickAccordion} id="1">
                                    (Tamaño) * (Costo Unitario)
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        Tamaño por costo unitario: Cantidad de unidades y costo o ingreso global por unidad. (tratar de poner el ejemplo).
                                        <Row>
                                            <Form.Label column sm="3"> Tamaño: </Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                    name="tamano2"
                                                    type="number"
                                                    placeholder=""
                                                    size="sm"
                                                    onChange={calculartotal}
                                                    ref={register({
                                                        required: "Por favor, ingrese el tamaño"
                                                    })}
                                                    isInvalid={errors.tamano2}/>
                                                <ErrorMessage errors={errors} name="tamano2" as="div" className="invalid-feedback" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column sm="3"> Valor Unitario: </Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                    name="valor_unitario2"
                                                    type="number"
                                                    placeholder=""
                                                    size="sm"
                                                    onChange={calculartotal}
                                                    ref={register({
                                                        required: "Por favor, ingrese el valor unitario"
                                                    })}
                                                    isInvalid={errors.valor_unitario2}/>
                                                <ErrorMessage errors={errors} name="valor_unitario2" as="div" className="invalid-feedback" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column sm="3"> Total: </Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                    name="total2"
                                                    type="number"
                                                    placeholder=""
                                                    size="sm"
                                                    disabled
                                                    ref={register({
                                                        required: "Por favor, ingrese el total"
                                                    })}
                                                    isInvalid={errors.total2}/>
                                                <ErrorMessage errors={errors} name="total2" as="div" className="invalid-feedback" />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="2" onClick={clickAccordion} id="2">
                                    (Cantidad por Unidad) * (Costo Unitario) * (Tamaño)
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        Se conoce la cantidad por unidad, el precio unitario del insumo o producto y el número de unidades involucradas (tamaño).
                                        <Row>
                                            <Form.Label column sm="3"> Cantidad por Unidad: </Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                    name="cantidad_unidad3"
                                                    type="number"
                                                    placeholder=""
                                                    size="sm"
                                                    onChange={calculartotal}
                                                    ref={register({
                                                        required: "Por favor, ingrese el campo"
                                                    })}
                                                    isInvalid={errors.cantidad_unidad3}/>
                                                <ErrorMessage errors={errors} name="cantidad_unidad3" as="div" className="invalid-feedback" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column sm="3"> Tamaño: </Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                    name="tamano3"
                                                    type="number"
                                                    placeholder=""
                                                    size="sm"
                                                    onChange={calculartotal}
                                                    ref={register({
                                                        required: "Por favor, ingrese el tamaño"
                                                    })}
                                                    isInvalid={errors.tamano3}/>
                                                <ErrorMessage errors={errors} name="tamano3" as="div" className="invalid-feedback" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column sm="3"> Valor Unitario: </Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                    name="valor_unitario3"
                                                    type="number"
                                                    placeholder=""
                                                    size="sm"
                                                    onChange={calculartotal}
                                                    ref={register({
                                                        required: "Por favor, ingrese el valor unitario"
                                                    })}
                                                    isInvalid={errors.valor_unitario3}/>
                                                <ErrorMessage errors={errors} name="valor_unitario3" as="div" className="invalid-feedback" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column sm="3"> Total: </Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                    name="total3"
                                                    type="number"
                                                    placeholder=""
                                                    size="sm"
                                                    disabled
                                                    ref={register({
                                                        required: "Por favor, ingrese el total"
                                                    })}
                                                    isInvalid={errors.total3}/>
                                                <ErrorMessage errors={errors} name="total3" as="div" className="invalid-feedback" />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" size="sm" onClick={cerrarModal}><FaWindowClose /> Cerrar</Button>
                        {tienePermisos('editar',contexto.predio.perfil) &&
                            <Button type="submit" onClick={validar} size="sm" variant="success"><FaSave /> guardar</Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default FormDatoPresupuesto;