import React, {useState, useContext} from 'react';
import {Api} from '../utils/api';
import UserContext from '../UserContext'
import {Alert, Modal, Button} from 'react-bootstrap';
import Buscador from '../componentes/Buscador';


function SelectorPredio(props) {

    const {hide, logout,  ...rest} = props
    const contexto = useContext(UserContext)
    const [predios, setPredios] = useState([]);
    const [prediosFiltrados, setPrediosFiltrados] = useState(predios);
    const [textoBuscar, setTextoBuscar] = useState('');

    function retornoBusqueda(texto) {
        if (texto.length>0){
            let nuevaListaPredios = [];
            predios.forEach(function(predio, x,y){
                let nombrePredioAux = predio.nombre_predio.toUpperCase();
                let indexPredio = nombrePredioAux.indexOf(texto.toUpperCase());
                let indexProductor = -1;
                if (predio.nombre_productor!==null) {
                    let nombreProductorAux = predio.nombre_productor.toUpperCase();
                    indexProductor = nombreProductorAux.indexOf(texto.toUpperCase());
                }
                if (indexPredio>=0 || indexProductor>=0) {
                    nuevaListaPredios.push(predio);
                }
            });
            setPrediosFiltrados([...nuevaListaPredios]);
        }
        else{
            setPrediosFiltrados([...predios])
        }
	}

    function listaPredios(predios){
        let listaPredios = predios.map((p) =>
        <button
            key={p.predio_id.toString()}
            type="button"
            onClick={(e)=>seleccionarPredio(p.predio_id, p.nombre_predio, p.periodo, p.cooperativa,p.perfil)}
            className="list-group-item list-group-item-action">
                {p.nombre_predio}
                {p.nombre_productor !== null &&
                    <><br/><small key={p.predio_id.toString() + 'a'}>{p.nombre_productor}</small></>
                }
                {p.roles_prediales !== [] &&
                    <><br/>{ p.roles_prediales.map((rol,i) => {
                        if (i!==0) {
                            return <small key={p.predio_id.toString() + i}>, {rol}</small>
                        }
                        else{
                            return <small key={p.predio_id.toString() + i}>{rol}</small>
                        }
                    }) }</>
                }
        </button>);
        return listaPredios;
    }

    async function getPredios() {
        let resultado = await Api('auth/usuario/predios', null, {}, true);
        if (resultado && resultado.status === 200) {
            setPredios(resultado.data);
            setPrediosFiltrados(resultado.data);
        } else {
            setPredios([]);
        }
    }

    function renderPredios(){
        if(predios.length > 0) {
            return listaPredios(prediosFiltrados);
        } else {
            return (
                <Alert  variant="warning">
                    Usted no tiene predios asignados por lo que no podrá utilizar el sistema. Por favor contáctese con el administrador para solicitar acceso a un predio.
                    <Button variant="outline-primary" onClick={logout}>Salir del sistema</Button>
                </Alert>);
        }
    }

    function seleccionarPredio(id, nombre_predio, periodo, cooperativa,perfil) {
        contexto.setPredio({predio_id: id, nombre_predio: nombre_predio, periodo: periodo, cooperativa: cooperativa, perfil: perfil});
        props.hide();
    }

    return (
        <Modal {...rest} onShow={getPredios}>
            <Modal.Header>
                <Modal.Title>Predios disponibles</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Selecciona el predio que quieres utilizar</p>
                <Buscador retornoBusqueda={retornoBusqueda} busqueda={textoBuscar}
                    placeholder='Búsqueda por nombre Predio o nombre Productor...'/>
                <div className="list-group" style={{maxHeight: 'calc(90vh - 210px)', overflowY: 'auto'}}>
                    {renderPredios()}
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default SelectorPredio;