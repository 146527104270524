import React, { useState, useEffect, useContext } from 'react';
import { useAlert } from 'react-alert';
import BaseGrid from '../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import GridMenu from '../../componentes/grid/GridMenu';
import Layout from '../../componentes/Layout';
import { LongTextFormatter, sortRows, EmptyRowsView, RutFormatter, RelFormatter, VigFormatter } from '../../componentes/grid/utils';
import { Api } from '../../utils/api';
import UserContext from '../../UserContext';
import { Button, Row, Col, ButtonGroup } from 'react-bootstrap';
import DefaultButtons from '../../componentes/grid/DefaultButtons';
import { FormEntidad, getEntidad, FormEliminarEntidad } from './FormEntidad';
import Buscador from '../../componentes/Buscador';
import { downloadBlob, tienePermisos } from '../../utils/Functions';
import { EntidadesPdf } from './pdf/EntidadesPdf';


function Entidades(props) {
    const alert = useAlert();			// Para las notificaciones
    const { ContextMenuTrigger } = Menu
    const [showForm, setShowForm] = useState(false);
    const [showEliminarEntidad, setShowEliminarEntidad] = useState(false);
    const [textoBuscar, setTextoBuscar] = useState('');
    const entidadDefault = {
        "rut": "",
        "razon_social": "",
        "giro": "",
        "comuna": "",
        "relacion": "C",
        "habilitada": true,
        "contacto": ""
    }
    const [entidadForm, setEntidadForm] = useState(entidadDefault);

    const columns = [

        { key: 'rut', name: 'Rut', width: 100, formatter: RutFormatter },
        { key: 'razon_social', name: 'Razón Social', formatter: LongTextFormatter },
        { key: 'giro', name: 'Giro' },
        { key: 'comuna', name: 'Comuna' },
        { key: 'relacion', name: 'Relación', formatter: RelFormatter },
        { key: 'habilitada', name: 'Vigencia', formatter: VigFormatter },
        { key: 'contacto', name: 'Contacto' },
    ];

    const [rows, setRows] = useState([]);

    const contexto = useContext(UserContext);

    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                obtenerDatos(contexto.predio.predio_id);
            }
        },
        [contexto]
    )

    async function obtenerDatos(predio) {
        let resultado = await Api(`api/entidad/listado?predio_id=${predio}`);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
        } else {
            alert.show('Error al cargar entidades', { type: 'error' });
            setRows([]);
        }
    }

    async function retornoBusqueda(texto) {
        let resultado = await Api(`api/entidad/listado?predio_id=${contexto.predio.predio_id}&texto=${texto}`);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
        } else {
            setRows([])
            alert.show('Error al cargar entidades', { type: 'error' });
        }
    }

    function nuevoDato() {
        setEntidadForm(entidadDefault);
        setShowForm(true);
    }

    const editRow = rowIdx => {
        new Promise(function (resolve, reject) {
            getEntidad(rows[rowIdx].id).then(function (entidad) {
                setEntidadForm(entidad);
                setShowForm(true);
            })
        })
    };

    const deleteRow = rowIdx => {
        new Promise(function (resolve, reject) {
            getEntidad(rows[rowIdx].id).then(function (entidad) {
                setEntidadForm(entidad);
                setShowEliminarEntidad(true);
            })
        })
    };

    function agregarEntidad() {
        setShowForm(false);
        obtenerDatos(contexto.predio.predio_id);
    }

    function cerrarModalEliminar() {
        setShowEliminarEntidad(false);
        obtenerDatos(contexto.predio.predio_id);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf() {
        let props = {
            columns: columns,
            rows: rows,
            titulo: "Clientes y Proveedores",
            bajada: contexto.predio.nombre_predio
        }
        alert.show('La descarga comenzará en breve', { type: 'success' });
        new Promise(function (resolve, reject) {
            EntidadesPdf(props).then(
                function (blob) {
                    downloadBlob(blob,
                        `Entidades-${contexto.predio.nombre_predio}.pdf`);
                }, function (blob) {
                    alert.show('Error al exportar', { type: 'error' })
                })
        })
    }


    return (
        <Layout title="Maestro Clientes y Proveedores">
            <Row style={{ paddingBottom: '10px' }}>
                <Col>
                    <Buscador retornoBusqueda={retornoBusqueda} busqueda={textoBuscar} placeholder='Búsqueda por razón social o rut...' />
                </Col>
                <Col>
                    <ButtonGroup className="float-right">
                        {tienePermisos('editar',contexto.predio.perfil) &&
                            <Button
                                variant="success"
                                size="sm"
                                onClick={nuevoDato}>Agregar Entidad</Button>
                        }

                        <DefaultButtons
                            imprimir={exportarPdf} />
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <BaseGrid
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            minHeight={400}
                            contextMenu={
                                <GridMenu
                                    id="gridUsuariosPredio"
                                    options={[
                                        {
                                            texto: "Editar",
                                            funcion: (e, { rowIdx }) => editRow(rowIdx)
                                        },
                                        {
                                            texto: "Eliminar",
                                            funcion: (e, { rowIdx }) => deleteRow(rowIdx)
                                        }
                                    ]}
                                />
                            }
                            RowsContainer={ContextMenuTrigger}
                            onGridSort={(sortColumn, sortDirection) =>
                                setRows(sortRows(rows, sortColumn, sortDirection))
                            }
                            emptyRowsView={EmptyRowsView} />
                    </div>
                </Col>
            </Row>
            <FormEntidad
                show={showForm}
                onHide={() => setShowForm(false)}
                entidad={entidadForm}
                funcionRetorno={function (data) { agregarEntidad() }} />
            <FormEliminarEntidad
                show={showEliminarEntidad}
                onHide={cerrarModalEliminar}
                entidad={entidadForm}
                funcionRetorno={function (data) { cerrarModalEliminar() }}
                size="md"
            />
        </Layout>
    );
}

export default Entidades;