import React from 'react';
import {BaseStylesPdf} from '../../../../componentes/pdf/BaseStylesPdf';
import {BaseLinkPdf} from '../../../../componentes/pdf/BaseLinkPdf';
import {Table, TableHeader, TableBody, TableCell, DataTableCell} from '@david.kucsai/react-pdf-table';
import { separadorDeMilesOrVacio, DateFormatterField, completaRut } from '../../../../componentes/grid/utils';
import { Text } from '@react-pdf/renderer'

export function ResumenComprasVentasPdf(props) {
    const styles = BaseStylesPdf();
    var tablaBalanceInicial = '';
    try {
        tablaBalanceInicial = (
            <>
            <Table data={props.rowsVentas}>
                <TableHeader textAlign={"center"}>
                    <TableCell weighting={1}>Compras</TableCell>
                </TableHeader>
            </Table>
            <Table data={props.rowsCompras}>
                <TableHeader textAlign={"center"}>
                    <TableCell style={styles.tableHeader} weighting={0.204}>Resumen de compras</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Exento</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Afecto</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>IVA</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Esp Rec</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Esp No Rec</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Retención Honorarios</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Total</TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{ textAlign: "left", fontSize: 10 }} weighting={0.2} getContent={(r) => r.titulo} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.exento)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.afecto)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.iva)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.especifico_recuperable)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.especifico_no_recuperable)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.retencion_honorarios)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.total)} />
                </TableBody>
            </Table>
                <Text break style={{marginTop: 20}}>

            </Text>
            <Table data={props.rowsVentas}>
                <TableHeader textAlign={"center"}>
                    <TableCell style={styles.tableHeader} weighting={1}>Ventas</TableCell>
                </TableHeader>
            </Table>
            <Table data={props.rowsVentas} style={{marginTop:'20px'}} marginTop={"20px"}>
                <TableHeader textAlign={"center"} style={{marginTop:'20px'}} marginTop={"20px"}>
                    <TableCell style={styles.tableHeader} weighting={0.203}>Resumen de compras</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Exento</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Afecto</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>IVA</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.2}>ILA</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Feria Retenido</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Total</TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{ textAlign: "left", fontSize: 10 }} weighting={0.2} getContent={(r) => r.titulo} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.exento)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10 }} weighting={0.101} getContent={(r) => separadorDeMilesOrVacio(r.afecto)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10 }} weighting={0.101} getContent={(r) => separadorDeMilesOrVacio(r.iva)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10 }} weighting={0.199} getContent={(r) => separadorDeMilesOrVacio(r.ila)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.feria_retenido)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.total)} />
                </TableBody>
            </Table>
            </>)
    } catch (error) {
        console.log("Error ResumenComprasVentasPdf", error);
    }

    let blob = BaseLinkPdf({ ...props, body: tablaBalanceInicial, orientation:"landscape"})

    return blob
}