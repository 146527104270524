import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { Api } from "../../utils/api";
import UserContext from "../../UserContext";
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell } from "recharts";
import { generaColor, agruparDatos, colorCuenta } from "../../componentes/chart/utils";

function ChartEstructuraCostos(props) {
  const contexto = useContext(UserContext);
  const [data, setData] = useState([]);
  const [colores, setColores] = useState([]);

  async function getReporte(predio) {
    let resultado = await Api(
      `api/reportes/presupuesto/estructura_costos/${predio}/${contexto.predio.periodo.id}`,
      null,
      {},
      true
    );
    if (resultado && resultado.status === 200) {
      resultado.data.datos.map( (dato) => { return dato.porcentaje = parseFloat(dato.porcentaje) } )
      setData(agruparDatos(resultado.data.datos, "6000", "Otros", 10));
    } else {
      setData([]);
      console.log("viene un error");
    }
  }

  useEffect(() => {
    if (contexto.predio.predio_id && contexto.predio.periodo) {
      getReporte(contexto.predio.predio_id);
    }
  }, [contexto]);

  useEffect(() => {
    if (data.length <= 18) {
      setColores(generaColor());
    } else {
      let coloresTemporales = generaColor();
      for (var i = 18; i < data.length; i++) {
        coloresTemporales.push(colorCuenta(data[i].codigo, 10000));
      }
      setColores(coloresTemporales);
    }
  }, [data]);

  return (
    <Modal {...props} backdrop="static" keyboard={false} size="lg">
      <Modal.Header>
        <Modal.Title>Gráfico Estructura de Costos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ width: "100%", height: 300 }}>
          <ResponsiveContainer>
            <PieChart
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <Pie
                dataKey="porcentaje"
                nameKey="nombre"
                isAnimationActive={false}
                data={data}
                outerRadius={100}
                fill="#8884d8"
                label
              >
                {data.map((entry, index) => (
                  // <Cell key={`cell-${index}`} fill={colorCuenta(entry.codigo, 10000)}/>
                  <Cell key={`cell-${index}`} fill={colores[index]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend layout="vertical" align="right" verticalAlign="top" />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ChartEstructuraCostos;