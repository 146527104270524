import React, {useContext} from 'react';
import "./GridMenu.css";
import { Menu } from "react-data-grid-addons";
import { tienePermisos } from '../../utils/Functions';
import UserContext from '../../UserContext';
function GridMenu({idx, id, rowIdx, options, filasDeshabilitadas=[]}) {

  const {ContextMenu, MenuItem, /*SubMenu*/} = Menu;
  const contexto = useContext(UserContext);
  /**
   * funcion que genera items extra para el context menu
   *
   * @param {number} rowIdx id de la fila
   * @param {number} idx id del elemento
   * @param {array} options array de la forma {texto, funcion}
   * @returns lista de items JSX
   */
  function menuItems(rowIdx, idx, options){
    let listaItems = [];
    let index = 1;
    options.forEach(option => {
      let conversion = '';
      if ((option.texto).toUpperCase() === 'EDITAR') { // para permitir ver la modal ya que quizás tiene más información que la grilla
        conversion = 'ver';
      } else {
        conversion = option.texto;
      }
      if (tienePermisos(conversion,contexto.predio.perfil)) {
        listaItems.push(
          <MenuItem data={{ rowIdx, idx }} key={index} onClick={option.funcion}>
            {option.texto}
          </MenuItem>
        )
        index++;
      }
    });
    return listaItems;
  }

  return (
    ((filasDeshabilitadas.indexOf(rowIdx)===-1) &&
    <ContextMenu id={id}>
      {options && menuItems(rowIdx, idx, options)}
      {/* <SubMenu title="Insert Row">
        <MenuItem data={{ rowIdx, idx }} onClick={onRowInsertAbove}>
          Above
        </MenuItem>
        <MenuItem data={{ rowIdx, idx }} onClick={onRowInsertBelow}>
          Below
        </MenuItem>
      </SubMenu> */}
    </ContextMenu>
  )
  );
}

export default GridMenu;