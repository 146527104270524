import React from 'react';
import {BaseStylesPdf} from '../../../componentes/pdf/BaseStylesPdf';
import {BaseLinkPdf} from '../../../componentes/pdf/BaseLinkPdf';
import {Table, TableHeader, TableBody, TableCell, DataTableCell} from '@david.kucsai/react-pdf-table';
import {separadorDeMiles} from '../../../componentes/grid/utils';

export function TecnicoEconomicoPdf(props) {
    const styles = BaseStylesPdf();
    const tablaTecnicoEconomico =(
        <>
        <Table data={props.rows}>
            <TableHeader textAlign={"center"}>
                {/* el "weighting" idealmente debe sumar 1 para que quede todo ordenado */}
                <TableCell style={styles.tableHeader} weighting={0.07}>Cuenta</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.15}>Descripcion</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.21}>Mes</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.05}>Un.</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.05}>Ctd/Un</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.1}>$ Unitario</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.07}>Tamaño</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.1}>Total/Un</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.1}>Total</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.1}>Acum. Cta.</TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell style={styles.textCenter} weighting={0.07} getContent={(r) => r.codigo}/>
                <DataTableCell style={styles.textLeft} weighting={0.15} getContent={(r) => r.nombre}/>
                <DataTableCell style={styles.textLeft} weighting={0.21} getContent={(r) => r.descripcion_fecha}/>
                <DataTableCell style={styles.textCenter} weighting={0.05} getContent={(r) => r.unidad}/>
                <DataTableCell style={styles.textRigh} weighting={0.05} getContent={(r) => separadorDeMiles(r.cantidad_unidad)}/>
                <DataTableCell style={styles.textRigh} weighting={0.1} getContent={(r) => separadorDeMiles(r.monto_unidad)}/>
                <DataTableCell style={styles.textRigh} weighting={0.07} getContent={(r) => separadorDeMiles(r.cantidad)}/>
                <DataTableCell style={styles.textRigh} weighting={0.1} getContent={(r) => separadorDeMiles(r.total_unidad)}/>
                <DataTableCell style={styles.textRigh} weighting={0.1} getContent={(r) => separadorDeMiles(r.monto_total)}/>
                <DataTableCell style={styles.textRigh} weighting={0.1} getContent={(r) => separadorDeMiles(r.acumulado)}/>
            </TableBody>
        </Table>
        <Table data={props.rowsTotales}>
            <TableHeader textAlign={"center"}>
                <TableCell weighting={0.7} style={{...styles.tableHeader,...{ padding: '2px 2px 2px 32px'}}}></TableCell>
                <TableCell weighting={0.1} style={styles.tableHeader}>Total/Un</TableCell>
                <TableCell weighting={0.1} style={styles.tableHeader}>Total</TableCell>
                <TableCell weighting={0.1} style={styles.tableHeader}></TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell style={{...styles.textRigh,...{ padding: '2px 2px 2px 32px'}}} weighting={0.7} getContent={(r) => r.nombre}/>
                <DataTableCell style={styles.textRigh} weighting={0.1} getContent={(r) => separadorDeMiles(r.total_unidad)}/>
                <DataTableCell style={styles.textRigh} weighting={0.1} getContent={(r) => separadorDeMiles(r.monto_total)}/>
                <DataTableCell style={styles.textCenter} weighting={0.1} getContent={(r) => ""}/>
            </TableBody>
        </Table>
        </>)

    let blob = BaseLinkPdf({...props, body:tablaTecnicoEconomico, orientation:"landscape"})

    return blob
}