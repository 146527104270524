import React, { useState, useEffect, useRef} from 'react';
import {Typeahead} from 'react-bootstrap-typeahead';
import { Button, InputGroup, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { FaPercentage } from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import {Api} from '../utils/api';
import { ErrorMessage } from 'react-hook-form';

function SelectorImpuestos(props) {
    const ref = useRef();
    const [impuestos, setImpuestos] = useState([]);
    const [oculto, setOculto] = useState('');

    useEffect(
        () => {
            obtenerImpuestos();
        },
        []
    )

    useEffect(
        () => {
            if (props.inicial) { // El props inicial es distinto de vacio '', tiene uno seleccionado(acordarse es el input oculto)
                if (impuestos.length === 0) { // No hay impuestos cargadas, tenemos que ir a buscar la seleccionada y seleccionar el combobox
                    obtenerImpuesto(props.inicial);
                } else { // Si hay impuestos cargadas, solo seleccionamos la que esté
                    if (ref.current.getInstance().state.selected.length === 0) { // Solo cuando no hay un elemento seleccionado en el combobox, esto se da solo en el inicio, ref.current.getInstance()._input.value
                        impuestos.forEach(impuesto => {  // Buscamos dentro de las impuestos la que tenemos que seleccionar
                            if (impuesto.nombre === props.inicial ) {
                                ref.current.getInstance()._updateSelected([impuesto]); // Automáticamente llama cambiarSelector
                            }
                        });
                    }
                }
            }
        },
        [props.inicial]
    )

    /**
     * Solicitar un impuesto
     *
     * @param {string} nombre
     */
    async function obtenerImpuesto(nombre) {
        let resultado = await Api(`api/administracion/impuestos?nombre=${nombre}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            if (resultado.data.datos) {
                ref.current.getInstance()._updateSelected([resultado.data.datos]); // Automáticamente llama cambiarSelector
            } else {
                alert.show('Error al cargar combobox impuestos', { type: 'error' });
            }
        } else {
            setImpuestos([]);
        }
    }

    /**
     * Cargar todos los impuestos
     */
    async function obtenerImpuestos() {
        if (props.movimiento_id){
            let resultado = await Api(`api/administracion/impuestos?movimiento_id=${props.movimiento_id}`, null, {}, true);
            if (resultado && resultado.status === 200) {
                setImpuestos(resultado.data.datos);
            } else {
                setImpuestos([]);
            }
        } else{
            let resultado = await Api(`api/administracion/impuestos`, null, {}, true);
            if (resultado && resultado.status === 200) {
                setImpuestos(resultado.data.datos);
            } else {
                setImpuestos([]);
            }
        }
    }

    /**
     * Cada vez que selecciona una opción en el combobox
     * También cuando borra un caracter o todo el contenido
     *
     * @param {*} selected
     */
    function cambiarSelector(selected) {
        if (props.retornar) {
            props.retornar(selected);
        }
        if (selected[0] !== undefined) {
            setOculto(selected[0].nombre);
        } else {
            setOculto('');
        }
    }

    return (
        <>
        <InputGroup size="sm">
            <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1"><FaPercentage /></InputGroup.Text>
            </InputGroup.Prepend>
            <div style={{ width: 'calc(100% - 64px)' }}>
                { props.register ?
                    <Typeahead
                        { ...props}
                        bsSize='small'
                        options={impuestos}
                        highlightOnlyResult={true}
                        selectHintOnEnter={true}
                        flip={true}
                        ref={ref}
                        isInvalid={props.errors[props.name] ? true : false}
                        onChange={cambiarSelector}
                        // onInputChange={onInputChange}
                        labelKey={(option) => (option.nombre + (option.tasa !== 0 ? (': '+ Math.round(option.tasa * 100) + '%') :''))}
                    />
                :
                    <Typeahead
                        { ...props}
                        bsSize='small'
                        options={impuestos}
                        highlightOnlyResult={true}
                        selectHintOnEnter={true}
                        flip={true}
                        ref={ref}
                        onChange={cambiarSelector}
                        labelKey={(option) => `${option.nombre}: ${option.tasa}`}
                    />
                }
            </div>
            <InputGroup.Append>
                <OverlayTrigger
                    placement='top'
                    overlay={<Tooltip>Limpiar opción</Tooltip>}>
                    <Button
                        variant="outline-secondary"
                        disabled={props.desactivarBotonBorrar ? true : false}
                        onClick={() => {
                            ref.current.getInstance().clear(); // solo limpia la casila de la oculto, no hace nada más
                            ref.current.getInstance()._updateSelected([]); // Este limpia la casilla y activa cambiarSelector
                        }}>
                        <IoMdClose />
                    </Button>
                </OverlayTrigger>
            </InputGroup.Append>
        </InputGroup>
        {/* INPUT INVISIBLE */}
        {props.register &&
            <Form.Control
                type="text"
                name={props.name}
                value={oculto}
                hidden
                onChange={(e) => {}} // Este onChange no se ocupa, ya que nunca modificamos este valor directamente, solo está para que no lance error reactjs
                ref={props.register({
                    required: "Seleccione una opción"
                })}
                isInvalid={props.errors[props.name]}
            />
        }

        {props.errors &&
            <ErrorMessage errors={props.errors} name={props.name} as="div" className="invalid-feedback" />
        }
        </>
    );
}

export default SelectorImpuestos;