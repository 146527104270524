import React, {useState, useEffect, useContext, useRef} from 'react';
import { useForm, ErrorMessage } from 'react-hook-form';
import BaseGrid from '../../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import GridMenu from '../../../componentes/grid/GridMenu';
import Layout from '../../../componentes/Layout';
import {LongTextFormatter, sortRows, EmptyRowsView, PercentageFormatter} from '../../../componentes/grid/utils';
import {Api, ObjectToFormdata, toBase64} from '../../../utils/api';
import UserContext from '../../../UserContext';
import { Button, Row, Col, ButtonGroup, Modal, Form, Tabs, Tab } from 'react-bootstrap';
import DefaultButtons from '../../../componentes/grid/DefaultButtons';
import Buscador from '../../../componentes/Buscador';
import { useAlert } from 'react-alert';
import { FaSave, FaWindowClose, FaFileDownload, FaSearch } from 'react-icons/fa';
import Fecha from '../../../componentes/Fecha';
import { fechaHoy, exportar_archivo, validaSize } from '../../../utils/Functions';
import FormAsistencia from './FormAsistencia';

export function LibroActas(props) {

    const {ContextMenuTrigger} = Menu;
    
    const [textoBuscar, setTextoBuscar] = useState('');
	const alert = useAlert();			// Para las notificaciones


	const columns = [
		{ key: 'fecha', name: 'Fecha', sortable: true, width: 250, },
		{ key: 'lugar', name: 'Lugar', width: 200, sortable: true, formatter: LongTextFormatter },
		{ key: 'resumen', name: 'Resumen', sortable: true, formatter: LongTextFormatter },
		{ key: 'archivo_acta', name: 'Archivo', width: 300, sortable: true, formatter: LongTextFormatter},
		{ key: 'asistencia', name: 'Libro de asistencia', sortable: true, width: 250, formatter: PercentageFormatter },
    ];
    
	const [rows, setRows] = useState([]);
	const contexto = useContext(UserContext);

	const [activarModalAgregarActa, setActivarModalActa] = useState(false);			// Modal para agregar acta
	const [activarModalEliminarActa, setActivarModalEliminarActa] = useState(false);			// Modal para eliminar acta
    const [actaForm, setActaForm] = useState(
		{
			"id":"",
			"fecha":fechaHoy(),
			"lugar": "",
			"resumen":"",
			"archivo_acta":"",
			"archivo_asistencia":"",
			"predio_id":contexto.predio.predio_id
		});
	const [acta, setActa] = useState({})

	useEffect(
		() => {
			cargarActas();
		},
		[contexto]
	)

	/**
	 * Buscamos y cargamos todos los actas en la grilla
	 */
	async function cargarActas() {

		let resultado = await Api(`api/contabilidad/cooperativas/libro-actas/${contexto.predio.predio_id}`, null, {}, true);
		if (resultado && resultado.status === 200) {
			setRows(resultado.data.datos);
		} else {
			alert.show('Error al cargar libro de actas', { type: 'error' });
			setRows([]);
		}
    }
    
    /**
     * Función para búsqueda específica de actas
     * 
     * @param {String} texto 
     */
    async function retornoBusqueda(texto) {
		let resultado = await Api(`api/contabilidad/cooperativas/libro-actas/${contexto.predio.predio_id}?texto=${texto}`, null, {}, true);
		if (resultado && resultado.status === 200) {
			setRows(resultado.data.datos);
		} else {
            setRows([])
			alert.show('Error al cargar libro de actas', { type: 'error' });
		}
	}

	/**
	 * Acción para desplegar modal agregar acta
	 */
	function nuevoDato() {
		setActaForm({
			"id":"",
			"fecha": fechaHoy(),
			"lugar":"",
			"resumen":"",
			"archivo_acta":"",
			"archivo_asistencia":"",
			"predio_id":contexto.predio.predio_id
		});
		setActivarModalActa(true);
	}

	/**
	 * Evento para cerrar el modal agregar acta
	 */
	function cerrarModalAgregar() {
		cargarActas();
		setActivarModalActa(false);
	}

	/**
	 * Evento para cerrar el modal eliminar acta
	 */
	function cerrarModalEliminar() {
		cargarActas();
		setActivarModalEliminarActa(false);
	}


	/**
	 * Permite enviar el formulario para crear un nuevo acta
	 */
	async function agregarActa(data) {
		cerrarModalAgregar();
	}

	/**
	 * Cuando queremos editar un acta, desde el context menú
	 *
	 * @param {integer} rowIdx
	 */
	const editRow = rowIdx => {
		new Promise(function(resolve, reject){
			getActa(rows[rowIdx].id).then(function(acta){
				setActaForm(acta);
				setActivarModalActa(true);
			})
		})
	};


	/**
	 * Cuando queremos elimininar un acta, desde el context menú
	 *
	 * @param {*} rowIdx
	 */
	const deleteRow = rowIdx => {
		new Promise(function(resolve, reject){
			getActa(rows[rowIdx].id).then(function(acta){
				setActaForm(acta);
				setActivarModalEliminarActa(true);
			})
		})
	};



	function onRowClick(rowIdx) {
        if (rowIdx !== -1) { // CUANDO PRESIONAN LOS TITULOS SE EJECUTA Y DEVUELVE -1, NO QUEREMOS QUE LANCE ERROR
            setActa(rows[rowIdx]);
        }
    }

    const archivoActions = [
        {
            icon: <span><FaFileDownload /></span>,
            callback: () => {
				if (acta.download !== undefined){
					exportar_archivo('api/uploads/'+acta.archivo_acta, acta.archivo_acta);
				}
            }
        },
      ];

    function getCellActions(column, row) {
        if (column.key === "archivo_acta") {
            const cellActions = {
                archivo_acta: archivoActions
            };
            return cellActions[column.key];
        } 
    }


	return (
		<Layout title="Libro de Actas">
			<Row style={{paddingBottom:'10px'}}>
                <Col>
                    <Buscador retornoBusqueda={retornoBusqueda} busqueda={textoBuscar} placeholder='Búsqueda de actas...'/>
                </Col>
				<Col>
					<ButtonGroup className="float-right">
						<Button variant="success" size="sm" onClick={nuevoDato}>Agregar Acta</Button>
						<DefaultButtons />
					</ButtonGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<div>
						<BaseGrid
							columns={columns}
							getCellActions={getCellActions}
                    		onRowClick={onRowClick}
							rowGetter={i => rows[i]}
							rowsCount={rows.length}
							minHeight={400}
							contextMenu={
								<GridMenu
									id="pac_1"
									options = {[
										{texto: 'Editar',
										funcion: (e, { rowIdx }) => editRow(rowIdx)},
										{texto: 'Eliminar',
										funcion: (e, { rowIdx }) => deleteRow(rowIdx)},
									]}
								/>
							}
							RowsContainer={ContextMenuTrigger}
							onGridSort={(sortColumn, sortDirection) =>
								setRows(sortRows(rows, sortColumn, sortDirection))
							}
							emptyRowsView={EmptyRowsView}/>
					</div>
				</Col>
			</Row>
			<ModalFormularioActa
				show={activarModalAgregarActa}
				onHide={cerrarModalAgregar} 
				acta={actaForm} 
				funcionRetorno={function(data){agregarActa(data)}}
				size="md"
			/>
			<FormularioEliminarActa
				show={activarModalEliminarActa}
				onHide={cerrarModalEliminar} 
				acta={actaForm} 
				funcionRetorno={function(data){cerrarModalEliminar()}}
				size="md"
			/>
		</Layout>
	);
}

export function ModalFormularioActa(props) {

    const refSubmitButton = useRef();
	const {acta, funcionRetorno, errors, setValue, register, ...rest} = props;
	
    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Agregar acta
            </Modal.Header>
            <Modal.Body>
				<FormularioActa acta={acta} funcionRetorno={funcionRetorno} fordwardRef={refSubmitButton}></FormularioActa>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.onHide}><FaWindowClose/> Cerrar</Button>
                <Button onClick={(e) => refSubmitButton.current.click()} size="sm" variant="success"><FaSave/> Guardar</Button>
            </Modal.Footer>

        </Modal>

    )
}

export function FormularioActa(props){
    const { register, handleSubmit, errors, setValue, watch } = useForm();
    const alert = useAlert();           // Para las notificaciones

	const {acta, funcionRetorno, ...rest} = props;
	const [archB64, setArchB64] = useState(null);
	const [archB64Asistencia, setArchB64Asistencia] = useState(null);
	
	const [tabSeleccionado, setTabSeleccionado] = useState('formulario_acta');
    
    /**
     * Luego de presionar un tab
     *
     * @param {*} e
     */
    function onSelect(e) {
        setTabSeleccionado(e);
    }

    const onSubmit = data => {
        enviarFormulario(data);
    }

    useEffect(
        () => {
            if (acta) {
                setValue([
                    {id:acta.id},
                    {fecha: acta.fecha},
                    {lugar:acta.lugar},
                    {resumen:acta.resumen},
					{archivo_acta:acta.archivo_acta},
					{archivo_asistencia:acta.archivo_asistencia},
					{predio_id:acta.predio_id}
                ]);
            }
        },
        [acta]
    )

    async function enviarFormulario(data){
		data.predio_id = acta.predio_id;
		data.archB64 = archB64;
		data.archB64Asistencia = archB64Asistencia;
		//data.append({"predio_id":acta.predio_id})
        data = ObjectToFormdata(data);
        let resultado = await Api('api/contabilidad/cooperativas/libro_actas/guardar', data, { 'Content-Type': 'application/json' }, true, 'post');
		if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            if (props.funcionRetorno) {
                props.funcionRetorno(data);
            }
		} else {
            alert.show(resultado.data.msg, { type: 'error' });
            //props.funcionRetorno(data);
		}
	}
	

    return (
		<Tabs defaultActiveKey="formulario_acta" id="acta_tabs" onSelect={onSelect}>
			<Tab eventKey="formulario_acta" title={`Acta`}>
				<br></br>
				<Form noValidate onSubmit={handleSubmit(onSubmit)}>
					<Col sm="12">
						<Form.Row>
							<Form.Control type="hidden" name="id" defaultValue={acta.id} ref={register({})}></Form.Control>
							<Form.Group as={Col} md="12" controlId="validationCustom01">
								<Form.Label>Fecha: </Form.Label>
								<Fecha
									name='fecha'
									register={register}
									errors={errors}
									dateFormat='dd/MM/yyyy h:mm aa' // dd/MM/Y h:mm aa - dd/MM/Y - MM/Y
									dateFormatOculto='y-m-d h:i:s' // y-m-d h:i:s
									inicial={watch('fecha')}/>
							</Form.Group>
							<Form.Group as={Col} md="12" controlId="validationCustom02">
								<Form.Label>Lugar</Form.Label>
								<Form.Control type="text" placeholder="Lugar..." name="lugar" defaultValue={acta.lugar}
									ref={register({
										required: "Por favor, ingrese el lugar donde se desarrolló la asamblea",
										minLength: {value: 4,message: "Largo mínimo 4 caracteres"},
										maxLength: {value: 50, message: "Largo máximmo 50"}
									})}
									isInvalid={errors.lugar} size="sm" />
								<ErrorMessage errors={errors} name="lugar" as="div" className="invalid-feedback" />
							</Form.Group>
							<Form.Group as={Col} md="12" controlId="validationCustom03">
								<Form.Label>Resumen / Tema</Form.Label>
								<Form.Control as="textarea" name="resumen" ref={register({
										required: "Por favor, ingrese la cantidad de cuotas",
									})} defaultValue={acta.resumen} />
								<ErrorMessage errors={errors} name="resumen" as="div" className="invalid-feedback" />
							</Form.Group>
							<Form.Group as={Col} md="12" controlId="validationCustom03">
								<Form.Label>Archivo</Form.Label>
								<Form.Control type="file" accept='.doc, .docx, .pdf, .xls, .xlsx' name="file"
									//defaultValue={acta.archivo}
									ref={register({
										required: (acta.id) ? "" : "Por favor, ingrese un archivo",
										validate: (value) => validaSize(value) || "Tamaño máximo 5 Mb"
									})}
									isInvalid={errors.file} size="sm"
									onChange={(e) => setArchB64(e.target.files[0])}/>
								<ErrorMessage errors={errors} name="file" as="div" className="invalid-feedback" />
							</Form.Group>
							<Form.Control
                                as="input"
                                name="cooperados"
                                rows="2"
                                hidden
                                ref={register({})}/>
							{ acta.id &&
								<>
									<Col md="12">
										<p>Archivo guardado: {acta.archivo_acta}</p>
										<Button size="sm" variant="primary" 
											onClick={()=>{exportar_archivo('api/uploads/'+acta.archivo_acta, acta.archivo_acta);}}>
												<FaFileDownload /> Descargar
										</Button>
									</Col>
								</>
							}
							
						</Form.Row>
					</Col>
					<Button type="submit" size="sm" variant="success" ref={props.fordwardRef} hidden><FaSave/> Guardar</Button>
				</Form>
			</Tab>
			<Tab eventKey="formulario_asistencia" title={`Asistencia`}>
				<br></br>
				<FormAsistencia
					show={true}
					acta={acta}
					actaid={acta.id}
					tabSeleccionado={tabSeleccionado}
					register={register}
					setValue={setValue}
					errors={errors}
					setArchB64Asistencia={setArchB64Asistencia} />
			</Tab>
		</Tabs>
			
    )
}

export async function getActa(actaId){
    let resultado = await Api('api/contabilidad/cooperativas/acta/'+actaId, null, {}, true);
    return resultado.data.acta;
}

export async function getArchivo(archivo){
    let blob = await Api('api/uploads/'+archivo, null, {}, true);
    return blob;
}

export function FormularioEliminarActa(props) {

    const {acta, funcionRetorno, ...rest} = props;
    const alert = useAlert();           // Para las notificaciones

    async function eliminarActa(data){
        //data = ObjectToFormdata(data);
        let resultado = await Api('api/contabilidad/cooperativas/acta/eliminar/'+data.id,null,{},true,'delete');
		if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            props.funcionRetorno(data);
		} else {
            alert.show(resultado.data.msg, { type: 'error' });
            //props.funcionRetorno(data);
		}
    }

    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Eliminar Acta
            </Modal.Header>
            <Modal.Body>
                <Col sm="12">
                ¿Está seguro de eliminar el acta de {acta.fecha}?
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.onHide}>
                    Cerrar
                    </Button>
                    <Button variant="danger" size="sm"
                        onClick={(e) => eliminarActa(acta)}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}