import React from 'react';
import MenuConga from './Menu.js';
import {Container, Row, Col, Breadcrumb} from 'react-bootstrap';


function Layout(props) {
    return (
        <Container fluid={true}>
            <Row>
                <Col>
                    <MenuConga />
                </Col>
            </Row>
            {/* <Row style={{paddingTop:'10px'}}>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item active>{props.title}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row> */}
            <Row>
                <Col>{props.children}</Col>
            </Row>
            <footer>
                <hr />
                <a href="/">CONGA: Sistema de contabilidad y gestión Agropecuaria. Desarrollado por AltaVenta SpA</a>
            </footer>
        </Container>
    );
}

export default Layout;