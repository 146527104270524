import { Api } from './api';
export function getYMD(fecha) {
    if (fecha) {
        let mes = fecha.getMonth() + 1;
        if (mes <= 9) {
            mes = '0' + mes;
        }
        let dia = fecha.getDate();
        if (dia <= 9) {
            dia = '0' + dia;
        }
        return fecha.getFullYear() + '-' + mes + '-' + dia;
    } else {
        return '';
    }
}

/**
 * Cambia el formato de fecha 2008-01-31 a 31-01-2008
 * @param {Date} fecha
 */
export function getDMY(fecha) {
    if (fecha) {
        fecha = fecha.replace(/-/g, '/');
        fecha = new Date(fecha);
        let mes = fecha.getMonth() + 1;
        if (mes <= 9) {
            mes = '0' + mes;
        }
        let dia = fecha.getDate();
        if (dia <= 9) {
            dia = '0' + dia;
        }
        return dia + '/' + mes + '/'  + fecha.getFullYear();
    } else {
        return '';
    }
}

export function getYMDHIS(fecha) {
    if (fecha) {
        let mes = fecha.getMonth() + 1;
        if (mes <= 9) {
            mes = '0' + mes;
        }
        let dia = fecha.getDate();
        if (dia <= 9) {
            dia = '0' + dia;
        }
        let hora = fecha.getHours();
        if (hora <= 9) {
            hora = '0' + hora;
        }
        let minutos = fecha.getMinutes();
        if (minutos <= 9) {
            minutos = '0' + minutos;
        }
        return fecha.getFullYear() + '-' + mes + '-' + dia + ' ' + hora + ':' + minutos + ':00';
    } else {
        return '';
    }
}

export function fechaHoy() {
    let fecha = new Date();
    let mes = fecha.getMonth() + 1;
    if (mes <= 9) {
        mes = '0' + mes;
    }
    let dia = fecha.getDate();
    if (dia <= 9) {
        dia = '0' + dia;
    }
    let hora = fecha.getHours();
    if (hora <= 9) {
        hora = '0' + hora;
    }
    let minutos = fecha.getMinutes();
    if (minutos <= 9) {
        minutos = '0' + minutos;
    }
    return fecha.getFullYear() + '-' + mes + '-' + dia;
}

/**
 * Esta función procesa la información y crea el archivo excel temporal
 *
 * @export
 * @param {string} ruta
 * @param {Alert} alert
 */
export async function exportar(ruta, alert) {
    let resultado = await Api(ruta, null, {}, true, 'get');
    if (resultado && resultado.status === 200 && resultado.data.data) {
        alert.show('La descarga comenzará en breve', { type: 'success' });
        download(`${process.env.REACT_APP_API_URL}${resultado.data.data.path}`, resultado.data.data.name);
    } else {
        if( resultado && resultado.data.msg){
            alert.show(`No se puede exportar, mensaje: ${resultado.data.msg}`, { type: 'error' });
        }else{
            alert.show('No se puede exportar', { type: 'error' });
        }
        
    }
}


export async function exportar_archivo(url, filename) {
    fetch(`${process.env.REACT_APP_API_URL}${url}`).then(function (t) {
        return t.blob().then((b) => {
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", filename);
            a.click();
        }
        );
    });
}

/**
 * Descarga el archivo
 *
 * @param {string} url
 * @param {string} filename
 */
function download(url, filename) {
    fetch(url).then(function (t) {
        return t.blob().then((b) => {
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", filename);
            a.click();
        }
        );
    });
}

/** Retorna el número del último día de cada mes
 *
 * @export
 * @param {number} y year
 * @param {number} m month
 * @returns
 */
export function ultimoDia(y,m){
    return new Date(y, m +1, 0).getDate();
}

/**
 * Retorna la fecha final luego de cierta cantidad de periodos
 *
 * @export
 * @param {*} fecha fecha de inicio
 * @param {number} [delta=1] cantidad de periodos
 * @returns
 */
export function deltaPeriodo(fecha, delta=1){
    let anhoSiguiente = fecha.getMonth === 0 ? fecha.getFullYear() + (delta-1) : fecha.getFullYear()+delta
    let mesAnterior = fecha.getMonth === 0 ? 12 : fecha.getMonth()-1;
    let ultimoDiaMes = ultimoDia(anhoSiguiente,mesAnterior);
    return new Date(anhoSiguiente,mesAnterior,ultimoDiaMes);
}

/**
 * Para descargar un blob generado en el frontend
 *
 * @export
 * @param {*} blob
 * @param {*} fileName
 */
export function downloadBlob(blob, fileName) {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
}

export function capitalize(txt){
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
}

export function validaSize(input){
    if (input.length > 0){
        if (input[0].size > 5242880){ // 5mb máximo
            return false;
        }
        else{
            return true;
        }
    }
    return true;
}

export function tienePermisos(permiso,perfil="") {
    let usuario = JSON.parse(localStorage.getItem('usuario'));
    if (perfil !==null){
        perfil = perfil.toLocaleLowerCase()
    }
    if (usuario !== null && usuario !== '' && usuario.admin_sistema === true) {
        if (['VER', 'EDITAR', 'ELIMINAR', 'CAMBIAR CONTRASEÑA', 'CREAR TOKEN'].includes(permiso.toUpperCase())) {
            return true;
        } else {
            return false;
        }
    } else if (usuario !== null && usuario !== '' && perfil === 'administrador') {
        if (['VER', 'EDITAR', 'ELIMINAR', 'CAMBIAR CONTRASEÑA', 'CREAR TOKEN'].includes(permiso.toUpperCase())) {
            return true;
        } else {
            return false;
        }
    } else if (usuario !== null && usuario !== '' && perfil === 'visualizador') {
        if (['VER'].includes(permiso.toUpperCase())) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}