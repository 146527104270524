import React, { useState, useEffect, useContext } from 'react';
import { Modal, Tabs, Tab, Row, Col, Button } from 'react-bootstrap';
import { Api } from '../../../utils/api';
import UserContext from '../../../UserContext';
import {
    BarChart, Bar, Legend, Tooltip, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Cell, Pie, PieChart
} from 'recharts';
import { colorActividad, NumberGraphicFormatter, colorCuenta } from '../../../componentes/chart/utils';


function ChartResumenDeMargenes(props) {

    const data = props.rows



    const [datosPorcentajesGastosGestion, setDatosPorcentajesGastosGestion] = useState([])
    const [datosPorcentajesIngresosGestion, setDatosPorcentajesIngresosGestion] = useState([])

    useEffect(
        () => {
            agrupaDatosGraficos(props.rows)
        },
        [props.rows]
    )

    function agrupaDatosGraficos(rows) {
        //Quita los valores nulos para gastos gestión
        let datosGraficosGastos = rows
        datosGraficosGastos = datosGraficosGastos.filter(data => data.operacion_gastos !== "")
        datosGraficosGastos = datosGraficosGastos.filter(data => data.operacion_gastos !== 0)

        //Quita los valores nulos para ingresos gestión
        let datosGraficosIngresos = rows
        datosGraficosIngresos = datosGraficosIngresos.filter(data => data.operacion_ingresos !== "")
        datosGraficosIngresos = datosGraficosIngresos.filter(data => data.operacion_ingresos !== 0)

        let total_porcentaje_gastos_gestion = 0
        let total_porcentaje_ingresos_gestion = 0

        let total_otros1 = 0
        let total_otros2 = 0

        let porcentaje_gastos_gestion;
        let porcentaje_ingresos_gestion;

        let datosPorcentajesGastosGestionArray = [];
        let datosPorcentajesIngresosGestionArray = [];

        datosGraficosGastos.forEach(data => {
            if (data.operacion_gastos != null) {
                total_porcentaje_gastos_gestion = parseFloat(total_porcentaje_gastos_gestion) + parseFloat(data.operacion_gastos)
            }

        })
        datosGraficosGastos.forEach(data => {
            if (data.operacion_gastos != null) {
                porcentaje_gastos_gestion = ((data.operacion_gastos * 100) / total_porcentaje_gastos_gestion)
                porcentaje_gastos_gestion = parseFloat(porcentaje_gastos_gestion.toFixed(2));
                if (porcentaje_gastos_gestion < 1) {
                    total_otros1 = total_otros1 + porcentaje_gastos_gestion
                } else {
                    datosPorcentajesGastosGestionArray.push({
                        'porcentaje_gastos_gestion': porcentaje_gastos_gestion, 'nombre': data.nombre, 'codigo': data.codigo
                    })
                }
            }

        })
        datosGraficosIngresos.forEach(data => {
            if (data.operacion_ingresos != null ) {
                total_porcentaje_ingresos_gestion = parseFloat(total_porcentaje_ingresos_gestion) + parseFloat(data.operacion_ingresos)
            }
        })
        datosGraficosIngresos.forEach(data => {
            if (data.operacion_ingresos != null) {
                porcentaje_ingresos_gestion = ((data.operacion_ingresos * 100) / total_porcentaje_ingresos_gestion)
                porcentaje_ingresos_gestion = parseFloat(porcentaje_ingresos_gestion.toFixed(2));
                if (porcentaje_ingresos_gestion < 1) {
                    total_otros2 = total_otros2 + porcentaje_ingresos_gestion
                } else {
                    datosPorcentajesIngresosGestionArray.push(
                        { 'porcentaje_ingresos_gestion': porcentaje_ingresos_gestion, 'nombre': data.nombre, 'codigo': data.codigo }
                    )
                }
            }
        })
        //Categoria "Otros"
        total_otros1 = parseFloat(total_otros1.toFixed(2))
        total_otros2 = parseFloat(total_otros2.toFixed(2))

        if (total_otros1 > 0) {
            datosPorcentajesGastosGestionArray.push({ 'porcentaje_gastos_gestion': total_otros1, 'nombre': 'Otros', 'codigo': 100000 })
        }
        if (total_otros2 > 0) {
            datosPorcentajesIngresosGestionArray.push({ 'porcentaje_ingresos_gestion': total_otros2, 'nombre': 'Otros', 'codigo_cuenta': 100000 })
        }
        setDatosPorcentajesGastosGestion(datosPorcentajesGastosGestionArray);
        setDatosPorcentajesIngresosGestion(datosPorcentajesIngresosGestionArray);
    }
    return (
        <Modal {...props} backdrop="static" keyboard={false} className="modal-xl">

            <Modal.Body>
                <Row style={{ borderBottom: '1px solid #ccc' }} >
                    <Col lg="6" >
                        <h4 style={{ textAlign: 'center' }}> Presupuesto</h4>
                        <Tabs defaultActiveKey="margen-presupuestado" id="MargenPresupuestado">
                            <Tab eventKey="margen-presupuestado" title="Margen Presupuestado">

                                <div style={{ width: '100%', height: 300 }}>
                                    <ResponsiveContainer>
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={data}
                                            margin={{
                                                top: 20, right: 30, left: 20, bottom: 5,
                                            }}
                                        >
                                            <Tooltip formatter={(value) => new Intl.NumberFormat('es').format(value)} />
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="nombre" />
                                            <YAxis tickFormatter={value => NumberGraphicFormatter({ value: value })} />
                                            <Tooltip />
                                            {/* <Legend /> */}
                                            <Bar dataKey="presupuesto_margen" >
                                                {
                                                    data.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={colorActividad(entry.codigo)} />
                                                    ))
                                                }
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>

                            </Tab>

                            <Tab eventKey="margen_unitario" title="Margen Unitario Presupuestado">
                                <div style={{ width: '100%', height: 300 }}>

                                    <ResponsiveContainer>
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={data}
                                            margin={{
                                                top: 20, right: 30, left: 20, bottom: 5,
                                            }}
                                        >
                                            <Tooltip formatter={(value) => new Intl.NumberFormat('es').format(value)} />
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="nombre" />
                                            <YAxis tickFormatter={value => NumberGraphicFormatter({ value: value })} />
                                            <Tooltip />
                                            {/* <Legend /> */}
                                            <Bar dataKey="presupuesto_margen_unitario" >
                                                {
                                                    data.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={colorActividad(entry.codigo)} />
                                                    ))
                                                }
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </Tab>
                        </Tabs>
                    </Col>
                    <Col lg="6">
                        <h4 style={{ textAlign: 'center' }}> Operaciones</h4>
                        <Tabs defaultActiveKey="margen-gestion" id="MargenGestion">
                            <Tab eventKey="margen-gestion" title="Margen Gestion">
                                <div style={{ width: '100%', height: 300 }}>
                                    <ResponsiveContainer>
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={data}
                                            margin={{
                                                top: 20, right: 30, left: 20, bottom: 5,
                                            }}
                                        >
                                            <Tooltip formatter={(value) => new Intl.NumberFormat('es').format(value)} />
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="nombre" />
                                            <YAxis tickFormatter={value => NumberGraphicFormatter({ value: value })} />
                                            <Tooltip />
                                            {/* <Legend /> */}
                                            <Bar dataKey="operacion_margen" >
                                                {
                                                    data.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={colorActividad(entry.codigo)} />
                                                    ))
                                                }
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>

                            </Tab>
                            <Tab eventKey="margen_unitario_gestion" title="Margen Unitario Gestión">
                                <div style={{ width: '100%', height: 300 }}>
                                    <ResponsiveContainer>
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={data}
                                            margin={{
                                                top: 20, right: 30, left: 20, bottom: 5,
                                            }}
                                        >
                                            <Tooltip formatter={(value) => new Intl.NumberFormat('es').format(value)} />
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="nombre" />
                                            <YAxis tickFormatter={value => '$'+ NumberGraphicFormatter({ value: value })} />
                                            <Tooltip />
                                            {/* <Legend /> */}
                                            <Bar dataKey="operacion_margen_unitario">
                                                {
                                                    data.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={colorActividad(entry.codigo)} />
                                                    ))
                                                }
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" style={{ borderRight: '1px solid #ccc' }}>
                        <h4 style={{ textAlign: 'center' }}>Gastos Gestión</h4>
                        <div style={{ width: '100%', height: 350 }}>
                            <ResponsiveContainer>
                                <PieChart
                                    margin={{
                                        top: 5, right: 30, left: 20, bottom: 5,
                                    }}>
                                    <Pie
                                        dataKey="porcentaje_gastos_gestion"
                                        nameKey="nombre"
                                        isAnimationActive={false}
                                        data={datosPorcentajesGastosGestion}
                                        outerRadius={100}
                                        label>
                                        {
                                            datosPorcentajesGastosGestion.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colorActividad(entry.codigo)} />
                                            ))
                                        }
                                    </Pie>
                                    <Tooltip />
                                    <Legend layout='vertical' align='right' verticalAlign='top' width='200px' className="olaaa" />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                    <Col lg="6">
                        <h4 style={{ textAlign: 'center' }}>Ingresos Gestión</h4>
                        <div style={{ width: '100%', height: 350 }}>
                            <ResponsiveContainer>
                                <PieChart
                                    margin={{
                                        top: 5, right: 30, left: 20, bottom: 5,
                                    }}>
                                    <Pie
                                        dataKey="porcentaje_ingresos_gestion"
                                        nameKey="nombre"
                                        isAnimationActive={false}
                                        data={datosPorcentajesIngresosGestion}
                                        outerRadius={100}
                                        label>
                                        {
                                            datosPorcentajesIngresosGestion.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colorActividad(entry.codigo)} />
                                            ))
                                        }
                                    </Pie>
                                    <Tooltip />
                                    <Legend layout='vertical' align='right' verticalAlign='top' width='200px' />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
        // <Modal {...props} backdrop="static" keyboard={false} size="lg">
        //     <Modal.Header closeButton>
        //         <Modal.Title>Gráfico Resultado Global de la explotación</Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body>

        //         <Tabs defaultActiveKey="margen-presupuestado" id="MargenPresupuestado">
        //             <Tab eventKey="margen-presupuestado" title="Margen Presupuestado">

        //                 <div style={{ width: '100%', height: 300 }}>
        //                     <ResponsiveContainer>
        //                         <BarChart
        //                             width={500}
        //                             height={300}
        //                             data={data}
        //                             margin={{
        //                                 top: 20, right: 30, left: 20, bottom: 5,
        //                             }}
        //                         >
        //                             <CartesianGrid strokeDasharray="3 3" />
        //                             <XAxis dataKey="nombre" />
        //                             <YAxis tickFormatter={value => NumberGraphicFormatter({ value: value })} />
        //                             <Tooltip />
        //                             <Legend />
        //                             <Bar dataKey="presupuesto_margen" fill="#8884d8">
        //                                 {
        //                                     data.map((entry, index) => (
        //                                         <Cell key={`cell-${index}`} fill={colorActividad(entry.codigo)} />
        //                                     ))
        //                                 }
        //                             </Bar>
        //                         </BarChart>
        //                     </ResponsiveContainer>
        //                 </div>

        //             </Tab>
        //             <Tab eventKey="margen_unitario" title="Margen Unitario">
        //                 <div style={{ width: '100%', height: 300 }}>
        //                     <ResponsiveContainer>
        //                         <BarChart
        //                             width={500}
        //                             height={300}
        //                             data={data}
        //                             margin={{
        //                                 top: 20, right: 30, left: 20, bottom: 5,
        //                             }}
        //                         >
        //                             <CartesianGrid strokeDasharray="3 3" />
        //                             <XAxis dataKey="nombre" />
        //                             <YAxis tickFormatter={value => NumberGraphicFormatter({ value: value })} />
        //                             <Tooltip />
        //                             <Legend />
        //                             <Bar dataKey="presupuesto_margen_unitario" fill="#82ca9d">
        //                                 {
        //                                     data.map((entry, index) => (
        //                                         <Cell key={`cell-${index}`} fill={colorActividad(entry.codigo)} />
        //                                     ))
        //                                 }
        //                             </Bar>
        //                         </BarChart>
        //                     </ResponsiveContainer>
        //                 </div>
        //             </Tab>
        //         </Tabs>
        //         <Tabs defaultActiveKey="margen-presupuestado" id="MargenPresupuestado">
        //             <Tab eventKey="margen-presupuestado" title="Margen Presupuestado">

        //                 <div style={{ width: '100%', height: 300 }}>
        //                     <ResponsiveContainer>
        //                         <BarChart
        //                             width={500}
        //                             height={300}
        //                             data={data}
        //                             margin={{
        //                                 top: 20, right: 30, left: 20, bottom: 5,
        //                             }}
        //                         >
        //                             <CartesianGrid strokeDasharray="3 3" />
        //                             <XAxis dataKey="nombre" />
        //                             <YAxis tickFormatter={value => NumberGraphicFormatter({ value: value })} />
        //                             <Tooltip />
        //                             <Legend />
        //                             <Bar dataKey="presupuesto_margen" fill="#8884d8">
        //                                 {
        //                                     data.map((entry, index) => (
        //                                         <Cell key={`cell-${index}`} fill={colorActividad(entry.codigo)} />
        //                                     ))
        //                                 }
        //                             </Bar>
        //                         </BarChart>
        //                     </ResponsiveContainer>
        //                 </div>

        //             </Tab>
        //             <Tab eventKey="margen_unitario" title="Margen Unitario">
        //                 <div style={{ width: '100%', height: 300 }}>
        //                     <ResponsiveContainer>
        //                         <BarChart
        //                             width={500}
        //                             height={300}
        //                             data={data}
        //                             margin={{
        //                                 top: 20, right: 30, left: 20, bottom: 5,
        //                             }}
        //                         >
        //                             <CartesianGrid strokeDasharray="3 3" />
        //                             <XAxis dataKey="nombre" />
        //                             <YAxis tickFormatter={value => NumberGraphicFormatter({ value: value })} />
        //                             <Tooltip />
        //                             <Legend />
        //                             <Bar dataKey="presupuesto_margen_unitario" fill="#82ca9d">
        //                                 {
        //                                     data.map((entry, index) => (
        //                                         <Cell key={`cell-${index}`} fill={colorActividad(entry.codigo)} />
        //                                     ))
        //                                 }
        //                             </Bar>
        //                         </BarChart>
        //                     </ResponsiveContainer>
        //                 </div>
        //             </Tab>
        //         </Tabs>
        //     </Modal.Body>
        //     <Modal.Footer>
        //     </Modal.Footer>
        // </Modal>
    );
}

export default ChartResumenDeMargenes;