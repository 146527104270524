export function colorCuenta(codigo, rango=1000000) {
    if (Math.round(codigo / 10) % 2 === 0) {
        codigo = rango - codigo;
    }
    var color = Math.round((16000000 / rango) * (codigo));
    color = color.toString(16).toUpperCase();
    return "#" + color;
}

export function generaColor(){
    let colores = []
    var color ="#"
    var letter = '0F911C8BC76D213F4A58'.split('');
    for (var i = 0; i < 20; i++){
        if(i<18){
            color+=letter[i]
            color+=letter[i+1]
            color+=letter[i+2]
            colores.push(color)
        }
        color="#"
    }
    return colores;
}

export function colorActividad(codigo) {

    if (Math.round(codigo / 10) % 2 === 0) {
        codigo = 10000 - codigo;
    }
    var color = Math.round((16000000 / 10000) * (codigo));
    color = color.toString(16).toUpperCase();
    return "#" + color;
}

export function agrupar(arreglo, key, umbral=20) {
    var menores = {key: 0};
    for (var i = 0; i < arreglo.length; i++) {
        var item = arreglo[i];
        var value = item[key];
        // console.log(value);
        if (value < umbral){
            // console.log('menor');
            menores[key] += arreglo[key]
            arreglo.splice( i, 1 );
        }
    }
    return arreglo;
}

export function agruparDatos(data,codigo,nombre,porcentajeLimite) {
    let datos = [];
    let otros = {
        categoria_id: 0,
        codigo: codigo,
        datos: 0,
        nombre: nombre,
        porcentaje: 0,
        total: 0,
        total_ha: 0,
    }
    data.forEach(dato => {
        if(dato.porcentaje>porcentajeLimite || (otros.porcentaje>porcentajeLimite && dato.porcentaje>1)){
            datos.push(dato);
        }
        else if(otros.porcentaje<porcentajeLimite || dato.porcentaje<1){
            otros.datos+=dato.datos;
            otros.porcentaje+=parseFloat(dato.porcentaje);
            otros.total+=parseFloat(dato.total);
            otros.total_ha+=parseFloat(dato.total_ha);
        }
    });
    if ((data.length - datos.length) > 1) {
        otros.porcentaje = +(Math.round(otros.porcentaje + "e+1")  + "e-1") // aproxima a 1 decimal
        datos.push(otros);
        return datos;
    }
    else{
        return data;
    }
}

export const NumberGraphicFormatter = ({ value }) => {
    let number = Math.abs(value).toString();
    // 2 decimal places => 100, 3 => 1000, etc
    var decPlaces = Math.pow(10,2);

    // Enumerate number abbreviations
    var abbrev = [ "M", "MM", "B", "T" ];

    // Go through the array backwards, so we do the largest first
    for (var i=abbrev.length-1; i>=0; i--) {

        // Convert array index to "1000", "1000000", etc
        var size = Math.pow(10,(i+1)*3);

        // If the number is bigger or equal do the abbreviation
        if(size <= number) {
             // Here, we multiply by decPlaces, round, and then divide by decPlaces.
             // This gives us nice rounding to a particular decimal place.
             number = Math.round(number*decPlaces/size)/decPlaces;

             // Handle special case where we round up to the next abbreviation
             if((number == 1000) && (i < abbrev.length - 1)) {
                 number = 1;
                 i++;
             }

             // Add the letter for the abbreviation
             number += abbrev[i];

             // We are done... stop
             break;
        }
    }
    return value >= 0 ? number : "-"+number;
};
