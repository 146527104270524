import React, {useState, useEffect, useContext} from 'react';
import {Modal, Tabs, Tab} from 'react-bootstrap';
import {Api} from '../../utils/api';
import UserContext from '../../UserContext';
import {
    BarChart, Bar, Legend, Tooltip, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Cell
  } from 'recharts';
import {colorActividad, NumberGraphicFormatter} from '../../componentes/chart/utils';


function ChartResultadoGlobal(props) {

    const contexto = useContext(UserContext);
    const [data, setData] = useState([]);

    async function getReporte(predio) {
        let resultado = await Api(`api/reportes/presupuesto/resultado_global/${predio}/${contexto.predio.periodo.id}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setData(resultado.data.presupuestos);
        } else {
            setData([]);
            console.log('viene un error');
        }
    }

    useEffect(
        () => {
            if (contexto.predio.predio_id, contexto.predio.periodo) {
                getReporte(contexto.predio.predio_id);
            }
        },
        [contexto]
    )

    return (
        <Modal { ...props}  backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Gráfico Resultado Global de la explotación</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <Tabs defaultActiveKey="total" id="ResGlobalTab">
                <Tab eventKey="total" title="Margen Total">

                    <div style={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                        <BarChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                            top: 20, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="nombre" />
                            <YAxis tickFormatter={value => NumberGraphicFormatter({value:value})} />
                            <Tooltip formatter={(value) => new Intl.NumberFormat('es').format(value)} />
                            <Bar dataKey="total">
                                {
                                data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colorActividad(entry.codigo)}/>
                                ))
                                }
                            </Bar>
                        </BarChart>
                        </ResponsiveContainer>
                    </div>
                    
                </Tab>
                <Tab eventKey="total_unidad" title="Margen Unitario">
                    <div style={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                        <BarChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                            top: 20, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="nombre" />
                            <YAxis tickFormatter={value => NumberGraphicFormatter({value:value})} />
                            <Tooltip formatter={(value) => new Intl.NumberFormat('es').format(value)} />
                            <Bar dataKey="total_unidad">
                                {
                                data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colorActividad(entry.codigo)}/>
                                ))
                                }
                            </Bar>
                        </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Tab>
            </Tabs>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    );
}

export default ChartResultadoGlobal;