import React, { useState, useEffect, useContext } from 'react';
import BaseGrid from '../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import GridMenu from '../../componentes/grid/GridMenu';
import Layout from '../../componentes/Layout';
import { LongTextFormatter, sortRows, EmptyRowsView, CheckFormatter, codigoActividad, zeroFill } from '../../componentes/grid/utils';
import Buscador from '../../componentes/Buscador';
import { Api } from '../../utils/api';
import UserContext from '../../UserContext';
import { Button, Row, Col, ButtonGroup } from 'react-bootstrap';
import DefaultButtons from '../../componentes/grid/DefaultButtons'
import ModalActividadesBase from '../../componentes/ModalActividadesBase'
import { useAlert } from 'react-alert';
import FormularioActividad from './FormularioActividad';
import ModalEliminar from '../../componentes/ModalEliminar';
import { exportar, tienePermisos } from '../../utils/Functions';

function Actividades(props) {

	const { ContextMenuTrigger } = Menu;
	const alert = useAlert();			// Para las notificaciones
	const [textoBuscar, setTextoBuscar] = useState('');
	const [activarModalAgregarActividad, setActivarModalAgregarActividad] = useState(false);			// Modal para agregar actividad
	const [modalBase, setModalBase] = useState(false); 	// Modal base

	const [rows, setRows] = useState([]);
	const columns = [
		{ key: 'categoria', name: 'Categoría', sortable: true },
		{ key: 'codigo', name: 'Código', width: 200, sortable: true, formatter: codigoActividad },
		{ key: 'nombre', name: 'Nombre', formatter: LongTextFormatter, sortable: true },
		{ key: 'habilitada', name: 'Habilitada', width: 100, formatter: CheckFormatter }
	];

	const contexto = useContext(UserContext);

	const [actividad, setActividad] = useState('');
	// Modal eliminar
	const [showEliminar, setShowEliminar] = useState(false);
	const [msgEliminar, setMsgEliminar] = useState('');

	useEffect(
		() => {
			if (contexto.predio.predio_id) {
				cargarActividadesPredio(contexto.predio.predio_id);
			}
		},
		[contexto]
	)

	/**
	 * Buscamos y cargamos todas las actividades del predio en la grilla
	 *
	 * @param {integer} predio
	 */
	async function cargarActividadesPredio(predio) {

		let resultado = await Api(`api/actividad?predio_id=${predio}&texto=${textoBuscar}`, null, {}, true);
		if (resultado && resultado.status === 200) {
			setRows(actualizarDatos(resultado.data.datos));
		} else {
			alert.show('Error al cargar actividades', { type: 'error' });
			setRows([]);
		}
	}

	/**
	 * Buscamos una actividad
	 */
	async function cargarActividad(id) {
		return await Api(`api/actividad?id=${id}&predio_id=${contexto.predio.predio_id}`, null, {}, true);
	}

	/**
	 * Acción para desplegar modal agregar actividad
	 */
	function nuevoDato() {
		setActivarModalAgregarActividad(true);
	}

	/**
	 * Evento para cerrar la modal agregar cuenta
	 */
	function cerrarModalAgregarActividad() {
		setActividad('');
		setActivarModalAgregarActividad(false);
		cargarActividadesPredio(contexto.predio.predio_id); // Recargamos la grilla
	}

	/**
	 * Cuando queremos editar una actividad, desde el context menú
	 *
	 * @param {integer} rowIdx
	 */
	const editRow = rowIdx => {
		new Promise(function (resolve, reject) {
			cargarActividad(rows[rowIdx].id).then(function (resultado) {
				if (resultado && resultado.status === 200) {
					setActivarModalAgregarActividad(true);
					setActividad(resultado.data.datos.length === 1 ? resultado.data.datos[0] : '');
				} else {
					alert.show('Error al cargar actividad', { type: 'error' });
					setActividad('');
				}
			})
		})
	};

	/**
	 * Cuando queremos elimininar una actividad, modal confirmación
	 *
	 * @param {*} rowIdx
	 */
	const deleteRow = rowIdx => {
		new Promise(function (resolve, reject) {
			cargarActividad(rows[rowIdx].id).then(function (resultado) {
				if (resultado && resultado.status === 200) {
					if (resultado.data.datos.length === 1) {
						let data = resultado.data.datos[0];
						setActividad(data);
						setShowEliminar(true);
						setMsgEliminar('¿Estás seguro de eliminar?<br><br><b>Categoría:</b> ' + data.categoria + '<br><b>código:</b> ' + data.codigo + '<br><b>Nombre:</b> ' + data.nombre);
					} else {
						alert.show('Este registro no éxiste', { type: 'error' });
					}
				} else {
					alert.show('Error al cargar actividad', { type: 'error' });
				}
			})
		})
	};

	/**
	 * Eliminar actividad
	 */
	async function retornoEliminarCuenta() {
		let resultado = await Api(`api/actividad/${actividad.id}`, null, {}, true, 'delete');
		if (resultado && resultado.status === 200) {
			setShowEliminar(false);
			setActividad('');
			cargarActividadesPredio(contexto.predio.predio_id); // Recargamos la grilla
			alert.show(resultado.data.msg, { type: 'success' });
		} else {
			alert.show(resultado.data.msg, { type: 'error' });
		}
	}

	/**
	 * Función retorno de la búsqueda, podemos filtrar la información
	 *
	 * @param {string} valor
	 */
	async function retornoBusqueda(texto) {
		setTextoBuscar(texto);
		let resultado = await Api(`api/actividad?predio_id=${contexto.predio.predio_id}&texto=${texto}`, null, {}, true);
		if (resultado && resultado.status === 200) {
			setRows(actualizarDatos(resultado.data.datos));
		} else {
			setRows([]);
			alert.show('Error al cargar actividades', { type: 'error' });
		}
	}

	/**
	 * Actualizar los registros antes de cargarlos a la grilla
	 *
	 * @param {array} datos
	 * @returns
	 */
	function actualizarDatos(datos) {
		datos.forEach(registro => {
			registro.categoria = `[${zeroFill(registro.categoria_codigo_minimo, 4)} - ${zeroFill(registro.categoria_codigo_maximo, 4)}] ${registro.categoria} `;
		});
		return datos;
	}

	/**
	 * Activamos la modal con todas las actividades bases disponibles
	 */
	function activarModalBase() {
		setModalBase(true);
	}

	/**
	 * Evento para cerrar modal base de actividades
	 */
	function cerrarModalBase() {
		setModalBase(false);
	}

	/**
	 * Es la devolución de llamada cuando seleccionamos una cuenta base en ModalAcuentasBase
	 *
	 * @param {array} cuentaData - información de la cuenta
	 */
	function callbackModalActividadesBase(actividadData) {
		cargarActividadesPredio(contexto.predio.predio_id); // Recargamos la grilla
	}

	/**
	* Permite exportar la información de la grilla a excel
	*/
	function exportarExcel() {
		exportar(`api/exportar/actividades?predio_id=${contexto.predio.predio_id}&texto=${textoBuscar}`, alert);
	}

	return (
		<Layout title="Actividades">
			<Row style={{ paddingBottom: '10px' }}>
				<Col sm="6">
					<Buscador retornoBusqueda={retornoBusqueda} busqueda={''} placeholder='Búsqueda por categoría, código o nombre...' />
				</Col>
				<Col sm="6">
					<ButtonGroup className="float-right">
						{tienePermisos('editar',contexto.predio.perfil) &&
							<Button variant="success" size="sm" onClick={nuevoDato}>Crear actividad</Button>
						}

						<Button variant="light" size="sm" onClick={activarModalBase} style={{ border: '1px solid grey' }}>Plan de actividades</Button>
						<DefaultButtons exportar={exportarExcel} />
					</ButtonGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<div>
						<BaseGrid
							columns={columns}
							rowGetter={i => rows[i]}
							rowsCount={rows.length}
							minHeight={400}
							contextMenu={
								<GridMenu
									id="pac_1"
									options={[
										{
											texto: "Editar",
											funcion: (e, { rowIdx }) => editRow(rowIdx)
										},
										{
											texto: "Eliminar",
											funcion: (e, { rowIdx }) => deleteRow(rowIdx)
										}
									]}
								/>
							}
							RowsContainer={ContextMenuTrigger}
							onGridSort={(sortColumn, sortDirection) =>
								setRows(sortRows(rows, sortColumn, sortDirection))
							}
							emptyRowsView={EmptyRowsView} />
					</div>
				</Col>
			</Row>
			<FormularioActividad
				show={activarModalAgregarActividad}
				onHide={cerrarModalAgregarActividad}
				actividad={actividad}
				size="lg" />
			<ModalActividadesBase
				show={modalBase}
				onHide={cerrarModalBase}
				onClickCerrarModal={cerrarModalBase}
				permiteAgregar={true}
				callbackSeleccionar={callbackModalActividadesBase} />
			<ModalEliminar titulo="Eliminar" show={showEliminar} setShow={setShowEliminar} confirmar={retornoEliminarCuenta}>
				{msgEliminar}
			</ModalEliminar>
		</Layout>
	);
}

export default Actividades;