import React, { useState, useContext, useEffect, useRef } from 'react';
import { useAlert } from 'react-alert';
import BaseGrid from '../../componentes/grid/BaseGrid';
import UserContext from '../../UserContext';
import Layout from '../../componentes/Layout.js';
import { Menu } from "react-data-grid-addons";
import GridMenu from '../../componentes/grid/GridMenu';
import { NumberFormatter, DecimalFormatter, sortRows, EmptyRowsView, CheckFormatter, LongTextFormatter } from '../../componentes/grid/utils';
import { FormDatoInventario, GetDatoInventario, FormularioEliminarDatoInventario } from './FormInventario';
import DefaultButtons from '../../componentes/grid/DefaultButtons'
import { Button, Row, Col, ButtonGroup, Form, InputGroup } from 'react-bootstrap';
import { Api } from '../../utils/api';
import { FaLock, FaPlus } from 'react-icons/fa';
import { exportar, downloadBlob,tienePermisos, getDMY, fechaHoy } from '../../utils/Functions';
import { InventarioPdf } from './pdf/InventarioPdf';

function Inventario(props) {

    const contexto = useContext(UserContext);
    const alert = useAlert();			// Para las notificaciones
    const { ContextMenuTrigger } = Menu
    const [showForm, setShowForm] = useState(false);
    const [showEliminar, setShowEliminar] = useState(false);  // Modal para eliminar inventario
    const [inventario, setInventario] = useState(null);;
    const [fechaExportar] = useState(fechaHoy());

    const columns = [
        { key: 'codigo_cuenta', name: 'Código', sortable: true },
        { key: 'nombre_cuenta', name: 'Cuenta', width: 300 },
        { key: 'item', name: 'Item', width: 250 },
        { key: 'fabricacion', name: 'Fabricacion' },
        { key: 'cantidad', name: 'Ctd.', formatter: DecimalFormatter },
        { key: 'unidad_abreviacion', name: 'Un.', formatter: NumberFormatter },
        { key: 'tipo', name: 'Tipo' },
        { key: 'valor_unitario', name: 'Valor Un.', formatter: NumberFormatter },
        { key: 'monto_total', name: 'Valor total', width: 100, formatter: NumberFormatter },
        { key: 'depreciacion', name: 'Depreciacion', formatter: CheckFormatter },
        { key: 'tiempo_depreciacion', name: 'Años Dep.' },
        { key: 'valor_residual', name: 'Valor Residual', formatter: NumberFormatter },
        { key: 'comentario', width: 300, name: 'Comentario', formatter: LongTextFormatter },
    ];

    const columnsTotales = [
        { key: 'c1', name: '', sortable: true },
        { key: 'c2', name: '', width: 300 },
        { key: 'c3', name: '', width: 250 },
        { key: 'c4', name: '' },
        { key: 'c5', name: '', formatter: DecimalFormatter },
        { key: 'c6', name: '', formatter: NumberFormatter },

        { key: 'nombre', name: '', width: 195, formatter: LongTextFormatter },
        { key: 'total', name: 'Total', width: 100, formatter: NumberFormatter },
        { key: 'c9', name: '', formatter: NumberFormatter },
        { key: 'c10', name: '', formatter: NumberFormatter },
        { key: 'c11', name: '', formatter: NumberFormatter },
        { key: 'c12', width: 300, name: '', formatter: LongTextFormatter },
    ];

    const [rows, setRows] = useState([]);
    const [rowsTotales, setRowsTotales] = useState({});
    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                getDatos(contexto.predio.predio_id);
            }
        },
        [contexto]
    )

    async function getDatos(predio_id) {
        let resultado = await Api(`api/inventario/${contexto.predio.predio_id}?periodo_id=${contexto.predio.periodo.id}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
            setRowsTotales(resultado.data.total)
        } else {
            alert.show('Error al cargar inventario', { type: 'error' });
            setRows([]);
        }
    }

    function nuevoDato() {
        setInventario(null);
        setShowForm(true);
    }

    const editRow = rowIdx => {
        //setDatoId(rows[rowIdx].id);

        new Promise(function (resolve, reject) {
            GetDatoInventario(contexto.predio.predio_id, rows[rowIdx].id).then(function (inventario) {
                setInventario(inventario);
                setShowForm(true);
            })
        })
    };

    function cerrarModalBalanceInicial() {
        setShowForm(false)
        getDatos(contexto.predio.predio_id);
    }

    const deleteRow = rowIdx => {
        new Promise(function (resolve, reject) {
            GetDatoInventario(contexto.predio.predio_id, rows[rowIdx].id).then(function (inventario) {
                setInventario(inventario);
                setShowEliminar(true);
            })
        })
    };

    function cerrarModalEliminar() {
        getDatos(contexto.predio.predio_id);
        setShowEliminar(false);
    }

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        exportar(`api/exportar/inventario/${contexto.predio.predio_id}/${contexto.predio.periodo.id}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf() {
        let props = {
            columns: columns,
            rows: rows,
            rowsTotales: rowsTotales,
            titulo: "Inventario",
            bajada: contexto.predio.nombre_predio,
            //subtitulo:cmbxPeriodos.current.selectedOptions[0].label
        }
        alert.show('La descarga comenzará en breve', { type: 'success' });
        new Promise(function (resolve, reject) {
            InventarioPdf(props).then(
                function (blob) {
                    downloadBlob(blob,
                        `Inventario_${contexto.predio.nombre_predio.replace(/ /g, '_')}_${getDMY(fechaExportar)}.pdf`);
                }, function (blob) {
                    alert.show('Error al exportar', { type: 'error' })
                })
        })
    }

    return (
        <Layout title="Inventario">
            <Form>
                <Form.Row style={{ paddingBottom: '10px' }}>
                    <Col md={8}></Col>
                    <Col md={4}>
                        <ButtonGroup className="float-right">
                            {tienePermisos('editar',contexto.predio.perfil) &&
                                <Button
                                    variant="success"
                                    size="sm"
                                    onClick={nuevoDato}><FaPlus /> Agregar dato</Button>
                            }

                            <DefaultButtons exportar={exportarExcel} />
                            <DefaultButtons imprimir={exportarPdf} />
                        </ButtonGroup>
                    </Col>
                </Form.Row>
            </Form>
            <div>
                <BaseGrid
                    columns={columns}
                    rowGetter={i => rows[i]}
                    rowsCount={rows.length}
                    minHeight={400}
                    contextMenu={
                        <GridMenu
                            id="pac_1"
                            options={[
                                {
                                    texto: "Editar",
                                    funcion: (e, { rowIdx }) => editRow(rowIdx)
                                },
                                {
                                    texto: "Eliminar",
                                    funcion: (e, { rowIdx }) => deleteRow(rowIdx)
                                }
                            ]}
                        //filasDeshabilitadas={[0]}
                        />
                    }
                    RowsContainer={ContextMenuTrigger}
                    onGridSort={(sortColumn, sortDirection) =>
                        setRows(sortRows(rows, sortColumn, sortDirection))
                    }
                    emptyRowsView={EmptyRowsView} />
            </div>
            <div>
                <BaseGrid
                    columns={columnsTotales}
                    rowGetter={i => rowsTotales[i]}
                    rowsCount={rowsTotales.length}
                    minHeight={150}

                    RowsContainer={ContextMenuTrigger}
                    onGridSort={(sortColumn, sortDirection) =>
                        setRowsTotales(sortRows(rowsTotales, sortColumn, sortDirection))
                    }
                    emptyRowsView={EmptyRowsView}
                    showCountRows={false} />
            </div>
            <FormDatoInventario
                show={showForm}
                //datoId={datoId}
                inventario={inventario}
                predioId={contexto.predio.predio_id}
                onHide={cerrarModalBalanceInicial} />

            <FormularioEliminarDatoInventario
                show={showEliminar}
                onHide={cerrarModalEliminar}
                inventario={inventario}
                predioId={contexto.predio.predio_id}
                funcionRetorno={function (data) { cerrarModalEliminar() }}
                size="md"
            />

        </Layout>
    );
}

export default Inventario;