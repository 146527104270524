import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {getPredio, FormularioPermisosPredio} from '../../modulos/administracion/FormularioPredio';
import LayoutAdmin from '../../componentes/LayoutAdmin';
import {Link} from 'react-router-dom';
import { capitalize } from '../../utils/Functions';

function AdminPermisosAcceso(props){
	const [predioForm, setPredioForm] = useState(
		{
			"id":null,
			"nombre": "",
			"superficie_util":0,
			"mano_obra_permanente":0,
			"rol_unico_pecuario":"",
			"nombre_productor":""
		});
	const [tipo, setTipo] = useState("")
	const {predioId} = useParams()

    useEffect(
        () => {
            getPredio(predioId).then(function(predio){
				setPredioForm(predio);
				if (predio.cooperativa === true) {
					setTipo("cooperativas");
				}
				else{
					setTipo("predios");
				}
            })
        },
        [predioId]
    )


	return (
		<>
			<LayoutAdmin title={
				<>
					<Link to={`/administracion/${tipo}/listado`}>{capitalize(tipo)}</Link> / Permisos de Acceso
				</>
			}>
		
            <FormularioPermisosPredio
                predio={predioForm}
				show={true}
				tabSeleccionado={'permisos_acceso'}
            />
		</LayoutAdmin>
		</>
	)
}

export default AdminPermisosAcceso;