import React, {useState, useContext, useEffect} from 'react';
import {Api} from '../../../utils/api';
import UserContext from '../../../UserContext';
import {Modal, Col, Tabs, Tab} from 'react-bootstrap';
import BaseGrid from '../../../componentes/grid/BaseGrid';
import {NumberFormatter, PercentFormatter} from '../../../componentes/grid/utils';


function ControlCajaDetalle(props) {

    const {cuenta, hide, logout, fecha_cierre,  ...rest} = props
    const contexto = useContext(UserContext)
    const [rows, setRows] = useState([]);

    const columns = [
        {key: 'tipo', name: 'Tipo', width: 40},
        {key: 'fecha', name: 'Fecha', width: 80},
        {key: 'folio', name: 'Folio', width: 60, sortable: true, formatter: NumberFormatter},
        {key: 'actividad', name: 'Actividad', formatter: NumberFormatter},
        {key: 'cuenta_provee', name: 'Provee', width: 150},
        {key: 'cuenta_recibe', name: 'Recibe', width: 150},
        {key: 'glosa', name: 'Glosa', width: 100},
        {key: 'abreviacion', name: 'Un', width: 40},
        {key: 'cantidad', name: 'Ctd', width: 70, formatter: NumberFormatter},
        {key: 'monto_total_neto', name: 'Neto', width: 100, formatter: NumberFormatter},
        {key: 'monto_total_impuestos', name: 'Impts', width: 100, formatter: NumberFormatter},
        {key: 'monto_total_bruto', name: 'Bruto', width: 100, formatter: NumberFormatter},
     ];

    async function getReporte() {
        var predio_id = contexto.predio.predio_id;
        var periodo_id = contexto.predio.periodo.id;
        let resultado = await Api(`api/contabilidad/control-caja-detalle?predio_id=${predio_id}&periodo_id=${periodo_id}&cuenta_id=${cuenta.id}&fecha_cierre=${fecha_cierre}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
        } else {
            console.log('viene un error');
            setRows([]);
        }
    }


    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                getReporte();
            }
        },
        [contexto, cuenta]
    )

    return (
        <Modal {...rest} size="xl" onShow={getReporte}>
            <Modal.Header closeButton>
                <Modal.Title>Detalle movimientos  {cuenta.codigo} {cuenta.nombre} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Tabs defaultActiveKey="grilla" id="grilla">
                <Tab eventKey="grilla" title="Detalle movimientos">
                    <Col sm="12">
                    <div>
                        <BaseGrid
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            minHeight={400}/>
                    </div>
                    </Col>
                </Tab>
                <Tab eventKey="graifco" title="Gráfico">
                </Tab>
            </Tabs>
            </Modal.Body>
        </Modal>
    );
}
export default ControlCajaDetalle;