import React from 'react';
import {BaseStylesPdf} from '../../../../componentes/pdf/BaseStylesPdf';
import {BaseLinkPdf} from '../../../../componentes/pdf/BaseLinkPdf';
import {Table, TableHeader, TableBody, TableCell, DataTableCell} from '@david.kucsai/react-pdf-table';
import { separadorDeMilesOrVacio} from '../../../../componentes/grid/utils';

export function CostoLitroDeLechePdf(props) {
    const styles = BaseStylesPdf();
    var tablaPresupuestoActividad = '';

    try {
        tablaPresupuestoActividad =(
            <>
            <Table data={props.rows}>
                <TableHeader textAlign={"center"}>
                    {/* el "weighting" idealmente debe sumar 1 para que quede todo ordenado */}
                    { props.columns.map((data, index) => {
                        return data.name === 'Cuenta' ?
                                <TableCell key={index} style={{...styles.tableHeader, ...{fontSize: 8}}} weighting={0.16}>{data.name}</TableCell>
                            :
                                <TableCell key={index}  style={{...styles.tableHeader, ...{fontSize: 8}}} weighting={0.06}>{data.name}</TableCell>
                    })}
                </TableHeader>
                <TableBody>
                    {props.columns.map((data, index) => {
                        return data.name === 'Cuenta' ?
                            <DataTableCell key={index} style={{ ...styles.textLeft, ...{ fontSize: 8 } }} weighting={0.16} getContent={(r) => r.nombre_cuenta} />
                        :
                            <DataTableCell key={index} style={{ ...styles.textRigh, ...{ fontSize: 8 } }} weighting={0.06} getContent={(r) => separadorDeMilesOrVacio(r[props.columns[index].key])} />
                    })}
                </TableBody>
            </Table>
            </>)
    } catch (error) {
        console.log("Error CostoLitroDeLeche", error);
    }

    let blob = BaseLinkPdf({...props, body:tablaPresupuestoActividad, orientation:"landscape"})

    return blob
}