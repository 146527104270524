import React from 'react';
import { Modal } from 'react-bootstrap';

import {
    ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';
import { NumberGraphicFormatter } from '../../../componentes/chart/utils';

function ChartCostoLitroDeLeche(props) {

    var data = props.rows
    var datos_graficos = []
    data.forEach(registro => {
        if (registro['nombre_cuenta'] == 'Precio Pagado') {
            datos_graficos.push({ 'mes': 'Ago', 'precio_pagado': Math.round(registro['agosto']) })
            datos_graficos.push({ 'mes': 'Sep', 'precio_pagado': Math.round(registro['septiembre']) })
            datos_graficos.push({ 'mes': 'Oct', 'precio_pagado': Math.round(registro['octubre']) })
            datos_graficos.push({ 'mes': 'Nov', 'precio_pagado': Math.round(registro['noviembre']) })
            datos_graficos.push({ 'mes': 'Dic', 'precio_pagado': Math.round(registro['diciembre']) })
            datos_graficos.push({ 'mes': 'Ene', 'precio_pagado': Math.round(registro['enero']) })
            datos_graficos.push({ 'mes': 'Feb', 'precio_pagado': Math.round(registro['febrero']) })
            datos_graficos.push({ 'mes': 'Mar', 'precio_pagado': Math.round(registro['marzo']) })
            datos_graficos.push({ 'mes': 'Abr', 'precio_pagado': Math.round(registro['abril']) })
            datos_graficos.push({ 'mes': 'May', 'precio_pagado': Math.round(registro['mayo']) })
            datos_graficos.push({ 'mes': 'Jun', 'precio_pagado': Math.round(registro['junio']) })
            datos_graficos.push({ 'mes': 'Jul', 'precio_pagado': Math.round(registro['julio']) })

        } else if (registro['nombre_cuenta'] == 'Costo Total') {
            datos_graficos.forEach(costo_total => {
                if (costo_total.mes === 'Ene') {
                    costo_total.costo_total = Math.round(registro['enero'])    
                }
                if (costo_total.mes === 'Feb') {
                    costo_total.costo_total = Math.round(registro['febrero'])
                }
                if (costo_total.mes === 'Mar') {
                    costo_total.costo_total = Math.round(registro['marzo'])
                }
                if (costo_total.mes === 'Abr') {
                    costo_total.costo_total = Math.round(registro['abril'])
                }
                if (costo_total.mes === 'May') {
                    costo_total.costo_total = Math.round(registro['mayo'])
                }
                if (costo_total.mes === 'Jun') {
                    costo_total.costo_total = Math.round(registro['junio'])
                }
                if (costo_total.mes === 'Jul') {
                    costo_total.costo_total = Math.round(registro['julio'])
                }
                if (costo_total.mes === 'Ago') {
                    costo_total.costo_total = Math.round(registro['agosto'])
                }
                if (costo_total.mes === 'Sep') {
                    costo_total.costo_total = Math.round(registro['septiembre'])
                }
                if (costo_total.mes === 'Oct') {
                    costo_total.costo_total = Math.round(registro['octubre'])
                }
                if (costo_total.mes === 'Nov') {
                    costo_total.costo_total = Math.round(registro['noviembre'])
                }
                if (costo_total.mes === 'Dic') {
                    costo_total.costo_total = Math.round(registro['diciembre'])
                }
            })
        }

    });

    return (
        <Modal {...props} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                Gráfico Costo Litro de Leche
            </Modal.Header>
            <Modal.Body>

                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                        <LineChart
                            width={500}
                            height={350}
                            data={datos_graficos}
                            margin={{
                                top: 20, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="mes" />
                            <YAxis tickFormatter={value => NumberGraphicFormatter({ value: value })} />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="precio_pagado" stroke="#00FF00" activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="costo_total" stroke="#FF0000" />
                        </LineChart>
                    </ResponsiveContainer>
                </div>

            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    );
}

export default ChartCostoLitroDeLeche;