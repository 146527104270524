import React, {useState, useEffect, useContext} from 'react';
import { useHistory } from "react-router-dom";
import BaseGrid from '../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import GridMenu from '../../componentes/grid/GridMenu';
import LayoutAdmin from '../../componentes/LayoutAdmin';
import {LongTextFormatter, sortRows, EmptyRowsView, CheckFormatter, NumberFormatter} from '../../componentes/grid/utils';
import {Api} from '../../utils/api';
import UserContext from '../../UserContext';
import { Button, Row, Col, ButtonGroup } from 'react-bootstrap';
import DefaultButtons from '../../componentes/grid/DefaultButtons';
import Buscador from '../../componentes/Buscador';
import { useAlert } from 'react-alert';
import { getPredio, FormularioEliminarPredio } from '../../modulos/administracion/FormularioPredio';
import { ModalFormularioCooperativa } from '../../modulos/administracion/FormularioCooperativa';


function Cooperativas(props) {

	var history = useHistory();
    const {ContextMenuTrigger} = Menu;
    
    const [textoBuscar, setTextoBuscar] = useState('');
	const alert = useAlert();			// Para las notificaciones


	const columns = [
		{ key: 'nombre', name: 'Nombre', sortable: true, width: 250, },
		{ key: 'tipo_cooperativa', name: 'Tipo', width: 200, sortable: true },
		{ key: 'nombre_productor', name: 'Nombre Gerente', width: 200, sortable: true },
		{ key: 'cooperados', name: 'Cooperados', sortable: true, formatter: LongTextFormatter},
		{ key: 'total_cuotas', name: 'Cuotas Participación', width: 200, formatter: NumberFormatter},
		{ key: 'usuarios', name: 'Usuarios', sortable: true, formatter: LongTextFormatter},
		{ key: 'habilitada', name: 'Habilitado', width: 70, formatter: CheckFormatter }
    ];
    
	const [rows, setRows] = useState([]);
	const contexto = useContext(UserContext);

	const [activarModalAgregarPredio, setActivarModalPredio] = useState(false);			// Modal para agregar predio
	const [activarModalEliminarPredio, setActivarModalEliminarPredio] = useState(false);			// Modal para eliminar predio
    const [predioForm, setPredioForm] = useState(
		{
			"id":"",
			"nombre": "",
			"nombre_productor":"",
			"total_cuotas":""
		});

	useEffect(
		() => {
			cargarPredios();
		},
		[contexto]
	)

	/**
	 * Buscamos y cargamos todos los predios en la grilla
	 */
	async function cargarPredios() {

		let resultado = await Api('api/administracion/cooperativas', null, {}, true);
		if (resultado && resultado.status === 200) {
			setRows(resultado.data.datos);
		} else {
			alert.show('Error al cargar cooperativas', { type: 'error' });
			setRows([]);
		}
    }
    
    /**
     * Función para búsqueda específica de predios
     * 
     * @param {String} texto 
     */
    async function retornoBusqueda(texto) {
		let resultado = await Api(`api/administracion/cooperativas?texto=${texto}`, null, {}, true);
		if (resultado && resultado.status === 200) {
			setRows(resultado.data.datos);
		} else {
            setRows([])
			alert.show('Error al cargar usuarios', { type: 'error' });
		}
	}

	/**
	 * Acción para desplegar modal agregar predio
	 */
	function nuevoDato() {
		setPredioForm({
			"id":"",
			"nombre": "",
			"nombre_productor":"",
			"total_cuotas":""
		});
		setActivarModalPredio(true);
	}

	/**
	 * Evento para cerrar el modal agregar predio
	 */
	function cerrarModalAgregar() {
		cargarPredios();
		setActivarModalPredio(false);
	}

	/**
	 * Evento para cerrar el modal eliminar predio
	 */
	function cerrarModalEliminar() {
		cargarPredios();
		setActivarModalEliminarPredio(false);
	}


	/**
	 * Permite enviar el formulario para crear un nuevo predio
	 */
	async function agregarPredio(data) {
		cerrarModalAgregar();
	}

	/**
	 * Cuando queremos editar un predio, desde el context menú
	 *
	 * @param {integer} rowIdx
	 */
	const editRow = rowIdx => {
		new Promise(function(resolve, reject){
			getPredio(rows[rowIdx].id).then(function(predio){
				setPredioForm(predio);
				setActivarModalPredio(true);
			})
		})
	};


	/**
	 * Cuando queremos elimininar un predio, desde el context menú
	 *
	 * @param {*} rowIdx
	 */
	const deleteRow = rowIdx => {
		new Promise(function(resolve, reject){
			getPredio(rows[rowIdx].id).then(function(predio){
				setPredioForm(predio);
				setActivarModalEliminarPredio(true);
			})
		})
	};

	/**
	 * Cuando queremos cambiar los permisos de acceso a un predio, desde el context menú
	 *
	 * @param {integer} rowIdx
	 */
	const permisosAcceso = rowIdx => {
		history.push(`/administracion/predios/permisos-acceso/${rows[rowIdx].id}`)
	};

	/**
	 * Cuando queremos cambiar los permisos de acceso a un predio, desde el context menú
	 *
	 * @param {integer} rowIdx
	 */
	const cooperados = rowIdx => {
		history.push(`/administracion/cooperativas/cooperados/${rows[rowIdx].id}`)
	};


	return (
		<LayoutAdmin title="Cooperativas">
			<Row style={{paddingBottom:'10px'}}>
                <Col>
                    <Buscador retornoBusqueda={retornoBusqueda} busqueda={textoBuscar} placeholder='Búsqueda de cooperativas...'/>
                </Col>
				<Col>
					<ButtonGroup className="float-right">
						<Button variant="success" size="sm" onClick={nuevoDato}>Agregar Cooperativa</Button>
						<DefaultButtons />
					</ButtonGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<div>
						<BaseGrid
							columns={columns}
							rowGetter={i => rows[i]}
							rowsCount={rows.length}
							minHeight={400}
							contextMenu={
								<GridMenu
									id="pac_1"
									options = {[
										{texto: 'Editar',
										funcion: (e, { rowIdx }) => editRow(rowIdx)},
										{texto: 'Eliminar',
										funcion: (e, { rowIdx }) => deleteRow(rowIdx)},
										{texto:'Permisos de acceso',
										funcion: (e, { rowIdx }) => permisosAcceso(rowIdx)},
										{texto:'Cooperados',
										funcion: (e, { rowIdx }) => cooperados(rowIdx)}
									]}
								/>
							}
							RowsContainer={ContextMenuTrigger}
							onGridSort={(sortColumn, sortDirection) =>
								setRows(sortRows(rows, sortColumn, sortDirection))
							}
							emptyRowsView={EmptyRowsView}/>
					</div>
				</Col>
			</Row>
			<ModalFormularioCooperativa
				show={activarModalAgregarPredio}
				onHide={cerrarModalAgregar} 
				predio={predioForm} 
				funcionRetorno={function(data){agregarPredio(data)}}
				size="md"
			/>
			<FormularioEliminarPredio
				show={activarModalEliminarPredio}
				onHide={cerrarModalEliminar} 
				predio={predioForm} 
				funcionRetorno={function(data){cerrarModalEliminar()}}
				size="md"
			/>
		</LayoutAdmin>
	);
}

export default Cooperativas;