import React, { useState, useEffect, useContext } from 'react';
import { useAlert } from 'react-alert';
import BaseGrid from '../../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import Layout from '../../../componentes/Layout';
import { NumberFormatter, sortRows, EmptyRowsView } from '../../../componentes/grid/utils';
import { Api } from '../../../utils/api';
import UserContext from '../../../UserContext';
import { Row, Col, Form, ButtonGroup, Button } from 'react-bootstrap';
import Fecha from '../../../componentes/Fecha';
import { getYMD, fechaHoy, getDMY } from '../../../utils/Functions';
import DefaultButtons from '../../../componentes/grid/DefaultButtons';
import { exportar, downloadBlob } from '../../../utils/Functions';
import ControlCajaDetalle from './ControlCajaDetalle';
import {FaSearch} from 'react-icons/fa';
import { ControlCajaPermanentePDF } from './pdf/ControlCajaPermanentePDF';
// import ChartBalanceGeneral from '../ChartBalanceGeneral'


function ControlCajaPermanente(props) {

    const contexto = useContext(UserContext);
    const {ContextMenu, MenuItem, ContextMenuTrigger} = Menu;
    const alert = useAlert();
    const [rows, setRows] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [cuenta, setCuenta] = useState({id:70,nombre:'',codigo:''});
    const [rowsTotales, setRowsTotales] = useState([]);
    const [fechaExportar] = useState(fechaHoy());
    // const [showChart, setShowChart] = useState(false);
    const defaultCellSelection = {}
    const [cellSelection, setCellSelection] = useState(defaultCellSelection)
    const columns = [
        { key: 'codigo', name: 'Cuenta', width: 120},
        { key: 'nombre', name: 'Nombre' },
        { key: 'credito_balance', name: 'Crédito Balance', width: 120, formatter: NumberFormatter },
        { key: 'debito_balance', name: 'Débito Balance', width: 120, formatter: NumberFormatter },
        { key: 'provee_operaciones', name: 'Provee', width: 120, formatter: NumberFormatter },
        { key: 'recibe_operaciones', name: 'Recibe', width: 120, formatter: NumberFormatter },
        { key: 'balance_final', name: 'Resultado', width: 120, formatter: NumberFormatter },
    ];

    const [fecha, setFecha] = useState('');

    useEffect(
        () => {
            if (contexto.predio.predio_id && contexto.predio.periodo.cierre) {
                if (contexto.predio.periodo.cierre === fecha) {
                    obtenerDatos();
                }
                setFecha(contexto.predio.periodo.cierre);
            } else {
                setRows([]);
            }
        },
        [contexto]
    )

    useEffect(
        () => {
            if (fecha) {
                obtenerDatos();
            }
        },
        [fecha]
    )

    /**
     * Cargar información de la grilla
     */
    async function obtenerDatos() {
        let resultado = await Api(`/api/contabilidad/control-caja-permanente?predio_id=${contexto.predio.predio_id}&periodo_id=${contexto.predio.periodo.id}&fecha_cierre=${fecha}`);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            setRows([]);
        }
    }

    /**
     * Cuando cambian la fecha
     *
     * @param {date} e
     */
    function onChangeInicio(e) {
        let fecha = getYMD(e);
        setFecha(fecha);
    }

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        exportar(`api/exportar/contabilidad/control-caja-permanente?predio_id=${contexto.predio.predio_id}&periodo_id=${contexto.predio.periodo.id}&fecha_cierre=${fecha}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf() {
        let props = {
            columns: columns,
            rows: rows,
            rowsTotales: rowsTotales,
            titulo: 'Control Caja permanente',
            bajada: contexto.predio.nombre_predio,
            subtitulo: 'Fecha de cierre: ' + getDMY(fecha)
        }
        alert.show('La descarga comenzará en breve', { type: 'success' });
        new Promise(function (resolve, reject) {
            ControlCajaPermanentePDF(props).then(
                function (blob) {
                    downloadBlob(blob, `Control_Caja_Permanente_${contexto.predio.nombre_predio.replace(/ /g, '_')}_cierre_${getDMY(fecha).replace(/ /g, '_').replaceAll('/', '-')}_${getDMY(fechaExportar)}.pdf`);
                }, function (blob) {
                    alert.show('Error al exportar', { type: 'error' })
                })
        })
    }

    const codigoActions = [
        {
            icon: <span><FaSearch /></span>,
            callback: () => {
                setShowForm(true);
            }
        },
      ];
    function getCellActions(column, row) {
        const cellActions = {
            codigo: codigoActions
        };
        return row.datos > 1 ? cellActions[column.key] : null;
    }

    function onRowDetail(rowIdx, idx) {
        setCuenta({
            id:rows[rowIdx].cuenta_id,
            nombre:rows[rowIdx].nombre,
            codigo:rows[rowIdx].codigo
        });
        setShowForm(true);
    }

    function DetailContextMenu({
        idx,
        id,
        rowIdx,
        onRowDetail
      }) {
        return (
          <ContextMenu id={id}>
            <MenuItem data={{ rowIdx, idx }} onClick={onRowDetail}>
              Ver Detalle
            </MenuItem>
          </ContextMenu>
        );
    }

    return (
        <Layout title="Control de caja permanente">
            <Row style={{ marginBottom: '10px' }}>
                <Col sm={6} md={4} lg={3} id="cont-fecha-desde">
                    <Form.Label>Fecha de Cierre</Form.Label>
                    <Fecha
                        name='inicio'
                        dateFormat='dd/MM/yyyy' // dd/MM/Y h:mm aa - dd/MM/Y - MM/Y
                        dateFormatOculto='y-m-d' // y-m-d h:i:s
                        inicial={fecha}
                        onChange={onChangeInicio}
                    />
                </Col>
                <Col sm={6} md={8} lg={9} style={{ paddingTop: '32px', textAlign: 'right' }}>

                    <ButtonGroup className="float-right">
                        {/* <Button
                            variant="info"
                            size="sm"
                            onClick={chart}>Gráfico</Button> */}
                        <DefaultButtons exportar={exportarExcel} />
                        <DefaultButtons imprimir={exportarPdf} />
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <BaseGrid
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            minHeight={400}
                            onCellSelectionChange={setCellSelection}
                            RowsContainer={ContextMenuTrigger}
                            onGridSort={(sortColumn, sortDirection) =>
                                setRows(sortRows(rows, sortColumn, sortDirection))
                            }
                            emptyRowsView={EmptyRowsView}
                            getCellActions={getCellActions}
                            contextMenu={
                                <DetailContextMenu
                                    id="control_caja"
                                    onRowDetail={(e, { rowIdx }) => onRowDetail(rowIdx)}
                                />
                            }
                            showCountRows={false} />
                    </div>
                    <div>
                        {/* <BaseGrid
                            columns={columnsTotales}
                            rowGetter={i => rowsTotales[i]}
                            rowsCount={rowsTotales.length}
                            minHeight={105}
                            showCountRows={false}
                            rowStyle={[{
                                columna: 'titulo',
                                valor: 'TOTAL BALANCE',
                                clases: 'row-totales',
                                css: { color: 'black', fontWeight: '400', textAlign: 'right' }
                            }, {
                                columna: 'titulo',
                                valor: 'RESULTADO BENEFICIO NETO',
                                clases: 'row-totales',
                                css: { color: 'black', fontWeight: '400', textAlign: 'right' }
                            }]} /> */}
                    </div>
                </Col>
            </Row>
            {/* <ChartBalanceGeneral
                rows={rows}
                rowsTotales={rowsTotales}
                fecha={fecha}
                show={showChart}
                onHide={() => setShowChart(false)} /> */}
            <ControlCajaDetalle
                cuenta={cuenta}
                show={showForm}
                onHide={() => setShowForm(false)}
                fecha_cierre={fecha} />
        </Layout>
    );
}

export default ControlCajaPermanente;