import React, {useState, useContext, useEffect, useRef} from 'react';
import {useAlert} from 'react-alert';
import BaseGrid from '../../componentes/grid/BaseGrid';
import UserContext from '../../UserContext';
import Layout from './Layout.js';
import {DecimalFormatter, PercentFormatter} from '../../componentes/grid/utils';
import DefaultButtons from '../../componentes/grid/DefaultButtons'
import {Button, Row, Col, ButtonGroup, Form, InputGroup} from 'react-bootstrap';
import {ApiBDL} from '../../utils/api';
import { exportar, downloadBlob, getDMY } from '../../utils/Functions';
// import { IndicadoresPdf } from './IndicadoresPdf';

function IndicadoresFinancieros(props) {

    const contexto = useContext(UserContext);
    const { predioId, periodoId, ...rest} = props
    const alert = useAlert();			// Para las notificaciones

    const columns = [
        {key: 'indicador', name: 'Indicador'},
        {key: 'presupuesto', name: 'Presupuesto', formatter: DecimalFormatter},
        {key: 'ejercicio', name: 'Fin Ejercicio', formatter: DecimalFormatter},
        {key: 'desviacion', name: 'Desviación', formatter: PercentFormatter},
     ];

    const [rows, setRows] = useState([]);

    useEffect(
        () => {
            // getPeriodos(predioId);
            if (predioId !== '' && periodoId !== '') {
                getDatos(predioId, periodoId);
            }
        },
        [predioId, periodoId]
    )

    async function getDatos(predio_id, periodo_id) {
        let resultado = await ApiBDL(`api/bdl/reportes/gestion/indicadores/${predioId}/${periodo_id}`, null, {}, true, 'get', props.token);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.indicadores);
        } else {
            alert.show('Error al cargar datos', { type: 'error' });
            setRows([]);
        }
    }

    /**
     * Permite exportar la información de la grilla a excel
     */
    // function exportarExcel() {
    //     exportar(`api/exportar/reportes/gestion/indicadores/${contexto.predio.predio_id}/${cmbxPeriodos.current.value}`, alert);
    // }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf() {
        // let props = {
        //     columns: columns,
        //     rows: rows,
        //     titulo: 'Indicadores Financieros',
        //     bajada: contexto.predio.nombre_predio,
        //     subtitulo: `Inicio: ${contexto.predio.periodo ? getDMY(contexto.predio.periodo.inicio) : ''}, Cierre: ${contexto.predio.periodo ? getDMY(contexto.predio.periodo.cierre) : ''}`
        // }
        // alert.show('La descarga comenzará en breve', { type: 'success' });
        // new Promise(function (resolve, reject) {
        //     IndicadoresPdf(props).then(
        //         function (blob) {
        //             downloadBlob(blob, `Indicadores_Financieros_${contexto.predio.nombre_predio.replace(/ /g, '_')}.pdf`);
        //         }, function (blob) {
        //             alert.show('Error al exportar', { type: 'error' })
        //         })
        // })
    }

    return (
        <>
            <Form>
                <Form.Row style={{paddingBottom:'10px'}}>
                    {/* <Col md={8}>
                        <Form.Group as={Row} controlId="select_periodo">
                            <Col sm="12">
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="text" size="sm">Periodo</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control ref={cmbxPeriodos} as="select" onChange={handleChange}>
                                        {listaPeriodos}
                                    </Form.Control>
                                </InputGroup>
                            </Col>
                        </Form.Group>
                    </Col> */}
                    {/* <Col md={4}>
                        <ButtonGroup className="float-right">
                            <Button
                                variant="info"
                                size="sm">Gráfico</Button>
                            <DefaultButtons exportar={exportarExcel} />
                            <DefaultButtons imprimir={exportarPdf} />
                        </ButtonGroup>
                    </Col> */}
                </Form.Row>
            </Form>
            <div>
                <BaseGrid
                    columns={columns}
                    rowGetter={i => rows[i]}
                    rowsCount={rows.length}
                    minHeight={400}
                    showCountRows={false}
                    rowStyle={[{
                        columna: 'indicador',
                        valor: 'RESULTADO',
                        clases: 'titulo-grilla',
                        css: { color: 'black', fontWeight: '700' }
                    }, {
                        columna: 'indicador',
                        valor: "PRODUCTIVIDAD ECONÓMICA",
                        clases: 'row-totales',
                        css: { color: 'black', fontWeight: '700' }
                    },{
                        columna: 'indicador',
                        valor: 'EFICIENCIA ECONÓMICA',
                        clases: 'titulo-grilla',
                        css: {color: 'black', fontWeight: '700' }
                    },{
                        columna: 'indicador',
                        valor: 'LÍQUIDEZ',
                        clases: 'titulo-grilla',
                        css: {color: 'black', fontWeight: '700' }
                    },{
                        columna: 'indicador',
                        valor: 'SOLVENCIA',
                        clases: 'titulo-grilla',
                        css: {color: 'black', fontWeight: '700' }
                    },{
                        columna: 'indicador',
                        valor: 'RENTABILIDAD',
                        clases: 'titulo-grilla',
                        css: {color: 'black', fontWeight: '700' }
                    }]}/>
            </div>
        </>
    );
}

export default IndicadoresFinancieros;