import React, {useState, useEffect, useContext } from 'react';
import {useAlert} from 'react-alert';
import BaseGrid from '../../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import Layout from '../../../componentes/Layout';
import { NumberFormatter, sortRows, EmptyRowsView, RutFormatter, DateFormatter, RightAlign} from '../../../componentes/grid/utils';
import {Api} from '../../../utils/api';
import UserContext from '../../../UserContext';
import { Row, Col, Form, ButtonGroup} from 'react-bootstrap';
import Buscador from '../../../componentes/Buscador';
import Fecha from '../../../componentes/Fecha';
import { fechaHoy, getYMD } from '../../../utils/Functions';
import SelectorCuenta from '../../../componentes/SelectorCuenta';
import { exportar, downloadBlob, getDMY } from '../../../utils/Functions';
import DefaultButtons from '../../../componentes/grid/DefaultButtons';
import { DiarioBancoCajaPdf } from './pdf/DiarioBancoCajaPdf';

function DiarioBancoCaja(props) {

    const {ContextMenuTrigger} = Menu
    const alert = useAlert();

    const columns = [
        {key: 'documento_fecha', name: 'Fecha', width: 100, formatter: DateFormatter, sortable: true},
        {key: 'documento_folio', name: 'Folio', width: 100},
        {key: 'entidad_rut', name: 'Rut', width: 100, formatter: RutFormatter},
        {key: 'entidad_razon_social', name: 'Razón Social'},
        {key: 'glosa', name: 'Glosa'},
        {key: 'giro', name: 'Giro', width: 100, formatter: NumberFormatter},
        {key: 'deposito', name: 'Deposito', width: 100, formatter: NumberFormatter},
        {key: 'saldo', name: 'Saldo', width: 100, formatter: NumberFormatter}
    ];
    const columnsTotales = [
        { key: 'titulo', name: '', formatter: RightAlign },
        { key: 'total_giro', name: 'Total Giros', width: 100, formatter: NumberFormatter },
        { key: 'total_deposito', name: 'Total Depósitos', width: 100, formatter: NumberFormatter },
        { key: 'saldo', name: 'Saldo', width: 100, formatter: NumberFormatter },
    ];

    const [rows, setRows] = useState([]);
    const [rowsTotales, setRowsTotales] = useState([]);
    const contexto = useContext(UserContext);
    const [codigoCuenta, setCodigoCuenta] = useState('');
    const [fechaInicio, setFechaInicio] = useState(fechaHoy());
    const [fechaCierre, setFechaCierre] = useState(fechaHoy());
    const [textoBuscar, setTextoBuscar] = useState('');
    const [fechaExportar] = useState(fechaHoy());


    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                let inicio = (contexto.predio.periodo ? contexto.predio.periodo.inicio : '');
                let cierre = (contexto.predio.periodo ? contexto.predio.periodo.cierre : '');
                setFechaInicio(inicio);
                setFechaCierre(cierre);
                let cuentaInicial = (codigoCuenta !== '' ? codigoCuenta : 570000);
                setCodigoCuenta(cuentaInicial);
                obtenerDatos(contexto.predio.predio_id, inicio, cierre, textoBuscar, cuentaInicial);
            }
        },
        [contexto]
    )

    async function obtenerDatos(predio, inicio, cierre, texto='', codigoCuenta='') {
        if (codigoCuenta === '') {
            alert.show('Debe seleccionar una cuenta', { type: 'error' });
            return false;
        }
        let resultado = await Api(`api/contabilidad/diario-banco-caja?predio_id=${predio}&inicio=${inicio}&cierre=${cierre}&texto=${texto}&codigo_cuenta=${codigoCuenta}`);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
            setRowsTotales(resultado.data.totales);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            setRows([]);
            setRowsTotales([]);
        }
    }

    /**
     * Cuando escriben en el buscador
     *
     * @param {string} texto
     */
    async function retornoBusqueda(texto) {
        obtenerDatos(contexto.predio.predio_id, fechaInicio, fechaCierre, texto, codigoCuenta);
        setTextoBuscar(texto);
    }

    /**
     * Cuando cambian la fecha inicio
     *
     * @param {date} e
     */
    function onChangeInicio(e) {
        let fecha = getYMD(e);
        setFechaInicio(fecha);
        obtenerDatos(contexto.predio.predio_id, fecha, fechaCierre, textoBuscar, codigoCuenta);
    }

    /**
     * Cuando cambian la fecha cierre
     *
     * @param {date} e
     */
    function onChangeCierre(e) {
        let fecha = getYMD(e);
        setFechaCierre(fecha);
        obtenerDatos(contexto.predio.predio_id, fechaInicio, fecha, textoBuscar, codigoCuenta);
    }

    function retornarSelectorCuenta(data) {
        if (data.length > 0) {
            if (data[0].codigo !== codigoCuenta) {
                obtenerDatos(contexto.predio.predio_id, fechaInicio, fechaCierre, textoBuscar, data[0].codigo);
                setCodigoCuenta(data[0].codigo);
            }
        } else {
            setCodigoCuenta('');
            setRows([]);
            setRowsTotales([]);
        }
    }

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        exportar(`api/exportar/contabilidad/diario-banco-caja?predio_id=${contexto.predio.predio_id}&inicio=${fechaInicio}&cierre=${fechaCierre}&texto=${textoBuscar}&codigo_cuenta=${codigoCuenta}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf() {
        if (codigoCuenta && fechaInicio && fechaCierre) {
            let props = {
                columns: columns,
                rows: rows,
                rowsTotales: rowsTotales,
                titulo: 'Diario Banco Caja',
                subtitulo: `Cuenta: ${codigoCuenta}, Desde: ${getDMY(fechaInicio)} hasta: ${getDMY(fechaCierre)}`,
                bajada: contexto.predio.nombre_predio
            }
            const periodo = `inicio_${getDMY(fechaInicio).replace(/ /g, '_').replaceAll('/', '-')}_fin_${getDMY(fechaCierre).replace(/ /g, '_').replaceAll('/', '-')}`
            alert.show('La descarga comenzará en breve', { type: 'success' });
            new Promise(function (resolve, reject) {
                DiarioBancoCajaPdf(props).then(
                    function (blob) {
                        downloadBlob(blob, `Diario_Banco_Caja_${contexto.predio.nombre_predio.replace(/ /g, '_')}_cuenta_${codigoCuenta}_${periodo}_${getDMY(fechaExportar)}.pdf`);
                    }, function (blob) {
                        alert.show('Error al exportar', { type: 'error' })
                    })
            })
        } else {
            alert.show('Debe seleccionar los filtros', { type: 'error' })
        }
    }

    return (
        <Layout title="Diario Banco / Caja">
            <Row style={{ marginBottom: '10px' }}>
                <Col sm={6} md={4} lg={3}>
                    <Form.Label>Búscador</Form.Label>
                    <Buscador retornoBusqueda={retornoBusqueda} busqueda={textoBuscar} placeholder='Glosa, Razón social...' />
                </Col>
                <Col sm={6} md={4} lg={4}>
                    <Form.Label>Cuenta</Form.Label>
                    <SelectorCuenta
                        id='1'
                        codigoMin={"500000"}
                        codigoMax={"599999"}
                        name="codigo_cuenta"
                        retornar={retornarSelectorCuenta}
                        inicial={codigoCuenta}
                    />
                </Col>
                <Col sm={6} md={4} lg={2} id="cont-fecha-desde">
                    <Form.Label>Desde</Form.Label>
                    <Fecha
                        name='inicio'
                        dateFormat='dd/MM/yyyy' // dd/MM/Y h:mm aa - dd/MM/Y - MM/Y
                        dateFormatOculto='y-m-d' // y-m-d h:i:s
                        inicial={contexto.predio.periodo ? contexto.predio.periodo.inicio : fechaHoy()}
                        minDate={contexto.predio.periodo ? contexto.predio.periodo.inicio : false}
                        maxDate={contexto.predio.periodo ? contexto.predio.periodo.cierre : false}
                        onChange={onChangeInicio}/>
                </Col>
                <Col sm={6} md={4} lg={2} id="cont-fecha-hasta">
                    <Form.Label>Hasta</Form.Label>
                    <Fecha
                        name='cierre'
                        dateFormat='dd/MM/yyyy' // dd/MM/Y h:mm aa - dd/MM/Y - MM/Y
                        dateFormatOculto='y-m-d' // y-m-d h:i:s
                        inicial={contexto.predio.periodo ? contexto.predio.periodo.cierre : fechaHoy()}
                        minDate={contexto.predio.periodo ? contexto.predio.periodo.inicio : false}
                        maxDate={contexto.predio.periodo ? contexto.predio.periodo.cierre : false}
                        onChange={onChangeCierre}
                    />
                </Col>
                <Col sm={1} style={{ paddingTop: '33px' }}>
                    <ButtonGroup className="float-right">
                        <DefaultButtons exportar={exportarExcel} />
                        <DefaultButtons imprimir={exportarPdf} />
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <BaseGrid
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            minHeight={400}
                            RowsContainer={ContextMenuTrigger}
                            onGridSort={(sortColumn, sortDirection) =>
                                setRows(sortRows(rows, sortColumn, sortDirection))
                            }
                            rowStyle={[{
                                columna: 'glosa',
                                valor: '--- SALDO INICIAL ---',
                                clases: 'titulo-grilla',
                                css: { color: 'black', fontWeight: '700' }
                            }]}
                            emptyRowsView={EmptyRowsView}
                            showCountRows={false}/>
                    </div>
                    <div>
                        <BaseGrid
                            columns={columnsTotales}
                            rowGetter={i => rowsTotales[i]}
                            rowsCount={rowsTotales.length}
                            minHeight={177}
                            showCountRows={false}
                            rowStyle={[{
                                columna: 'agregar_titulo',
                                valor: '',
                                clases: '',
                                css: { color: 'black', fontWeight: '700' }
                            }]}/>
                    </div>
                </Col>
            </Row>
        </Layout>
    );
}

export default DiarioBancoCaja;