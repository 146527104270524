import React from 'react';
import {BaseStylesPdf} from '../../../../componentes/pdf/BaseStylesPdf';
import {BaseLinkPdf} from '../../../../componentes/pdf/BaseLinkPdf';
import {Table, TableHeader, TableBody, TableCell, DataTableCell} from '@david.kucsai/react-pdf-table';
import {separadorDeMiles} from '../../../../componentes/grid/utils';

export function BalanceGeneralPdf(props) {
    const styles = BaseStylesPdf();
    var tablaPresupuestoActividad = '';
    try {
        tablaPresupuestoActividad =(
            <>
            <Table data={props.rowsTotales}>
                <TableHeader textAlign={"center"}>
                    <TableCell style={styles.tableHeader} weighting={0.4}></TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.3}>ACTIVOS</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.3}>PASIVOS</TableCell>
                </TableHeader>
            </Table>
            <Table data={props.rows}>
                <TableHeader textAlign={"center"}>
                    {/* el "weighting" idealmente debe sumar 1 para que quede todo ordenado */}
                    <TableCell style={styles.tableHeader} weighting={0.1}>Cuenta</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.311}>Descripción</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Inicio</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Operaciones</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Fin Periodo</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Fin Periodo</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Operaciones</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Inicio</TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{textAlign:"center"}} weighting={0.1} getContent={(r) => r.codigo}/>
                    <DataTableCell style={{textAlign:"left"}} weighting={0.3} getContent={(r) => r.nombre_cuenta}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.1} getContent={(r) => separadorDeMiles(r.activos_inicio)}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.1} getContent={(r) => separadorDeMiles(r.activos_operaciones)}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.1} getContent={(r) => separadorDeMiles(r.activos_fin_periodos)}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.1} getContent={(r) => separadorDeMiles(r.pasivos_fin_periodos)}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.1} getContent={(r) => separadorDeMiles(r.pasivos_operaciones)}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.1} getContent={(r) => separadorDeMiles(r.pasivos_inicio)}/>
                </TableBody>
            </Table>
            <Table data={props.rowsTotales}>
                <TableHeader textAlign={"center"}>
                    {/* el "weighting" idealmente debe sumar 1 para que quede todo ordenado */}
                    <TableCell style={styles.tableHeader} weighting={0.417}></TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Inicio</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Operaciones</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Fin Periodo</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Fin Periodo</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Operaciones</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Inicio</TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{textAlign:"right"}} weighting={0.4} getContent={(r) => r.titulo}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.1} getContent={(r) => separadorDeMiles(r.total_activos_inicio)}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.1} getContent={(r) => separadorDeMiles(r.total_activos_operaciones)}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.1} getContent={(r) => separadorDeMiles(r.total_activos_fin_periodos)}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.1} getContent={(r) => separadorDeMiles(r.total_pasivos_fin_periodos)}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.1} getContent={(r) => separadorDeMiles(r.total_pasivos_operaciones)}/>
                    <DataTableCell style={{textAlign:"right", fontSize:11}} weighting={0.1} getContent={(r) => separadorDeMiles(r.total_pasivos_inicio)}/>
                </TableBody>
            </Table>
            </>)
    } catch (error) {
        console.log("Error BalanceGeneralPdf", error);
    }

    let blob = BaseLinkPdf({...props, body:tablaPresupuestoActividad, orientation:"landscape"})

    return blob
}