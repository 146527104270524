import React, { useState } from 'react'

const UserContext = React.createContext(
    {predio: {predio_id: null, nombre_predio: null, periodo: null, cooperativa: null, perfil: null},
    setPredio: () => {}})

export const UserConsumer = UserContext.Consumer

export const UserProvider = (props) => {

    const setPredio = (predio) => {
      setState({...state, predio: predio})
    }

    const initState = {
        predio: {predio_id: null, nombre_predio: null, periodo: null, cooperativa: null, perfil: null},
        setPredio: setPredio
    }

    const [state, setState] = useState(initState)

    return (
      <UserContext.Provider value={state}>
        {props.children}
      </UserContext.Provider>
    )
  }


export default UserContext