import React from 'react';
import {ButtonGroup, Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FaRegFileExcel, FaRegQuestionCircle, FaRegFilePdf} from 'react-icons/fa';

function DefaultButtons(props){

    return (
        <ButtonGroup>
            {props.exportar &&
                <OverlayTrigger
                    key={'excel'}
                    placement={'top'}
                    overlay={
                        <Tooltip>
                            Presione para exportar
                        </Tooltip>
                    }>
                    <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={props.exportar}>
                            <FaRegFileExcel />
                    </Button>
                </OverlayTrigger>
            }
            {props.imprimir &&
                <OverlayTrigger
                    key={'excel'}
                    placement={'top'}
                    overlay={
                        <Tooltip>
                            Presione para imprimir
                        </Tooltip>
                    }>
                    <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={props.imprimir}>
                        <FaRegFilePdf />
                    </Button>
                </OverlayTrigger>
            }
            {props.ayuda &&
                <OverlayTrigger
                    key={'excel'}
                    placement={'top'}
                    overlay={
                        <Tooltip>
                            Presione para saber más
                        </Tooltip>
                    }>
                    <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={props.ayuda}>
                        <FaRegQuestionCircle />
                    </Button>
                </OverlayTrigger>
            }
        </ButtonGroup>
    );
}

export default DefaultButtons;