import React from 'react';
import {BaseStylesPdf} from '../../../componentes/pdf/BaseStylesPdf';
import {BaseLinkPdf} from '../../../componentes/pdf/BaseLinkPdf';
import {Table, TableHeader, TableBody, TableCell, DataTableCell} from '@david.kucsai/react-pdf-table';
import {separadorDeMiles} from '../../../componentes/grid/utils';

export function FlujoCajaPdf(props) {
    const styles = BaseStylesPdf();
    let headers=[];
    for (let i = 0; i < props.columns.length; i++) {
        const element = props.columns[i];
        headers.push(<TableCell style={styles.tableHeader}>{element.name}</TableCell>)
    }
    const tablaFlujoCaja =(
        <>
        <Table data={props.rows}>
            <TableHeader textAlign={"center"}>
                {/* el "weighting" idealmente debe sumar 1 para que quede todo ordenado */}
                {headers}
            </TableHeader>
            <TableBody>
                <DataTableCell style={{ ...styles.textLeft, ...{ fontSize: 10 }}} getContent={(r) => r.nombre}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[1].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[2].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[3].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[4].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[5].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[6].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[7].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[8].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[9].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[10].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[11].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[12].key])}/>
            </TableBody>
        </Table>
        <Table data={props.rowsTotales}>
            <TableHeader textAlign={"center"}>
                {headers}
            </TableHeader>
            <TableBody>
                <DataTableCell style={{ ...styles.textLeft, ...{ fontSize: 10 }}} getContent={(r) => r.nombre}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[1].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[2].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[3].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[4].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[5].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[6].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[7].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[8].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[9].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[10].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[11].key])}/>
                <DataTableCell style={{ ...styles.textRigh, ...{ fontSize: 10 }}} getContent={(r) => separadorDeMiles(r[props.columns[12].key])}/>
            </TableBody>
        </Table>
        </>)

    let blob = BaseLinkPdf({...props,body:tablaFlujoCaja, orientation:"landscape"})

    return blob
}