import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import PrivateRoute from './componentes/PrivateRoute';
import AdminRoute from './componentes/AdminRoute';
import Login from './componentes/Login';
import Logout from './componentes/Logout';
import PresupuestoCaja from './modulos/presupuesto/PresupuestoCaja';
import Cuentas from './modulos/configuracion/Cuentas';
import Actividades from './modulos/configuracion/Actividades';
import PresupuestoActividad from './modulos/presupuesto/PresupuestoActividad';
import Documentos from './modulos/contabilidad/Documentos';
import FormDocumento from './modulos/contabilidad/FormDocumento';
import Indicadores from './modulos/gestion/Indicadores';
import Entidades from './modulos/configuracion/Entidades';
import PermisosAcceso from './modulos/configuracion/PermisosAccesoPredio';
import Cooperados from './modulos/configuracion/Cooperados';
import Periodos from './modulos/configuracion/Periodos';
import Inventario from './modulos/contabilidad/Inventario';
import BalanceInicial from './modulos/contabilidad/BalanceInicial';
import DiarioCompraVenta from './modulos/contabilidad/libros-diarios/DiarioCompraVenta';
import DiarioBancoCaja from './modulos/contabilidad/libros-diarios/DiarioBancoCaja';
import ResumenComprasVentas from './modulos/contabilidad/libros-diarios/ResumenComprasVentas';
import LibroMayor from './modulos/contabilidad/control-gestion/LibroMayor';
import GestionPorActividad from './modulos/contabilidad/control-gestion/GestionPorActividad';
import MovimientosDeCaja from './modulos/contabilidad/control-gestion/MovimientosDeCaja';
import BalanceGeneral from './modulos/contabilidad/control-gestion/BalanceGeneral';
import ControlCajaPermanente from './modulos/contabilidad/control-gestion/ControlCajaPermanente';
import {LibroActas} from './modulos/contabilidad/cooperativas/LibroActas';
import ResumenDeMargenes from './modulos/contabilidad/control-gestion/ResumenDeMargenes';
import CostoLitroDeLeche from './modulos/contabilidad/control-gestion/CostoLitroDeLeche';
import RentabilidadRubros from './modulos/contabilidad/control-gestion/RentabilidadRubros';
import TecnicoEconomico from './modulos/presupuesto/TecnicoEconomico';
import EstructuraIngresos from './modulos/presupuesto/EstructuraIngresos';
import EstructuraCostos from './modulos/presupuesto/EstructuraCostos';
import ResultadoGlobal from './modulos/presupuesto/ResultadoGlobal';
import FlujoCaja from './modulos/presupuesto/FlujoCaja';
import Usuarios from './modulos/administracion/Usuarios';
import Predios from './modulos/administracion/Predios';
import AdminPermisosAcceso from './modulos/administracion/PermisosAccesoPredio';
import AdminRolesPredio from './modulos/administracion/RolesPredio';
import CuentasBase from './modulos/administracion/CuentasBase';
import CategoriaCuentas from './modulos/administracion/CategoriaCuentas';
import ActividadesBase from './modulos/administracion/ActividadesBase';
import CategoriaActividades from './modulos/administracion/CategoriaActividades';
import Unidades from './modulos/administracion/Unidades';
import Impuestos from './modulos/administracion/Impuestos';
import Inicio from './componentes/inicio/Inicio';
import { UserProvider } from './UserContext';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic';
import "./Global.css";
import history from './componentes/History';
import Cooperativas from './modulos/administracion/Cooperativas';
import AdminCooperados from './modulos/administracion/Cooperados';
import CierreAnual from './modulos/contabilidad/cierre/CierreAnual';
// BDL
import ReporteBdl from './modulos/bdl/ReporteBdl';

// Configuración inicial notificaciones
const options = {
  position: positions.TOP_RIGHT,
  timeout: 6000,
  offset: '5px',
  transition: transitions.FADE,
  containerStyle: {
    zIndex: 99999999
  }
}

function App() {


  return (
    <div className="container-fluid">
      <AlertProvider template={AlertTemplate} {...options}>
        <UserProvider>
          <Router history={history}>
            <Switch>
              <Route exact path="/" component={Inicio} />
              <Route exact path="/bdl/reporte/:predio_id" component={ReporteBdl} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <PrivateRoute path="/presupuesto/caja" component={PresupuestoCaja}/>
              <PrivateRoute path="/presupuesto/actividad" component={PresupuestoActividad}/>
              <PrivateRoute path="/reportes/presupuesto/tecnico-economico" component={TecnicoEconomico} />
              <PrivateRoute path="/reportes/presupuesto/estructura-ingresos" component={EstructuraIngresos}/>
              <PrivateRoute path="/reportes/presupuesto/estructura-costos" component={EstructuraCostos}/>
              <PrivateRoute path="/reportes/presupuesto/resultado-global" component={ResultadoGlobal}/>
              <PrivateRoute path="/reportes/presupuesto/flujo-caja" component={FlujoCaja}/>
              <PrivateRoute path="/contabilidad/inventario" component={Inventario}/>
              <PrivateRoute path="/contabilidad/balance" component={BalanceInicial}/>
              <PrivateRoute exact strict path="/contabilidad/documentos" component={Documentos}/>
              <PrivateRoute exact strict path="/contabilidad/documento/:id" component={FormDocumento} />
              <PrivateRoute exact strict path="/contabilidad/documento/crear" component={FormDocumento} />
              <PrivateRoute path="/gestion/indicadores-financieros" component={Indicadores}/>
              <PrivateRoute path="/configuracion/entidades" component={Entidades}/>
              <PrivateRoute path="/configuracion/cuentas" component={Cuentas} />
              <PrivateRoute path="/configuracion/actividades" component={Actividades}/>
              <PrivateRoute path="/configuracion/periodos" component={Periodos}/>
              <PrivateRoute path="/configuracion/predio/permisos-acceso" component={PermisosAcceso}/>
              <PrivateRoute path="/configuracion/cooperativas/cooperados" component={Cooperados}/>
              <PrivateRoute path="/contabilidad/diario-compra-venta" component={DiarioCompraVenta} />
              <PrivateRoute path="/contabilidad/diario-banco-caja" component={DiarioBancoCaja} />
              <PrivateRoute path="/contabilidad/resumen-compras-ventas" component={ResumenComprasVentas} />
              <PrivateRoute path="/contabilidad/libro-mayor" component={LibroMayor} />
              <PrivateRoute path="/contabilidad/gestion-por-actividad" component={GestionPorActividad} />
              <PrivateRoute path="/contabilidad/movimientos-de-caja" component={MovimientosDeCaja} />
              <PrivateRoute path="/contabilidad/balance-general" component={BalanceGeneral} />
              <PrivateRoute path="/contabilidad/control-caja-permanente" component={ControlCajaPermanente} />
              <PrivateRoute path="/contabilidad/cooperativas/libro-actas" component={LibroActas} />
              <PrivateRoute path="/contabilidad/resumen-de-margenes" component={ResumenDeMargenes} />
              <PrivateRoute path="/contabilidad/costo-litro-leche" component={CostoLitroDeLeche} />
              <PrivateRoute path="/contabilidad/rentabilidad-rubros" component={RentabilidadRubros} />
              <PrivateRoute path="/contabilidad/cierre-anual" component={CierreAnual} />
              <AdminRoute path="/administracion/usuarios" component={Usuarios}/>
              <AdminRoute path="/administracion/predios/listado" component={Predios}/>
              <AdminRoute path="/administracion/predios/permisos-acceso/:predioId" component={AdminPermisosAcceso}/>
              <AdminRoute path="/administracion/predios/roles/:predioId" component={AdminRolesPredio}/>
              <AdminRoute path="/administracion/cuentas" component={CuentasBase} />
              <AdminRoute path="/administracion/categoria-cuentas" component={CategoriaCuentas} />
              <AdminRoute path="/administracion/actividades" component={ActividadesBase} />
              <AdminRoute path="/administracion/categoria-actividades" component={CategoriaActividades} />
              <AdminRoute path="/administracion/unidades" component={Unidades} />
              <AdminRoute path="/administracion/Impuestos" component={Impuestos} />
              <AdminRoute path="/administracion/cooperativas/listado" component={Cooperativas} />
              <AdminRoute path="/administracion/cooperativas/cooperados/:cooperativaId" component={AdminCooperados} />
            </Switch>
          </Router>
        </UserProvider>
      </AlertProvider>
    </div>
  );
}

export default App;
