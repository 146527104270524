import React, {useState} from 'react';
import { NavDropdown, Dropdown, ButtonGroup} from 'react-bootstrap';
import { Link} from 'react-router-dom';
import {FaUserTie} from 'react-icons/fa';
import {FormUsuario, getUsuario, FormCambiarPassword} from '../../modulos/administracion/FormularioUsuario';


function MenuUsuario() {
    const [showFormUsuario, setShowFormUsuario] = useState(false);
    const [showCambiarPassword, setShowCambiarPassword] = useState(false);
    const [usuarioForm, setUsuarioForm] = useState(
		{
			"id":"",
			"nombre_completo": "",
			"correo":"",
			"usuario":"",
			"admin_sistema":false
		});

    const usuario = JSON.parse(localStorage.getItem('usuario'));

    function formularioUsuario(){
        new Promise(function(resolve, reject){
			getUsuario(usuario.id).then(function(usuario){
				setUsuarioForm(usuario);
				setShowFormUsuario(true);
			})
		})
    }

    function formularioCambiarContrasena(){
        new Promise(function(resolve, reject){
			getUsuario(usuario.id).then(function(usuario){
				setUsuarioForm(usuario);
				setShowCambiarPassword(true);
			})
		})
    }

    function cerrarModalUsuario(){
        setShowFormUsuario(false);
        setShowCambiarPassword(false);
    }

    return (
        <Dropdown alignRight as={ButtonGroup}>
            <Dropdown.Toggle variant="outline-success" id="dropdown-basic" size="sm"><FaUserTie /></Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item onClick={formularioUsuario}>{usuario ? usuario.nombre_completo : ''}</Dropdown.Item>
                <Dropdown.Item onClick={formularioCambiarContrasena}>Cambiar Contraseña</Dropdown.Item>
                {usuario && usuario.admin_sistema === true ?
                <div>
                    <Dropdown.Item as={Link} to='/administracion/usuarios'>Administracion</Dropdown.Item>
                </div>
                : ""}
                {/*<Button variant="outline-success" onClick={logout}><FaSignOutAlt /></Button>*/}
                <NavDropdown.Divider />
                <Dropdown.Item as={Link} to='/logout'>Cerrar sesión</Dropdown.Item>
            </Dropdown.Menu>
            <FormUsuario
				show={showFormUsuario}
				onHide={cerrarModalUsuario}
				usuario={usuarioForm}
				funcionRetorno={cerrarModalUsuario}
				size="md"
			/>
            <FormCambiarPassword
				show={showCambiarPassword}
				onHide={cerrarModalUsuario}
				usuario={usuarioForm}
				funcionRetorno={cerrarModalUsuario}
				size="md"
			/>
        </Dropdown>
    );
}

export default MenuUsuario;