import React, { useState, useEffect } from 'react';
import {Api} from '../utils/api';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './SelectorUsuarioMulti.css';


function SelectorUsuarioMulti(props) {
  const [usuarios, setUsuarios] = useState([])
  const {
    seleccionadosId, // Array con id de usuarios seleccionados
    retorno, // Funcion que retorna los usarios seleccionados
    setValue, // Props React-hook-form para setear dato
    name, // nombre del componente para obtener la información
  } = props;
  const [usuariosSeleccionados, setUsuariosSeleccionados] = useState(seleccionadosId)
  const [informacion, setInformacion] = useState([]);

  useEffect(
    () => {
      cargarUsuarios()
    }, []
  )

  async function cargarUsuarios() {
		let resultado = await Api('api/administracion/usuarios', null, {}, true);
		if (resultado && resultado.status === 200) {
			setUsuarios(resultado.data.datos);

		} else {
			alert.show('Error al cargar usuarios', { type: 'error' });
			setUsuarios([]);
		}
	}

  useEffect(
    () => {
      if (informacion.length > 0) {

        const usuarios = [];
        informacion.forEach(dato => {
          seleccionadosId.forEach(id => {
            if (parseInt(id) === dato.value) {
              usuarios.push({ value: parseInt(id), label: dato.label });
            }
          });
        });
      }
    },
    [informacion, seleccionadosId, setValue, name]
  )

  const setMultiSelections = (users) => {
    setUsuariosSeleccionados(users)
    retorno(users)
  }

  return (
    <>
      <Typeahead
        {...props}
        id='selector-usuarios-multiple'
        labelKey={(option) => `${option.username}`}
        options={usuarios}
        name={props.name}
        multiple
        selected={usuariosSeleccionados}
        onChange={setMultiSelections}
        placeholder="Seleccione usuarios administradores..."
        bsSize='small'
      />
    </>
  );
}

export default SelectorUsuarioMulti;