import React, {useState, useEffect, useContext} from 'react';
import BaseGrid from '../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import GridMenu from '../../componentes/grid/GridMenu';
import Layout from '../../componentes/Layout';
import {LongTextFormatter, sortRows, EmptyRowsView, CheckFormatter} from '../../componentes/grid/utils';
import ModalCuentasBase from '../../componentes/ModalCuentasBase';
import Buscador from '../../componentes/Buscador';
import {Api} from '../../utils/api';
import UserContext from '../../UserContext';
import { Button, Row, Col, ButtonGroup} from 'react-bootstrap';
import DefaultButtons from '../../componentes/grid/DefaultButtons';
import {useAlert} from 'react-alert';
import FormularioCuenta from './FormularioCuenta';
import ModalEliminar from '../../componentes/ModalEliminar';
import { exportar, tienePermisos } from '../../utils/Functions';

function Cuentas(props) {

	const {ContextMenuTrigger} = Menu;	// Context Menú Datagrid
	const alert = useAlert();			// Para las notificaciones

	const [activarModalAgregarCuenta, setActivarModalAgregarCuenta] = useState(false);			// Modal para agregar cuenta
	const [modalBase, setModalBase] = useState(false); 	// Modal base

	const [cuentasPredio, setCuentasPredio] = useState([]);	//Todas las cuentas del predio
	const columns = [
		{ key: 'categoria', name: 'Categoría', sortable: true},
		{ key: 'codigo', name: 'Código', width: 200, sortable: true },
		{ key: 'nombre', name: 'Nombre', formatter: LongTextFormatter, sortable: true},
		{ key: 'habilitada', name: 'Habilitada', width: 100, formatter: CheckFormatter}
	 ];

	const contexto = useContext(UserContext);
	const [textoBuscar, setTextoBuscar] = useState('');

	const [cuenta, setCuenta] = useState('');
	// Modal eliminar
	const [showEliminar, setShowEliminar] = useState(false);
	const [msgEliminar, setMsgEliminar] = useState('');

	useEffect(
		() => {
			if (contexto.predio.predio_id) {
				cargarCuentasPredio(contexto.predio.predio_id);
			}
		},
		[contexto]
	)

	/**
	 * Solicitamos las cuentas del predio seleccionado y actualizamos la grilla
	 *
	 * @param {integer} predio
	 */
	async function cargarCuentasPredio(predio) {
		let resultado = await Api(`api/cuenta?predio_id=${predio}&texto=${textoBuscar}`, null, {}, true);
		if (resultado && resultado.status === 200) {
			setCuentasPredio(actualizarDatos(resultado.data.datos));
		} else {
			alert.show('Error al cargar cuentas del predio', { type: 'error' });
			setCuentasPredio([]);
		}
	}

	/**
	 * Buscamos una cuenta base
	 */
	async function cargarCuenta(cuentaBaseId) {
		return await Api(`api/cuenta?id=${cuentaBaseId}&predio_id=${contexto.predio.predio_id}`, null, {}, true);
	}

	/**
	 * Activar modal de cuenta
	 */
	function nuevaCuenta() {
		setActivarModalAgregarCuenta(true);
	}

	/**
	 * Evento para cerrar la modal agregar cuenta, función retorno
	 */
	function cerrarModalAgregarCuenta() {
		setCuenta('');
		cargarCuentasPredio(contexto.predio.predio_id);
		setActivarModalAgregarCuenta(false);
	}

	/**
	 * Cuando queremos editar una cuenta, desde el context menú
	 *
	 * @param {integer} rowIdx
	 */
	const editRow = rowIdx => {
		new Promise(function (resolve, reject) {
			cargarCuenta(cuentasPredio[rowIdx].id).then(function (resultado) {
				if (resultado && resultado.status === 200) {
					setActivarModalAgregarCuenta(true);
					setCuenta(resultado.data.datos.length === 1 ? resultado.data.datos[0] : '');
				} else {
					alert.show('Error al cargar cuenta base', { type: 'error' });
					setCuenta('');
				}
			})
		})
	};

	/**
	 * Cuando queremos elimininar una cuenta, modal confirmación
	 *
	 * @param {*} rowIdx
	 */
	const deleteRow = rowIdx => {
		new Promise(function (resolve, reject) {
			cargarCuenta(cuentasPredio[rowIdx].id).then(function (resultado) {
				if (resultado && resultado.status === 200) {
					if (resultado.data.datos.length === 1) {
						let data = resultado.data.datos[0];
						setCuenta(data);
						setShowEliminar(true);
						setMsgEliminar('¿Estás seguro de eliminar?<br><br><b>Categoría</b>: ' + data.categoria + '<br><b>código:</b> ' + data.codigo + '<br><b>Nombre:</b> ' + data.nombre);
					} else {
						alert.show('Este registro no éxiste', { type: 'error' });
					}
				} else {
					alert.show('Error al cargar cuenta base', { type: 'error' });
				}
			})
		})
	};

    /**
     * Eliminar cuenta
     */
	async function retornoEliminarCuenta() {
		let resultado = await Api(`api/cuenta/${cuenta.id}`, null, {}, true, 'delete');
		if (resultado && resultado.status === 200) {
			setShowEliminar(false);
			setCuenta('');
			cargarCuentasPredio(contexto.predio.predio_id);
			alert.show(resultado.data.msg, { type: 'success' });
		} else {
			alert.show(resultado.data.msg, { type: 'error' });
		}
	}

	/**
	 * Función retorno de la búsqueda, podemos filtrar la información
	 *
	 * @param {string} valor
	 */
	async function retornoBusqueda(texto) {
		setTextoBuscar(texto);
		let resultado = await Api(`api/cuenta?predio_id=${contexto.predio.predio_id}&texto=${texto}`, null, {}, true);
		if (resultado && resultado.status === 200) {
			setCuentasPredio(actualizarDatos(resultado.data.datos));
		} else {
			setCuentasPredio([]);
			alert.show('Error al cargar cuentas base', { type: 'error' });
		}
	}

	/**
	 * Actualizar los registros antes de cargarlos a la grilla
	 *
	 * @param {array} datos
	 * @returns
	 */
	function actualizarDatos(datos) {
		datos.forEach(registro => {
			registro.categoria = `[${registro.categoria_codigo_minimo} - ${registro.categoria_codigo_maximo}] ${registro.categoria}`;
		});
		return datos;
	}

    /**
     * Activamos la modal con todas las cuentas bases disponibles
     */
	function activarModalBase() {
		setModalBase(true);
	}

    /**
     * Evento para cerrar la modal agregar cuenta
     */
	function cerrarModalBase() {
		setModalBase(false);
	}

    /**
     * Es la devolución de llamada cuando seleccionamos una cuenta base en ModalAcuentasBase
     *
     * @param {array} cuentaData - información de la cuenta
     */
	function callbackModalCuentasBase(cuentaData) {
		cargarCuentasPredio(contexto.predio.predio_id); // recargamos la grilla
	}

	/**
    * Permite exportar la información de la grilla a excel
    */
    function exportarExcel() {
		exportar(`api/exportar/cuenta?predio_id=${contexto.predio.predio_id}&texto=${textoBuscar}`, alert);
	}

	return (
		<Layout title="Cuentas">
			<Row style={{paddingBottom:'10px'}}>
				<Col sm="6">
					<Buscador retornoBusqueda={retornoBusqueda} busqueda={textoBuscar} placeholder='Búsqueda por categoría, código o nombre...' />
				</Col>
				<Col sm="6">
					<ButtonGroup className="float-right">
						{tienePermisos('editar',contexto.predio.perfil) &&
							<Button variant="success" size="sm" onClick={nuevaCuenta}>Crear cuenta</Button>
						}
						<Button variant="light" size="sm" onClick={activarModalBase} style={{ border: '1px solid grey' }}>Plan de cuentas</Button>
						<DefaultButtons exportar={exportarExcel} />
					</ButtonGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<div>
						<BaseGrid
							columns={columns}
							rowGetter={i => cuentasPredio[i]}
							rowsCount={cuentasPredio.length}
							minHeight={400}
							contextMenu={
								<GridMenu
									id="pac_1"
									options={ [
                                        {texto:"Editar",
                                        funcion: (e, { rowIdx }) => editRow(rowIdx)},
                                        {texto:"Eliminar",
                                        funcion: (e, { rowIdx }) => deleteRow(rowIdx)}
                                    ]}
								/>
							}
							RowsContainer={ContextMenuTrigger}
							onGridSort={(sortColumn, sortDirection) =>
								setCuentasPredio(sortRows(cuentasPredio, sortColumn, sortDirection))
							}
							emptyRowsView={EmptyRowsView}/>
					</div>
				</Col>
			</Row>
			<FormularioCuenta
				show={activarModalAgregarCuenta}
				onHide={cerrarModalAgregarCuenta}
				cuenta={cuenta}
				size="lg" />
			<ModalCuentasBase show={modalBase} onHide={cerrarModalBase} onClickCerrarModal={cerrarModalBase} permiteAgregar={true} callbackSeleccionar={callbackModalCuentasBase}/>
			<ModalEliminar titulo="Eliminar" show={showEliminar} setShow={setShowEliminar} confirmar={retornoEliminarCuenta}>
				{msgEliminar}
			</ModalEliminar>
		</Layout>
	);
}

export default Cuentas;