import React, {useState, useEffect, useContext} from 'react';
import BaseGrid from '../../componentes/grid/BaseGrid'
import { sortRows, EmptyRowsView} from '../../componentes/grid/utils';
import {Api} from '../../utils/api';
import UserContext from '../../UserContext';
import { Row, Col } from 'react-bootstrap';
import Buscador from '../../componentes/Buscador';
import { useAlert } from 'react-alert';


function TablaVidaUtil(props) {
    
    const [textoBuscar, setTextoBuscar] = useState('');
	const alert = useAlert();			// Para las notificaciones


	const columns = [
		{ key: 'texto', name: 'Nombre', width: 400, sortable: true },
		{ key: 'vida_util', name: 'Vida Útil', width: 180, sortable: true },
		{ key: 'dep_acelerada', name: 'Dep. Acelerada', width: 180, sortable: true },
    ];
    
	const [rows, setRows] = useState([]);
	const contexto = useContext(UserContext);

	useEffect(
		() => {
			cargarVidaUtil();
		},
		[contexto]
	)

	/**
	 * Buscamos y cargamos todos los activos en la grilla
	 */
	async function cargarVidaUtil() {

		let resultado = await Api('api/inventario/tabla_vida_util', null, {}, true);
		if (resultado && resultado.status === 200) {
			setRows(resultado.data.datos);
		} else {
			alert.show('Error al cargar activos', { type: 'error' });
			setRows([]);
		}
    }
    
    /**
     * Función para búsqueda específica de activos
     * 
     * @param {String} texto 
     */
    async function retornoBusqueda(texto) {
		let resultado = await Api(`api/inventario/tabla_vida_util?texto=${texto}`, null, {}, true);
		if (resultado && resultado.status === 200) {
			setRows(resultado.data.datos);
		} else {
            setRows([])
			alert.show('Error al cargar activos', { type: 'error' });
		}
	}


	return (
		<>
			<Row style={{paddingBottom:'10px'}}>
                <Col>
                    <Buscador retornoBusqueda={retornoBusqueda} busqueda={textoBuscar} placeholder='Búsqueda de activo...'/>
                </Col>
			</Row>
			<Row>
				<Col>
					<div>
						<BaseGrid
							columns={columns}
							rowGetter={i => rows[i]}
							rowsCount={rows.length}
							minHeight={400}
							onGridSort={(sortColumn, sortDirection) =>
								setRows(sortRows(rows, sortColumn, sortDirection))
							}
							emptyRowsView={EmptyRowsView}/>
					</div>
				</Col>
			</Row>
		</>
	);
}

export default TablaVidaUtil;