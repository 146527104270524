import React, {useState, useEffect, useContext} from 'react';
import BaseGrid from './grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import GridMenu from '../componentes/grid/GridMenu';
import {sortRows, EmptyRowsView, codigoActividad, zeroFill} from './grid/utils';
import {Api} from '../utils/api';
import Buscador from './Buscador';
import { Button, Row, Col, Modal} from 'react-bootstrap';
import { useAlert} from 'react-alert';
import UserContext from '../UserContext';
import DefaultButtons from '../componentes/grid/DefaultButtons';
import { exportar } from '../utils/Functions';

function ModalActividadesBase(props) {
	const contexto = useContext(UserContext);
	const [cuentasBase, setCuentasBase] = useState([]);
	const [textoBuscar, setTextoBuscar] = useState('');
	const { ContextMenuTrigger } = Menu;
	const [columnasActividadBase, setColumnasActividadBase] = useState([]);
	const alert = useAlert();

	useEffect(
		() => {
			if (props.show === true) {
				cargarActividadesBase();
				// Este es un bug de react-data-grid cuando se despliega en una modal o dentro de un tab(las columnas no ocupan el 100%)
				// Esto pasa porque cuando se carga la grilla en una modal, la modal está oculta y el render de la grilla se basa en ese tamaño que es más pequeño
				// Entonces lo que debemos hacer es cargar las columnas de la grilla luego que se desplego la modal
				// Cuando cerramos la modal debemos vaciar las columnas si no tendremos el mismo problema al abrir la modal de nuevo
				setColumnasActividadBase([
					{ key: 'categoria', name: 'Categoría'},
					{ key: 'rango', name: 'Rango', width: 150 },
					{ key: 'nombre', name: 'Actividad'},
					{ key: 'codigo', name: 'Código', width: 100, formatter: codigoActividad },
				]);
			} else {
				setColumnasActividadBase([]);
			}
		},
		[props.show]
	)

	/**
	 * Cargamos todas las actividades base y actualizamos estado
	 */
	async function cargarActividadesBase() {
		let resultado = await Api(`api/actividad/base`, null, {}, true);
		if (resultado && resultado.status === 200) {
			setCuentasBase(actualizarDatos(resultado.data.datos));
		} else {
			alert.show('Error al cargar actividades base', { type: 'error' });
		}
	}

	/**
	 * Actualizar los registros antes de cargarlos a la grilla
	 *
	 * @param {array} datos
	 * @returns
	 */
	function actualizarDatos(datos) {
		datos.forEach(registro => {
			registro.rango = `${zeroFill(registro.categoria_codigo_minimo, 4)} - ${zeroFill(registro.categoria_codigo_maximo, 4)}`;
		});
		return datos;
	}

	/**
	 * Función retorno de la búsqueda, podemos filtrar la información
	 *
	 * @param {string} valor
	 */
	async function retornoBusqueda(texto) {
		setTextoBuscar(texto);
		let resultado = await Api(`api/actividad/base?texto=${texto}`, null, {}, true);
		if (resultado && resultado.status === 200) {
			setCuentasBase(actualizarDatos(resultado.data.datos));
		} else {
			alert.show('Error al cargar actividades base', { type: 'error' });
		}
	}

	const crearActividad = rowIdx => {
		if (props.permiteAgregar) {
			agregarActividadDirectamenteBase(cuentasBase[rowIdx]);
		}
	};

    /**
     * Permite crear una actividad que ha sido seleccionada desde ModalActividadesBase
     * Esta lógica se hizo así para que no pueda cambiar el nombre y el código de la cuenta base principal de la  categoría
     * Ejemplo: si selecciona Capital Propio: 100000 y lo no tiene, no queremos que cambie el nombre y el código ya que es la primera de su categoría
     */
	async function agregarActividadDirectamenteBase(actividadData) {
		actividadData.categoria_actividad_id = actividadData.categoria_id;
		actividadData.predio_id = contexto.predio.predio_id;
		actividadData.depreciacion = 1;
		let resultado = await Api('api/actividad', actividadData, { 'Content-Type': 'application/json' }, true, 'post');
		if (resultado && resultado.status === 200) {
			alert.show(resultado.data.msg, { type: 'success' });
			props.callbackSeleccionar(resultado.data.datos);
		} else {
			alert.show(resultado.data.msg, { type: 'error' });
		}
	}

	/**
	* Permite exportar la información de la grilla a excel
	*/
	function exportarExcel() {
		exportar(`api/exportar/actividades/base?texto=${textoBuscar}`, alert);
	}

	return (
		<Modal show={props.show} onHide={props.onHide} size="lg">
			<Modal.Header closeButton>
				Plan de actividades
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col sm="11">
						<Buscador retornoBusqueda={retornoBusqueda} busqueda={textoBuscar} placeholder='Búsqueda por categoría o cuenta...' />
					</Col>
					<Col sm="1" className="float-right" style={{ textAlign: 'right' }}>
						<DefaultButtons exportar={exportarExcel} />
					</Col>
				</Row>
				<Row>
					<Col sm="12">
						<div>
							<BaseGrid
								columns={columnasActividadBase}
								rowGetter={i => cuentasBase[i]}
								rowsCount={cuentasBase.length}
								minHeight={300}
								RowsContainer={ContextMenuTrigger}
								// onGridSort={(sortColumn, sortDirection) =>
								// 	setCuentasBase(sortRows(cuentasBase, sortColumn, sortDirection))
								// }
								contextMenu={
									<GridMenu
										permiteAgregar={props.permiteAgregar}
										id="pac_12xxxx"
										options={ [
											{texto:"Crear",
											funcion: (e, { rowIdx }) => crearActividad(rowIdx)}
										]}
									/>
								}
								emptyRowsView={EmptyRowsView} />
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={props.onClickCerrarModal} size="sm">
					Cerrar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default ModalActividadesBase;