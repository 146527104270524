import React, {useState, useEffect, useContext, useRef } from 'react';
import { Navbar, Nav, NavDropdown, Form, Button, Dropdown, ButtonGroup,Image,Row } from 'react-bootstrap';
import {withRouter, Link} from 'react-router-dom';
import SelectorPredio from '../SelectorPredio';
import UserContext from '../../UserContext'
import MenuUsuario from './MenuUsuario';
import icono_conga from '../inicio/images/Group.png'
import { Api } from '../../utils/api'

function MenuConga(props) {
    const [periodos, setPeriodos] = useState([]);
    const [periodoid, setPeriodoId] = useState();
    const listaPeriodos = periodos.map((p) =>
        <option key={p.id} value={p.id}>
            {p.numero}: ({p.inicio} {p.cierre})
    </option>
    );
    const cmbxPeriodos = useRef(null);
    const style_header = {
        background: 'linear-gradient(178.18deg, #20B099 18.81%, #1C8AB7 108.17%, #1A74C8 158.3%)',

    };
    const style_titulo = {
        fontStyle:'normal',
        fontWeight:'700 !important',
        fontSize:33,
        textAlign:'center',
        color:'#FFFFFF'
    }

    const contexto = useContext(UserContext)
    const [mostrarSelectorPredio, setMostrarSelectorPredio] = useState(false);
    const tipoPredio = contexto.predio.cooperativa === true ? "Cooperativa" : "Predio";

    function handleChange(e) {
        setPeriodoId(e.target.value)
        periodos.forEach(element => {
            if (parseInt(element.id) === parseInt(e.target.value) ){
                contexto.setPredio({...contexto.predio,periodo:element})
            }
        });
    }
    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                getPeriodos(contexto.predio.predio_id);
            }
        },
        [contexto]
    )
    async function getPeriodos(predio) {
        let resultado = await Api(`api/balance/periodos/${predio}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setPeriodos(resultado.data.datos);
            // getDatos(cmbxPeriodos.current.value);
            setPeriodoId(cmbxPeriodos.current.value);
        } else {
            alert.show('Error al cargar periodos', { type: 'error' });
        }
    }

    useEffect(
        () => {
            if (!contexto.predio.predio_id) {
                setMostrarSelectorPredio(true);
            }
            if (contexto.predio.periodo){
                setPeriodoId(contexto.predio.periodo.id)
            }
        },
        [contexto]
    )

    const logout = () => {
        props.history.push('/logout');
    }

    function mostrarModalPredios() {
        setMostrarSelectorPredio(true);
    }
    function valorPeriodo(contexto){
        periodos.forEach(element => {

            if (parseInt(element.id) === parseInt(contexto.predio.periodo.id) ){
                return element.inicio

            }
        });
    }
    return (
        <Navbar style={style_header} expand="lg">
            <Navbar.Brand href="#home">
                {/* <img
                    src={window.location.origin + '/logo_uach.png'}
                    // width="50"
                    // height="50"
                    className="d-inline-block align-top"
                    alt="Logo Universidad Austral de Chile"
                /> */}
                <Image src={icono_conga} />
                <span style={style_titulo}>  Conga  </span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    {/* <Nav.Link as={Link} to='/'>Home</Nav.Link> */}
                    <NavDropdown title="Presupuesto" id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to='/presupuesto/actividad'>Presupuesto por Actividad</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/presupuesto/caja'>Presupuesto de Caja</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <Dropdown.Header>INFORMES</Dropdown.Header>
                        <NavDropdown.Item as={Link} to='/reportes/presupuesto/tecnico-economico'>Presupuesto Técnico Económico</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/reportes/presupuesto/estructura-costos'>Estructura de Costos</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/reportes/presupuesto/estructura-ingresos'>Estructura de Ingresos</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/reportes/presupuesto/resultado-global'>Resultado Global de la Explotación</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/reportes/presupuesto/flujo-caja'>Flujo de Caja</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Contabilidad" id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to='/contabilidad/inventario'>Inventario / Balance Inicial</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/contabilidad/balance'>Resumen Balance Inicial</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/contabilidad/documentos'>Movimientos Contables</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <Dropdown.Header>LIBROS DIARIOS</Dropdown.Header>
                        <NavDropdown.Item as={Link} to='/contabilidad/diario-compra-venta'>Diario Compras / Ventas</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/contabilidad/diario-banco-caja'>Diario Banco / Caja</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/contabilidad/resumen-compras-ventas'>Resumen Compras / Ventas</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/contabilidad/libro-mayor'>Libro Mayor</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/contabilidad/cierre-anual'>Cierre Anual</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title='Control de Gestión' id='basic-nav-dropdown'>
                        <NavDropdown.Item as={Link} to="/contabilidad/gestion-por-actividad">Gestión por Actividad</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/contabilidad/balance-general">Balance General</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/contabilidad/resumen-de-margenes">Resumen de Márgenes</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/gestion/indicadores-financieros'>Indicadores Financieros</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/contabilidad/movimientos-de-caja'>Movimientos de Caja</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/contabilidad/control-caja-permanente">Control de Caja Permanente</NavDropdown.Item>
                        {/* <NavDropdown.Item as={Link} to='/contabilidad/costo-litro-leche'>Costo Litro de Leche</NavDropdown.Item> */}
                        <NavDropdown.Item as={Link} to='/contabilidad/rentabilidad-rubros'>Rentabilidad por Rubros</NavDropdown.Item>
                        { tipoPredio === "Cooperativa" &&
                            <>
                                <NavDropdown.Divider />
                                <Dropdown.Header>LIBROS COOPERATIVAS</Dropdown.Header>
                                <NavDropdown.Item as={Link} to='/contabilidad/cooperativas/libro-actas'>Libro de Actas</NavDropdown.Item>
                            </>
                        }
                    </NavDropdown>
                    <NavDropdown title="Configuración" id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to={'/configuracion/predio/permisos-acceso'}>{tipoPredio}</NavDropdown.Item>
                        { tipoPredio === "Cooperativa" &&
                            <NavDropdown.Item as={Link} to={'/configuracion/cooperativas/cooperados'}>Cooperados</NavDropdown.Item>
                        }
                        <NavDropdown.Item as={Link} to='/configuracion/entidades'>Clientes y Proveedores</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/configuracion/cuentas">Cuentas</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/configuracion/actividades">Actividades</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/configuracion/periodos">Periodos</NavDropdown.Item>
                    </NavDropdown>
                </Nav>

                <Form inline className="float-right">
                    {/* <FormControl type="text" placeholder="Search" className="mr-sm-2" /> */}
                    <ButtonGroup as={Row} style={{background:"white"}}>
                        <Form.Control ref={cmbxPeriodos} as="select"  onChange={(e)=>handleChange(e)} value={periodoid} size="sm" >
                            {listaPeriodos}

                        </Form.Control>
                        <Button variant="outline-secondary" onClick={mostrarModalPredios} size="sm">
                            {contexto.predio.nombre_predio}
                        </Button>
                        <MenuUsuario />
                    </ButtonGroup>
                </Form>
            </Navbar.Collapse>
            <SelectorPredio
                show={mostrarSelectorPredio}
                hide={() => setMostrarSelectorPredio(false)}
                logout={logout}
                backdrop="static"
                keyboard={false}
            >
            </SelectorPredio>
        </Navbar>
    );
}

export default withRouter(MenuConga);