import React, { useState,useEffect } from 'react';
import { Modal, Button, Col } from 'react-bootstrap';
import {
    PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell
} from 'recharts';
import { colorCuenta } from '../../componentes/chart/utils';
import { Row } from 'react-bootstrap';

function ChartBalanceGeneral(props) {

    const [datosPorcentajesActivos, setDatosPorcentajesActivos]=useState([]);
    const [datosPorcentajesPasivos, setDatosPorcentajesPasivos]=useState([]);

    useEffect(
        ()=>{
            agrupaDatos(props.rows,props.rowsTotales)
        },
        [props.rows,props.rowsTotales]
    )

    function agrupaDatos(rows,rowsTotales) {
        //Activos
        let datosGraficosActivos = rows
        datosGraficosActivos = datosGraficosActivos.filter(data => data.codigo !== "")
        datosGraficosActivos = datosGraficosActivos.filter(data => data.codigo !== null)
        datosGraficosActivos = datosGraficosActivos.filter(data => data.activos_operaciones !== null)

        //Pasivos
        let datosGraficosPasivos = rows
        datosGraficosPasivos = datosGraficosPasivos.filter(data => data.codigo !== "")
        datosGraficosPasivos = datosGraficosPasivos.filter(data => data.codigo !== null)
        datosGraficosPasivos = datosGraficosPasivos.filter(data => data.pasivos_operaciones !== null)
        
        let total_porcentaje_activos = 0;
        let total_porcentaje_pasivos = 0

        let total_otros1 = 0;
        let total_otros2 = 0;

        let porcentaje_activos;
        let porcentaje_pasivos;

        let datosPorcentajesActivosArray = []
        let datosPorcentajesPasivosArray = []

        if (rowsTotales[0]) {
            total_porcentaje_activos = rowsTotales[0].total_activos_operaciones
        }
        datosGraficosActivos.forEach(data => {
            if (data.activos_operaciones !== null && data.activos_operaciones !== "") {
                porcentaje_activos = (data.activos_operaciones * 100) / total_porcentaje_activos
                porcentaje_activos = parseFloat(porcentaje_activos.toFixed(2))
                if (porcentaje_activos < 1) {
                    total_otros1 = total_otros1 + porcentaje_activos;
                } else {
                    datosPorcentajesActivosArray.push({
                        'activos_operaciones': porcentaje_activos, 'nombre_cuenta': data.nombre_cuenta, 'codigo': data.codigo
                    })
                }

            }
        })

        if (rowsTotales[0]) {
            total_porcentaje_pasivos = rowsTotales[0].total_pasivos_operaciones
        }
        datosGraficosPasivos.forEach(data => {
            if (data.pasivos_operaciones !== null && data.pasivos_operaciones !== "") {
                porcentaje_pasivos = (data.pasivos_operaciones * 100) / total_porcentaje_pasivos
                porcentaje_pasivos = parseFloat(porcentaje_pasivos.toFixed(2))
                if (porcentaje_pasivos < 1) {
                    total_otros2 = total_otros2 + porcentaje_pasivos;
                } else {
                    datosPorcentajesPasivosArray.push({
                        'pasivos_operaciones': porcentaje_pasivos, 'nombre_cuenta': data.nombre_cuenta, 'codigo': data.codigo
                    })
                }
            }
        })
        total_otros1 = parseFloat(total_otros1.toFixed(2))
        total_otros2 = parseFloat(total_otros2.toFixed(2))

        if (total_otros1>0){
            datosPorcentajesActivosArray.push({
                'activos_operaciones': total_otros1, 'nombre_cuenta': 'Otros', 'codigo': 100000
            })
        }
        if (total_otros2>0){
            datosPorcentajesPasivosArray.push({
                'pasivos_operaciones': total_otros2, 'nombre_cuenta': 'Otros', 'codigo': 100000
            })
        }
        setDatosPorcentajesActivos(datosPorcentajesActivosArray)
        setDatosPorcentajesPasivos(datosPorcentajesPasivosArray)
    }
    
    return (
        <Modal {...props} backdrop="static" keyboard={false} className="modal-xl">
            <Modal.Header>
                <Modal.Title>Balance General</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Row >
                    <Col lg="6" style={{ borderRight: '1px solid #ccc' }}>
                        <h4 style={{ textAlign: 'center' }}>Activos</h4>
                        <div style={{ width: '100%', height: 350 }}>
                            <ResponsiveContainer>
                                <PieChart
                                    margin={{
                                        top: 5, right: 30, left: 20, bottom: 5,
                                    }}>
                                    <Pie
                                        dataKey="activos_operaciones"
                                        nameKey="nombre_cuenta"
                                        isAnimationActive={false}
                                        data={datosPorcentajesActivos}
                                        outerRadius={100}
                                        label>
                                        {
                                            datosPorcentajesActivos.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colorCuenta(entry.codigo)} />
                                            ))
                                        }
                                    </Pie>
                                    <Tooltip />
                                    <Legend layout='vertical' align='right' verticalAlign='top' width='200px' />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                    <Col lg="6" >
                        <h4 style={{ textAlign: 'center' }}>Pasivos</h4>
                        <div style={{ width: '100%', height: 350 }}>
                            <ResponsiveContainer>
                                <PieChart
                                    margin={{
                                        top: 5, right: 30, left: 20, bottom: 5,
                                    }}>
                                    <Pie
                                        dataKey="pasivos_operaciones"
                                        nameKey="nombre_cuenta"
                                        isAnimationActive={false}
                                        data={datosPorcentajesPasivos}
                                        outerRadius={100}
                                        label>
                                        {
                                            datosPorcentajesPasivos.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colorCuenta(entry.codigo)} />
                                            ))
                                        }
                                    </Pie>
                                    <Tooltip />
                                    <Legend layout='vertical' align='right' verticalAlign='top' width='200px' />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ChartBalanceGeneral;