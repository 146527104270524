import React, {useState} from 'react';
import {Modal, Button, Row, Col, Form} from 'react-bootstrap';
import { useForm, ErrorMessage } from 'react-hook-form'
import SelectorCuenta from '../../componentes/SelectorCuenta';
import SelectorUnidades from '../../componentes/SelectorUnidades';
import {Api, ObjectToFormdata} from '../../utils/api';
import { useAlert } from 'react-alert';
import { FaSave, FaWindowClose } from 'react-icons/fa';


export function FormBalanceInicial(props) {
    const alert = useAlert();           // Para las notificaciones
    const { ...rest} = props
    const initState = {
        id: null
    };
    const [dato, setDato] = useState(initState);

    // para la validacion y envio de formulario - react hook form
    const { register, handleSubmit, watch, errors, setValue } = useForm({
        defaultValues: {
            codigo_cuenta: '',
            tipo: 'C',
            monto_total: '',
            unidad: '',
            cantidad: '',
            comentario: ''
        }
    })

    const onSubmit = data => {
        data.periodo_id = props.periodoid;
        enviarFormulario(data);
    }

    async function enviarFormulario(data){
        data = ObjectToFormdata(data);
        if (props.balance!==null) {
            let resultado = await Api(`api/balance/${props.periodoid}/dato_balance/${props.balance.id}`, data, { 'Content-Type': 'application/json' }, true, 'put');
            if (resultado && resultado.status === 200) {
                alert.show(resultado.data.msg, { type: 'success' });
                cerrarModal();
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
            }
        }
        else{
            let resultado = await Api(`api/balance/${props.periodoid}/dato_balance`, data, { 'Content-Type': 'application/json' }, true, 'post');
            if (resultado && resultado.status === 200) {
                alert.show(resultado.data.msg, { type: 'success' });
                cerrarModal();
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
            }
        }
		
    }

    function initForm() {
        //getData();
        if (props.balance!=null){
            setValue([
                { codigo_cuenta: props.balance.codigo_cuenta },
                { tipo: props.balance.tipo },
                { monto_total: props.balance.monto_total },
                { unidad: props.balance.unidad },
                { cantidad: props.balance.cantidad },
                { comentario: props.balance.comentario}
            ]);
            setDato(props.balance)
        }
    }

    function closeForm() {
        setDato(initState);
    }

    function cerrarModal() {
        setDato(initState);
        props.onHide();
    }

    return (
        <Modal { ...rest} onShow={initForm} onHide={closeForm}  backdrop="static" keyboard={false}>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header>
                    <Modal.Title>Balance Inicial</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="hidden" name="id" defaultValue={dato.id} ref={register({})}></Form.Control>
                    {/* cuenta, tipo (d,c), monto, unidad, cantidad*/}
                    <Form.Group as={Row} controlId="1">
                        <Form.Label column sm="2">
                            Cuenta:
                        </Form.Label>
                        <Col sm="10">
                            <SelectorCuenta
                                id='1'
                                codigoMin={"100001"}
                                codigoMax={"599999"}
                                name="codigo_cuenta"
                                register={register}
                                errors={errors}
                                inicial={watch('codigo_cuenta')}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="2">
                        <Form.Label column sm="2">
                            Tipo:
                        </Form.Label>
                        <Col sm="10">
                            <Form.Check name='tipo' inline type='radio' label={`Débito`} id={`ingreso_id`}
                                value="D" ref={register({})}/>
                            <Form.Check name='tipo' inline type='radio' label={`Crédito`} id={`egreso_id}`}
                                value="C" ref={register({})}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="5">
                        <Form.Label column sm="2">
                            Monto:
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control
                                name="monto_total"
                                type="number"
                                placeholder="Ingrese el monto ($) Total"
                                ref={register({
                                    required: "Por favor, ingrese el monto total",
                                    min:{value:5,message:"minimo 5"}
                                })}
                                isInvalid={errors.monto_total} />
                            <ErrorMessage errors={errors} name="monto_total" as="div" className="invalid-feedback" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="selectUnidad">
                            <Form.Label column sm="2"> Unidad: </Form.Label>
                            <Col sm="10">
                                <SelectorUnidades
                                    id='2'
                                    name='unidad'
                                    register={register}
                                    errors={errors}
                                    inicial={watch('unidad')}
                                    //returnSelectorUnidades={returnSelectorUnidades}
                                    placeholder="Unidad en que se medirá el presupuesto"
                                    // disabled={props.presupuestoid ? true : false}
                                    // desactivarBotonBorrar={props.presupuestoid ? true : false}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formTam">
                            <Form.Label column sm="2"> Cantidad: </Form.Label>
                            <Col sm="10">
                                <Form.Control
                                    name="cantidad"
                                    type="number"
                                    placeholder="Cantidad"
                                    size="sm"
                                    ref={register({
                                        /*required: "Por favor, ingrese el tamaño del predio",
                                        min: { value: 1, message: "mínimo 1" }*/
                                    })}
                                    isInvalid={errors.cantidad}
                                />
                                <ErrorMessage errors={errors} name="cantidad" as="div" className="invalid-feedback" />
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="textarea">
                            <Form.Label>Comentario:</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="comentario"
                                rows="2"
                                ref={register({})}/>
                        </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cerrarModal}><FaWindowClose/> Cerrar</Button>
                    <Button variant="success" type="submit"><FaSave/> Guardar</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export async function GetBalanceInicial(periodoId,balanceId){
    let resultado = await Api(`api/balance/${periodoId}/${balanceId}`, null, {}, true);
    return resultado.data.dato;
}

export function FormularioEliminarBalanceInicial(props) {
    const alert = useAlert();           // Para las notificaciones
    const {balance, funcionRetorno, ...rest} = props;

    async function eliminarBalance(data){
        let resultado = await Api(`api/balance/${props.periodoid}/dato_balance/`+data.id,null,{},true,'delete');
		if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            props.funcionRetorno(data);
		} else {
            alert.show(resultado.data.msg, { type: 'error' });
		}
    }

    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Eliminar balance
            </Modal.Header>
            <Modal.Body>
                <Col sm="12">
                ¿Está seguro de eliminar el balance {balance?balance.nombre_cuenta:""}?
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.onHide}>
                    Cerrar
                    </Button>
                    <Button variant="danger" size="sm" 
                        onClick={(e) => eliminarBalance(balance)}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}