import React from 'react';
import {BaseStylesPdf} from '../../../../componentes/pdf/BaseStylesPdf';
import {BaseLinkPdf} from '../../../../componentes/pdf/BaseLinkPdf';
import {Table, TableHeader, TableBody, TableCell, DataTableCell} from '@david.kucsai/react-pdf-table';
import { separadorDeMilesOrVacio, DateFormatterField, completaRut } from '../../../../componentes/grid/utils';

export function DiarioBancoCajaPdf(props) {
    const styles = BaseStylesPdf();
    var tablaBalanceInicial = '';
    try {
        tablaBalanceInicial = (
            <>
            <Table data={props.rows}>
                <TableHeader textAlign={"center"}>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Fecha</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Folio</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Rut</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.205}>Razón Social</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.205}>Glosa</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Giro</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Deposito</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Saldo</TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{ textAlign: "center", fontSize: 10 }} weighting={0.1} getContent={(r) => DateFormatterField(r.documento_fecha)} />
                    <DataTableCell style={{ textAlign: "center", fontSize: 10 }} weighting={0.1} getContent={(r) => r.documento_folio} />
                    <DataTableCell style={{ textAlign: "center", fontSize: 10 }} weighting={0.1} getContent={(r) => completaRut(r.entidad_rut)} />
                    <DataTableCell style={{ textAlign: "left", fontSize: 10 }} weighting={0.2} getContent={(r) => (r.entidad_razon_social)} />
                    <DataTableCell style={{ textAlign: "left", fontSize: 10 }} weighting={0.2} getContent={(r) => r.glosa} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.giro)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.deposito)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.saldo)} />
                </TableBody>
            </Table>
            <Table data={props.rowsTotales}>
                <TableHeader textAlign={"center"}>
                    <TableCell style={styles.tableHeader} weighting={0.735}> </TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Total Giros</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Total Depósitos</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Saldo</TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.7} getContent={(r) => r.titulo} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.total_giro)} />
                    <DataTableCell style={{ textAlign: "center", fontSize: 10, fontWeight: 700 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.total_deposito)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.saldo)} />
                </TableBody>
            </Table>
            </>)
    } catch (error) {
        console.log("Error DiarioBancoCajaPdf", error);
    }

    let blob = BaseLinkPdf({ ...props, body: tablaBalanceInicial, orientation:"landscape"})

    return blob
}