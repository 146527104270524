import React from 'react';
import "./BaseGrid.css";
import { EmptyRowsView } from './utils';
import ReactDataGrid from 'react-data-grid';

function BaseGrid(props) {
    const { minHeight, showCountRows, ...rest } = props
    var compRows = showCountRows === undefined ? true : showCountRows;

    // RowRenderer permite recorrer cada fila de la grilla, de esta manera podemos agregar estilo, ejemplo de como utilizar
    // rowStyle = {[{
    //         columna: 'titulo',
    //         valor: true,
    //         clases: 'titulo-grilla',
    //         css: { color: 'black', fontWeight: '700' }
    //     }]}
    const RowRenderer = ({ renderBaseRow, ...props }) => {
        if (rest.rowStyle && (rest.rowStyle.length > 0)) { // Si viene, agregamos clases y diseño
            let clases = '';
            let css = {};
            rest.rowStyle.forEach(data => {
                if (props.row[data.columna] === data.valor) {
                    css = { ...css, ...data.css};
                    clases += ' ' + data.clases;
                }
            });
            return <div style={ css } className={clases}>{renderBaseRow(props)}</div>;
        } else { // Si no viene RowRenderer no agregamos nada de diseño
            return <div>{renderBaseRow(props)}</div>;
        }
    };

    return (
        <div>
            <ReactDataGrid
                {...rest}
                minHeight={minHeight ? minHeight : 400}
                emptyRowsView={EmptyRowsView}
                rowRenderer={RowRenderer}
            />

            {compRows === true &&
                <div align="center">
                    <button type="button" className="btn btn-info btn-sm" style={{ width: "100%" }}>
                        Datos: <span className="badge badge-light">{props.rowsCount}</span>
                    </button>
                </div>
            }
        </div>
    );
}

export default BaseGrid;