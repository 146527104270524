import React, {useState, useContext, useEffect, useRef} from 'react';
import {Typeahead} from 'react-bootstrap-typeahead';
import { Button, InputGroup, Row, Col, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FaPlusCircle, FaCoins} from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import {Api} from '../utils/api';
import UserContext from '../UserContext';
import FormularioCuenta from '../modulos/configuracion/FormularioCuenta';
import { ErrorMessage } from 'react-hook-form'
import { useAlert } from 'react-alert';
import { tienePermisos } from '../utils/Functions';

function SelectorCuenta(props) {
    const alert = useAlert();           // Para las notificaciones
    const refSelectorCuenta = useRef(null);
    const contexto = useContext(UserContext);
    const [cuentas, setCuentas] = useState([]);
    const [oculto, setOculto] = useState('')
    const codigoMin = props.codigoMin || "";
    const codigoMax = props.codigoMax || "";
    const soloPredio = props.solo_predio || ''; // Si viene true traemos solo las cuentas del predio
    const noCargar = props.no_cargar || false; // Si este parametro viene true, no cargamos valores por defecto en el combobox
    const [activarModalAgregar, setActivarModalAgregar] = useState(false);  // Modal para agregar cuenta

    useEffect(
        () => {
            if (contexto.predio.predio_id && noCargar === false) {
                obtenerCuentas(contexto.predio.predio_id);
            }
        },
        [contexto]
    )

    useEffect(
        () => {
            if (props.inicial) { // Solo cuando es distinto a vacio
                if (cuentas.length === 0) { // No hay cuentas cargadas, tenemos que ir a buscar la seleccionada y seleccionar el combobox
                    if (contexto.predio.predio_id) {
                        obtenerCuenta(props.inicial);
                    }
                } else { // Si hay cuentas cargadas, solo seleccionamos la que esté
                    if (refSelectorCuenta.current.getInstance().state.selected.length === 0) { // Solo cuando no hay un elemento seleccionado en el combobox, esto se da solo en el inicio, ref.current.getInstance()._input.value
                        cuentas.forEach(cuenta => {  // Buscamos dentro de las cuentas la que tenemos que seleccionar
                            if (parseInt(cuenta.codigo) === parseInt(props.inicial)) {
                                refSelectorCuenta.current.getInstance()._updateSelected([cuenta]); // Automáticamente llama cambiarSelector
                            }
                            // console.log(cuenta);
                        });
                    }
                }
            }
        },
        [props.inicial]
    )

    useEffect(
        () => {
            if (props.informacion) {
                setCuentas(props.informacion);
            }
        },
        [props.informacion]
    )

    useEffect(
        () => {
            if (props.selecionar_valor) {
                if (props.selecionar_valor !== 'limpiar') {
                    cuentas.forEach(cuenta => {  // Buscamos dentro de las cuentas la que tenemos que seleccionar
                        if (parseInt(cuenta.codigo) === parseInt(props.selecionar_valor)) {
                            refSelectorCuenta.current.getInstance()._updateSelected([cuenta]); // Automáticamente llama cambiarSelector
                        }
                    });
                } else {
                    refSelectorCuenta.current.getInstance().clear(); // solo limpia la casila de la unidad, no hace nada más
                    refSelectorCuenta.current.getInstance()._updateSelected([]); // Este limpia la casilla y activa cambiarSelector
                }
            }
        },
        [props.selecionar_valor]
    )

    /**
     * uscamos la cuenta y actualizamos el valor por defecto del combobox
     *
     * @param {integer} predio
     */
    async function obtenerCuenta(codigo) {
        let resultado = await Api(`api/cuenta/plan/${contexto.predio.predio_id}?codigo=${codigo}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            if (resultado.data.datos.length > 0) {
                refSelectorCuenta.current.getInstance()._updateSelected([resultado.data.datos[0]]); // Automáticamente llama cambiaSelectorCuenta
            } else {
                alert.show('Error al actualizar combobox cuenta', { type: 'error' });
            }
        } else {
            alert.show('Error al actualizar combobox cuenta', { type: 'error' });
        }
    }


    /**
     * Cargamos las cuentas en el combobox
     *
     * @param {integer} predio
     */
    async function obtenerCuentas(predio) {
        let resultado = '';
        if (soloPredio) {
            resultado = await Api(`api/cuenta?predio_id=${predio}&codigo_min=${codigoMin}&codigo_max=${codigoMax}`, null, {}, true);
        } else {
            resultado = await Api(`api/cuenta/plan/${predio}?codigo_min=${codigoMin}&codigo_max=${codigoMax}`, null, {}, true);
        }
        if (resultado && resultado.status === 200) {
            setCuentas(resultado.data.datos);  // se obtiene la lista de cuentas
        } else {
            setCuentas([]);
        }
    }

    /**
     * Cada vez que cambia el selector cuenta
     *
     * @param {array} selected
     */
    function cambiaSelectorCuenta(selected){
        if (props.retornar) {
            props.retornar(selected);
        }
        if (selected[0] !== undefined){
            setOculto(selected[0].codigo);
        } else{
            setOculto('');
        }
    }

    /**
     * Evento para cerrar la modal agregar cuenta, si viene cuentaData, es porque se creo uno
     *
     * @param {Object} cuentaData
     */
    function cerrarModalAgregar(cuentaData) {
        if (cuentaData) {
            let cuentasData = Object.assign([], cuentas);
            cuentasData.push(cuentaData);
            setCuentas(cuentasData);
            refSelectorCuenta.current.getInstance()._updateSelected([cuentaData]); // Automáticamente llama cambiaSelectorCuenta
        }
        setActivarModalAgregar(false);
    }

    return (
        <>
            <InputGroup size="sm">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1"><FaCoins /></InputGroup.Text>
                </InputGroup.Prepend>
                <div style={{ width: 'calc(100% - 96px)' }}>
                    <Typeahead
                        { ...props}
                        maxResults={400}
                        bsSize='small'
                        options={cuentas}
                        highlightOnlyResult={true}
                        selectHintOnEnter={true}
                        flip={true}
                        ref={refSelectorCuenta}
                        isInvalid={props.errors ? (props.errors[props.name]?true:false) : false}
                        onChange={cambiaSelectorCuenta}
                        labelKey={(option) => `${option.codigo} ${option.nombre}`}
                        />
                </div>
                <InputGroup.Append>
                    <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Limpiar opción</Tooltip>}>
                        <Button
                            variant="outline-secondary"
                            disabled={props.desactivarBotonBorrar ? true : false}
                            onClick={() => {
                                // refSelectorCuenta.current.getInstance().clear();
                                // setOculto('');
                                refSelectorCuenta.current.getInstance().clear(); // solo limpia la casila de la unidad, no hace nada más
                                refSelectorCuenta.current.getInstance()._updateSelected([]); // Este limpia la casilla y activa cambiarSelector
                            }}>
                            <IoMdClose />
                        </Button>
                    </OverlayTrigger>
                    {tienePermisos('editar',contexto.predio.perfil) &&
                        <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip>Agregar</Tooltip>}>
                            <Button
                                onClick={() => setActivarModalAgregar(true)}
                                disabled={props.desactivarBotonBorrar ? true : false}
                                variant="outline-secondary"><FaPlusCircle />
                            </Button>
                        </OverlayTrigger>
                    }
                </InputGroup.Append>
            </InputGroup>
            {props.register &&
                <Form.Group as={Row} controlId="6">
                    <Col sm="10">
                        <Form.Control
                            type="hidden"
                            name={props.name}
                            value={oculto}
                            onChange={
                                (e) => {
                                    setOculto(e.target.value)
                                }
                            }
                            ref={props.register({
                                required: "Por favor, seleccione una cuenta"
                            })}
                            isInvalid={props.errors[props.name]}/>
                        <ErrorMessage errors={props.errors} name={props.name} as="div"
                            className="invalid-feedback" />
                    </Col>
                </Form.Group>
            }
            <FormularioCuenta
                show={activarModalAgregar}
                onHide={cerrarModalAgregar}
                cuenta={''}
                size="lg"/>
        </>
    );
}

export default SelectorCuenta;