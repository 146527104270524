import React, { useState, useEffect, useRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Button, InputGroup, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import {Api} from '../../utils/api';
import { ErrorMessage } from 'react-hook-form';
import { useAlert } from 'react-alert';

function SelectorMeses(props) {
    const alert = useAlert();
    const refSelectorMeses= useRef();
    const [meses, setMeses] = useState([]);
    const [mes, setMesOculto] = useState('');

    useEffect(
        () => {
            obtenerMeses('');
        },
        []
    )

    useEffect(
        () => {
            if (props.inicial && props.finalizar === false) {
                if (meses.length === 0) { // No hay actividades cargadas, tenemos que ir a buscar la seleccionada y seleccionar el combobox
                    obtenerMes(props.inicial);
                } else { // Si hay actividades cargadas, solo seleccionamos la que esté
                    if (refSelectorMeses.current.getInstance().state.selected.length === 0) { // Solo cuando no hay un elemento seleccionado en el combobox, esto se da solo en el inicio, ref.current.getInstance()._input.value
                        meses.forEach(actividad => {  // Buscamos dentro de las actividades la que tenemos que seleccionar
                            if (parseInt(actividad.codigo) === parseInt(props.inicial)) {
                                obtenerMeses.current.getInstance()._updateSelected([actividad]); // Automáticamente llama cambiarSelector
                            }
                        });
                    }
                }
            }
        },
        [props.inicial]
    )

    /**
     * Cargamos la información de los meses
     *
     * @param {string} [codigoMesDefault='']
     */
    async function obtenerMes(codigoMesDefault='') {
        let resultado = await Api(`api/catalogo/meses?codigo=${codigoMesDefault}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            if (resultado.data.datos) {
                refSelectorMeses.current.getInstance()._updateSelected([resultado.data.datos]); // Automáticamente llama cambiaSelectorActividad
            } else {
                alert.show('Error al actualizar combobox meses', { type: 'error' });
            }
        } else {
            alert.show('Error al actualizar combobox meses', { type: 'error' });
        }
    }

    /**
     * Cargamos los meses en el combobox
     *
     * @param {integer} predio
     */
    async function obtenerMeses() {
        let resultado = await Api(`api/catalogo/meses`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setMeses(resultado.data.datos);
        } else {
            setMeses([]);
        }
    }

    /**
     * Cada vez que cambia el selector meses
     *
     * @param {*} selected
     */
    function cambiarSelector(selected) {
        if (selected[0] !== undefined) {
            setMesOculto(selected[0].codigo);
        } else {
            setMesOculto('');
        }
    }

    return (
        <>
            <InputGroup size="sm">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1"><FaRegCalendarAlt /></InputGroup.Text>
                </InputGroup.Prepend>
                <div style={{ width: 'calc(100% - 64px)' }}>
                    {props.register ?
                        <Typeahead
                            {...props}
                            bsSize='small'
                            options={meses}
                            highlightOnlyResult={true}
                            selectHintOnEnter={true}
                            flip={true}
                            ref={refSelectorMeses}
                            isInvalid={props.errors[props.name] === '' ? true : false}
                            onChange={cambiarSelector}
                            labelKey={(option) => `${option.codigo}: ${option.descripcion}`}
                        />
                    :
                        <Typeahead
                            {...props}
                            bsSize='small'
                            options={meses}
                            highlightOnlyResult={true}
                            selectHintOnEnter={true}
                            flip={true}
                            ref={refSelectorMeses}
                            onChange={cambiarSelector}
                            labelKey={(option) => `${option.codigo}: ${option.descripcion}`}
                        />
                    }
                </div>
                <InputGroup.Append>
                    <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Limpiar opción</Tooltip>}>
                        <Button
                            variant="outline-secondary"
                            disabled={props.desactivarBotonBorrar ? true : false}
                            onClick={() => {
                                refSelectorMeses.current.getInstance().clear();
                                setMesOculto('');
                            }}>
                            <IoMdClose />
                        </Button>
                    </OverlayTrigger>
                </InputGroup.Append>
            </InputGroup>
            {props.register &&
                <Form.Control
                    type="text"
                    name={props.name}
                    value={mes}
                    hidden
                    onChange={
                        (e) => {
                        }
                    }
                    ref={props.register({
                        required: "Seleccione una opción"
                    })}
                    isInvalid={props.errors[props.name]}
                />
            }

            {props.errors &&
                <ErrorMessage errors={props.errors} name={props.name} as="div" className="invalid-feedback" />
            }
        </>
    );
}

export default SelectorMeses;