import React, {useState, useEffect, useContext} from 'react';
import {useAlert} from 'react-alert';
import BaseGrid from '../../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import Layout from '../../../componentes/Layout';
import { NumberFormatter, sortRows, EmptyRowsView, RutFormatter, DateFormatter, RightAlign} from '../../../componentes/grid/utils';
import {Api} from '../../../utils/api';
import UserContext from '../../../UserContext';
import { Row, Col, Form, ButtonGroup} from 'react-bootstrap';
import Buscador from '../../../componentes/Buscador';
import Fecha from '../../../componentes/Fecha';
import { fechaHoy, getYMD } from '../../../utils/Functions';
import { exportar, downloadBlob, getDMY } from '../../../utils/Functions';
import DefaultButtons from '../../../componentes/grid/DefaultButtons';
import { DiarioCompraVentaPdf } from './pdf/DiarioCompraVentaPdf';

function DiarioCompraVenta(props) {

    const {ContextMenuTrigger} = Menu
    const [textoBuscar, setTextoBuscar] = useState('');
    const alert = useAlert();

    const columns = [
        {key: 'fecha', name: 'Fecha', width: 100, formatter: DateFormatter, sortable: true},
        {key: 'folio', name: 'Folio', width: 80},
        {key: 'rut_entidad', name: 'Rut', width: 100, formatter: RutFormatter},
        {key: 'razon_social', name: 'Razón Social'},
        {key: 'glosa', name: 'Glosa'},
        {key: 'total_neto', name: 'Neto', width: 100, formatter: NumberFormatter},
        {key: 'total_impuestos', name: 'IVA', width: 100, formatter: NumberFormatter},
        {key: 'impuesto_adicional', name: 'Impuestos Adicionales', width: 160, formatter: NumberFormatter},
        // {key: 'combustible_recuperable', name: 'Esp. Rec.', width: 100, formatter: NumberFormatter},
        // {key: 'combustible_no_recuperable', name: 'Esp. No Rec.', width: 100, formatter: NumberFormatter},
        {key: 'total_bruto', name: 'Bruto', width: 100, formatter: NumberFormatter},
    ];
    const columnsTotales = [
        { key: 'nombre', name: '', formatter: RightAlign },
        { key: 'suma_total_neto', name: 'Total Neto', width: 100, formatter: NumberFormatter },
        { key: 'suma_total_impuestos', name: 'Total IVA', width: 100, formatter: NumberFormatter },
        { key: 'suma_total_impuesto_adicional', name: 'Total Imp. Adic.', width: 160, formatter: NumberFormatter },
        // { key: 'suma_total_recuperable', name: 'T. Esp. Rec.', width: 100, formatter: NumberFormatter },
        // { key: 'suma_total_no_recuperable', name: 'T. Esp. No Rec.', width: 100, formatter: NumberFormatter },
        { key: 'suma_total_bruto', name: 'Total Bruto', width: 100, formatter: NumberFormatter },
    ];

    const [rows, setRows] = useState([]);
    const [rowsTotales, setRowsTotales] = useState([]);
    const contexto = useContext(UserContext);
    const [tipo, setTipo] = useState('C');
    const [fechaInicio, setFechaInicio] = useState(fechaHoy());
    const [fechaCierre, setFechaCierre] = useState(fechaHoy());
    const [fechaExportar] = useState(fechaHoy());

    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                let inicio = (contexto.predio.periodo ? contexto.predio.periodo.inicio : '');
                let cierre = (contexto.predio.periodo ? contexto.predio.periodo.cierre : '');
                obtenerDatos(contexto.predio.predio_id, tipo, inicio, cierre, textoBuscar);
                setFechaInicio(inicio);
                setFechaCierre(cierre);
            }
        },
        [contexto]
    )

    async function obtenerDatos(predio, tipo, inicio, cierre, texto) {
        let resultado = await Api(`api/contabilidad/diario-compras-ventas?predio_id=${predio}&tipo=${tipo}&inicio=${inicio}&cierre=${cierre}&texto=${texto}`);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
            setRowsTotales(resultado.data.totales);
        } else {
            alert.show('Error al cargar documentos', { type: 'error' });
            setRows([]);
            setRowsTotales([]);
        }
    }

    /**
     * Cuando escriben en el buscador
     *
     * @param {string} texto
     */
    async function retornoBusqueda(texto) {
        obtenerDatos(contexto.predio.predio_id, tipo, fechaInicio, fechaCierre, texto);
        setTextoBuscar(texto);
    }

    /**
     * Cuando cambian el tipo de documento compra/venta
     *
     * @param {string} e
     */
    function changeTipo(e) {
        setTipo(e.target.value);
        obtenerDatos(contexto.predio.predio_id, e.target.value, fechaInicio, fechaCierre, textoBuscar);
    }

    /**
     * Cuando cambian la fecha inicio
     *
     * @param {date} e
     */
    function onChangeInicio(e) {
        let fecha = getYMD(e);
        setFechaInicio(fecha);
        obtenerDatos(contexto.predio.predio_id, tipo, fecha, fechaCierre, textoBuscar);
    }

    /**
     * Cuando cambian la fecha cierre
     *
     * @param {date} e
     */
    function onChangeCierre(e) {
        let fecha = getYMD(e);
        setFechaCierre(fecha);
        obtenerDatos(contexto.predio.predio_id, tipo, fechaInicio, fecha, textoBuscar);
    }

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        exportar(`api/exportar/contabilidad/diario-compras-ventas?predio_id=${contexto.predio.predio_id}&tipo=${tipo}&inicio=${fechaInicio}&cierre=${fechaCierre}&texto=${textoBuscar}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf() {
        if (fechaInicio && fechaCierre) {
            let props = {
                columns: columns,
                rows: rows,
                rowsTotales: rowsTotales,
                titulo: 'Diaro Compra Venta',
                subtitulo: `Tipo: ${tipo == 'C' ? 'Compras' : 'Ventas'}, Desde: ${getDMY(fechaInicio)} Hasta: ${getDMY(fechaCierre)}`,
                bajada: contexto.predio.nombre_predio
            }
            const sub_formated = `tipo_${tipo == 'C' ? 'Compras' : 'Ventas'}_inicio_${getDMY(fechaInicio).replace(/ /g, '_').replaceAll('/', '-')}_fin_${getDMY(fechaCierre).replace(/ /g, '_').replaceAll('/', '-')}`
            alert.show('La descarga comenzará en breve', { type: 'success' });
            new Promise(function (resolve, reject) {
                DiarioCompraVentaPdf(props).then(
                    // function (blob) {
                    //     downloadBlob(blob, `Diario_Compra_Venta_${contexto.predio.nombre_predio.replace(/ /g, '_')}_${props.subtitulo.replace(/ /g, '_')}_${getDMY(fechaExportar)}.pdf`);
                    function (blob) {
                        downloadBlob(blob, `Diario_Compras_Ventas_${contexto.predio.nombre_predio.replace(/ /g, '_')}_${sub_formated}_${getDMY(fechaExportar)}.pdf`);
                    }, function (blob) {
                        alert.show('Error al exportar', { type: 'error' })
                    })
            })
        } else {
            alert.show('Debe seleccionar las fechas', { type: 'error' })
        }
    }

    return (
        <>
            <Layout title="Diario Compras / Ventas">
                <Row>

                </Row>
                <Row style={{ marginBottom: '10px' }}>
                    <Col sm={6} md={4} lg={3}>
                        <Form.Label>Búscador</Form.Label>
                        <Buscador retornoBusqueda={retornoBusqueda} busqueda={textoBuscar} placeholder='Folio, Glosa, etc...' />
                    </Col>
                    <Col sm={6} md={4} lg={2}>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Tipo</Form.Label>
                            <Form.Control as="select" size="sm" onChange={changeTipo}>
                                <option value="C">Compras</option>
                                <option value="V">Ventas</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col sm={6} md={4} lg={2} id="cont-fecha-desde">
                        <Form.Label>Desde</Form.Label>
                        <Fecha
                            name='inicio'
                            dateFormat='dd/MM/yyyy' // dd/MM/Y h:mm aa - dd/MM/Y - MM/Y
                            dateFormatOculto='y-m-d' // y-m-d h:i:s
                            inicial={contexto.predio.periodo ? contexto.predio.periodo.inicio : fechaHoy()}
                            onChange={onChangeInicio}
                             />
                    </Col>
                    <Col sm={6} md={4} lg={2} id="cont-fecha-hasta">
                        <Form.Label>Hasta</Form.Label>
                        <Fecha
                            name='cierre'
                            dateFormat='dd/MM/yyyy' // dd/MM/Y h:mm aa - dd/MM/Y - MM/Y
                            dateFormatOculto='y-m-d' // y-m-d h:i:s
                            inicial={contexto.predio.periodo ? contexto.predio.periodo.cierre : fechaHoy()}
                            onChange={onChangeCierre}
                        />
                    </Col>
                    <Col sm={3} style={{ paddingTop: '33px' }}>
                        <ButtonGroup className="float-right">
                            <DefaultButtons exportar={exportarExcel} />
                            <DefaultButtons imprimir={exportarPdf} />
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                            <BaseGrid
                                columns={columns}
                                rowGetter={i => rows[i]}
                                rowsCount={rows.length}
                                minHeight={400}
                                RowsContainer={ContextMenuTrigger}
                                onGridSort={(sortColumn, sortDirection) =>
                                    setRows(sortRows(rows, sortColumn, sortDirection))
                                }
                                emptyRowsView={EmptyRowsView}
                                showCountRows={false}/>
                        </div>
                        <div>
                            <BaseGrid
                                columns={columnsTotales}
                                rowGetter={i => rowsTotales[i]}
                                rowsCount={rowsTotales.length}
                                minHeight={70}
                                showCountRows={false}/>
                        </div>
                    </Col>
                </Row>
            </Layout>
        </>
    );
}

export default DiarioCompraVenta;