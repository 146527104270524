import React, {useState, useContext, useEffect, useRef} from 'react';
import {Typeahead} from 'react-bootstrap-typeahead';
import {Button, InputGroup, Form} from 'react-bootstrap';
import { ErrorMessage } from 'react-hook-form';
import {FaCity} from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import {Api} from '../utils/api';


function SelectorComuna(props) {
    const refSelectorComuna = useRef()
    const [comunas, setComunas] = useState([]);
    const [comunaSeleccionada, setComunaSeleccionada] = useState('');

    async function obtenerComunas(comunaDefault='') {
        let resultado = await Api(`api/catalogo/comunas`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setComunas(resultado.data.datos);
            resultado.data.datos.forEach(comuna => {
                if (comuna === comunaDefault.toUpperCase()) {
                    refSelectorComuna.current.getInstance()._updateSelected([comuna]); // Automáticamente llama cambiarSelector
                }
            });
        } else {
            setComunas([]);
        }
    }

    useEffect(
        () => {
            obtenerComunas();
        },
        []
    )

    useEffect(
        () => {
            if (props.inicial) {
                obtenerComunas(props.inicial);
            }
        },
        [props.inicial]
    )

    /**
     * Cada vez que cambia el selector
     *
     * @param {*} selected
     */
    function cambiarSelector(selected) {
        if (selected[0] !== undefined) {
            setComunaSeleccionada(selected[0]);
            //
        } else {
            setComunaSeleccionada('');
        }
    }

    return (
        <>
        <InputGroup size="sm">
            <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1"><FaCity /></InputGroup.Text>
            </InputGroup.Prepend>
            <div style={{ width: '79%' }}>
                <Typeahead
                    { ...props}
                    bsSize='small'
                    options={comunas}
                    highlightOnlyResult={true}
                    selectHintOnEnter={true}
                    flip={true}
                    ref={refSelectorComuna}
                    onChange={cambiarSelector}
                    labelKey={(option) => `${option}`}/>
            </div>
            <InputGroup.Append>
                <Button variant="outline-secondary"
                    onClick={() => {
                        refSelectorComuna.current.getInstance().clear();
                    }}><IoMdClose /></Button>
            </InputGroup.Append>
        </InputGroup>
        {props.register &&
            <Form.Control
                type="hidden"
                name={props.name}
                value={comunaSeleccionada}
                onChange={
                    (e) => {
                    }
                }
                ref={props.register({
                    required: "Seleccione una opción"
                })}
                isInvalid={props.errors[props.name]}
            />
        }

        {props.errors &&
            <ErrorMessage errors={props.errors} name={props.name} as="div" className="invalid-feedback" />
        }
        </>
    );
}

export default SelectorComuna;