import React, {useState, useContext, useEffect, useRef} from 'react';
import BaseGrid from '../../componentes/grid/BaseGrid';
import UserContext from '../../UserContext';
import Layout from '../../componentes/Layout.js';
import {NumberFormatter, LongTextFormatter, RightAlign, zeroFill} from '../../componentes/grid/utils';
import DefaultButtons from '../../componentes/grid/DefaultButtons'
import {Button, Row, Col, ButtonGroup, Form, InputGroup, OverlayTrigger, Popover} from 'react-bootstrap';
import {Api} from '../../utils/api';
import {FaCommentAlt} from 'react-icons/fa';
import { exportar, downloadBlob } from '../../utils/Functions';
import {TecnicoEconomicoPdf} from './pdf/TecnicoEconomicoPdf';
import { useAlert } from 'react-alert';
import { fechaHoy, getDMY } from '../../utils/Functions';


function TecnicoEconomico(props) {
    const alert = useAlert();			// Para las notificaciones
    const contexto = useContext(UserContext);
    const [presupuestos, setPresupuestos] = useState([]);
    const [fechaExportar] = useState(fechaHoy());
    const listaPresupuestos = presupuestos.map((p) =>
        <option key={p.id.toString()} value={p.id}>
            {zeroFill(p.codigo_actividad, 4)}:{p.actividad} ({p.cantidad} {p.unidad_abreviacion})
        </option>

    );
    const cmbxPresupuestos = useRef(null);
    const [comentario, setComentario] = useState();

    const popover = (
        <Popover id="popover-comentario">
          <Popover.Title as="h3">Comentario Presupuesto</Popover.Title>
          <Popover.Content>
            {comentario}
          </Popover.Content>
        </Popover>
      );

    const columns = [
        {key: 'codigo', name: 'Cuenta', sortable: true},
        {key: 'nombre', name: 'Descripción', width: 300},
        {key: 'descripcion_fecha', name: 'Mes', width: 300, formatter: LongTextFormatter},
        {key: 'unidad', name: 'Un.'},
        {key: 'cantidad_unidad', name: 'Ctd/ Un.', formatter: NumberFormatter},
        {key: 'monto_unidad', name: '$ Unitario', formatter: NumberFormatter},
        {key: 'cantidad', name: 'Tamaño', formatter: NumberFormatter},
        {key: 'total_unidad', name: 'Total / Un.', formatter: NumberFormatter},
        {key: 'monto_total', name: 'Total', formatter: NumberFormatter},
        {key: 'acumulado', name: 'Acum. Cta.', formatter: NumberFormatter},
     ];

     const columnsTotales = [
        {key: 'codigo', name: '', sortable: true},
        {key: 'nombre1', name: '', width: 300},
        {key: 'descripcion_fecha', name: '', width: 300, formatter: LongTextFormatter},
        {key: 'unidad', name: ''},
        {key: 'cantidad_unidad', name: '', formatter: NumberFormatter},
        {key: 'monto_unidad', name: '', formatter: NumberFormatter},
        {key: 'nombre', name: '', formatter: RightAlign},
        {key: 'total_unidad', name: 'Total / Un.', formatter: NumberFormatter},
        {key: 'monto_total', name: 'Total', formatter: NumberFormatter},
        {key: '', name: ''},
     ];

    const [rows, setRows] = useState([]);
    const [rowsTotales, setRowsTotales] = useState([]);

    async function getPresupuestos(predio) {
        let resultado = await Api(`api/presupuestos/${predio}?periodo_id=${contexto.predio.periodo.id}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setPresupuestos(resultado.data.datos);
            //setComentario(presupuestos[0].comentario);
            getDatos(contexto.predio.predio_id, cmbxPresupuestos.current.value);
        } else {
            alert.show('Error al cargar presupuestos' + (resultado.data.msg ? resultado.data.msg : ''), { type: 'error' })
        }
    }

    function handleChange(e) {
        setComentario(presupuestos[e.target.selectedIndex].comentario);
        getDatos(contexto.predio.predio_id, e.target.value);
    }

    async function getDatos(predio_id, presupuesto_id) {
        if (presupuesto_id === '') {
            alert.show('No hay un presupuesto seleccionado', { type: 'error' })
            return false;
        }
        let resultado = await Api(`api/reportes/presupuesto/tecnico_economico/${predio_id}/${presupuesto_id}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
            setRowsTotales(resultado.data.totales);
        } else {
            setRows([]);
            setRowsTotales([]);
        }
    }

    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                getPresupuestos(contexto.predio.predio_id);
            }
        },
        [contexto]
    )

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        if (cmbxPresupuestos.current.value) {
            exportar(`api/exportar/reportes/presupuesto/tecnico_economico/${contexto.predio.predio_id}/${cmbxPresupuestos.current.value}`, alert);
        } else {
            alert.show('No se puede exportar, no hay presupuesto.', { type: 'error' })
        }
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf(){
        if (cmbxPresupuestos.current.value) {
            if (presupuestos.length > 0){
                let props = {
                    columns:columns,
                    rows:rows,
                    columnsTotales: columnsTotales,
                    rowsTotales: rowsTotales,
                    titulo:"Presupuesto Técnico Económico",
                    bajada:contexto.predio.nombre_predio,
                    subtitulo:cmbxPresupuestos.current.selectedOptions[0].label,
                    comentario: comentario,
                }
                alert.show('La descarga comenzará en breve', { type: 'success' });
                new Promise(function(resolve, reject){
                    TecnicoEconomicoPdf(props).then(
                        function(blob){
                            downloadBlob(blob,
                                (`Presupuesto_técnico_económico_${contexto.predio.nombre_predio.replace(/ /g, '_')}_${cmbxPresupuestos.current.selectedOptions[0].label.replace(/ /g, '_').replace(/\(|\)/g, "")}_${getDMY(fechaExportar)}.pdf`));
                        },function(blob){
                            alert.show('Error al exportar', { type: 'error' })
                    })
                })
            } else {
                alert.show('Error al exportar', { type: 'error' })
            }
        } else {
            alert.show('No se puede exportar, no hay presupuesto.', { type: 'error' })
        }
    }

    return (
        <Layout title="Presupuesto Técnico Económico">
            <Form>
                <Form.Row style={{paddingBottom:'10px'}}>
                    <Col md={8}>
                        <Form.Group as={Row} controlId="select_presupuesto">
                            <Col sm="12">
                                <InputGroup size="sm">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Presupuesto:</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control ref={cmbxPresupuestos} as="select" size="sm" onChange={handleChange}>
                                        {listaPresupuestos}
                                    </Form.Control>
                                    {comentario &&
                                        <InputGroup.Append>
                                            <OverlayTrigger placement="bottom" overlay={popover}>
                                                <Button variant="info"><FaCommentAlt /></Button>
                                            </OverlayTrigger>
                                        </InputGroup.Append>
                                    }
                                </InputGroup>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <ButtonGroup className="float-right">
                            <DefaultButtons exportar={exportarExcel}/>
                            <DefaultButtons imprimir={exportarPdf}/>
                        </ButtonGroup>
                    </Col>
                </Form.Row>
            </Form>
            <div>
                <BaseGrid
                    columns={columns}
                    rowGetter={i => rows[i]}
                    rowsCount={rows.length}
                    minHeight={350}
                    showCountRows={false}/>
            </div>

            <div>
                <BaseGrid
                    columns={columnsTotales}
                    rowGetter={i => rowsTotales[i]}
                    rowsCount={rowsTotales.length}
                    minHeight={150}
                    showCountRows={false}/>
            </div>
        </Layout>
    );
}

export default TecnicoEconomico;