import React from 'react';
import {BaseStylesPdf} from '../../../../componentes/pdf/BaseStylesPdf';
import {BaseLinkPdf} from '../../../../componentes/pdf/BaseLinkPdf';
import {Table, TableHeader, TableBody, TableCell, DataTableCell} from '@david.kucsai/react-pdf-table';
import { separadorDeMilesOrVacio, separadorDeMilesOrVacioOrVacio} from '../../../../componentes/grid/utils';

export function GestionPorActividadPdf(props) {
    const styles = BaseStylesPdf();
    var tablaPresupuestoActividad = '';

    try {
        tablaPresupuestoActividad =(
            <>
            <Table data={props.rows}>
                <TableHeader textAlign={"center"}>
                    {/* el "weighting" idealmente debe sumar 1 para que quede todo ordenado */}
                    <TableCell tyle={styles.tableHeader} weighting={0.23}>Cuenta</TableCell>
                    <TableCell tyle={styles.tableHeader} weighting={0.07}>Unidad</TableCell>
                    <TableCell tyle={styles.tableHeader} weighting={0.09}>Cantidad</TableCell>
                    <TableCell tyle={styles.tableHeader} weighting={0.09}>Valor</TableCell>
                    <TableCell tyle={styles.tableHeader} weighting={0.1}>Val/Unidad</TableCell>
                    <TableCell tyle={styles.tableHeader} weighting={0.09}>Cantidad</TableCell>
                    <TableCell tyle={styles.tableHeader} weighting={0.09}>Gastos</TableCell>
                    <TableCell tyle={styles.tableHeader} weighting={0.09}>Ingresos</TableCell>
                    <TableCell tyle={styles.tableHeader} weighting={0.1}>Val/Unidad</TableCell>
                    <TableCell tyle={styles.tableHeader} weighting={0.05}>Desv.</TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{ textAlign: "left", fontSize: 10, fontWeight: 700 }} weighting={0.23} getContent={(r) => r.nombre} />
                    <DataTableCell style={{ textAlign: "center", fontSize: 10, fontWeight: 700 }} weighting={0.07} getContent={(r) => r.unidad} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.09} getContent={(r) => separadorDeMilesOrVacio(r.cantidad_presupuesto)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.09} getContent={(r) => separadorDeMilesOrVacio(r.total_presupuesto)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.total_unidad_presupuesto)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.09} getContent={(r) => separadorDeMilesOrVacio(r.cantidad_operacion)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.09} getContent={(r) => separadorDeMilesOrVacio(r.gasto_operacion)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.09} getContent={(r) => separadorDeMilesOrVacio(r.ingreso_operacion)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.total_unidad_operacion)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.05} getContent={(r) => separadorDeMilesOrVacio(r.desviacion)} />
                </TableBody>
            </Table>
            <Table data={props.rowsTotales}>
                <TableHeader textAlign={"center"}>
                    <TableCell tyle={styles.tableHeader} weighting={0.23}>Cuenta</TableCell>
                    <TableCell tyle={styles.tableHeader} weighting={0.07}>Unidad</TableCell>
                    <TableCell tyle={styles.tableHeader} weighting={0.09}>Cantidad</TableCell>
                    <TableCell tyle={styles.tableHeader} weighting={0.09}>Valor</TableCell>
                    <TableCell tyle={styles.tableHeader} weighting={0.1}>Val/Unidad</TableCell>
                    <TableCell tyle={styles.tableHeader} weighting={0.09}>Cantidad</TableCell>
                    <TableCell tyle={styles.tableHeader} weighting={0.09}>Gastos</TableCell>
                    <TableCell tyle={styles.tableHeader} weighting={0.09}>Ingresos</TableCell>
                    <TableCell tyle={styles.tableHeader} weighting={0.1}>Val/Unidad</TableCell>
                    <TableCell tyle={styles.tableHeader} weighting={0.05}>Desv.</TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{ textAlign: "left", fontSize: 10, fontWeight: 700 }} weighting={0.23} getContent={(r) => r.nombre} />
                    <DataTableCell style={{ textAlign: "center", fontSize: 10, fontWeight: 700 }} weighting={0.07} getContent={(r) => r.unidad} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.09} getContent={(r) => separadorDeMilesOrVacio(r.cantidad_presupuesto)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.09} getContent={(r) => separadorDeMilesOrVacio(r.total_presupuesto)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.total_unidad_presupuesto)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.09} getContent={(r) => separadorDeMilesOrVacio(r.cantidad_operacion)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.09} getContent={(r) => separadorDeMilesOrVacio(r.total_gasto_operacion)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.09} getContent={(r) => separadorDeMilesOrVacio(r.total_ingreso_operacion)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.total_unidad_operacion)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.05} getContent={(r) => separadorDeMilesOrVacio(r.desviacion)} />
                </TableBody>
            </Table>
            </>)
    } catch (error) {
        console.log("Error GestionPorActividadPdf", error);
    }

    let blob = BaseLinkPdf({...props, body:tablaPresupuestoActividad, orientation:"landscape"})

    return blob
}