import React from 'react';
import { Page, Text, View, Document, pdf } from '@react-pdf/renderer';
import {BaseStylesPdf} from './BaseStylesPdf';

export function BaseLinkPdf(props) {

    const styles = BaseStylesPdf();
    var orientation = props.orientation ? props.orientation : "portrait"

    // Create Document Component
    const Pdf =(
        <Document>
            <Page size="A4" orientation={orientation} style={styles.body}>
                <Text style={styles.header} fixed>
                ~ Conga - Uach ~
                </Text>
                <Text style={styles.titulo}>{props.titulo}</Text>
                {props.bajada && <Text style={styles.bajada}>{props.bajada}</Text>}
                {props.subtitulo && <Text style={styles.subtitulo}>{props.subtitulo}</Text>}
                {props.comentario && <Text style={styles.comentario}>{props.comentario}</Text>}
                <View>
                    {props.body}
                </View>

                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>)
    const blob = pdf(Pdf).toBlob();
    return blob;
}