import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../../UserContext';
import { Form, Button, Col, Modal, Row, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useForm, ErrorMessage } from 'react-hook-form'
import { useAlert } from 'react-alert';
import { Api } from '../../utils/api';
import { tienePermisos } from '../../utils/Functions'
import { FaSave, FaWindowClose } from 'react-icons/fa';
import SelectorCategoriaActividad from '../../componentes/SelectorCategoriaActividad';

function FormularioActividad(props) {
    const contexto = useContext(UserContext);
    const alert = useAlert();           // Para las notificaciones
    const { actividad, ...rest } = props;
    const { register, handleSubmit, watch, errors, setValue } = useForm({
        defaultValues: {
            categoria_actividad_id: '',
            nombre: '',
            codigo: '',
            habilitada: 'SI'
        }
    });
    const inicial = {
        codigo_minimo: '',
        codigo_maximo: ''
    }
    const [global, setGlobal] = useState(inicial);
    const [actividadesUtilizadas, setActividadesUtilizadas] = useState([]);
    const [actividadBaseSeleccionada, setActividadBaseSeleccionada] = useState([]);
    const [actividadesBaseDisponibles, setActividadesBaseDisponibles] = useState([]);

    useEffect(
        () => {
            if (actividad) {
                setValue([
                    { categoria_actividad_id: actividad.categoria_id },
                    { nombre: actividad.nombre },
                    { codigo: actividad.codigo },
                    { habilitada: (actividad.habilitada ? 'SI' : 'NO') }
                ]);
            } else {
                setGlobal(inicial);
            }
        },
        [actividad]
    )
    /**
     * Cuando el formulario está validado
     *
     * @param {*} data
     * @returns
     */
    async function guardar(data) {
        data.predio_id = contexto.predio.predio_id;
        if (actividad === '') { // Crear
            let resultado = await Api('api/actividad', data, { 'Content-Type': 'application/json' }, true, 'post');
            if (resultado && resultado.status === 200) { // Presupuesto creado
                alert.show(resultado.data.msg, { type: 'success' });
                cerrarModal(resultado.data.datos);
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
            }
        } else { // Actualizar
            let resultado = await Api(`api/actividad/${actividad.id}`, data, { 'Content-Type': 'application/json' }, true, 'put');
            if (resultado && resultado.status === 200) { // Presupuesto creado
                alert.show(resultado.data.msg, { type: 'success' });
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
            }
        }
    }

    /**
     * Cargar las cuentas utilizadas y mostramos en el formulario
     *
     * @param {*} data
     * @returns
     */
    async function getActividadesUtilizadas(categoriaId) {
        let resultado = await Api(`api/predio/${contexto.predio.predio_id}/actividades-utilizadas?categoria_id=${categoriaId}`, '', { 'Content-Type': 'application/json' }, true, 'get');
        if (resultado && resultado.status === 200) {
            setActividadesUtilizadas(resultado.data.datos);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
        }
    }

    /**
     * Cargar las cuentas base disponibles
     *
     * @param {*} data
     * @returns
     */
    async function getActividadesBaseDisponible(categoriaId) {
        let resultado = await Api(`api/actividad/base?categoria_id=${categoriaId}&no_utilizadas=true&predio_id=${contexto.predio.predio_id}`, '', { 'Content-Type': 'application/json' }, true, 'get');
        if (resultado && resultado.status === 200) {
            setActividadesBaseDisponibles(resultado.data.datos);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
        }
    }

    /**
     * Retorna la categoria actividad seleccionada, actualizamos codigo_minimo y codigo_maximo
     *
     * @param {Object} data
     */
    function returnSelectorCategoriaCuenta(categoria) {
        if (categoria.length > 0) {
            getActividadesUtilizadas(categoria[0].id);
            getActividadesBaseDisponible(categoria[0].id);
            setGlobal({
                ...global,
                codigo_minimo: categoria[0].codigo_minimo,
                codigo_maximo: categoria[0].codigo_maximo
            });
        } else {
            setActividadesUtilizadas([]);
            setActividadesBaseDisponibles([]);
            setGlobal({
                ...global,
                codigo_minimo: '',
                codigo_maximo: ''
            })
        }
    }

    function cerrarModal(data) {
        setGlobal({
            ...global,
            codigo_minimo: '',
            codigo_maximo: ''
        });
        setActividadesUtilizadas([]);
        setActividadBaseSeleccionada('');
        setActividadesBaseDisponibles([]);
        props.onHide(data);
    }

    /**
     * Cuando quiere rellenar el formulario desde una cuenta base disponible
     *
     * @param {integer} cuentaBaseId
     */
    async function agregarActividadBase(cuentaBaseId) {
        let resultado = await Api(`api/actividad/base?id=${cuentaBaseId}`, '', { 'Content-Type': 'application/json' }, true, 'get');
        if (resultado && resultado.status === 200) {
            if (resultado.data.datos.length === 1) {
                let actividadBase = resultado.data.datos[0];
                setActividadBaseSeleccionada(actividadBase);
                setValue([
                    { nombre: actividadBase.nombre },
                    { codigo: actividadBase.codigo },
                    { depreciacion: actividadBase.depreciacion }
                ]);
            } else {
                alert.show('Información no disponible', { type: 'error' });
            }
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
        }
    }

    return (
        <Modal {...rest} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>{actividad ? 'Editar' : 'Agregar'} Actividad</Modal.Title>
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit(guardar)}>
                <Modal.Body>
                    <Row>
                        <Form.Label column sm="4"> Categoría: </Form.Label>
                        <Col sm="8">
                            <SelectorCategoriaActividad
                                id='selectorCategoriaActividad'
                                name="categoria_actividad_id"
                                register={register}
                                errors={errors}
                                inicial={watch('categoria_actividad_id')}
                                funcionRetorno={returnSelectorCategoriaCuenta}
                                actividadesUtilizadas={true}
                                placeholder=""
                                disabled={actividad ? true : false}
                                desactivarBotonBorrar={actividad ? true : false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="4">Rangos:</Form.Label>
                        <Col sm="8">
                            {global.codigo_minimo} - {global.codigo_maximo}
                        </Col>
                    </Row>
                    {!actividad &&
                        <>
                            {actividadesBaseDisponibles.length > 0 &&
                                <Row style={{ marginBottom: '5px' }}>
                                    <Form.Label column sm="4">Cuentas base disponibles:</Form.Label>
                                    <Col sm="8">
                                        <label>Puedes utilizar una actividad del plan base haciendo click en ella</label>
                                        {actividadesBaseDisponibles.map((p) =>
                                            <OverlayTrigger
                                                key={'top' + p.id}
                                                placement={'top'}
                                                overlay={
                                                    <Tooltip>
                                                        Presione para utilizar
                                                    </Tooltip>
                                                }>
                                                <Button key={p.id} variant={actividadBaseSeleccionada.id === p.id ? 'success' : 'info'} size="sm" onClick={(e) => agregarActividadBase(p.id)} style={{ marginRight: '2px', padding: '0px 5px' }}>{p.codigo + ': ' + p.nombre}</Button>
                                            </OverlayTrigger>
                                        )}
                                    </Col>
                                </Row>
                            }
                            {actividadesUtilizadas.length > 0 &&
                                <Row style={{ marginBottom: '10px' }}>
                                    <Form.Label column sm="4">Actividades utilizadas:</Form.Label>
                                    <Col sm="8">
                                        {actividadesUtilizadas.map((p) =>
                                            <Badge key={p.id} variant="light" style={{ marginRight: '2px', border: '1px solid #212529' }}>{p.codigo + ': ' + p.nombre}</Badge>
                                        )}
                                    </Col>
                                </Row>
                            }
                        </>
                    }
                    <Row>
                        <Form.Label column sm="4">Nombre:</Form.Label>
                        <Col sm="8">
                            <Form.Control
                                name="nombre"
                                type="text"
                                placeholder=""
                                size="sm"
                                ref={register({
                                    required: "Por favor, ingrese el nombre"
                                })}
                                isInvalid={errors.nombre} />
                            <ErrorMessage errors={errors} name="nombre" as="div" className="invalid-feedback" />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="4">Código:</Form.Label>
                        <Col sm="8">
                            <Form.Control
                                name="codigo"
                                type="number"
                                placeholder=""
                                size="sm"
                                ref={register({
                                    required: "Por favor, ingrese el código"
                                })}
                                isInvalid={errors.codigo} />
                            <ErrorMessage errors={errors} name="codigo" as="div" className="invalid-feedback" />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="4">Habilitada:</Form.Label>
                        <Col sm="8">
                            <Form.Check name='habilitada' inline type='radio' label={`Sí`} id={`habilitada_si`}
                                value={'SI'} ref={register({ required: true })} />
                            <Form.Check name='habilitada' inline type='radio' label={`No`} id={`habilitada_no`}
                                value={'NO'} ref={register({ required: true })} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={(e) => cerrarModal('')}><FaWindowClose /> Cerrar</Button>
                    {tienePermisos('editar',contexto.predio.perfil) &&
                        <Button type="submit" size="sm" variant="success"><FaSave /> guardar</Button>
                    }

                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default FormularioActividad;