import React from 'react';
import {BaseStylesPdf} from '../../../../componentes/pdf/BaseStylesPdf';
import {BaseLinkPdf} from '../../../../componentes/pdf/BaseLinkPdf';
import {Table, TableHeader, TableBody, TableCell, DataTableCell} from '@david.kucsai/react-pdf-table';
import { separadorDeMilesOrVacio, DateFormatterField, completaRut } from '../../../../componentes/grid/utils';

export function DiarioCompraVentaPdf(props) {
    const styles = BaseStylesPdf();
    var tablaBalanceInicial = '';
    try {
        tablaBalanceInicial = (
            <>
            <Table data={props.rows}>
                <TableHeader textAlign={"center"}>
                    {/* el "weighting" idealmente debe sumar 1 para que quede todo ordenado */}
                    <TableCell style={styles.tableHeader10} weighting={0.1}>Fecha</TableCell>
                    <TableCell style={styles.tableHeader10} weighting={0.05}>Folio</TableCell>
                    <TableCell style={styles.tableHeader10} weighting={0.1}>Rut</TableCell>
                    <TableCell style={styles.tableHeader10} weighting={0.15}>Razón Social</TableCell>
                    <TableCell style={styles.tableHeader10} weighting={0.2}>Glosa</TableCell>
                    <TableCell style={styles.tableHeader10} weighting={0.08}>Neto</TableCell>
                    <TableCell style={styles.tableHeader10} weighting={0.08}>IVA</TableCell>
                    {/* <TableCell style={styles.tableHeader10} weighting={0.08}>Esp. Rec.</TableCell>
                    <TableCell style={styles.tableHeader10} weighting={0.08}>Esp. No Rec.</TableCell> */}
                    <TableCell style={styles.tableHeader10} weighting={0.16}>Impuestos Adicionales</TableCell>
                    <TableCell style={styles.tableHeader10} weighting={0.08}>Bruto</TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{ textAlign: "center", fontSize: 8 }} weighting={0.1} getContent={(r) => DateFormatterField(r.fecha)} />
                    <DataTableCell style={{ textAlign: "center", fontSize: 8 }} weighting={0.051} getContent={(r) => r.folio} />
                    <DataTableCell style={{ textAlign: "center", fontSize: 8 }} weighting={0.099} getContent={(r) => completaRut(r.rut_entidad)} />
                    <DataTableCell style={{ textAlign: "left", fontSize: 8 }} weighting={0.147} getContent={(r) => (r.razon_social)} />
                    <DataTableCell style={{ textAlign: "left", fontSize: 8 }} weighting={0.194} getContent={(r) => r.glosa} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 8 }} weighting={0.08} getContent={(r) => separadorDeMilesOrVacio(r.total_neto)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 8 }} weighting={0.08} getContent={(r) => separadorDeMilesOrVacio(r.total_impuestos)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 8 }} weighting={0.16} getContent={(r) => separadorDeMilesOrVacio(r.impuesto_adicional)} />
                    {/*
                    <DataTableCell style={{ textAlign: "right", fontSize: 8 }} weighting={0.08} getContent={(r) => separadorDeMilesOrVacio(r.combustible_recuperable)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 8 }} weighting={0.08} getContent={(r) => separadorDeMilesOrVacio(r.combustible_no_recuperable)} /> */}
                    <DataTableCell style={{ textAlign: "right", fontSize: 8 }} weighting={0.08} getContent={(r) => separadorDeMilesOrVacio(r.total_bruto)} />
                </TableBody>
            </Table>
            <Table data={props.rowsTotales}>
                <TableHeader textAlign={"center"}>
                    <TableCell style={styles.tableHeader10} weighting={0.63}> </TableCell>
                    <TableCell style={styles.tableHeader10} weighting={0.08}>Total Neto</TableCell>
                    <TableCell style={styles.tableHeader10} weighting={0.08}>Total IVA</TableCell>
                    <TableCell style={styles.tableHeader10} weighting={0.16}>Total Imp Adic.</TableCell>
                    {/* <TableCell style={styles.tableHeader10} weighting={0.08}>T. Esp. Rec.</TableCell>
                    <TableCell style={styles.tableHeader10} weighting={0.08}>T. Esp. No Rec.</TableCell> */}
                    <TableCell style={styles.tableHeader10} weighting={0.08}>Total Bruto</TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{ textAlign: "right", fontSize: 8, fontWeight: 700 }} weighting={0.597} getContent={(r) => ''} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 8, fontWeight: 700 }} weighting={0.081} getContent={(r) => separadorDeMilesOrVacio(r.suma_total_neto)} />
                    <DataTableCell style={{ textAlign: "center", fontSize: 8, fontWeight: 700 }} weighting={0.08} getContent={(r) => separadorDeMilesOrVacio(r.suma_total_impuestos)} />
                    <DataTableCell style={{ textAlign: "center", fontSize: 8, fontWeight: 700 }} weighting={0.16} getContent={(r) => separadorDeMilesOrVacio(r.suma_impuestos_adicionales)} />
                    {/* <DataTableCell style={{ textAlign: "right", fontSize: 8, fontWeight: 700 }} weighting={0.08} getContent={(r) => separadorDeMilesOrVacio(r.suma_total_recuperable)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 8, fontWeight: 700 }} weighting={0.08} getContent={(r) => separadorDeMilesOrVacio(r.suma_total_no_recuperable)} /> */}
                    <DataTableCell style={{ textAlign: "right", fontSize: 8, fontWeight: 700 }} weighting={0.08} getContent={(r) => separadorDeMilesOrVacio(r.suma_total_bruto)} />
                </TableBody>
            </Table>
            </>)
    } catch (error) {
        console.log("Error DiarioCompraVentaPdf", error);
    }

    let blob = BaseLinkPdf({ ...props, body: tablaBalanceInicial, orientation:"landscape"})

    return blob
}