import React from 'react';
import {BaseStylesPdf} from '../../../componentes/pdf/BaseStylesPdf';
import {BaseLinkPdf} from '../../../componentes/pdf/BaseLinkPdf';
import {Table, TableHeader, TableBody, TableCell, DataTableCell} from '@david.kucsai/react-pdf-table';
import {separadorDeMiles} from '../../../componentes/grid/utils';

export function PresupuestoActividadPdf(props) {
    const styles = BaseStylesPdf();
    var tablaPresupuestoActividad = '';
    try {
        tablaPresupuestoActividad =(
            <Table data={props.rows}>
                <TableHeader textAlign={"center"}>
                    {/* el "weighting" idealmente debe sumar 1 para que quede todo ordenado */}
                    <TableCell style={styles.tableHeader} weighting={0.07}>Cuenta</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.18}>Descripcion</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.18}>Mes</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.05}>Un.</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.05}>Ctd/Un</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>$ Unitario</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.07}>Tamaño</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Total / Un</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Ingreso</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Egreso</TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={styles.textCenter} weighting={0.07} getContent={(r) => r.codigo_cuenta}/>
                    <DataTableCell style={styles.textLeft} weighting={0.18} getContent={(r) => r.nombre_cuenta}/>
                    <DataTableCell style={styles.textLeft} weighting={0.18} getContent={(r) => r.codigo_mes}/>
                    <DataTableCell style={styles.textCenter} weighting={0.05} getContent={(r) => r.unidad_abreviacion}/>
                    <DataTableCell style={styles.textRigh} weighting={0.05} getContent={(r) => separadorDeMiles(r.cantidad_unidad)}/>
                    <DataTableCell style={styles.textRigh} weighting={0.1} getContent={(r) => separadorDeMiles(r.monto_unidad)}/>
                    <DataTableCell style={styles.textRigh} weighting={0.07} getContent={(r) => separadorDeMiles(r.cantidad)}/>
                    <DataTableCell style={styles.textRigh} weighting={0.1} getContent={(r) => separadorDeMiles(r.total_unidad)}/>
                    <DataTableCell style={styles.textRigh} weighting={0.1} getContent={(r) => separadorDeMiles(r.ingresos)}/>
                    <DataTableCell style={styles.textRigh} weighting={0.1} getContent={(r) => separadorDeMiles(r.egresos)}/>
                </TableBody>
            </Table>)
    } catch (error) {
        console.log("ERROOOOOOOOOOOOOOOOOOOOR");
        console.log(error);
    }


    let blob = BaseLinkPdf({...props, body:tablaPresupuestoActividad, orientation:"landscape"})

    return blob
}