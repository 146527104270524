import React, { useState, useEffect, useRef,useContext } from 'react';
import BaseGrid from '../../componentes/grid/BaseGrid'
import { Menu, Editors } from "react-data-grid-addons";
import GridMenu from '../../componentes/grid/GridMenu';
import { sortRows, EmptyRowsView } from '../../componentes/grid/utils';
import { Form, Button, Col, Modal, Row, ButtonGroup } from 'react-bootstrap';
import DefaultButtons from '../../componentes/grid/DefaultButtons';
import { useForm, ErrorMessage } from 'react-hook-form';
import { useAlert } from 'react-alert';
import { Api, ObjectToFormdata } from '../../utils/api';
import { tienePermisos } from '../../utils/Functions'
import { FaSave, FaWindowClose, FaPlus, FaMapMarkerAlt } from 'react-icons/fa';
import SelectorUsuariosPredio from './SelectorUsuariosPredio';
import UserContext from '../../UserContext';
import {FormularioPermisos} from '../../modulos/administracion/FormularioUsuario'
import ReactMapGL, { Marker,} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import SelectorUsuarioMulti from '../../componentes/SelectorUsuarioMulti';

export function FormularioPredio(props) {
    const { register, handleSubmit, errors, setValue } = useForm();
    const {predio, administradores, visualizadores, funcionRetorno, clonacionPredio, ...rest} = props;
    const alert = useAlert();           // Para las notificaciones
    const [usuariosAdministrador, setUsuariosAdministrador] = useState(administradores)
    const [usuariosVisualizador, setUsuariosVisualizador] = useState(visualizadores)

    const onSubmit = data => {
        enviarFormulario(data);
    }
    useEffect(
        () => {
            if (predio) {
                setValue([
                    { id: predio.id },
                    { nombre: predio.nombre },
                    { superficie_util: predio.superficie_util },
                    { mano_obra_permanente: predio.mano_obra_permanente },
                    { rol_unico_pecuario: predio.rol_unico_pecuario },
                    { nombre_productor: predio.nombre_productor },
                    { mes_inicio: predio.mes_inicio },
                ]);
            }
        },
        [predio]
    )

    async function enviarFormulario(data) {
        data.clonacionPredio = clonacionPredio;
        data.usuariosAdministrador = JSON.stringify(usuariosAdministrador);
        data.usuariosVisualizador = JSON.stringify(usuariosVisualizador);
        data = ObjectToFormdata(data);
        let resultado = await Api('api/administracion/predios/guardar', data, { 'Content-Type': 'application/json' }, true, 'post');
        if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            if (props.funcionRetorno) {
                props.funcionRetorno(data);
            }
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            //props.funcionRetorno(data);
        }
    }

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Col sm="12">
                <Form.Row>
                    <Form.Control type="hidden" name="id" defaultValue={predio.id} ref={register({})}></Form.Control>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label>Nombre Predio</Form.Label>
                        <Form.Control type="text" placeholder="Nombre..." name="nombre" defaultValue={predio.nombre}
                            ref={register({
                                required: "Por favor, ingrese el nombre del predio",
                                minLength: { value: 4, message: "Largo mínimo 4 caracteres" },
                                maxLength: { value: 100, message: "Largo máximmo 100" }
                            })}
                            isInvalid={errors.nombre} size="sm" />
                        <ErrorMessage errors={errors} name="nombre" as="div" className="invalid-feedback" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                        <Form.Label>RUP</Form.Label>
                        <Form.Control type="text" placeholder="Rol Único Pecuario..." name="rol_unico_pecuario"
                            defaultValue={predio.rol_unico_pecuario}
                            ref={register({
                                minLength: { value: 4, message: "Largo mínimo 4 caracteres" },
                                maxLength: { value: 20, message: "Largo máximo 20 caracteres" }
                            })}
                            isInvalid={errors.rol_unico_pecuario} size="sm" />
                        <ErrorMessage errors={errors} name="rol_unico_pecuario" as="div" className="invalid-feedback" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                        <Form.Label>Superficie Silvoagropecuaria</Form.Label>
                        <Form.Control type="number" placeholder="Superficie Silvoagropecuaria..." name="superficie_util"
                            defaultValue={predio.superficie_util}
                            ref={register({
                                required: "Por favor, ingrese la superficie silvoagropecuaria",
                                min: { value: 0.1, message: "Debe ser mayor a 0" }
                            })}
                            isInvalid={errors.superficie_util} size="sm" />
                        <ErrorMessage errors={errors} name="superficie_util" as="div" className="invalid-feedback" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                        <Form.Label>Mano de obra permanente</Form.Label>
                        <Form.Control type="number" placeholder="Mano de obra permanente..." name="mano_obra_permanente"
                            defaultValue={predio.mano_obra_permanente}
                            ref={register({
                                required: "Por favor, ingrese la mano_obra_permanente",
                                min: { value: 0, message: "Mínimo 0" }
                            })}
                            isInvalid={errors.mano_obra_permanente} size="sm" />
                        <ErrorMessage errors={errors} name="mano_obra_permanente" as="div" className="invalid-feedback" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom05">
                        <Form.Label>Nombre Productor</Form.Label>
                        <Form.Control type="text" placeholder="Nombre Productor..." name="nombre_productor"
                            defaultValue={predio.nombre_productor}
                            ref={register({
                                minLength: { value: 4, message: "Largo mínimo 4 caracteres" }
                            })}
                            isInvalid={errors.nombre_productor} size="sm" />
                        <ErrorMessage errors={errors} name="nombre_productor" as="div" className="invalid-feedback" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom06">
                        <Form.Label>Mes Inicio</Form.Label>
                        <Form.Control as="select" name="mes_inicio"
                            defaultValue={predio.mes_inicio}
                            ref={register({})}
                            isInvalid={errors.mes_inicio} size="sm">
                            <option key={"1"} value={"1"}>Enero</option>
                            <option key={"2"} value={"2"}>Febrero</option>
                            <option key={"3"} value={"3"}>Marzo</option>
                            <option key={"4"} value={"4"}>Abril</option>
                            <option key={"5"} value={"5"}>Mayo</option>
                            <option key={"6"} value={"6"}>Junio</option>
                            <option key={"7"} value={"7"}>Julio</option>
                            <option key={"8"} value={"8"}>Agosto</option>
                            <option key={"9"} value={"9"}>Septiembre</option>
                            <option key={"10"} value={"10"}>Octubre</option>
                            <option key={"11"} value={"11"}>Noviembre</option>
                            <option key={"12"} value={"12"}>Diciembre</option>
                        </Form.Control>
                        <ErrorMessage errors={errors} name="mes_inicio" as="div" className="invalid-feedback" />
                    </Form.Group>
                    <Form.Group as={Col} md="12">
                        <Form.Label>Usuarios administradores</Form.Label>
                        <SelectorUsuarioMulti seleccionadosId={administradores} name="usuarios_administrador" retorno={(usuarios) => setUsuariosAdministrador(usuarios)} />
                    </Form.Group>
                    <Form.Group as={Col} md="12">
                        <Form.Label>Usuarios visualizadores</Form.Label>
                        <SelectorUsuarioMulti seleccionadosId={visualizadores} name="usuarios_visualizador" retorno={(usuarios) => setUsuariosVisualizador(usuarios)}/>
                    </Form.Group>
                </Form.Row>
            </Col>
            <Button type="submit" size="sm" variant="success" ref={props.fordwardRef} hidden><FaSave /> Guardar</Button>
        </Form>
    )
}

export function ModalFormularioPredio(props) {
    const refSubmitButton = useRef();
    const {predio, administradores, visualizadores, funcionRetorno, clonacionPredio, ...rest} = props;
    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                {props.tituloFormPredio}
            </Modal.Header>

            <Modal.Body>
                <FormularioPredio predio={predio} funcionRetorno={funcionRetorno} clonacionPredio={clonacionPredio} fordwardRef={refSubmitButton} administradores={administradores} visualizadores={visualizadores} ></FormularioPredio>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.onHide}><FaWindowClose /> Cerrar</Button>
                <Button onClick={(e) => refSubmitButton.current.click()} size="sm" variant="success"><FaSave /> Guardar</Button>
            </Modal.Footer>

        </Modal>

    )
}

export async function getPredio(predioId) {
    let resultado = await Api('api/administracion/predios/' + predioId, null, {}, true);
    return resultado.data.predio;
}

export function FormularioEliminarPredio(props) {

    const { predio, funcionRetorno, ...rest } = props;
    const tipo = predio.cooperativa === true ? "cooperativa" : "predio";
    const alert = useAlert();           // Para las notificaciones

    async function eliminarPredio(data) {
        //data = ObjectToFormdata(data);
        let resultado = await Api('api/administracion/predios/eliminar/' + data.id, null, {}, true, 'delete');
        if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            props.funcionRetorno(data);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            //props.funcionRetorno(data);
        }
    }

    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Eliminar {tipo}
            </Modal.Header>
            <Modal.Body>
                <Col sm="12">
                    ¿Está seguro de eliminar a {predio.nombre}?
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.onHide}>
                    Cerrar
                    </Button>
                <Button variant="danger" size="sm"
                    onClick={(e) => eliminarPredio(predio)}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}


export function FormularioPermisosPredio(props) {
    const contexto = useContext(UserContext);
    const alert = useAlert();
    const { register, handleSubmit, errors } = useForm()
    const { ContextMenuTrigger } = Menu;
    const { DropDownEditor } = Editors;
    const perfilTypes = [
        { id: "Administrador", value: "Administrador" },
        { id: "Visualizador", value: "Visualizador" }
    ];
    const PerfilEditor = <DropDownEditor options={perfilTypes} />;
    const [limpiaSelector, setLimpiaSelector] = useState(false);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [showFormUsuario, setShowFormUsuario] = useState(false);
    const { predio } = props;
    const tipo = predio.cooperativa ? "cooperativa" : "predio";
    const [usuarioForm, setUsuarioForm] = useState(
		{
			"id":"",
			"nombre_completo": "",
			"usuario":"",
			"perfil":"",
		});
    useEffect(
        () => {
            if (predio.id !== null) {
                initGrid()
            }
        },
        [predio]
    )

    /** Para resolver problema cuando presionan el tab las colunas no se ven al 100%, luego de presionar el tab se cargan las columnas
     * ya que si no toma el ancho cuando está oculto el tab
     */
    useEffect(
        () => {
            if (props.tabSeleccionado === 'permisos_acceso') {
                setColumns([
                    { key: 'username', name: 'Usuario', sortable: true, width: 200 },
                    { key: 'nombre_completo', name: 'Nombre', sortable: true },
                    { key: 'perfil', name: 'Perfil', width: 200, editor: PerfilEditor },
                ]);
            } else {
                setColumns([]);
            }
        },
        [props.tabSeleccionado]
    )

    const agregaUsuario = data => {
        let nuevoUsuario = JSON.parse(data.usuario_predio);
        let indexNewRow = rows.map(function (item) { return item.username; }).indexOf(nuevoUsuario.username);
        if (indexNewRow > -1) {
            alert.show("El usuario ya está asociado al predio", { type: 'error' });
        }
        else {
            setLimpiaSelector(true);
            let newRow = { ...nuevoUsuario, ...{ perfil: data.perfil, usuario_id: nuevoUsuario.id } }
            setRows([...rows, ...[newRow]]);
            setLimpiaSelector(false);
        }
    }
	function cerrarModalEditar() {
		setShowFormUsuario(false);
	}
    async function enviarGrilla(data) {
        let rowsEnvio = []
        data.forEach(row => {
            rowsEnvio.push(JSON.stringify(row))
        });
        rowsEnvio = ObjectToFormdata(rowsEnvio);
        let resultado = await Api(`api/administracion/predios/${predio.id}/usuarios`, rowsEnvio, { 'Content-Type': 'application/json' }, true, 'put');
        if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            //props.funcionRetorno(data);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            //props.funcionRetorno(data);
        }
    }

    async function initGrid() {
        if (predio.id !== null) {
            let resultado = await Api(`api/administracion/predios/${predio.id}/usuarios`, null, {}, true);
            if (resultado && resultado.status === 200) {
                setRows(resultado.data.datos);
            } else {
                alert.show('Error al cargar usuarios del predio', { type: 'error' });
                setRows([]);
            }
        }
    }

    function onGridRowsUpdated({ fromRow, toRow, updated }) {
        const newRows = rows.slice();
        for (let i = fromRow; i <= toRow; i++) {
            newRows[i] = { ...newRows[i], ...updated };
        }
        setRows(newRows);
    };

    /**
     * Cuando queremos elimininar un usuario de la grilla
     *
     * @param {*} rowIdx
     */
    const deleteRow = rowIdx => {
        rows.splice(rowIdx, 1);
        setRows([...rows]);
    };

    const editRow = rowIdx => {
        setUsuarioForm({
            "id":rows[rowIdx].usuario_id,
			"nombre_completo": rows[rowIdx].nombre_completo,
			"usuario":rows[rowIdx].username,
			"perfil":rows[rowIdx].perfil,
        })
        setShowFormUsuario(true)
        setRows([...rows]);
    };

    return (
        <>
            {predio.cooperativa == false &&
                <>
                    Usuarios con acceso al predio: <b>{predio.nombre}</b><br />
                Agregar usuarios a este predio <br /><br />
                </>
            }
            {predio.cooperativa == true &&
                <>
                    Usuarios con acceso a la cooperativa: <b>{predio.nombre}</b><br />
                Agregar usuarios a esta cooperativa <br /><br />
                </>
            }

            <Form noValidate onSubmit={handleSubmit(agregaUsuario)}>
                <Row>
                    <Col sm="6">
                        <Form.Group as={Row} controlId="validationCustom01">
                            <Col sm="2">
                                <Form.Label size="sm" >Usuario: </Form.Label>
                            </Col>
                            <Col>
                                <SelectorUsuariosPredio
                                    id="selector_up"
                                    name="usuario_predio"
                                    register={register}
                                    errors={errors}
                                    predioid={predio.id}
                                    limpiaSelector={limpiaSelector}
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm="4">
                        <Form.Group as={Row} controlId="validationCustom01">
                            <Col sm="2">
                                <Form.Label size="sm" >Perfil: </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control name="perfil" ref={register({})} as="select" size="sm">
                                    <option key={"administrador"} value={"Administrador"}>
                                        Administrador
                                </option>
                                    <option key={"visualizador"} value={"Visualizador"}>
                                        Vizualizador
                                </option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Col>
                    <Col sm="2">
                        {tienePermisos('editar',contexto.predio.perfil) &&
                            <Button size="sm" type="submit" variant="primary"><FaPlus /> Agregar</Button>
                        }

                    </Col>
                </Row>
            </Form>

            <br></br>
            <Row>
                <Col>
                    <div>
                        <BaseGrid
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            minHeight={250}
                            contextMenu={
                                <GridMenu
                                    id="gridUsuariosPredio"
                                    options={ [
                                        {texto:"Editar",
                                        funcion: (e, { rowIdx }) => editRow(rowIdx)},
                                        {texto:"Eliminar",
                                        funcion: (e, { rowIdx }) => deleteRow(rowIdx)}
                                    ]}
                                />
                            }
                            RowsContainer={ContextMenuTrigger}
                            onGridSort={(sortColumn, sortDirection) =>
                                setRows(sortRows(rows, sortColumn, sortDirection))
                            }
                            emptyRowsView={EmptyRowsView}
                            enableCellSelect={true}
                            onGridRowsUpdated={(fromRow, toRow, updated) => onGridRowsUpdated(fromRow, toRow, updated)} />
                    </div>
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col md={{ span: 2, offset: 10 }}>
                    {tienePermisos('editar',contexto.predio.perfil) &&
                        <Button size="sm" variant="success" onClick={() => { enviarGrilla(rows) }}><FaSave /> Guardar</Button>
                    }

                </Col>
            </Row>
			<FormularioPermisos 
				show={showFormUsuario}
				onHide={cerrarModalEditar} 
                usuario={usuarioForm}
                rows={rows}
                funcionRetorno={function(data){cerrarModalEditar()}}
				size="md"
			/>
        </>

    )
}

export async function getRol(predioId, rolId) {
    let resultado = await Api(`api/administracion/predios/${predioId}/roles/${rolId}`, null, {}, true);
    return resultado.data.rol;
}

export function FormularioRol(props) {
    const { register, handleSubmit, errors, setValue, getValues, watch } = useForm({
        defaultValues: {
            latitud: '',
            longitud: ''
        }
    });
    const alert = useAlert();           // Para las notificaciones

    const { rol, predioId, funcionRetorno, ...rest } = props;
    const [viewport, setViewport] = useState({
        latitude: -43.4095433,
        longitude: -71.6486941,
        zoom: 6
    })
    const SIZE = 20;
    const _onClick = (event) => {
        setValue([
            { latitud: event.lngLat[1] },
            { longitud: event.lngLat[0] }
        ]);
    }
    const _onDragEnd = (event) => {
        setValue([
            { latitud: event.lngLat[1] },
            { longitud: event.lngLat[0] }
        ]);
    }

    const onSubmit = data => {
        enviarFormulario(data);
    }

    useEffect(
        () => {
            if (rol) {
                setValue([
                    { id: rol.id },
                    { rol: rol.rol },
                    { nombre: rol.nombre },
                    { superficie_util: rol.superficie_util },
                    { latitud: rol.latitud },
                    { longitud:rol.longitud }
                ]);
                if (rol.latitud && rol.longitud) {
                    setViewport({
                        ...viewport,
                        latitude: rol.latitud,
                        longitude: rol.longitud
                    })
                }
            }
        },
        [rol]
    )

    async function enviarFormulario(data) {
        data = ObjectToFormdata(data);
        let resultado = await Api(`api/administracion/predios/${predioId}/roles/guardar`, data, { 'Content-Type': 'application/json' }, true, 'post');
        if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            if (props.funcionRetorno) {
                props.funcionRetorno(data);
            }
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            //props.funcionRetorno(data);
        }
    }

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Col sm="12">
                <Form.Row>
                <Col lg={12}>
                        <Row>
                            <Form.Control type="hidden" name="id" defaultValue={rol.id} ref={register({})}></Form.Control>
                            <Form.Group as={Col} md={6} lg={4} controlId="validationCustom01">
                                <Form.Label>ROL</Form.Label>
                                <Form.Control type="text" placeholder="ROL..." name="rol" defaultValue={rol.rol}
                                    ref={register({
                                        required: "Por favor, ingrese el rol",
                                        minLength: {value: 4,message: "Largo mínimo 4 caracteres"},
                                        maxLength: {value: 100, message: "Largo máximmo 100"}
                                    })}
                                    isInvalid={errors.rol} size="sm" />
                                <ErrorMessage errors={errors} name="rol" as="div" className="invalid-feedback" />
                            </Form.Group>
                            <Form.Group as={Col} md={6} lg={4}  controlId="validationCustom02">
                                <Form.Label>Nombre Rol</Form.Label>
                                <Form.Control type="text" placeholder="Nombre ROL..." name="nombre" defaultValue={rol.nombre}
                                    ref={register({
                                        required: "Por favor, ingrese el nombre del rol",
                                        minLength: {value: 4,message: "Largo mínimo 4 caracteres"},
                                        maxLength: {value: 100, message: "Largo máximmo 100"}
                                    })}
                                    isInvalid={errors.nombre} size="sm" />
                                <ErrorMessage errors={errors} name="nombre" as="div" className="invalid-feedback" />
                            </Form.Group>
                            <Form.Group as={Col} md={6} lg={4} controlId="validationCustom03">
                                <Form.Label>Superficie</Form.Label>
                                <Form.Control type="number" placeholder="Superficie..." name="superficie_util"
                                    defaultValue={rol.superficie_util}
                                    ref={register({
                                        required: "Por favor, ingrese la superficie asociada al rol",
                                        min: {value:0.1, message:"Debe ser mayor a 0"}
                                    })}
                                    isInvalid={errors.superficie_util} size="sm" />
                                <ErrorMessage errors={errors} name="superficie_util" as="div" className="invalid-feedback" />
                            </Form.Group>
                        </Row>
                    </Col>
                    <Col lg={12}>
                        <Row>
                            <Form.Group as={Col} md={6} lg="4">
                                <Form.Label>Latitud</Form.Label>
                                <Form.Control type="number" name="latitud"
                                    // defaultValue={rol.latitud}
                                    ref={register({})}
                                    size="sm" />
                                {/* <ErrorMessage errors={errors} name="superficie_util" as="div" className="invalid-feedback" /> */}
                            </Form.Group>
                            <Form.Group as={Col} md={6} lg="4">
                                <Form.Label>Longitud</Form.Label>
                                <Form.Control type="number" name="longitud"
                                    // defaultValue={rol.longitud}
                                    ref={register({})}
                                    size="sm" />
                                {/* <ErrorMessage errors={errors} name="superficie_util" as="div" className="invalid-feedback" /> */}
                            </Form.Group>
                            <Col lg={12} style={{ height: '300px' }}>
                                <ReactMapGL
                                    {...viewport}
                                    width="100%"
                                    height="100%"
                                    onClick={_onClick}
                                    mapStyle="mapbox://styles/mapbox/streets-v11"//"mapbox://styles/dcarreraes/ckisysrko1ewu19o8vi16u4r3"//"mapbox://styles/mapbox/satellite-v9" //mapbox://styles/dcarreraes/ckisysrko1ewu19o8vi16u4r3
                                    mapboxApiAccessToken={"pk.eyJ1IjoiZGNhcnJlcmFlcyIsImEiOiJja2lxZzU4ZzIwOHR2MnFwNGdvanRnMWFnIn0.5R5VEaeu1-i9-64usvuL8A"}
                                    onViewportChange={(viewport) => setViewport(viewport)}>

                                    {((watch('latitud') !== '') && (watch('longitud') !== '')) &&
                                    <>
                                            {/* <div style={{ position: 'absolute', right: 0 }}>
                                                <FullscreenControl container={document.getElementById('mapa')} />
                                            </div> */}
                                            <Marker latitude={parseFloat(watch('latitud'))} longitude={parseFloat(watch('longitud'))} draggable={true} onDragEnd={_onDragEnd}>
                                                <FaMapMarkerAlt
                                                    // style={{ color: 'red' }}
                                                    height={SIZE}
                                                    // viewBox="0 0 24 24"
                                                    style={{
                                                        color: 'red',
                                                        cursor: 'pointer',
                                                        fill: '#d00',
                                                        stroke: 'none',
                                                        transform: `translate(${-SIZE / 2}px,${-SIZE}px)`
                                                    }} />
                                            </Marker>
                                        </>
                                    }
                                </ReactMapGL>
                            </Col>
                        </Row>
                    </Col>
                </Form.Row>
            </Col>
            <Button type="submit" size="sm" variant="success" ref={props.fordwardRef} hidden><FaSave /> Guardar</Button>
        </Form>
    )
}


export function ModalFormularioRol(props) {
    const contexto = useContext(UserContext);
    const refSubmitButton = useRef();
    const { rol, predioId, funcionRetorno, ...rest } = props;
    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Agregar rol
            </Modal.Header>

            <Modal.Body>
                {<FormularioRol rol={rol} predioId={predioId} funcionRetorno={funcionRetorno} fordwardRef={refSubmitButton}></FormularioRol>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.onHide}><FaWindowClose /> Cerrar</Button>
                {tienePermisos('editar',contexto.predio.perfil) &&
                    <Button onClick={(e) => refSubmitButton.current.click()} size="sm" variant="success"><FaSave /> Guardar</Button>
                }

            </Modal.Footer>

        </Modal>

    )
}

export function FormularioEliminarRol(props) {

    const { rol, predioId, funcionRetorno, ...rest } = props;
    const alert = useAlert();           // Para las notificaciones

    async function eliminarRol(data) {
        //data = ObjectToFormdata(data);
        let resultado = await Api(`api/administracion/predios/${predioId}/roles/${rol.id}/eliminar`, null, {}, true, 'delete');
        if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            props.funcionRetorno(data);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            //props.funcionRetorno(data);
        }
    }

    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Eliminar Rol
            </Modal.Header>
            <Modal.Body>
                <Col sm="12">
                    ¿Está seguro de eliminar a {rol.nombre}?
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.onHide}>
                    Cerrar
                    </Button>
                <Button variant="danger" size="sm"
                    onClick={(e) => eliminarRol(rol)}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export function FormularioRolesPredio(props) {
    const contexto = useContext(UserContext);
    const alert = useAlert();
    const { ContextMenuTrigger } = Menu;
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);

    const [activarModalAgregarRol, setActivarModalRol] = useState(false);			// Modal para agregar rol
    const [activarModalEliminarRol, setActivarModalEliminarRol] = useState(false);
    const [rolForm, setRolForm] = useState(
        {
            "id": "",
            "rol": "",
            "nombre": "",
            "superficie_util": "",
            "latitud": "",
            "longitud": ""
        });

    const { predio } = props;
    const tipo = predio.cooperativa ? "cooperativa" : "predio";

    useEffect(
        () => {
            if (predio.id !== null) {
                initGrid()
            }
        },
        [predio]
    )

    /** Para resolver problema cuando presionan el tab las colunas no se ven al 100%, luego de presionar el tab se cargan las columnas
     * ya que si no toma el ancho cuando está oculto el tab
     */
    useEffect(
        () => {
            if (props.tabSeleccionado === 'roles_predio') {
                setColumns([
                    { key: 'rol', name: 'ROL', sortable: true, width: 200 },
                    { key: 'nombre', name: 'Nombre', sortable: true },
                    { key: 'superficie_util', name: 'Superficie Util', width: 200, sortable: true },
                    { key: 'latitud', name: 'Latitud', width: 200 },
                    { key: 'longitud', name: 'Longitud', width: 200 },
                ]);
            } else {
                setColumns([]);
            }
        },
        [props.tabSeleccionado]
    )

    async function initGrid() {
        if (predio.id !== null) {
            let resultado = await Api(`api/administracion/predios/${predio.id}/roles`, null, {}, true);
            if (resultado && resultado.status === 200) {
                setRows(resultado.data.datos);
            } else {
                alert.show('Error al cargar roles del predio', { type: 'error' });
                setRows([]);
            }
        }
    }

    function onGridRowsUpdated({ fromRow, toRow, updated }) {
        const newRows = rows.slice();
        for (let i = fromRow; i <= toRow; i++) {
            newRows[i] = { ...newRows[i], ...updated };
        }
        setRows(newRows);
    };

    /**
     * Cuando queremos elimininar un rol, desde el context menú
     *
     * @param {*} rowIdx
     */
    const deleteRow = rowIdx => {
        new Promise(function (resolve, reject) {
            getRol(predio.id, rows[rowIdx].id).then(function (rol) {
                setRolForm(rol);
                setActivarModalEliminarRol(true);
            })
        })
    };

    /**
     * Cuando queremos editar un rol, desde el context menú
     *
     * @param {integer} rowIdx
     */
    const editRow = rowIdx => {
        new Promise(function (resolve, reject) {
            getRol(predio.id, rows[rowIdx].id).then(function (rol) {
                setRolForm(rol);
                setActivarModalRol(true);
            })
        })
    };

    function nuevoDato() {
        setRolForm({
            "id": "",
            "rol": "",
            "nombre": "",
            "superficia":"",
            "latitud": "",
            "longitud": ""
        });
        setActivarModalRol(true);
    }

    /**
     * Evento para cerrar el modal agregar rol
     */
    function cerrarModalAgregar() {
        initGrid();
        setActivarModalRol(false);
    }

    /**
     * Evento para cerrar el modal eliminar rol
     */
    function cerrarModalEliminar() {
        initGrid();
        setActivarModalEliminarRol(false);
    }

    return (
        <>
            {predio.cooperativa == false &&
                <>
                    Roles del predio: <b>{predio.nombre}</b><br />
                Agregar roles a este predio <br /><br />
                </>
            }
            {predio.cooperativa == true &&
                <>
                    Roles de la cooperativa: <b>{predio.nombre}</b><br />
                Agregar roles a esta cooperativa <br /><br />
                </>
            }
            <Row style={{ paddingBottom: '10px' }}>
                <Col>
                    <ButtonGroup className="float-right">
                        {tienePermisos('editar',contexto.predio.perfil) &&
                            <Button variant="success" size="sm" onClick={nuevoDato}>Agregar ROL</Button>
                        }

                        <DefaultButtons />
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <BaseGrid
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            minHeight={250}
                            contextMenu={
                                <GridMenu
                                    id="gridRolesPredio"
                                    options={[
                                        {
                                            texto: "Editar",
                                            funcion: (e, { rowIdx }) => editRow(rowIdx)
                                        },
                                        {
                                            texto: "Eliminar",
                                            funcion: (e, { rowIdx }) => deleteRow(rowIdx)
                                        }
                                    ]}
                                />
                            }
                            RowsContainer={ContextMenuTrigger}
                            onGridSort={(sortColumn, sortDirection) =>
                                setRows(sortRows(rows, sortColumn, sortDirection))
                            }
                            emptyRowsView={EmptyRowsView}
                            enableCellSelect={true}
                            onGridRowsUpdated={(fromRow, toRow, updated) => onGridRowsUpdated(fromRow, toRow, updated)} />
                    </div>
                </Col>
            </Row>
            <ModalFormularioRol
                show={activarModalAgregarRol}
                onHide={cerrarModalAgregar}
                rol={rolForm}
                predioId={predio.id}
                funcionRetorno={function (data) { cerrarModalAgregar() }}
                size="lg"
            />
            {<FormularioEliminarRol
                show={activarModalEliminarRol}
                onHide={cerrarModalEliminar}
                rol={rolForm}
                predioId={predio.id}
                funcionRetorno={function (data) { cerrarModalEliminar() }}
                size="md"
            />}
        </>

    )
}