import React, { useState, useEffect, useContext } from 'react';
import { useAlert } from 'react-alert';
import BaseGrid from '../../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import Layout from '../../../componentes/Layout';
import { NumberFormatter, sortRows, EmptyRowsView } from '../../../componentes/grid/utils';
import { Api } from '../../../utils/api';
import UserContext from '../../../UserContext';
import { Row, Col, Form, ButtonGroup, Button } from 'react-bootstrap';
import Fecha from '../../../componentes/Fecha';
import { getYMD, fechaHoy, getDMY } from '../../../utils/Functions';
import DefaultButtons from '../../../componentes/grid/DefaultButtons';
import { exportar, downloadBlob } from '../../../utils/Functions';
import { BalanceGeneralPdf } from './pdf/BalanceGeneralPdf';
import ChartBalanceGeneral from '../ChartBalanceGeneral'


function BalanceGeneral(props) {

    const contexto = useContext(UserContext);
    const { ContextMenuTrigger } = Menu
    const alert = useAlert();
    const [rows, setRows] = useState([]);
    const [rowsTotales, setRowsTotales] = useState([]);
    const [fechaExportar] = useState(fechaHoy());
    const [showChart, setShowChart] = useState(false);
    const columns = [
        { key: 'codigo', name: 'Cuenta', width: 200 },
        { key: 'nombre_cuenta', name: 'Descripción' },
        { key: 'activos_inicio', name: 'Inicio', width: 100, formatter: NumberFormatter },
        { key: 'activos_operaciones', name: 'Operaciones', width: 100, formatter: NumberFormatter },
        { key: 'activos_fin_periodos', name: 'Fin Periodo', width: 100, formatter: NumberFormatter },
        { key: 'pasivos_fin_periodos', name: 'Fin Periodo', width: 100, formatter: NumberFormatter },
        { key: 'pasivos_operaciones', name: 'Operaciones', width: 100, formatter: NumberFormatter },
        { key: 'pasivos_inicio', name: 'Inicio', width: 100, formatter: NumberFormatter }
    ];
    const columnsTotales = [
        { key: 'titulo', name: '' },
        { key: 'total_activos_inicio', name: 'Inicio', width: 100, formatter: NumberFormatter },
        { key: 'total_activos_operaciones', name: 'Operaciones', width: 100, formatter: NumberFormatter },
        { key: 'total_activos_fin_periodos', name: 'Fin Periodo', width: 100, formatter: NumberFormatter },
        { key: 'total_pasivos_fin_periodos', name: 'Fin Periodo', width: 100, formatter: NumberFormatter },
        { key: 'total_pasivos_operaciones', name: 'Operaciones', width: 100, formatter: NumberFormatter },
        { key: 'total_pasivos_inicio', name: 'Inicio', width: 100, formatter: NumberFormatter }
    ];
    const titulos = [
        { key: 'titulo2', name: '' },
        { key: 'unidad', name: 'ACTIVOS', width: 300 },
        { key: 'cantidad', name: 'PASIVOS', width: 300 },
    ];
    const [fecha, setFecha] = useState('');

    function chart() {
        setShowChart(true);
    }
    useEffect(
        () => {
            if (contexto.predio.predio_id && contexto.predio.periodo.cierre) {
                if (contexto.predio.periodo.cierre === fecha) {
                    obtenerDatos();
                }
                setFecha(contexto.predio.periodo.cierre);
            } else {
                setRows([]);
                setRowsTotales([]);
            }
        },
        [contexto]
    )

    useEffect(
        () => {
            if (fecha) {
                obtenerDatos();
            }
        },
        [fecha]
    )

    /**
     * Cargar información de la grilla
     */
    async function obtenerDatos() {
        let resultado = await Api(`api/contabilidad/balance-general?predio_id=${contexto.predio.predio_id}&periodo_id=${contexto.predio.periodo.id}&fecha_cierre=${fecha}`);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
            setRowsTotales(resultado.data.resultados);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            setRows([]);
            setRowsTotales([]);
        }
    }

    /**
     * Cuando cambian la fecha
     *
     * @param {date} e
     */
    function onChangeInicio(e) {
        let fecha = getYMD(e);
        setFecha(fecha);
    }

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        exportar(`api/exportar/contabilidad/balance-general?predio_id=${contexto.predio.predio_id}&periodo_id=${contexto.predio.periodo.id}&fecha_cierre=${fecha}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf() {
        let props = {
            columns: columns,
            rows: rows,
            rowsTotales: rowsTotales,
            titulo: 'Balance General',
            bajada: contexto.predio.nombre_predio
        }
        alert.show('La descarga comenzará en breve', { type: 'success' });
        new Promise(function (resolve, reject) {
            BalanceGeneralPdf(props).then(
                function (blob) {
                    downloadBlob(blob, `Balance_general_${contexto.predio.nombre_predio.replace(/ /g, '_')}_cierre_${getDMY(fecha).replace(/ /g, '_').replaceAll('/', '-')}_${getDMY(fechaExportar)}.pdf`);
                }, function (blob) {
                    alert.show('Error al exportar', { type: 'error' })
                })
        })
    }

    return (
        <Layout title="Balance General">
            <Row style={{ marginBottom: '10px' }}>
                <Col sm={6} md={4} lg={3} id="cont-fecha-desde">
                    <Form.Label>Fecha de Cierre</Form.Label>
                    <Fecha
                        name='inicio'
                        dateFormat='dd/MM/yyyy' // dd/MM/Y h:mm aa - dd/MM/Y - MM/Y
                        dateFormatOculto='y-m-d' // y-m-d h:i:s
                        inicial={fecha}
                        onChange={onChangeInicio}
                    />
                </Col>
                <Col sm={6} md={8} lg={9} style={{ paddingTop: '32px', textAlign: 'right' }}>

                    <ButtonGroup className="float-right">
                        <Button
                            variant="info"
                            size="sm"
                            onClick={chart}>Gráfico</Button>
                        <DefaultButtons exportar={exportarExcel} />
                        <DefaultButtons imprimir={exportarPdf} />
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <BaseGrid
                            columns={titulos}
                            rowGetter={i => rowsTotales[i]}
                            rowsCount={1}
                            minHeight={35}
                            RowsContainer={ContextMenuTrigger}
                            emptyRowsView={EmptyRowsView}
                            showCountRows={false} />
                    </div>
                    <div>
                        <BaseGrid
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            minHeight={400}
                            RowsContainer={ContextMenuTrigger}
                            onGridSort={(sortColumn, sortDirection) =>
                                setRows(sortRows(rows, sortColumn, sortDirection))
                            }
                            rowStyle={[{
                                columna: 'codigo',
                                valor: '',
                                clases: '',
                                css: { color: 'black', fontWeight: '700' }
                            }, {
                                columna: 'nombre_cuenta',
                                valor: 'TOTAL',
                                clases: 'row-totales',
                                css: { color: 'black', fontWeight: '700' }
                            }]}
                            emptyRowsView={EmptyRowsView}
                            showCountRows={false} />
                    </div>
                    <div>
                        <BaseGrid
                            columns={columnsTotales}
                            rowGetter={i => rowsTotales[i]}
                            rowsCount={rowsTotales.length}
                            minHeight={105}
                            showCountRows={false}
                            rowStyle={[{
                                columna: 'titulo',
                                valor: 'TOTAL BALANCE',
                                clases: 'row-totales',
                                css: { color: 'black', fontWeight: '400', textAlign: 'right' }
                            }, {
                                columna: 'titulo',
                                valor: 'RESULTADO BENEFICIO NETO',
                                clases: 'row-totales',
                                css: { color: 'black', fontWeight: '400', textAlign: 'right' }
                            }]} />
                    </div>
                </Col>
            </Row>
            <ChartBalanceGeneral
                rows={rows}
                rowsTotales={rowsTotales}
                fecha={fecha}
                show={showChart}
                onHide={() => setShowChart(false)} />
        </Layout>
    );
}

export default BalanceGeneral;