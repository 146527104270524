import React, { useState, useContext, useEffect, useRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Button, InputGroup, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaTools } from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import { Api } from '../utils/api';
import UserContext from '../UserContext';
import { ErrorMessage } from 'react-hook-form';
import { useAlert } from 'react-alert';

function SelectorTipoDocumento(props) {
    const alert = useAlert();           // Para las notificaciones
    const refSelectorTipoDocumento = useRef();
    const contexto = useContext(UserContext);
    const [tiposdocumentos, setTiposDocumentos] = useState([]);
    const [oculto, setOculto] = useState('');

    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                obtenerTiposDocumentos(contexto.predio.predio_id);
            }
        },
        [contexto]
    )

    useEffect(
        () => {
            if (props.inicial) {
                if (tiposdocumentos.length === 0) { // No hay tiposdocumentos cargadas, tenemos que ir a buscar la seleccionada y seleccionar el combobox
                    obtenerTipoDocumento(props.inicial);
                } else { // Si hay tiposdocumentos cargadas, solo seleccionamos la que esté
                    if (refSelectorTipoDocumento.current.getInstance().state.selected.length === 0) { // Solo cuando no hay un elemento seleccionado en el combobox, esto se da solo en el inicio, ref.current.getInstance()._input.value
                        tiposdocumentos.forEach(actividad => {  // Buscamos dentro de las tiposdocumentos la que tenemos que seleccionar
                            if (actividad.nombre === props.inicial) {
                                refSelectorTipoDocumento.current.getInstance()._updateSelected([actividad]); // Automáticamente llama cambiarSelector
                            }
                        });
                    }
                }
            }
        },
        [props.inicial]
    )

    useEffect(
        () => {
            if (props.limpiar) {
                refSelectorTipoDocumento.current.getInstance().clear(); // solo limpia la casila de la unidad, no hace nada más
                refSelectorTipoDocumento.current.getInstance()._updateSelected([]); // Este limpia la casilla y activa cambiarSelector
            }
        },
        [props.limpiar]
    )

    /**
     * Buscamos una actividad y actualizamos el valor por defecto del combobox
     *
     * @param {integer} predio
     */
    async function obtenerTipoDocumento(nombre) {
        let resultado = await Api(`api/catalogo/documentos?nombre=${nombre}`);
        if (resultado && resultado.status === 200) {
            if (resultado.data.datos) {
                refSelectorTipoDocumento.current.getInstance()._updateSelected([resultado.data.datos]); // Automáticamente llama cambiaSelector
            } else {
                alert.show('Error al actualizar combobox tipos de documentos', { type: 'error' });
            }
        } else {
            alert.show('Error al actualizar combobox tipos de documentos', { type: 'error' });
        }
    }

    /**
     * Cargamos las tiposdocumentos en el combobox
     *
     * @param {integer} predio
     */
    async function obtenerTiposDocumentos(predio) {
        let resultado = await Api(`api/catalogo/documentos`);
        if (resultado && resultado.status === 200) {
            setTiposDocumentos(resultado.data.datos);
        } else {
            setTiposDocumentos([]);
        }
    }


    /**
     * Cada vez que cambia el selector actividad
     *
     * @param {array} selected
     */
    function cambiaSelector(selected) {
        if (selected[0] !== undefined) {
            props.retorno(selected);
            setOculto(selected[0].nombre);
        } else {
            setOculto('');
        }
    }

    return (
        <>
            <InputGroup size="sm">
                <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1"><FaTools /></InputGroup.Text>
                </InputGroup.Prepend>
                <div style={{ width: 'calc(100% - 62px)' }}>
                    {props.register ?
                        <Typeahead
                            {...props}
                            bsSize='small'
                            maxResults={400}
                            options={tiposdocumentos}
                            highlightOnlyResult={true}
                            selectHintOnEnter={true}
                            flip={true}
                            ref={refSelectorTipoDocumento}
                            isInvalid={props.errors[props.name] ? true : false}
                            onChange={cambiaSelector}
                            labelKey={(option) => `${option.nombre}`}
                        />
                        :
                        <Typeahead
                            {...props}
                            bsSize='small'
                            options={tiposdocumentos}
                            highlightOnlyResult={true}
                            selectHintOnEnter={true}
                            flip={true}
                            ref={refSelectorTipoDocumento}
                            onChange={cambiaSelector}
                            labelKey={(option) => `${option.nombre}`}
                        />
                    }
                </div>
                <InputGroup.Append>
                    <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Limpiar opción</Tooltip>}>
                        <Button
                            variant="outline-secondary"
                            disabled={props.desactivarBotonBorrar ? true : false}
                            onClick={() => {
                                refSelectorTipoDocumento.current.getInstance().clear();
                                setOculto('');
                            }}>
                            <IoMdClose />
                        </Button>
                    </OverlayTrigger>
                </InputGroup.Append>
            </InputGroup>
            {/* INPUT INVISIBLE */}
            {props.register &&
                <Form.Control
                    type="text"
                    name={props.name}
                    value={oculto}
                    hidden
                    onChange={ // Este onChange no se ocupa, ya que nunca modificamos este valor directamente, solo está para que no lance error reactjs
                        (e) => {
                            // setOculto(e.target.value)
                        }
                    }
                    ref={props.register({
                        required: "Seleccione una opción"
                    })}
                    isInvalid={props.errors[props.name]}
                />
            }

            {props.errors &&
                <ErrorMessage errors={props.errors} name={props.name} as="div" className="invalid-feedback" />
            }
        </>
    );
}

export default SelectorTipoDocumento;