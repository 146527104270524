import React, {useState, useContext, useEffect} from 'react';
import {Api} from '../../utils/api';
import UserContext from '../../UserContext';
import {Modal, Col, Tabs, Tab} from 'react-bootstrap';
import BaseGrid from '../../componentes/grid/BaseGrid';
import {NumberFormatter, PercentFormatter} from '../../componentes/grid/utils';
import {
    PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell
  } from 'recharts';
  import {colorCuenta, agruparDatos} from '../../componentes/chart/utils';


function EstructuraCostosDetalle(props) {

    const {categoria, hide, logout,  ...rest} = props
    const contexto = useContext(UserContext)
    const [rows, setRows] = useState([]);
    const [dataGrafico, setDataGrafico] = useState([]);

    const columns = [
        {key: 'codigo', name: 'Cuenta', width: 70, sortable: true},
        {key: 'nombre', name: 'Descripción', width: 250},
        {key: 'datos', name: 'Datos', width: 70, formatter: NumberFormatter},
        {key: 'total', name: 'Total', width: 100, formatter: NumberFormatter},
        {key: 'total_ha', name: 'Total / ha', width: 100, formatter: NumberFormatter},
        {key: 'porcentaje', name: '%', width: 100, formatter: PercentFormatter},
     ];

    async function getReporte() {
        var predio = contexto.predio.predio_id;
        let resultado = await Api(`api/reportes/presupuesto/estructura_costos_detalle/${predio}/${categoria.id}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
        } else {
            console.log('viene un error');
            setRows([]);
        }
    }

    async function getGrafico() {
        var predio = contexto.predio.predio_id;
        let resultado = await Api(`api/reportes/presupuesto/estructura_costos_detalle/${predio}/${categoria.id}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setDataGrafico(agruparDatos(resultado.data.datos,"6000","Otros",10));
        } else {
            setDataGrafico([]);
            console.log('viene un error');
        }
    }

    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                getGrafico();
                getReporte();
            }
        },
        [contexto, categoria]
    )

    return (
        <Modal {...rest} size="lg" onShow={getReporte}>
            <Modal.Header closeButton>
                <Modal.Title>Costos en {categoria.codigo} {categoria.nombre} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Tabs defaultActiveKey="grilla" id="grilla">
                <Tab eventKey="grilla" title="Detalle Costos">
                    <Col sm="12">
                    <div>
                        <BaseGrid
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            minHeight={400}/>
                    </div>
                    </Col>
                </Tab>
                <Tab eventKey="graifco" title="Gráfico">
                    <div style={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                            <PieChart
                                margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                                }}>
                                <Pie
                                    dataKey="porcentaje"
                                    nameKey="nombre"
                                    isAnimationActive={false}
                                    data={dataGrafico} 
                                    outerRadius={100}
                                    fill="#8884d8"
                                    label>
                                        {
                                            dataGrafico.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colorCuenta(entry.codigo)}/>
                                            ))
                                        }
                                </Pie>
                                <Tooltip />
                                <Legend layout='vertical' align='right' verticalAlign='top'/>
                            </PieChart>
                        </ResponsiveContainer>
                </div>
                </Tab>
            </Tabs>
            </Modal.Body>
        </Modal>
    );
}
export default EstructuraCostosDetalle;