import React, {useState, useEffect, useContext } from 'react';
import {useAlert} from 'react-alert';
import BaseGrid from '../../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import Layout from '../../../componentes/Layout';
import { NumberFormatter, sortRows, EmptyRowsView } from '../../../componentes/grid/utils';
import {Api} from '../../../utils/api';
import UserContext from '../../../UserContext';
import { Row, Col, Form, ButtonGroup} from 'react-bootstrap';
import Fecha from '../../../componentes/Fecha';
import { fechaHoy, getYMD } from '../../../utils/Functions';
import { exportar, downloadBlob, getDMY } from '../../../utils/Functions';
import DefaultButtons from '../../../componentes/grid/DefaultButtons';
import { ResumenComprasVentasPdf } from './pdf/ResumenComprasVentasPdf';

function ResumenComprasVentas(props) {

    const {ContextMenuTrigger} = Menu
    const alert = useAlert();

    const columnsCompras = [
        {key: 'titulo', name: 'Resumen de compras', width: 170},
        {key: 'exento', name: 'Exento', width: 130, formatter: NumberFormatter},
        {key: 'afecto', name: 'Afecto', width: 130, formatter: NumberFormatter},
        { key: 'iva', name: 'IVA', formatter: NumberFormatter},
        {key: 'especifico_recuperable', name: 'Esp Rec', width: 100, formatter: NumberFormatter},
        {key: 'especifico_no_recuperable', name: 'Esp No Rec', width: 100, formatter: NumberFormatter},
        {key: 'retencion_honorarios', name: 'Retención Honorarios', width: 150, formatter: NumberFormatter},
        {key: 'total', name: 'Total', width: 100, formatter: NumberFormatter}
    ];
    const columnsVentas = [
        { key: 'titulo', name: 'Resumen de ventas', width: 170},
        {key: 'exento', name: 'Exento', width: 130, formatter: NumberFormatter},
        {key: 'afecto', name: 'Afecto', width: 130, formatter: NumberFormatter},
        {key: 'iva', name: 'IVA', formatter: NumberFormatter},
        {key: 'ila', name: 'ILA', width: 200,formatter: NumberFormatter},
        {key: 'feria_retenido', name: 'Feria Retenido', width: 150, formatter: NumberFormatter},
        {key: 'total', name: 'Total', width: 100, formatter: NumberFormatter}
    ];

    const [rowsCompras, setRowsCompras] = useState([]);
    const [rowsVentas, setRowsVentas] = useState([]);
    const contexto = useContext(UserContext);
    const [fechaInicio, setFechaInicio] = useState(fechaHoy());
    const [fechaCierre, setFechaCierre] = useState(fechaHoy());
    const [fechaExportar] = useState(fechaHoy());

    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                let inicio = (contexto.predio.periodo ? contexto.predio.periodo.inicio : '');
                let cierre = (contexto.predio.periodo ? contexto.predio.periodo.cierre : '');
                setFechaInicio(inicio);
                setFechaCierre(cierre);
                obtenerDatos(contexto.predio.predio_id, inicio, cierre);
            }
        },
        [contexto]
    )

    /**
     * Búsqueda información del reporte
     *
     * @param {integer} predio
     * @param {date} inicio
     * @param {date} cierre
     */
    async function obtenerDatos(predio, inicio, cierre) {
        let resultado = await Api(`api/contabilidad/resumen-compras-ventas?predio_id=${predio}&inicio=${inicio}&cierre=${cierre}`);
        if (resultado && resultado.status === 200) {
            setRowsCompras(resultado.data.compras);
            setRowsVentas(resultado.data.ventas);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            setRowsCompras([]);
            setRowsVentas([]);
        }
    }

    /**
     * Cuando cambian la fecha inicio
     *
     * @param {date} e
     */
    function onChangeInicio(e) {
        let fecha = getYMD(e);
        setFechaInicio(fecha);
        obtenerDatos(contexto.predio.predio_id, fecha, fechaCierre);
    }

    /**
     * Cuando cambian la fecha cierre
     *
     * @param {date} e
     */
    function onChangeCierre(e) {
        let fecha = getYMD(e);
        setFechaCierre(fecha);
        obtenerDatos(contexto.predio.predio_id, fechaInicio, fecha);
    }

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        exportar(`api/exportar/contabilidad/resumen-compras-ventas?predio_id=${contexto.predio.predio_id}&inicio=${fechaInicio}&cierre=${fechaCierre}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf() {
        if (fechaInicio && fechaCierre) {
            let props = {
                columnsCompras: columnsCompras,
                columnsVentas: columnsVentas,
                rowsVentas: rowsVentas,
                rowsCompras: rowsCompras,
                titulo: 'Resumen Compras Ventas',
                subtitulo: `Desde: ${getDMY(fechaInicio)}, Hasta: ${getDMY(fechaCierre)}`,
                bajada: contexto.predio.nombre_predio
            }
            alert.show('La descarga comenzará en breve', { type: 'success' });
            const periodo = `inicio_${getDMY(fechaInicio).replace(/ /g, '_').replaceAll('/', '-')}_fin_${getDMY(fechaCierre).replace(/ /g, '_').replaceAll('/', '-')}`
            new Promise(function (resolve, reject) {
                ResumenComprasVentasPdf(props).then(
                    function (blob) {
                        downloadBlob(blob, `Resumen_Compras_Ventas_${contexto.predio.nombre_predio.replace(/ /g, '_')}_${periodo}_${getDMY(fechaExportar)}.pdf`);
                    }, function (blob) {
                        alert.show('Error al exportar', { type: 'error' })
                    })
            })
        } else {
            alert.show('Debes ingresar las fechas', { type: 'error' })
        }
    }

    return (
        <Layout title="Resumen Compras / Ventas">
            <Row style={{ marginBottom: '10px' }}>
                <Col sm={6} md={4} lg={2} id="cont-fecha-desde">
                    <Form.Label>Desde</Form.Label>
                    <Fecha
                        name='inicio'
                        dateFormat='dd/MM/yyyy' // dd/MM/Y h:mm aa - dd/MM/Y - MM/Y
                        dateFormatOculto='y-m-d' // y-m-d h:i:s
                        minDate={contexto.predio.periodo ? contexto.predio.periodo.inicio : false}
                        maxDate={contexto.predio.periodo ? contexto.predio.periodo.cierre : false}
                        inicial={contexto.predio.periodo ? contexto.predio.periodo.inicio : fechaHoy()}
                        onChange={onChangeInicio}/>
                </Col>
                <Col sm={6} md={4} lg={2} id="cont-fecha-hasta">
                    <Form.Label>Hasta</Form.Label>
                    <Fecha
                        name='cierre'
                        dateFormat='dd/MM/yyyy' // dd/MM/Y h:mm aa - dd/MM/Y - MM/Y
                        dateFormatOculto='y-m-d' // y-m-d h:i:s
                        minDate={contexto.predio.periodo ? contexto.predio.periodo.inicio : false}
                        maxDate={contexto.predio.periodo ? contexto.predio.periodo.cierre : false}
                        inicial={contexto.predio.periodo ? contexto.predio.periodo.cierre : fechaHoy()}
                        onChange={onChangeCierre}
                    />
                </Col>
                <Col sm={6} md={4} lg={8} style={{ paddingTop: '33px' }}>
                    <ButtonGroup className="float-right">
                        <DefaultButtons exportar={exportarExcel} />
                        <DefaultButtons imprimir={exportarPdf} />
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <div style={{width: '100%', height: 'auto', background: 'rgb(249, 249, 249)', color: 'rgb(52, 55, 59)', textAlign: 'center', border: '1px solid #dddddd', borderBottom: '0px', fontWeight: '600'}}>Compras</div>
                        <div style={{overflow:'hidden'}}>
                            <BaseGrid
                                columns={columnsCompras}
                                rowGetter={i => rowsCompras[i]}
                                rowsCount={rowsCompras.length}
                                minHeight={142}
                                RowsContainer={ContextMenuTrigger}
                                onGridSort={(sortColumn, sortDirection) =>
                                    setRowsCompras(sortRows(rowsCompras, sortColumn, sortDirection))
                                }
                                emptyRowsView={EmptyRowsView}
                                showCountRows={false}/>
                        </div>
                        <div style={{ width: '100%', height: 'auto', background: 'rgb(249, 249, 249)', color: 'rgb(52, 55, 59)', textAlign: 'center', border: '1px solid #dddddd', borderBottom: '0px', fontWeight: '600', marginTop: '20px' }}>Ventas</div>
                        <div style={{ overflow: 'hidden' }}>
                            <BaseGrid
                                columns={columnsVentas}
                                rowGetter={i => rowsVentas[i]}
                                rowsCount={rowsVentas.length}
                                minHeight={177}
                                RowsContainer={ContextMenuTrigger}
                                onGridSort={(sortColumn, sortDirection) =>
                                    setRowsCompras(sortRows(rowsVentas, sortColumn, sortDirection))
                                }
                                rowStyle={[{
                                    columna: 'afecto',
                                    valor: 'IVA a pagar',
                                    clases: 'fondo-fila',
                                    css: { color: 'black', fontWeight: '700'}
                                }]}
                                emptyRowsView={EmptyRowsView}
                                showCountRows={false} />
                        </div>
                    </div>
                </Col>
            </Row>
        </Layout>
    );
}

export default ResumenComprasVentas;