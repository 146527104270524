import React, {useState, useContext, useEffect} from 'react';
import BaseGrid from '../../componentes/grid/BaseGrid';
import UserContext from '../../UserContext';
import Layout from '../../componentes/Layout.js';
import {NumberFormatter, PercentFormatter, RightAlign} from '../../componentes/grid/utils';
import DefaultButtons from '../../componentes/grid/DefaultButtons'
import {Button, Row, Col, ButtonGroup, Form} from 'react-bootstrap';
import {Api} from '../../utils/api';
import ChartEstructuraIngresos from './ChartEstructuraIngresos';
import { exportar, downloadBlob, getDMY, fechaHoy } from '../../utils/Functions';
import {EstructuraIngresosPdf} from './pdf/EstructuraIngresosPdf';
import { useAlert } from 'react-alert';

function EstructuraIngresos(props) {
    const alert = useAlert();			// Para las notificaciones
    const contexto = useContext(UserContext);
    const [rows, setRows] = useState([]);
    const [rowsTotales, setRowsTotales] = useState([]);
    const [showChart, setShowChart] = useState(false);
    const [fechaExportar] = useState(fechaHoy());

    const columns = [
        {key: 'codigo', name: 'Cuenta', width: 120, sortable: true},
        {key: 'nombre', name: 'Descripción'},
        {key: 'cantidad', name: 'Cantidad', formatter: NumberFormatter},
        {key: 'unidad', name: 'Unidad' },
        {key: 'total', name: 'Total', width: 200, formatter: NumberFormatter},
        {key: 'total_ha', name: 'Total / ha', width: 200, formatter: NumberFormatter},
        {key: 'porcentaje', name: '%', width: 200, formatter: PercentFormatter},
    ];

    const columnsTotales = [
        {key: 'nombre', name: '', formatter: RightAlign},
        {key: 'total', name: 'Total', width: 200, formatter: NumberFormatter},
        {key: 'total_ha', name: 'Total / ha', width: 200, formatter: NumberFormatter},
        {key: '', name: '', width: 200},
    ];


    async function getReporte(predio) {
        let resultado = await Api(`api/reportes/presupuesto/estructura_ingresos/${predio}/${contexto.predio.periodo.id}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
            setRowsTotales(resultado.data.totales);
        } else {
            setRows([]);
            setRowsTotales([]);
            alert.show(`Error al obtener información, mensaje: ${resultado.data.msg}`, { type: 'error' })
        }
    }


    function chart() {
        setShowChart(true);
    }

    useEffect(
        () => {
            if (contexto.predio.predio_id, contexto.predio.periodo) {
                getReporte(contexto.predio.predio_id);
            }
        },
        [contexto]
    )

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        exportar(`api/exportar/reportes/presupuesto/estructura_ingresos/${contexto.predio.predio_id}/${contexto.predio.periodo.id}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf(){
        let props = {
            columns:columns,
            rows:rows,
            columnsTotales: columnsTotales,
            rowsTotales: rowsTotales,
            titulo:"Estructura de Ingresos",
            bajada:contexto.predio.nombre_predio
        }
        alert.show('La descarga comenzará en breve', { type: 'success' });
        new Promise(function(resolve, reject){
            EstructuraIngresosPdf(props).then(
                function(blob){
                    downloadBlob(blob,
                        `Estructura_Ingresos_${contexto.predio.nombre_predio.replace(/ /g, '_')}_${getDMY(fechaExportar)}.pdf`);
                },function(blob){
                    alert.show('Error al exportar', { type: 'error' })
            })
        })
    }

    return (
        <Layout title="Estructura de Ingresos">
            <Form>
                <Form.Row style={{paddingBottom:'10px'}}>
                    <Col md={8}>
                        <Form.Group as={Row} controlId="select_periodo">
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <ButtonGroup className="float-right">
                            <Button
                                variant="info"
                                size="sm"
                                onClick={chart}>Gráfico</Button>
                            <DefaultButtons exportar={exportarExcel}/>
                            <DefaultButtons imprimir={exportarPdf}/>
                        </ButtonGroup>
                    </Col>
                </Form.Row>
            </Form>
            <div>
                <BaseGrid
                    columns={columns}
                    rowGetter={i => rows[i]}
                    rowsCount={rows.length}
                    minHeight={250}
                    showCountRows={false} />
            </div>
            <div>
                <BaseGrid
                    columns={columnsTotales}
                    rowGetter={i => rowsTotales[i]}
                    rowsCount={rowsTotales.length}
                    minHeight={150}
                    showCountRows={false}  />
            </div>
            <ChartEstructuraIngresos
                show={showChart}
                onHide={() => setShowChart(false)} />
        </Layout>
    );
}

export default EstructuraIngresos;