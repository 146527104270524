import React, {useState, useEffect, useContext} from 'react';
import {useAlert} from 'react-alert';
import BaseGrid from '../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import GridMenu from '../../componentes/grid/GridMenu';
import Layout from '../../componentes/Layout';
import { LongTextFormatter, sortRows, EmptyRowsView, DateFormatter } from '../../componentes/grid/utils';
import {Api} from '../../utils/api';
import UserContext from '../../UserContext';
import {Button, Row, Col, ButtonGroup} from 'react-bootstrap';
import DefaultButtons from '../../componentes/grid/DefaultButtons';
import {FormPeriodo, getPeriodo, FormEliminarPeriodo} from './FormPeriodo';
import Buscador from '../../componentes/Buscador';
import { deltaPeriodo } from '../../utils/Functions';

function Periodos(props) {
    const alert = useAlert();			// Para las notificaciones
    const {ContextMenuTrigger} = Menu
    const [showForm, setShowForm] = useState(false);
    const [showEliminarPeriodo, setShowEliminarPeriodo] = useState(false);
    const [textoBuscar, setTextoBuscar] = useState('');
    const hoy = new Date();
    const fechaCierre = deltaPeriodo(hoy, 1);
    const periodoDefault = {
        "numero":"",
        "inicio": `${hoy.getFullYear()}-${hoy.getMonth()+1}-1`,
        "cierre": `${fechaCierre.getDate()}/${fechaCierre.getMonth()+1}/${fechaCierre.getFullYear()}`,
        "abierto":true,
        "superficie_util":"",
        "mano_obra_permanente":""
    }
    const [periodoForm, setPeriodoForm] = useState(periodoDefault);


    const AbiertoFormatter = ({ value }) => {
        return ((value === true) ? 'Abierto' : 'Cerrado');
    };



    const columns = [

        {key: 'numero', name: 'Numero', width: 50},
        {key: 'inicio', name: 'Inicio', formatter: DateFormatter},
        {key: 'cierre', name: 'Cierre', formatter: DateFormatter},
        {key: 'abierto', name: 'Estado', formatter: AbiertoFormatter},
        {key: 'superficie_util', name: 'Superficie Silvoagropecuaria'},
        {key: 'mano_obra_permanente', name: 'Mano Obra Permanente'}
     ];

    const [rows, setRows] = useState([]);

    const contexto = useContext(UserContext);

    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                obtenerDatos(contexto.predio.predio_id);
            }
        },
        [contexto]
    )

    async function obtenerDatos(predio) {
        let resultado = await Api(`api/periodo/listado?predio_id=${predio}`);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
        } else {
            alert.show('Error al cargar periodos', { type: 'error' });
            setRows([]);
        }
    }

    async function retornoBusqueda(texto) {
		let resultado = await Api(`api/periodo/listado?predio_id=${contexto.predio.predio_id}&texto=${texto}`);
		if (resultado && resultado.status === 200) {
			setRows(resultado.data.datos);
		} else {
            setRows([])
			alert.show('Error al cargar periodos', { type: 'error' });
		}
	}

    function nuevoDato() {
        let actPeriodoDefault = {
            ...periodoDefault,
            numero: rows[rows.length -1].numero + 1
        }
        setPeriodoForm(actPeriodoDefault);
        setShowForm(true);
    }

    const editRow = rowIdx => {
        new Promise(function(resolve, reject){
			getPeriodo(rows[rowIdx].id).then(function(periodo){
                
                let fechaCierre = periodo.cierre.split("-");
                setPeriodoForm({
                    ...periodo,
                    cierre: `${fechaCierre[2]}/${fechaCierre[1]}/${fechaCierre[0]}`
                });
				setShowForm(true);
			})
		})
    };

    const deleteRow = rowIdx => {
        new Promise(function(resolve, reject){
			getPeriodo(rows[rowIdx].id).then(function(periodo){
                setPeriodoForm(periodo);
				setShowEliminarPeriodo(true);
			})
		})
    };

    function agregarPeriodo(){
        setShowForm(false);
        obtenerDatos(contexto.predio.predio_id);
    }

    function cerrarModalEliminar() {
        setShowEliminarPeriodo(false);
        obtenerDatos(contexto.predio.predio_id);
	}


    return (
        <Layout title="Periodos">
            <Row style={{paddingBottom:'10px'}}>
                <Col>
                    <Buscador retornoBusqueda={retornoBusqueda} busqueda={textoBuscar} placeholder='Búsqueda por razón social o rut...'/>
                </Col>
                <Col>
                    <ButtonGroup className="float-right">
                        <Button
                            variant="success"
                            size="sm"
                            onClick={nuevoDato}
                            hidden>Agregar Periodo</Button>
                        <DefaultButtons />
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <BaseGrid
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            minHeight={400}
                            contextMenu={
                                <GridMenu
                                    id="gridUsuariosPredio"
                                    options={ [
                                        {texto:"Editar",
                                        funcion: (e, { rowIdx }) => editRow(rowIdx)},
                                        {texto:"Eliminar",
                                        funcion: (e, { rowIdx }) => deleteRow(rowIdx)}
                                    ]}
                                />
                            }
                            RowsContainer={ContextMenuTrigger}
                            onGridSort={(sortColumn, sortDirection) =>
                                setRows(sortRows(rows, sortColumn, sortDirection))
                            }
                            emptyRowsView={EmptyRowsView}/>
                    </div>
                </Col>
            </Row>
            <FormPeriodo
                show={showForm}
                onHide={() => setShowForm(false)}
                periodo={periodoForm}
                funcionRetorno={function(data){agregarPeriodo()}}/>
            <FormEliminarPeriodo
				show={showEliminarPeriodo}
				onHide={cerrarModalEliminar}
				periodo={periodoForm}
				funcionRetorno={function(data){cerrarModalEliminar()}}
				size="md"
			/>
        </Layout>
    );
}

export default Periodos;