import React, {useState, useContext, useEffect, useRef} from 'react';
import {useAlert} from 'react-alert';
import BaseGrid from '../../componentes/grid/BaseGrid';
import UserContext from '../../UserContext';
import Layout from '../../componentes/Layout.js';
import {NumberFormatter, DecimalFormatter, PercentFormatter} from '../../componentes/grid/utils';
import DefaultButtons from '../../componentes/grid/DefaultButtons'
import {Button, Row, Col, ButtonGroup, Form, InputGroup} from 'react-bootstrap';
import {Api} from '../../utils/api';
import { exportar, downloadBlob, getDMY, fechaHoy} from '../../utils/Functions';
import { IndicadoresPdf } from './IndicadoresPdf';

function Indicadores(props) {

    const contexto = useContext(UserContext);
    const alert = useAlert();			// Para las notificaciones
    const [fechaExportar] = useState(fechaHoy());

    const columns = [
        {key: 'indicador', name: 'Indicador'},
        {key: 'presupuesto', name: 'Presupuesto', formatter: DecimalFormatter},
        {key: 'ejercicio', name: 'Fin Ejercicio', formatter: DecimalFormatter},
        {key: 'desviacion', name: 'Desviación', formatter: PercentFormatter},
     ];

    const [rows, setRows] = useState([]);


    async function getDatos() {
        let resultado = await Api(`api/reportes/gestion/indicadores/${contexto.predio.predio_id}/${contexto.predio.periodo.id}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.indicadores);
        } else {
            alert.show('Error al cargar datos', { type: 'error' });
            setRows([]);
        }
    }

    useEffect(
        () => {
            if (contexto.predio, contexto.predio.periodo) {
                getDatos();
            }
        },
        [contexto]
    )

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        exportar(`api/exportar/reportes/gestion/indicadores/${contexto.predio.predio_id}/${contexto.predio.periodo.id}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf() {
        let props = {
            columns: columns,
            rows: rows,
            titulo: 'Indicadores Financieros',
            bajada: contexto.predio.nombre_predio,
            subtitulo: `Inicio: ${contexto.predio.periodo ? getDMY(contexto.predio.periodo.inicio) : ''}, Cierre: ${contexto.predio.periodo ? getDMY(contexto.predio.periodo.cierre) : ''}`
        }
        const periodo = `inicio_${getDMY(contexto.predio.periodo.inicio).replace(/ /g, '_').replaceAll('/', '-')}_fin_${getDMY(contexto.predio.periodo.cierre).replace(/ /g, '_').replaceAll('/', '-')}`
        alert.show('La descarga comenzará en breve', { type: 'success' });
        new Promise(function (resolve, reject) {
            IndicadoresPdf(props).then(
                function (blob) {
                    downloadBlob(blob, `Indicadores_Financieros_${contexto.predio.nombre_predio.replace(/ /g, '_')}_${periodo}_${getDMY(fechaExportar)}.pdf`);
                }, function (blob) {
                    alert.show('Error al exportar', { type: 'error' })
                })
        })
    }
    return (
        <Layout title="Indicadores Financieros">
            <Form>
                <Form.Row style={{paddingBottom:'10px'}}>
                    <Col md={8}>
                    </Col>
                    <Col md={4}>
                        <ButtonGroup className="float-right">
                            <DefaultButtons exportar={exportarExcel} />
                            <DefaultButtons imprimir={exportarPdf} />
                        </ButtonGroup>
                    </Col>
                </Form.Row>
            </Form>
            <div>
                <BaseGrid
                    columns={columns}
                    rowGetter={i => rows[i]}
                    rowsCount={rows.length}
                    minHeight={400}
                    showCountRows={false}
                    rowStyle={[{
                        columna: 'indicador',
                        valor: 'RESULTADO',
                        clases: 'titulo-grilla',
                        css: { color: 'black', fontWeight: '700' }
                    }, {
                        columna: 'indicador',
                        valor: "PRODUCTIVIDAD ECONÓMICA",
                        clases: 'row-totales',
                        css: { color: 'black', fontWeight: '700' }
                    },{
                        columna: 'indicador',
                        valor: 'EFICIENCIA ECONÓMICA',
                        clases: 'titulo-grilla',
                        css: {color: 'black', fontWeight: '700' }
                    },{
                        columna: 'indicador',
                        valor: 'LÍQUIDEZ',
                        clases: 'titulo-grilla',
                        css: {color: 'black', fontWeight: '700' }
                    },{
                        columna: 'indicador',
                        valor: 'SOLVENCIA',
                        clases: 'titulo-grilla',
                        css: {color: 'black', fontWeight: '700' }
                    },{
                        columna: 'indicador',
                        valor: 'RENTABILIDAD',
                        clases: 'titulo-grilla',
                        css: {color: 'black', fontWeight: '700' }
                    }]}/>
            </div>
        </Layout>
    );
}

export default Indicadores;