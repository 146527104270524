import React, {useState, useEffect, useContext} from 'react';
import { Navbar, Nav, Form, NavDropdown,Image} from 'react-bootstrap';
import {withRouter, Link} from 'react-router-dom';
import SelectorPredio from '../SelectorPredio';
import UserContext from '../../UserContext'
import MenuUsuario from './MenuUsuario';
import icono_conga from '../inicio/images/Group.png'

function MenuAdmin(props) {
    const style_header = {
        background: 'linear-gradient(178.18deg, #20B099 18.81%, #1C8AB7 108.17%, #1A74C8 158.3%)',

    };
    const style_titulo = {
        fontStyle:'normal',
        fontWeight:'700 !important',
        fontSize:33,
        textAlign:'center',
        color:'#FFFFFF'
    }
    const contexto = useContext(UserContext)
    const [mostrarSelectorPredio, setMostrarSelectorPredio] = useState(false);

    useEffect(
        () => {
            if (!contexto.predio.predio_id) {
                setMostrarSelectorPredio(true);
            }
        },
        [contexto]
    )

    const logout = () => {
        props.history.push('/logout');
    }


    return (
        <Navbar style={style_header} variant="dark" expand="lg" >
            <Navbar.Brand href="#home">
                Administración
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto" style={{alignItems:'center'}}>
                    <Nav.Link as={Link} to='/presupuesto/actividad'>
                        <Image src={icono_conga} />
                        <span style={style_titulo}>  Conga  </span>
                    </Nav.Link>
                    <Nav.Link as={Link} to='/administracion/usuarios'>Usuarios</Nav.Link>
                    <Nav.Link as={Link} to='/administracion/predios/listado'>Predios</Nav.Link>
                    <NavDropdown title="Cuentas" id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to='/administracion/categoria-cuentas'>Categorías</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/administracion/cuentas'>Cuentas Base</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Actividades" id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to='/administracion/categoria-actividades'>Categorías</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/administracion/actividades'>Actividades Base</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link as={Link} to='/administracion/unidades'>Unidades</Nav.Link>
                    <Nav.Link as={Link} to='/administracion/impuestos'>Impuestos</Nav.Link>
                    <Nav.Link as={Link} to='/administracion/cooperativas/listado'>Cooperativas</Nav.Link>
                </Nav>

                <Form inline>
                    <MenuUsuario />
                </Form>
            </Navbar.Collapse>
            <SelectorPredio
                show={mostrarSelectorPredio}
                hide={() => setMostrarSelectorPredio(false)}
                logout={logout}
                backdrop="static"
                keyboard={false}
                >
            </SelectorPredio>
        </Navbar>
    );
}

export default withRouter(MenuAdmin);