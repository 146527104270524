import React from 'react';
import {BaseStylesPdf} from '../../../../componentes/pdf/BaseStylesPdf';
import {BaseLinkPdf} from '../../../../componentes/pdf/BaseLinkPdf';
import {Table, TableHeader, TableBody, TableCell, DataTableCell} from '@david.kucsai/react-pdf-table';
import { separadorDeMilesOrVacio, DateFormatterField } from '../../../../componentes/grid/utils';

export function LibroMayorPdf(props) {
    const styles = BaseStylesPdf();
    var tablaPresupuestoActividad = '';

    try {
        tablaPresupuestoActividad =(
            <>
            <Table data={props.rows}>
                <TableHeader textAlign={"center"}>
                    {/* el "weighting" idealmente debe sumar 1 para que quede todo ordenado */}
                    <TableCell style={styles.tableHeader} weighting={0.099}>Fecha</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Folio</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.311}>Glosa</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Cantidad</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Unidad</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>$/Unidad</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Débito</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Crédito</TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{ textAlign: "center", fontSize: 10, fontWeight: 700 }} weighting={0.1} getContent={(r) => DateFormatterField(r.documento_fecha)} />
                    <DataTableCell style={{ textAlign: "left", fontSize: 10, fontWeight: 700 }} weighting={0.1} getContent={(r) => r.documento_folio} />
                    <DataTableCell style={{ textAlign: "left", fontSize: 10, fontWeight: 700 }} weighting={0.3} getContent={(r) => (r.glosa)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.movimiento_cantidad)} />
                    <DataTableCell style={{ textAlign: "center", fontSize: 10, fontWeight: 700 }} weighting={0.1} getContent={(r) => (r.movimiento_unidad)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.precio_unidad)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.debito)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.credito)} />
                </TableBody>
            </Table>
            <Table data={props.rowsTotales}>
                <TableHeader textAlign={"center"}>
                    <TableCell style={styles.tableHeader} weighting={0.525}> </TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Cantidad</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Unidad</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>$/Unidad</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Débito</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Crédito</TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.5} getContent={(r) => r.titulo} />
                    <DataTableCell style={{ textAlign: "center", fontSize: 10, fontWeight: 700 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.total_cantidad)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.1} getContent={(r) => (r.unidad)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.total_precio_unidad)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.total_debito)} />
                    <DataTableCell style={{ textAlign: "right", fontSize: 10, fontWeight: 700 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.total_credito)} />
                </TableBody>
            </Table>
            </>)
    } catch (error) {
        console.log("Error LibroMayorPdf", error);
    }

    let blob = BaseLinkPdf({...props, body:tablaPresupuestoActividad, orientation:"landscape"})

    return blob
}