import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../../UserContext';
import { Form, Button, Col, Modal, Row, Card } from 'react-bootstrap';
import { useForm, ErrorMessage } from 'react-hook-form'
import { FaSave, FaWindowClose } from 'react-icons/fa';
import SelectorActividad from '../../componentes/SelectorActividad';
import SelectorUnidades from '../../componentes/SelectorUnidades';
import SelectorCuenta from '../../componentes/SelectorCuenta';
import SelectorImpuestos from '../../componentes/SelectorImpuestos';
import { zeroFill} from '../../componentes/grid/utils';
import { tienePermisos } from '../../utils/Functions';

function FormMovimiento(props) {
    const contexto = useContext(UserContext);
    const { movimiento, ...rest } = props;
    const { register, handleSubmit, watch, errors, setValue } = useForm({
        defaultValues: {
            categoria_cuenta_id: '',
            nombre: '',
            codigo: '',
            habilitada: 'SI',
            depreciacion: '',
            impuesto_nombre:''
        }
    });

    const [cuentaProvee, setCuentaProvee] = useState('');
    const [cuentaRecibe, setCuentaRecibe] = useState('');
    const [actividad, setActividad] = useState('');
    const [impuesto, setImpuesto] = useState('');
    const [unidad, setUnidad] = useState('');

    useEffect(
        () => {
            if (movimiento) {
                let imp = movimiento.detalle_impuestos;
                let nombreImpuesto = ''
                if (imp && imp.length > 0) {
                    nombreImpuesto = imp[0].impuesto;
                }
                let combustible_recuperable = '';
                let combustible_no_recuperable = '';
                let tieneImpuestoCombustible = false;
                if (movimiento.otros_impuestos.length > 0) {
                    movimiento.otros_impuestos.forEach(otro_impuesto => {
                        if (Object.keys(otro_impuesto)[0] === 'combustible_recuperable') {
                            combustible_recuperable = otro_impuesto['combustible_recuperable'];
                            tieneImpuestoCombustible = true;
                        } else if (Object.keys(otro_impuesto)[0] === 'combustible_no_recuperable') {
                            combustible_no_recuperable = otro_impuesto['combustible_no_recuperable'];
                            tieneImpuestoCombustible = true;
                        }
                    });
                }
                if (!tieneImpuestoCombustible) {
                    if (movimiento.impuesto_adicional) {
                        tieneImpuestoCombustible = true;
                    }
                }
                setValue([
                    { glosa: movimiento.glosa },
                    { monto_total_neto: movimiento.monto_total_neto },
                    { unidad_id: movimiento.unidad_id },
                    { actividad_codigo: movimiento.actividad_codigo },
                    { cuenta_provee_codigo: movimiento.cuenta_provee_codigo },
                    { cuenta_recibe_codigo: movimiento.cuenta_recibe_codigo },
                    { cantidad: movimiento.cantidad },
                    { impuesto_nombre: nombreImpuesto },
                    { impuesto_especifico_combustible: tieneImpuestoCombustible },
                    { combustible_recuperable: combustible_recuperable },
                    { combustible_no_recuperable: combustible_no_recuperable },
                    { impuesto_adicional: movimiento.impuesto_adicional }
                ]);
            } else {
                // setGlobal(inicial);
                setImpuesto(''); // Para que cuando volvamos a abrir la modal no aparezca activa "Impuesto Feria Ganado"
            }
        },
        [movimiento]
    )

    useEffect(
        () => {
            // Calcula el impuesto feria ganado
            if (watch('monto_total_neto')) {
                let feria_ganado_iva_pagar = watch('monto_total_neto') * 0.11;
                let iva = watch('monto_total_neto') * 0.19;
                let feria_ganado_iva_retenido = iva - feria_ganado_iva_pagar;
                setValue([
                    { feria_ganado_iva_pagar: feria_ganado_iva_pagar },
                    { feria_ganado_iva_retenido: feria_ganado_iva_retenido }
                ]);
            } else {
                setValue([
                    { feria_ganado_iva_pagar: 0 },
                    { feria_ganado_iva_retenido: 0 }
                ]);
            }

        },
        [watch('monto_total_neto')]
    )

    /**
     * Cuando el formulario está validado
     *
     * @param {object} data
     * @returns
     */
    async function guardar(data) {
        // Si no tiene seleccionada la opción impuesto_especifico_combustible dejamos los otros impuestos vacios
        data.otros_impuestos = [];
        let otros_impuestos = Object.assign([], movimiento.otros_impuestos);

        if (data.impuesto_especifico_combustible) {
            // Por si tenemos varios tipos de otros impuestos solo actualizamos el que estamos ocupando
            let existe = false;
            otros_impuestos.forEach(otro_impuesto => {
                if (Object.keys(otro_impuesto)[0] === 'combustible_recuperable') {
                    otro_impuesto['combustible_recuperable'] = data.combustible_recuperable;
                    existe = true;
                } else if (Object.keys(otro_impuesto)[0] === 'combustible_no_recuperable') {
                    otro_impuesto['combustible_no_recuperable'] = data.combustible_no_recuperable;
                    existe = true;
                }
            });
            if (!existe) { // Como estos valores no existen los creamos
                otros_impuestos.push({ 'combustible_recuperable': data.combustible_recuperable });
                otros_impuestos.push({ 'combustible_no_recuperable': data.combustible_no_recuperable });
            }
        } else { // ya no tiene seleccionado checkbox impuesto combustible, debemos eliminar del json
            let eliminar = ['combustible_recuperable', 'combustible_no_recuperable'];
            let nuevosImpuestos = [];
            otros_impuestos.forEach(otro_impuesto => {
                if (eliminar.includes(Object.keys(otro_impuesto)[0]) === false) {
                    nuevosImpuestos.push(otro_impuesto);
                }
            });
            otros_impuestos = nuevosImpuestos;

            data.impuesto_adicional = 0;
        }

        // Cuando el impuesto seleccionado es Feria Ganado
        if (impuesto && impuesto.nombre === 'Feria Ganado') {
            // Si los tiene actualiza si no los crea
            let existe = false;
            otros_impuestos.forEach(otro_impuesto => {
                if (Object.keys(otro_impuesto)[0] === 'feria_ganado_iva_pagar') {
                    otro_impuesto['feria_ganado_iva_pagar'] = data.feria_ganado_iva_pagar;
                    existe = true;
                } else if (Object.keys(otro_impuesto)[0] === 'feria_ganado_iva_retenido') {
                    otro_impuesto['feria_ganado_iva_retenido'] = data.feria_ganado_iva_retenido;
                    existe = true;
                }
            });
            if (!existe) { // Como estos valores no existen los creamos
                otros_impuestos.push({ 'feria_ganado_iva_pagar': data.feria_ganado_iva_pagar });
                otros_impuestos.push({ 'feria_ganado_iva_retenido': data.feria_ganado_iva_retenido });
            }
        } else { // Deberiamos eliminar feria_ganado_iva_pagar y feria_ganado_iva_retenido en caso de tenerlos
            let eliminar = ['feria_ganado_iva_pagar', 'feria_ganado_iva_retenido'];
            let nuevosImpuestos = [];
            otros_impuestos.forEach(otro_impuesto => {
                if (eliminar.includes(Object.keys(otro_impuesto)[0]) === false) {
                    nuevosImpuestos.push(otro_impuesto);
                }
            });
            otros_impuestos = nuevosImpuestos;
        }

        data.otros_impuestos = otros_impuestos;
        data.predio_id = contexto.predio.predio_id;
        if (movimiento) { // editar
            data.id = movimiento.id;
        } else { // Crear
            // Creamos un id temporal, este es actualizado a vacio antes de enviar, utilizado para actualizar
            let newId = new Date();
            newId = newId.getTime();
            data.id = newId;
            data.temporal = true;
        }
        data.cuenta_provee_nombre_combinado = data.cuenta_provee_codigo + ' ' + cuentaProvee.nombre;
        data.cuenta_recibe_nombre_combinado = data.cuenta_recibe_codigo + ' ' + cuentaRecibe.nombre;
        data.actividad_nombre_combinado = zeroFill(data.actividad_codigo, 4) + ' ' + actividad.nombre;
        data.actividad_nombre = actividad.nombre;
        data.cuenta_provee_nombre = cuentaProvee.nombre;
        data.cuenta_recibe_nombre = cuentaRecibe.nombre;
        data.detalle_impuestos = [{
            impuesto: data.impuesto_nombre,
            tasa: impuesto.tasa
        }]
        let impuestoCalculado = parseFloat(data.monto_total_neto) * parseFloat(data.detalle_impuestos[0].tasa);
        data.monto_total_bruto = parseFloat(data.monto_total_neto) + impuestoCalculado + (data.impuesto_adicional ? parseFloat(data.impuesto_adicional) : 0);
        data.monto_total_impuestos = impuestoCalculado + (data.impuesto_adicional ? parseFloat(data.impuesto_adicional) : 0); // sumamos los otros impuestos a los combustibles(total)
        data.unidad_nombre = unidad.nombre;
        data.unidad_abreviacion = unidad.abreviacion;
        cerrarModal(data, (movimiento ? 'editar': 'crear'));
    }

    /**
     * El callback cerrar modal le pasamos el elemento creado, si no vacio
     *
     * @param {object} data
     * @param {string} tipo
     */
    function cerrarModal(data, tipo) {
        props.onHide(data, tipo);
    }

    /**
     * Se activa en los siguientes casos
     * Cuando seleccionamos un elemento del combobox
     * Cuando presionamos el botón borrar
     * Cuando borramos un caracter del combobox y ya no tenemos ninguna opción valida seleccionada
     * Siempre que deseemos actualizar un estado dentro de esta función(cualquiera del formuario) debemos actualizar el campo oculto del combobox en caso de ser vacio
     * @param {array} data
     */
    function returnSelectorActividad(data) {
        if (data.length === 0) {
            setValue([
                { actividad_codigo: '' },
            ]);
            setActividad('');
        } else {
            setActividad(data[0]);
        }
    }

    /**
     * Se activa en los siguientes casos
     * Cuando seleccionamos un elemento del combobox
     * Cuando presionamos el botón borrar
     * Cuando borramos un caracter del combobox y ya no tenemos ninguna opción valida seleccionada
     * Siempre que deseemos actualizar un estado dentro de esta función(cualquiera del formuario) debemos actualizar el campo oculto del combobox en caso de ser vacio
     * @param {array} data
     */
    function returnSelectorCuentaProvee(data) {
        if (data.length === 0) {
            setValue([
                { cuenta_provee_codigo: '' },
            ]);
            setCuentaProvee('');
        } else {
            setCuentaProvee(data[0]);
        }
    }

    /**
     * Se activa en los siguientes casos
     * Cuando seleccionamos un elemento del combobox
     * Cuando presionamos el botón borrar
     * Cuando borramos un caracter del combobox y ya no tenemos ninguna opción valida seleccionada
     * Siempre que deseemos actualizar un estado dentro de esta función(cualquiera del formuario) debemos actualizar el campo oculto del combobox en caso de ser vacio
     * @param {array} data
     */
    function returnSelectorCuentaRecibe(data) {
        if (data.length === 0) {
            setValue([
                { cuenta_recibe_codigo: '' },
            ]);
            setCuentaRecibe('');
        } else {
            setCuentaRecibe(data[0]);
        }
    }

    /**
     * Se activa en los siguientes casos
     * Cuando seleccionamos un elemento del combobox
     * Cuando presionamos el botón borrar
     * Cuando borramos un caracter del combobox y ya no tenemos ninguna opción valida seleccionada
     * Siempre que deseemos actualizar un estado dentro de esta función(cualquiera del formuario) debemos actualizar el campo oculto del combobox en caso de ser vacio
     * @param {array} data
     */
    function returnSelectorImpuestos(data) {
        if (data.length === 0) {
            setValue([
                { impuesto_nombre: '' },
            ]);
            setImpuesto('');
        } else {
            setImpuesto(data[0]);
        }
    }

    /**
     * Se activa en los siguientes casos
     * Cuando seleccionamos un elemento del combobox
     * Cuando presionamos el botón borrar
     * Cuando borramos un caracter del combobox y ya no tenemos ninguna opción valida seleccionada
     * Siempre que deseemos actualizar un estado dentro de esta función(cualquiera del formuario) debemos actualizar el campo oculto del combobox en caso de ser vacio
     * @param {array} data
     */
    function returnSelectorUnidad(data) {
        if (data.length === 0) {
            setValue([
                { impuesto_nombre: '' },
            ]);
            setUnidad('');
        } else {
            setUnidad(data[0]);
        }
    }

    return (
        <Modal {...rest} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>{movimiento ? 'Editar' : 'Agregar'} Movimiento</Modal.Title>
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit(guardar)}>
                <Modal.Body>
                    <Row>
                        <Form.Label column sm="3">Glosa:</Form.Label>
                        <Col sm="9">
                            <Form.Control
                                name="glosa"
                                type="text"
                                placeholder=""
                                size="sm"
                                ref={register({})}
                                // isInvalid={errors.glosa}
                                />
                            {/* <ErrorMessage errors={errors} name="glosa" as="div" className="invalid-feedback" /> */}
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="3">Monto Neto:</Form.Label>
                        <Col sm="9">
                            <Form.Control
                                name="monto_total_neto"
                                type="number"
                                placeholder=""
                                size="sm"
                                ref={register({
                                    required: "Por favor, ingrese el monto"
                                })}
                                isInvalid={errors.monto_total_neto} />
                            <ErrorMessage errors={errors} name="monto_total_neto" as="div" className="invalid-feedback" />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="3"> Actividad: </Form.Label>
                        <Col sm="9">
                            <SelectorActividad
                                id='1'
                                name="actividad_codigo"
                                register={register}
                                errors={errors}
                                inicial={watch('actividad_codigo')}
                                returnSelectorActividad={returnSelectorActividad}
                                actividadesUtilizadas={true}
                                placeholder=""
                                codigoMin=""
                                codigoMax=""/>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="3">Cta. Provee:</Form.Label>
                        <Col sm="9">
                            <SelectorCuenta
                                id='cuenta_provee_codigo'
                                // codigoMin={"100001"}
                                // codigoMax={"599999"}
                                name="cuenta_provee_codigo"
                                register={register}
                                errors={errors}
                                inicial={watch('cuenta_provee_codigo')}
                                retornar={returnSelectorCuentaProvee}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="3">Cta. Recibe:</Form.Label>
                        <Col sm="9">
                            <SelectorCuenta
                                id='cuenta_recibe_codigo'
                                // codigoMin={"100001"}
                                // codigoMax={"599999"}
                                name="cuenta_recibe_codigo"
                                register={register}
                                errors={errors}
                                inicial={watch('cuenta_recibe_codigo')}
                                retornar={returnSelectorCuentaRecibe}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="3">Unidad: </Form.Label>
                        <Col sm="9">
                            <SelectorUnidades
                                id='2'
                                register={register}
                                name="unidad_id"
                                errors={errors}
                                inicial={watch('unidad_id')}
                                retornar={returnSelectorUnidad}
                                // returnSelectorUnidades={returnSelectorUnidades}
                                placeholder=""/>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="3">Cantidad:</Form.Label>
                        <Col sm="9">
                            <Form.Control
                                name="cantidad"
                                type="number"
                                placeholder=""
                                size="sm"
                                ref={register({})}/>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="3">Impuesto: </Form.Label>
                        <Col sm="9">
                            <SelectorImpuestos
                                id='impuesto_nombre'
                                register={register}
                                name="impuesto_nombre"
                                errors={errors}
                                inicial={watch('impuesto_nombre')}
                                retornar={returnSelectorImpuestos}
                                placeholder=""
                                movimiento_id={movimiento.id}
                            />
                        </Col>
                    </Row>

                    <Card hidden={(impuesto ? (impuesto.nombre === 'Feria Ganado' ? false : true) : true)} style={{marginBottom:'10px', marginTop: '10px'}}>
                        <Card.Header>Impuesto Feria Ganado</Card.Header>
                        <Card.Body style={{padding:'5px'}}>
                            <Row hidden={(impuesto ? (impuesto.nombre === 'Feria Ganado' ? false : true) : true)}>
                                <Form.Label column sm="3">IVA a pagar:</Form.Label>
                                <Col sm="9">
                                    <Form.Control
                                        name="feria_ganado_iva_pagar"
                                        type="number"
                                        placeholder=""
                                        size="sm"
                                        disabled
                                        ref={register({
                                            required: watch('feria_ganado_iva_pagar') ? 'Debe ingresar el valor' : false
                                        })}
                                        isInvalid={errors.feria_ganado_iva_pagar}
                                    />
                                    <ErrorMessage errors={errors} name="feria_ganado_iva_pagar" as="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                            <Row hidden={(impuesto ? (impuesto.nombre === 'Feria Ganado' ? false : true) : true)}>
                                <Form.Label column sm="3">IVA retenido:</Form.Label>
                                <Col sm="9">
                                    <Form.Control
                                        name="feria_ganado_iva_retenido"
                                        type="number"
                                        placeholder=""
                                        size="sm"
                                        disabled
                                        ref={register({
                                            required: watch('feria_ganado_iva_retenido') ? 'Debe ingresar el valor' : false
                                        })}
                                        isInvalid={errors.feria_ganado_iva_retenido}
                                    />
                                    <ErrorMessage errors={errors} name="feria_ganado_iva_retenido" as="div" className="invalid-feedback" />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Row>
                        <Col sm="9">
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check
                                    type="checkbox"
                                    name="impuesto_especifico_combustible"
                                    label="Impuesto específico combustibles"
                                    ref={register({})}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row hidden={!watch('impuesto_especifico_combustible')}>
                        <Form.Label column sm="3">IE Base:</Form.Label>
                        <Col sm="9">
                            <Form.Control
                                name="combustible_recuperable"
                                type="number"
                                placeholder=""
                                size="sm"
                                ref={register({})}
                                // ref={register({
                                //     required: watch('impuesto_especifico_combustible') ? 'Debe ingresar el valor' : false
                                // })}
                                isInvalid={errors.combustible_recuperable}
                                />
                                <ErrorMessage errors={errors} name="combustible_recuperable" as="div" className="invalid-feedback" />
                        </Col>
                    </Row>
                    <Row hidden={!watch('impuesto_especifico_combustible')}>
                        <Form.Label column sm="3">IE Variable:</Form.Label>
                        <Col sm="9">
                            <Form.Control
                                name="combustible_no_recuperable"
                                type="number"
                                placeholder=""
                                size="sm"
                                ref={register({})}
                                // ref={register({
                                //     required: watch('impuesto_especifico_combustible') ? 'Debe ingresar el valor': false
                                // })}
                                isInvalid={errors.combustible_no_recuperable}
                                />
                                <ErrorMessage errors={errors} name="combustible_no_recuperable" as="div" className="invalid-feedback" />
                        </Col>
                    </Row>
                    <Row hidden={!watch('impuesto_especifico_combustible')}>
                        <Form.Label column sm="3">Impuesto adicional:</Form.Label>
                        <Col sm="9">
                            <Form.Control
                                name="impuesto_adicional"
                                type="number"
                                placeholder=""
                                size="sm"
                                ref={register({
                                    required: watch('impuesto_especifico_combustible') ? 'Debe ingresar el valor': false
                                })}
                                isInvalid={errors.impuesto_adicional}
                            />
                            <ErrorMessage errors={errors} name="impuesto_adicional" as="div" className="invalid-feedback" />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={(e) => cerrarModal('', 'cerrar')}><FaWindowClose /> Cerrar</Button>
                    {tienePermisos('editar',contexto.predio.perfil) &&
                        <Button type="submit" size="sm" variant="success"><FaSave /> guardar</Button>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default FormMovimiento;