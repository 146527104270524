import React, { useState, useEffect, useContext } from 'react';
import { useAlert } from 'react-alert';
import BaseGrid from '../../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import Layout from '../../../componentes/Layout';
import { NumberFormatter, sortRows, EmptyRowsView, PercentageFormatter } from '../../../componentes/grid/utils';
import { Api } from '../../../utils/api';
import UserContext from '../../../UserContext';
import { Row, Col, Form, ButtonGroup,Button } from 'react-bootstrap';
import Fecha from '../../../componentes/Fecha';
import { getYMD, getDMY, fechaHoy} from '../../../utils/Functions';
import DefaultButtons from '../../../componentes/grid/DefaultButtons';
import { exportar, downloadBlob } from '../../../utils/Functions';
// import { ControlCajaPermanentePDF } from './pdf/ControlCajaPermanentePDF';
// import ChartResumenDeMargenes from './ChartResumenDeMargenes';

function RentabilidadRubros(props) {
    const contexto = useContext(UserContext);
    const { ContextMenuTrigger } = Menu
    const alert = useAlert();
    const [rows, setRows] = useState([]);
    const [rowsTotales, setRowsTotales] = useState([]);
    const [fechaExportar] = useState(fechaHoy());
    const [showChart, setShowChart] = useState(false);
    const [jubilacion, setJubilacion] = useState(0);
    const [asignacion, setAsignacion] = useState("base_ingresos");
    const columns = [
        { key: 'codigo', name: 'Código', width: 60},
        { key: 'rubro', name: 'Actividad', width: 120},
        { key: 'pingreso_bruto', name: 'Ingreso', width: 90, formatter: NumberFormatter},
        { key: 'pcosto_directo', name: 'Cto. directo', width: 90, formatter: NumberFormatter },
        { key: 'pmargen', name: 'Margen Dir.', width: 90, formatter: NumberFormatter },
        { key: 'pasignacion', name: '% Asign.', width: 70, formatter: PercentageFormatter },
        { key: 'pa000_asignacion', name: '0000', width: 90, formatter: NumberFormatter },
        { key: 'pmargen_final', name: 'Margen', width: 90, formatter: NumberFormatter },
        { key: 'pinversion_asignacion', name: 'Inversión.', width: 90, formatter: NumberFormatter },
        { key: 'prentabilidad', name: 'Rent.', width: 50, formatter: PercentageFormatter },
        { key: 'mingreso_bruto', name: 'Ingreso', width: 90, formatter: NumberFormatter},
        { key: 'mcosto_directo', name: 'Cto. directo', width: 90, formatter: NumberFormatter },
        { key: 'mmargen', name: 'Margen Dir.', width: 90, formatter: NumberFormatter },
        { key: 'masignacion', name: '% Asign.', width: 70, formatter: PercentageFormatter },
        { key: 'ma000_asignacion', name: '0000', width: 90, formatter: NumberFormatter },
        { key: 'mmargen_final', name: 'Margen', width: 90, formatter: NumberFormatter },
        { key: 'minversion_asignacion', name: 'Inversión.', width: 90, formatter: NumberFormatter },
        { key: 'mrentabilidad', name: 'Rent.', width: 50, formatter: PercentageFormatter },
        { key: 'desviacion', name: 'Desv.', width: 70, formatter: PercentageFormatter },
    ];
    const titulos = [

        { key: '', name: '', width: 180  },
        { key: '', name: 'PRESUPUESTO', width: 660 },
        { key: '', name: 'OPERACIONES', width: 660 },
        { key: '', name: '', width: 70 },
    ];
    const columnsTotales = [
        { key: 'debito_balance', name: 'Cap. Total', width: 90, formatter: NumberFormatter },
        { key: 'capital_propio', name: 'Cap. Propio', width: 90, formatter: NumberFormatter },
        { key: 'psaldo_0000', name: 'Saldo 000', width: 165, formatter: NumberFormatter },
        { key: 'pmargen_total_rubros', name: 'Margen Rubros', width: 165, formatter: NumberFormatter },
        { key: 'prent_cap_propio', name: 'Rent. Cap. Propio', width: 165, formatter: PercentageFormatter },
        { key: 'prent_cap_total', name: 'Rent. Cap. Total', width: 165, formatter: PercentageFormatter },
        { key: 'msaldo_0000', name: 'Saldo 000', width: 165, formatter: NumberFormatter },
        { key: 'mmargen_total_rubros', name: 'Margen Rubros', width: 165, formatter: NumberFormatter },
        { key: 'mrent_cap_propio', name: 'Rent. Cap. Propio', width: 165, formatter: PercentageFormatter },
        { key: 'mrent_cap_total', name: 'Rent. Cap. Total', width: 165, formatter: PercentageFormatter },
        { key: 'desviacion', name: 'Desv', width: 70, formatter: PercentageFormatter },
    ];
    const [fecha, setFecha] = useState('');

    function chart() {
        setShowChart(true);
    }
    useEffect(
        () => {
            if (contexto.predio.predio_id && contexto.predio.periodo.cierre) {
                if (contexto.predio.periodo.cierre === fecha) {
                    obtenerDatos();
                }
                setFecha(contexto.predio.periodo.cierre);
            } else {
                setRows([]);
                setRowsTotales([]);
            }
        },
        [contexto]
    )

    useEffect(
        () => {
            if (fecha && asignacion) {
                obtenerDatos();
            }
        },
        [fecha, asignacion]
    )

    /**
     * Cargar información de la grilla
     */
    async function obtenerDatos() {
        let resultado = await Api(`api/contabilidad/rentabilidad-rubros?predio_id=${contexto.predio.predio_id}&periodo_id=${contexto.predio.periodo.id}&asignacion=${asignacion}&fecha_cierre=${fecha}&jubilacion=${jubilacion}`);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos.rubros);
            setRowsTotales(resultado.data.datos.totales);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            setRows([]);
            setRowsTotales([]);
        }
    }

    /**
     * Cuando cambian la fecha
     *
     * @param {date} e
     */
    function onChangeCierre(e) {
        let fecha = getYMD(e);
        setFecha(fecha);
    }

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        exportar(`api/exportar/contabilidad/rentabilidad-rubros?predio_id=${contexto.predio.predio_id}&periodo_id=${contexto.predio.periodo.id}&jubilacion=${jubilacion}&asignacion=${asignacion}&fecha_cierre=${fecha}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf() {

    }
    return (
        <Layout title="Rentabilidad por rubro">
            <Row style={{ marginBottom: '10px' }}>
                <Col sm={6} md={4} lg={2} id="cont-jubilacion">
                    <Form.Label>Jubilación</Form.Label>
                    <Form.Control
                        name="jubilacion"
                        value={jubilacion}
                        onChange={(e) => {
                            setJubilacion(e.target.value);
                        }}
                        type="number"
                        placeholder=""
                        size="sm" />
                </Col>
                <Col sm={6} md={4} lg={2} id="cont-asignacion">
                    <Form.Label>Asignación</Form.Label>
                    <Form.Control as="select"
                        name="asignacion"
                        size="sm"
                        onChange={(e) => {
                            setAsignacion(e.target.value);
                            // obtenerDatos();
                        }}>
                        <option value="base_ingresos">Base ingresos</option>
                        <option value="base_costos">Base Costos</option>
                    </Form.Control>
                </Col>
                <Col sm={6} md={4} lg={2} id="cont-fecha-desde">
                    <Form.Label>Fecha de Cierre</Form.Label>
                    <Fecha
                        name='inicio'
                        dateFormat='dd/MM/yyyy' // dd/MM/Y h:mm aa - dd/MM/Y - MM/Y
                        dateFormatOculto='y-m-d' // y-m-d h:i:s
                        inicial={fecha}
                        size="sm"
                        onChange={onChangeCierre}
                    />
                </Col>
                {/* <Col sm={2} md={2} lg={2}>
                    <Form.Label>&nbsp;</Form.Label>
                    <Button variant="primary" size="sm"
                        onClick={(e) => {
                            obtenerDatos();
                        }}>Go</Button>
                </Col> */}
                <Col sm={6} md={6} lg={6} style={{ paddingTop: '32px', textAlign: 'right' }}>
                    <ButtonGroup className="float-right">
                        {/* <Button
                            variant="info"
                            size="sm"
                            onClick={chart}>Gráfico</Button> */}
                        <DefaultButtons exportar={exportarExcel} />
                        <DefaultButtons imprimir={exportarPdf} />
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <BaseGrid
                            columns={titulos}
                            rowGetter={i => rowsTotales[i]}
                            rowsCount={1}
                            minHeight={35}
                            RowsContainer={ContextMenuTrigger}
                            emptyRowsView={EmptyRowsView}
                            showCountRows={false} />
                    </div>
                    <div>
                        <BaseGrid
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            minHeight={200}
                            RowsContainer={ContextMenuTrigger}
                            onGridSort={(sortColumn, sortDirection) =>
                                setRows(sortRows(rows, sortColumn, sortDirection))
                            }
                            emptyRowsView={EmptyRowsView}
                            showCountRows={false} />
                    </div>
                    <div>
                        <BaseGrid
                            columns={titulos}
                            rowGetter={i => rowsTotales[i]}
                            rowsCount={1}
                            minHeight={35}
                            RowsContainer={ContextMenuTrigger}
                            emptyRowsView={EmptyRowsView}
                            showCountRows={false} />
                    </div>
                    <div>
                        <BaseGrid
                            columns={columnsTotales}
                            rowGetter={i => rowsTotales[i]}
                            rowsCount={rowsTotales.length}
                            minHeight={142}
                            showCountRows={false} />
                    </div>
                </Col>
            </Row>
            {/* <ChartResumenDeMargenes
                rows={rows}
                show={showChart}
                onHide={() => setShowChart(false)} /> */}
        </Layout>
    );
}

export default RentabilidadRubros;