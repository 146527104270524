import React, {useEffect, useRef} from 'react';
import { Form, Button, Col, Modal, Row} from 'react-bootstrap';
import { useForm, ErrorMessage } from 'react-hook-form';
import { useAlert } from 'react-alert';
import {Api, ObjectToFormdata} from '../../utils/api';
import { FaSave, FaWindowClose, FaPlus } from 'react-icons/fa';


export function FormularioUnidad(props){
    const { register, handleSubmit, errors, setValue } = useForm();
    const alert = useAlert();           // Para las notificaciones

    const {unidad, funcionRetorno, ...rest} = props;
  
    const onSubmit = data => { 
        enviarFormulario(data);
    }

    useEffect(
        () => {
            if (unidad) {
                setValue([
                    {id:unidad.id},
                    {nombre: unidad.nombre},
                    {superficie_util:unidad.superficie_util},
                    {mano_obra_permanente:unidad.mano_obra_permanente},
                    {habilitada: (unidad.habilitada ? 'SI' : 'NO')}
                ]);
            }
        },
        [unidad]
    )

    async function enviarFormulario(data){
        data = ObjectToFormdata(data);
        let resultado = await Api('api/administracion/unidades/guardar', data, { 'Content-Type': 'application/json' }, true, 'post');
		if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            if (props.funcionRetorno) {
                props.funcionRetorno(data);
            }
		} else {
            alert.show(resultado.data.msg, { type: 'error' });
            //props.funcionRetorno(data);
		}
    }

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Col sm="12">
                <Form.Row>
                    <Form.Control type="hidden" name="id" defaultValue={unidad.id} ref={register({})}></Form.Control>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label>Nombre Unidad</Form.Label>
                        <Form.Control type="text" placeholder="Nombre..." name="nombre" defaultValue={unidad.nombre}
                            ref={register({ 
                                required: "Por favor, ingrese el nombre de la unidad", 
                                minLength: {value: 4,message: "Largo mínimo 4 caracteres"},
                                maxLength: {value: 100, message: "Largo máximmo 100"}
                            })}
                            isInvalid={errors.nombre} size="sm" />
                        <ErrorMessage errors={errors} name="nombre" as="div" className="invalid-feedback" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                        <Form.Label>Abreviación</Form.Label>
                        <Form.Control type="text" placeholder="Abreviación..." name="abreviacion" 
                            defaultValue={unidad.abreviacion}
                            ref={register({ 
                                minLength: {value: 1,message: "Largo mínimo 1 caracter"},
                                maxLength: {value: 4, message: "Largo máximo 4 caracteres"}
                            })}
                            isInvalid={errors.abreviacion} size="sm" />
                        <ErrorMessage errors={errors} name="abreviacion" as="div" className="invalid-feedback" />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                        <Form.Label column sm="3">Habilitada:</Form.Label>
                        <Col sm="9">
                            <Form.Check name='habilitada' inline type='radio' label={`Sí`} id={`habilitada_si`}
                                value={'SI'} ref={register({ required: true })} />
                            <Form.Check name='habilitada' inline type='radio' label={`No`} id={`habilitada_no`}
                                value={'NO'} ref={register({ required: true })} />
                        </Col>
                    </Form.Group>
                </Form.Row>
            </Col>
            <Button type="submit" size="sm" variant="success" ref={props.fordwardRef} hidden><FaSave/> Guardar</Button>
        </Form>
    )
}

export function ModalFormularioUnidad(props) {
    
    const refSubmitButton = useRef();
    const {unidad, funcionRetorno, ...rest} = props;
    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Agregar unidad
            </Modal.Header>
            
            <Modal.Body>
                <FormularioUnidad unidad={unidad} funcionRetorno={funcionRetorno} fordwardRef={refSubmitButton}></FormularioUnidad>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.onHide}><FaWindowClose/> Cerrar</Button>
                <Button onClick={(e) => refSubmitButton.current.click()} size="sm" variant="success"><FaSave/> Guardar</Button>
            </Modal.Footer>
            
        </Modal>
        
    )
}

export async function getUnidad(unidadId){
    let resultado = await Api('api/administracion/unidades/'+unidadId, null, {}, true);
    return resultado.data.unidad;
}

export function FormularioEliminarUnidad(props) {

    const {unidad, funcionRetorno, ...rest} = props;
    const alert = useAlert();           // Para las notificaciones

    async function eliminarUnidad(data){
        //data = ObjectToFormdata(data);
        let resultado = await Api('api/administracion/unidades/eliminar/'+data.id,null,{},true,'delete');
		if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            props.funcionRetorno(data);
		} else {
            alert.show(resultado.data.msg, { type: 'error' });
            //props.funcionRetorno(data);
		}
    }

    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Eliminar unidad
            </Modal.Header>
            <Modal.Body>
                <Col sm="12">
                ¿Está seguro de eliminar la unidad {unidad.nombre}?
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.onHide}>
                    Cerrar
                    </Button>
                    <Button variant="danger" size="sm" 
                        onClick={(e) => eliminarUnidad(unidad)}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
