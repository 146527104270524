import React, {useState, useEffect, useContext} from 'react';
import BaseGrid from '../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import GridMenu from '../../componentes/grid/GridMenu';
import LayoutAdmin from '../../componentes/LayoutAdmin';
import {LongTextFormatter, sortRows, EmptyRowsView, CheckFormatter} from '../../componentes/grid/utils';
import {Api} from '../../utils/api';
import UserContext from '../../UserContext';
import { Button, Row, Col, ButtonGroup } from 'react-bootstrap';
import DefaultButtons from '../../componentes/grid/DefaultButtons'
import Buscador from '../../componentes/Buscador';
import { useAlert } from 'react-alert';
import {ModalFormularioUnidad, getUnidad, FormularioEliminarUnidad } from '../../modulos/administracion/FormularioUnidad';


function Unidades(props) {

    const {ContextMenuTrigger} = Menu;
    
    const [textoBuscar, setTextoBuscar] = useState('');
	const alert = useAlert();			// Para las notificaciones


	const columns = [
		{ key: 'nombre', name: 'Nombre', sortable: true },
		{ key: 'abreviacion', name: 'Abreviación', formatter: LongTextFormatter, sortable: true },
		{ key: 'habilitada', name: 'Habilitado', formatter: CheckFormatter }
    ];
    
	const [rows, setRows] = useState([]);
	const contexto = useContext(UserContext);

	const [activarModalAgregarUnidad, setActivarModalUnidad] = useState(false);			// Modal para agregar unidad
	const [activarModalEliminarUnidad, setActivarModalEliminarUnidad] = useState(false);			// Modal para eliminar unidad
    const [unidadForm, setUnidadForm] = useState(
		{
			"id":"",
			"nombre": "",
			"abreviacion":"",
			"habilitada":true,
		});

	useEffect(
		() => {
			cargarUnidades();
		},
		[contexto]
	)

	/**
	 * Buscamos y cargamos todas las unidades en la grilla
	 */
	async function cargarUnidades() {

		let resultado = await Api('api/administracion/unidades', null, {}, true);
		if (resultado && resultado.status === 200) {
			setRows(resultado.data.datos);
		} else {
			alert.show('Error al cargar unidades', { type: 'error' });
			setRows([]);
		}
    }
    
    /**
     * Función para búsqueda específica de unidades
     * 
     * @param {String} texto 
     */
    async function retornoBusqueda(texto) {
		let resultado = await Api(`api/administracion/unidades?texto=${texto}`, null, {}, true);
		if (resultado && resultado.status === 200) {
			setRows(resultado.data.datos);
		} else {
            setRows([])
			alert.show('Error al cargar usuarios', { type: 'error' });
		}
	}

	/**
	 * Acción para desplegar el modal agregar unidad
	 */
	function nuevoDato() {
		setUnidadForm({
			"id":"",
			"nombre": "",
			"abreviacion":"",
			"habilitada":true,
		});
		setActivarModalUnidad(true);
	}

	/**
	 * Evento para cerrar el modal agregar unidad
	 */
	function cerrarModalAgregar() {
		cargarUnidades();
		setActivarModalUnidad(false);
	}

	/**
	 * Evento para cerrar el modal eliminar unidad
	 */
	function cerrarModalEliminar() {
		cargarUnidades();
		setActivarModalEliminarUnidad(false);
	}


	/**
	 * Permite enviar el formulario para crear una nueva unidad
	 */
	async function agregarUnidad(data) {
		cerrarModalAgregar();
	}

	/**
	 * Cuando queremos editar una unidad, desde el context menú
	 *
	 * @param {integer} rowIdx
	 */
	const editRow = rowIdx => {
		new Promise(function(resolve, reject){
			getUnidad(rows[rowIdx].id).then(function(unidad){
				setUnidadForm(unidad);
				setActivarModalUnidad(true);
			})
		})
	};


	/**
	 * Cuando queremos eliminar una unidad, desde el context menú
	 *
	 * @param {*} rowIdx
	 */
	const deleteRow = rowIdx => {
		new Promise(function(resolve, reject){
			getUnidad(rows[rowIdx].id).then(function(unidad){
				setUnidadForm(unidad);
				setActivarModalEliminarUnidad(true);
			})
		})
	};


	return (
		<LayoutAdmin title="Unidades">
			<Row style={{paddingBottom:'10px'}}>
                <Col>
                    <Buscador retornoBusqueda={retornoBusqueda} busqueda={textoBuscar} placeholder='Búsqueda de unidades...'/>
                </Col>
				<Col>
					<ButtonGroup className="float-right">
						<Button variant="success" size="sm" onClick={nuevoDato}>Agregar Unidad</Button>
						<DefaultButtons />
					</ButtonGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<div>
						<BaseGrid
							columns={columns}
							rowGetter={i => rows[i]}
							rowsCount={rows.length}
							minHeight={400}
							contextMenu={
								<GridMenu
									id="pac_1"
									options = {[
										{texto: 'Editar',
										funcion: (e, { rowIdx }) => editRow(rowIdx)},
										{texto: 'Eliminar',
										funcion: (e, { rowIdx }) => deleteRow(rowIdx)}
									]}
								/>
							}
							RowsContainer={ContextMenuTrigger}
							onGridSort={(sortColumn, sortDirection) =>
								setRows(sortRows(rows, sortColumn, sortDirection))
							}
							emptyRowsView={EmptyRowsView}/>
					</div>
				</Col>
			</Row>
			<ModalFormularioUnidad 
				show={activarModalAgregarUnidad}
				onHide={cerrarModalAgregar} 
				unidad={unidadForm} 
				funcionRetorno={function(data){agregarUnidad(data)}}
				size="md"
			/>
			<FormularioEliminarUnidad
				show={activarModalEliminarUnidad}
				onHide={cerrarModalEliminar} 
				unidad={unidadForm} 
				funcionRetorno={function(data){cerrarModalEliminar()}}
				size="md"
			/>
		</LayoutAdmin>
	);
}

export default Unidades;