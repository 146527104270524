import React from 'react';
import {BaseStylesPdf} from '../../../../componentes/pdf/BaseStylesPdf';
import {BaseLinkPdf} from '../../../../componentes/pdf/BaseLinkPdf';
import {Table, TableHeader, TableBody, TableCell, DataTableCell} from '@david.kucsai/react-pdf-table';
import { separadorDeMilesOrVacio} from '../../../../componentes/grid/utils';

export function ControlCajaPermanentePDF(props) {
    const styles = BaseStylesPdf();
    var tablaPresupuestoActividad = '';
    try {
        tablaPresupuestoActividad =(
            <>
            <Table data={props.rows}>
                <TableHeader textAlign={"center"}>
                    {/* el "weighting" idealmente debe sumar 1 para que quede todo ordenado */}
                    <TableCell style={styles.tableHeader} weighting={0.1}>Cuenta</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.405}>Nombre</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.121}>Crédito Balance</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.121}>Débito Balance</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Provee</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.1}>Recibe</TableCell>
                    <TableCell style={styles.tableHeader} weighting={0.099}>Resultado</TableCell>
                </TableHeader>
                <TableBody>
                    <DataTableCell style={{ textAlign: "center" }} weighting={0.1} getContent={(r) => r.codigo}/>
                    <DataTableCell style={{ textAlign: "left" }} weighting={0.39} getContent={(r) => r.nombre}/>
                    <DataTableCell style={{ textAlign: "right", fontSize: 11 }} weighting={0.12} getContent={(r) => separadorDeMilesOrVacio(r.credito_balance)}/>
                    <DataTableCell style={{ textAlign: "right", fontSize: 11 }} weighting={0.12} getContent={(r) => separadorDeMilesOrVacio(r.debito_balance)}/>
                    <DataTableCell style={{ textAlign: "right", fontSize: 11 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.provee_operaciones)}/>
                    <DataTableCell style={{ textAlign: "right", fontSize: 11 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.recibe_operaciones)}/>
                    <DataTableCell style={{ textAlign: "right", fontSize: 11 }} weighting={0.1} getContent={(r) => separadorDeMilesOrVacio(r.balance_final)}/>
                </TableBody>
            </Table>
            </>)
    } catch (error) {
        console.log("Error IndicadoresPdf", error);
    }

    let blob = BaseLinkPdf({...props, body:tablaPresupuestoActividad, orientation:"landscape"})

    return blob
}