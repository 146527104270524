import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {getPredio} from '../../modulos/administracion/FormularioPredio';
import { FormularioCooperados } from '../../modulos/administracion/FormularioCooperativa';

import LayoutAdmin from '../../componentes/LayoutAdmin';
import {Link} from 'react-router-dom';

function AdminCooperados(props){
	const [cooperadoForm, setCooperadoForm] = useState(
		{
			"id":null,
			"nombre_socio": "",
			"predio_asociado":"",
			"cuotas_participacion":0,
			"tipo_socio":""
		});
	const {cooperativaId} = useParams()

    useEffect(
        () => {
            getPredio(cooperativaId).then(function(predio){
				setCooperadoForm(predio);
            })
        },
        [cooperativaId]
    )


	return (
		<>
			<LayoutAdmin title={
				<>
					<Link to={`/administracion/cooperativas/listado`}>Cooperativas</Link> / Cooperados
				</>
			}>
		
            <FormularioCooperados
                cooperativa={cooperadoForm}
				show={true}
				tabSeleccionado={'permisos_acceso'}
				botonVolver={true}
            />
		</LayoutAdmin>
		</>
	)
}

export default AdminCooperados;