import React from 'react';
import { Modal, Button, ButtonGroup } from 'react-bootstrap';
import { FaWindowClose, FaPlus, FaPen, FaArrowLeft } from 'react-icons/fa';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';

function ModalAccion(props) {

    const handleClose = () => props.setShow(false);

    return (
        <Modal show={props.show} onHide={handleClose} autoFocus={true} enforceFocus={true}>
            <Modal.Header>
                <Modal.Title>{props.titulo}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {props.children &&
                    <>
                        {ReactHtmlParser(props.children)}
                    </>
                }
                <ButtonGroup style={{width: '100%'}}>
                    <Link to={props.linkGrilla}>
                        <Button variant="light" size="sm" style={{border: '1px solid grey'}}>
                            <FaArrowLeft /> Volver a la Grilla
                        </Button>
                    </Link>
                    <Button variant="success" size="sm" onClick={() => props.confirmar('crear')}>
                        <FaPlus /> Crear nuevo
                    </Button>
                    <Button variant="warning" size="sm" onClick={() => props.confirmar('editar')}>
                        <FaPen /> Seguir editando
                    </Button>
                </ButtonGroup>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={handleClose}>
                    <FaWindowClose /> Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalAccion;