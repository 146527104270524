import React, { useEffect} from 'react';
import { Form, Button, Col, Modal, Row} from 'react-bootstrap';
import { useForm, ErrorMessage } from 'react-hook-form'
import { useAlert } from 'react-alert';
import {Api} from '../../utils/api';
import { FaSave, FaWindowClose } from 'react-icons/fa';
import SelectorTipoCategoriaActividad from '../../componentes/SelectorTipoCategoriaActividad';

function FormCategoriaActividades(props) {
    const alert = useAlert();           // Para las notificaciones
    const { categoriaactividad, ...rest } = props;
    const { register, handleSubmit, watch, errors, setValue } = useForm({
        defaultValues: {
            nombre: '',
            codigo_minimo: '',
            codigo_maximo: '',
            tipo_categoria_actividad_id: '',
            habilitada: 'SI'
        }
    });

    useEffect(
        () => {
            if (categoriaactividad) {
                setValue([
                    { nombre: categoriaactividad.nombre},
                    { codigo_minimo: categoriaactividad.codigo_minimo},
                    { codigo_maximo: categoriaactividad.codigo_maximo},
                    { habilitada: (categoriaactividad.habilitada ? 'SI' : 'NO')},
                    { tipo_categoria_actividad_id: categoriaactividad.tipo_categoria_actividad_id }
                ]);
            }
        },
        [categoriaactividad]
    )

    /**
     * Cuando el formulario está validado
     *
     * @param {*} data
     * @returns
     */
    async function guardar(data) {
        if (categoriaactividad === '') { // Crear
            let resultado = await Api('api/categoria-actividades', data, { 'Content-Type': 'application/json' }, true, 'post');
            if (resultado && resultado.status === 200) {
                alert.show(resultado.data.msg, { type: 'success' });
                cerrarModal();
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
            }
        } else { // Actualizar
            let resultado = await Api(`api/categoria-actividades/${categoriaactividad.id}`, data, { 'Content-Type': 'application/json' }, true, 'put');
            if (resultado && resultado.status === 200) {
                alert.show(resultado.data.msg, { type: 'success' });
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
            }
        }
    }

    function cerrarModal() {
        props.onHide();
    }

    return (
        <Modal {...rest} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>{categoriaactividad ? 'Editar' : 'Agregar'} Categoría Actividad</Modal.Title>
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit(guardar)}>
                <Modal.Body>
                    <Row>
                        <Form.Label column sm="3">Nombre</Form.Label>
                        <Col sm="9">
                            <Form.Control
                                name="nombre"
                                type="text"
                                placeholder=""
                                size="sm"
                                ref={register({
                                    required: "Por favor, ingrese el nombre"
                                })}
                                isInvalid={errors.nombre} />
                            <ErrorMessage errors={errors} name="nombre" as="div" className="invalid-feedback" />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="3">Código mínimo</Form.Label>
                        <Col sm="9">
                            <Form.Control
                                name="codigo_minimo"
                                type="number"
                                placeholder=""
                                size="sm"
                                ref={register({
                                    required: "Por favor, ingrese el código"
                                })}
                                isInvalid={errors.codigo_minimo} />
                                <ErrorMessage errors={errors} name="codigo_minimo" as="div" className="invalid-feedback" />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="3">Código máximo</Form.Label>
                        <Col sm="9">
                            <Form.Control
                                name="codigo_maximo"
                                type="number"
                                placeholder=""
                                size="sm"
                                ref={register({
                                    required: "Por favor, ingrese el código"
                                })}
                                isInvalid={errors.codigo_maximo} />
                                <ErrorMessage errors={errors} name="codigo_maximo" as="div" className="invalid-feedback" />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="3">Tipo</Form.Label>
                        <Col sm="9">
                            <SelectorTipoCategoriaActividad
                                id='tipo_categoria_actividad_id'
                                name="tipo_categoria_actividad_id"
                                register={register}
                                errors={errors}
                                inicial={watch('tipo_categoria_actividad_id')}
                                // retornar={returnSelectorCuentaProvee}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="3">Habilitada</Form.Label>
                        <Col sm="9">
                            <Form.Check name='habilitada' inline type='radio' label={`Sí`} id={`habilitada_si`}
                                value={'SI'} ref={register({ required: true })} />
                            <Form.Check name='habilitada' inline type='radio' label={`No`} id={`habilitada_no`}
                                value={'NO'} ref={register({ required: true })} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={cerrarModal}><FaWindowClose /> Cerrar</Button>
                    <Button type="submit" size="sm" variant="success"><FaSave /> guardar</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default FormCategoriaActividades;