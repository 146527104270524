import React, { useState, useEffect, useContext} from 'react';
import BaseGrid from '../../componentes/grid/BaseGrid'
import GridMenu from '../../componentes/grid/GridMenu';
import { Menu } from "react-data-grid-addons";
import Layout from '../../componentes/Layout';
import { NumberFormatter, EmptyRowsView, completaRut, zeroFill} from '../../componentes/grid/utils';
import {Api} from '../../utils/api';
import UserContext from '../../UserContext';
import { Button, Row, Col, Card, Form} from 'react-bootstrap';
import SelectorEntidades3 from '../../componentes/SelectorEntidades3';
import SelectorTipoDocumento from '../../componentes/SelectorTipoDocumento';
import Fecha from '../../componentes/Fecha';
import { useAlert } from 'react-alert';
import { useForm, ErrorMessage } from 'react-hook-form';
import { fechaHoy, tienePermisos } from '../../utils/Functions';
import FormMovimiento from './FormMovimiento';
import { Link } from 'react-router-dom';
import { FaPlus, FaSave } from 'react-icons/fa';
import ModalEliminar from '../../componentes/ModalEliminar';
import ModalAccion from '../../componentes/ModalAccion';
import {FormEntidad} from '../configuracion/FormEntidad';

function FormDocumento(props) {
    const alert = useAlert();			// Para las notificaciones
    const contexto = useContext(UserContext);
    const [documento, setDocumento] = useState({
        movimientos: []
    });
    const [movimiento, setMovimiento] = useState('');
    const { register, handleSubmit, errors, setValue, watch, getValues, reset } = useForm({
        mode: "formulario_documentos",
        defaultValues: {
            cantidad: '',
            actividad_codigo: '',
            unidad_id: '',
            comentario: '',
            fecha: fechaHoy(),
            tipo_documento: '',
            id: '',
            numero_factura: '',
            cheque: '',
            entidad_id: '',
            rut: '',
            razon_social: '',
            direccion:'',
            comuna: '',
            giro: '',
            contacto: ''
        }
    });

    const inicialCliente = {
        rut: '',
        nombre: ''
    }
    const [dataCliente, setDataCliente] = useState(inicialCliente);

    const columns = [
        { key: 'actividad_nombre_combinado', name: 'Actividad'},
        { key: 'cuenta_provee_nombre_combinado', name: 'Cta. Provee'},
        { key: 'cuenta_recibe_nombre_combinado', name: 'Cta. Recibe'},
        { key: 'unidad_abreviacion', name: 'Unidad', width: 70 },
        { key: 'cantidad', name: 'Cantidad', width: 80, formatter: NumberFormatter },
        { key: 'monto_total_neto', name: 'Neto', width: 80, formatter: NumberFormatter },
        { key: 'monto_total_impuestos', name: 'Impuestos', width: 90, formatter: NumberFormatter },
        { key: 'monto_total_bruto', name: 'Bruto', width: 80, formatter: NumberFormatter },
        { key: 'glosa', name: 'Glosa', width: 150 }
    ];
    const { ContextMenuTrigger } = Menu;

    const [activarModalMovimiento, setActivarModalMovimiento] = useState(false);			// Modal para agregar

    // Modal eliminar actividad
    const [showEliminarMovimiento, setShowEliminarMovimiento] = useState(false);
    const [msgEliminar, setMsgEliminar] = useState('');
    const [movimientoSeleccionado, setMovimientoSeleccionado] = useState('');
    const [movimientosEliminados, setMovimientosEliminados] = useState([]);
    // Modal acción despues de agregar/editar
    const [showModalAccion, setShowModalAccion] = useState(false);
    const [limpiarSelectores, setLimpiarSelectores] = useState(false);


    const [activarModalAgregar, setActivarModalAgregar] = useState(false);  // Modal para agregar registro
    const entidadDefault = {
        "rut": "",
        "razon_social": "",
        "giro": "",
        "comuna": "",
        "relacion": "C",
        "habilitada": true,
        "contacto": ""
    }
    const [entidadForm, setEntidadForm] = useState(entidadDefault);
    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                if (props.match.params.id !== 'crear') {
                    getDocumento(props.match.params.id);
                }
            }
        },
        [contexto]
    )

    /**
     * Buscamos el documento que quiere editar el usuario
     *
     * @param {integer} id
     */
    async function getDocumento(id) {
        let resultado = await Api(`api/contabilidad/documento/${id}?movimientos=true`);
        if (resultado && resultado.status === 200) {
            if (resultado.data.datos.length === 1) {
                if (resultado.data.datos[0].predio_id === contexto.predio.predio_id) {
                    let doc = resultado.data.datos[0];
                    doc.movimientos = agregarColumnasMovimiento(doc.movimientos);
                    setDocumento(doc);
                    setValue([
                        { id: doc.id },
                        { tipo_documento: doc.tipo_desc },
                        { fecha: doc.fecha },
                        { numero_cheque: doc.numero_cheque },
                        { numero_factura: doc.folio },
                        { entidad_id: doc.entidad_id },
                    ]);
                } else {
                    alert.show('No tienes permiso de acceso', { type: 'error' });
                }
            } else {
                setDocumento({
                    ...documento,
                    movimientos: []
                });
                alert.show('No hay un documento asociado', { type: 'error' });
            }
        } else {
            setDocumento({
                ...documento,
                movimientos: []
            });
            alert.show('Error al cargar documento', { type: 'error' });
        }
    }

    /**
     * Guardar información cuando el formulario está validado
     *
     * @param {*} data
     * @returns
     */
    async function guardar(data) {
        data.predio_id = contexto.predio.predio_id;
        let movimientos = Object.assign([], documento.movimientos);
        movimientos.forEach(movimiento => {
            // Este movimiento lo creamos recién, tiene un id temporal, debemos eliminar el id
            if (movimiento.temporal === true) {
                movimiento.id = '';
            }
        });
        data.movimientos = movimientos;
        data.movimientos_eliminados = movimientosEliminados;

        if (props.match.params.id === 'crear') {
            let resultado = await Api('api/contabilidad/documento', data, { 'Content-Type': 'application/json' }, true, 'post');
            if (resultado && resultado.status === 200) { // contabilidad creado
                alert.show(resultado.data.msg, { type: 'success' });
                // Cambiamos la url y actualizamos el estado, la próxima vez actualizará

                resultado.data.datos[0].movimientos = agregarColumnasMovimiento(resultado.data.datos[0].movimientos);
                setDocumento(resultado.data.datos[0]);
                setMovimientosEliminados([]);
                props.history.push(`/contabilidad/documento/${resultado.data.datos[0].id}`);
                setShowModalAccion(true);
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
            }
        } else { // Actualizar
            let resultado = await Api(`api/contabilidad/documento/${documento.id}`, data, { 'Content-Type': 'application/json' }, true, 'put');
            if (resultado && resultado.status === 200) { // contabilidad actualizado
                resultado.data.datos[0].movimientos = agregarColumnasMovimiento(resultado.data.datos[0].movimientos);
                setDocumento({
                    ...documento,
                    movimientos: resultado.data.datos[0].movimientos
                });
                setMovimientosEliminados([]);
                alert.show(resultado.data.msg, { type: 'success' });
                setShowModalAccion(true);
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
            }
        }
    }

    /**
     * A los movimientos le agregamos nuevas columnas para la grilla
     * Además de un estado para indiciar que el movimiento no es temporal(viene desde la base de datos)
     *
     * @param {Object} movimientos
     * @returns {Object}
     */
    function agregarColumnasMovimiento(movimientos) {
        movimientos.forEach(movimiento => {
            movimiento.actividad_nombre_combinado = zeroFill(movimiento.actividad_codigo, 4) + ' ' + movimiento.actividad_nombre;
            movimiento.cuenta_provee_nombre_combinado = movimiento.cuenta_provee_codigo + ' ' + movimiento.cuenta_provee_nombre;
            movimiento.cuenta_recibe_nombre_combinado = movimiento.cuenta_recibe_codigo + ' ' + movimiento.cuenta_recibe_nombre;
            movimiento.temporal = false;
        });

        return movimientos;
    }

    /**
     * Retorno cuando seleccionan una opción en el selector entidad
     *
     * @param {array} data
     */
    function returnSelectorEntidad(data) {
        if (data && data.length === 1) {
            data = data[0];
            setDataCliente({
                ...dataCliente,
                rut: (data.rut)
            })
            setValue([
                { rut: completaRut(data.rut) },
                { razon_social: data.razon_social },
                { direccion: data.direccion },
                { comuna: data.comuna }, // key
                { giro: data.giro },
                { contacto: data.contacto },
                { entidad_id: data.id},
                { fecha: documento.fecha }
            ]);
        } else {
            setValue([
                { entidad_id: '' },
                { rut: '' },
                { razon_social: '' },
                { direccion: '' },
                { comuna: '' }, // key
                { giro: '' },
                { contacto: '' }
            ]);
        }
    }

    /**
     * Función retorno del tipo de documento
     */
    function returnSelectorTipoDocumento() {
        // console.log('returnSelectorTipoDocumento');
    }

    /**
     * Cuando cerramos la modal de agregar/editar movimiento actualizamos el array con los movimientos del documento
     *
     * @param {array} data
     * @param {string} tipo
     */
    function cerrarModalAgregaMovimiento(data, tipo) {
        let movimientos = Object.assign([], documento.movimientos);
        if (tipo === 'crear') {
            movimientos.push(data);
            setDocumento({
                ...documento,
                movimientos: movimientos
            })
        } else if (tipo === 'editar') {
            documento.movimientos.forEach(function (movimiento, i) {
                if (movimiento.id === data.id) {
                    // Info actividad
                    movimientos[i].actividad_nombre_combinado = data.actividad_nombre_combinado;
                    movimientos[i].actividad_nombre = data.actividad_nombre;
                    movimientos[i].actividad_id = data.actividad_id;
                    movimientos[i].actividad_codigo = data.actividad_codigo;

                    // Info cuentas
                    movimientos[i].cuenta_provee_nombre_combinado = data.cuenta_provee_nombre_combinado;
                    movimientos[i].cuenta_recibe_nombre_combinado = data.cuenta_recibe_nombre_combinado;
                    movimientos[i].cuenta_provee_codigo = data.cuenta_provee_codigo;
                    movimientos[i].cuenta_recibe_codigo = data.cuenta_recibe_codigo;
                    movimientos[i].cuenta_provee_nombre = data.cuenta_provee_nombre;
                    movimientos[i].cuenta_recibe_nombre = data.cuenta_recibe_nombre;
                    movimientos[i].detalle_impuestos = data.detalle_impuestos;

                    movimientos[i].glosa = data.glosa;
                    movimientos[i].unidad_id = data.unidad_id;
                    movimientos[i].unidad_nombre = data.unidad_nombre;
                    movimientos[i].unidad_abreviacion = data.unidad_abreviacion;
                    movimientos[i].monto_total_bruto = data.monto_total_bruto;
                    movimientos[i].monto_total_neto = data.monto_total_neto;
                    movimientos[i].monto_total_impuestos = data.monto_total_impuestos;
                    movimientos[i].cantidad = data.cantidad;

                    movimientos[i].otros_impuestos = data.otros_impuestos;

                    movimientos[i].impuesto_adicional = data.impuesto_adicional; // tercer campo impuesto a combustibles
                }
            });
            setDocumento({
                ...documento,
                movimientos: movimientos
            })
        } else if(tipo === 'cerrar') {

        }
        setMovimiento('');
        setActivarModalMovimiento(false);
    }

    async function modalMovimiento() {
        setActivarModalMovimiento(true);
    }

    /**
     * Cuando queremos editar un movimiento
     *
     * @param {integer} fila
     */
    function editarMovimiento(fila) {
        setActivarModalMovimiento(true);
        setMovimiento(documento.movimientos[fila]);
    }

    /**
     * Cuando queremos eliminar un movimiento
     *
     * @param {integer} fila
     */
    function eliminarMovimiento(fila) {
        let movimiento = documento.movimientos[fila];
        setMovimientoSeleccionado(documento.movimientos[fila]);
        setMsgEliminar(`Los cambios serán confirmados una vez presione guardar al final del formulario.<br><br><b>Glosa:</b> ${movimiento.glosa} </br><b>Actividad:</b> ${movimiento.actividad_nombre}`);
        setShowEliminarMovimiento(true);
    }

    /**
     * Si el movimiento viene de la base de datos lo eliminamos de la grilla y lo agregamos a movimientosEliminados
     * Si el movimiento se creo en modo local lo eliminamos directo
     */
    function retornoEliminarMovimiento() {
        let movimientos = Object.assign([], documento.movimientos);
        let nuevosMovimientos = [];
        movimientos.forEach(function (movimiento, i) {
            if (movimiento.id === movimientoSeleccionado.id) {
                // Movimiento que existe en la base de datos
                if (movimiento.temporal === false) {
                    let movEliminados = Object.assign([], movimientosEliminados);
                    movEliminados.push(movimiento);
                    setMovimientosEliminados(movEliminados);
                } // en caso contrario fue creado en local, hay que eliminar
            } else { // Hay que agregarlo, este no será eliminado
                nuevosMovimientos.push(movimiento);
            }
        });
        setDocumento({
            ...documento,
            movimientos: nuevosMovimientos
        })
        setMovimientoSeleccionado('');
        setShowEliminarMovimiento(false);
    }

    /**
     * Retorno de la modal acción luego de guardar cambios
     * Limpiamos formulario o seguimos editando
     */
    function retornoModalAccion(opcion) {
        if (opcion === 'editar') {
            setShowModalAccion(false);
        } else if (opcion === 'crear') {
            setShowModalAccion(false);
            props.history.push('/contabilidad/documento/crear');
            // Limpiamos el formulario completo, ya al hacer cambio de URL no limpia los estados
            setLimpiarSelectores(new Date()); // le pasamos un valor nuevo cada vez, si no solo limpiara la primera vez
            setMovimientoSeleccionado('');
            setMovimientosEliminados([]);
            setDataCliente(inicialCliente);
            setMovimiento('');
            reset();
            setDocumento({
                movimientos: []
            });
        }
    }

    /**
     * Activar modal agregar entidad
     */
    function clickBotonAgregar() {
        setActivarModalAgregar(true)
    }

    /**
     * Resultado luego de agregar una entidad, puede venir respuesta correcta o error
     *
     * @param {json} resultado
     */
    function agregarEntidad(resultado) {
        if (resultado && resultado.status === 200) {
            setActivarModalAgregar(false);
            setValue('entidad_id', resultado.data.data.id);
        }
    }

    return (
        <>
            <Layout
                title={
                    <><Link to='/contabilidad/documentos'>Documentos Contables</Link> / Documento</>
                }>
                <Form key={1} noValidate onSubmit={handleSubmit(guardar)}>
                <Row>
                    <Col lg={3}>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <Card.Header>Información del documento</Card.Header>
                                        <Card.Body style={{ padding: '5px', fontSize: '13px'}}>
                                        <Row style={{ marginBottom: '5px'}}>
                                            <Form.Label column sm="12"> Tipo: </Form.Label>
                                            <Col sm="12">
                                                <SelectorTipoDocumento
                                                    id='tipo_documento'
                                                    name="tipo_documento"
                                                    register={register}
                                                    errors={errors}
                                                    limpiar={limpiarSelectores}
                                                    inicial={watch('tipo_documento')}
                                                    retorno={returnSelectorTipoDocumento}
                                                />
                                            </Col>
                                        </Row>
                                        <Row as={Row} >
                                            <Form.Label column sm="4"> Fecha: </Form.Label>
                                            <Col sm="8" id="cont-fecha-documento">
                                                <Fecha
                                                    name='fecha'
                                                    register={register}
                                                    errors={errors}
                                                    dateFormat='dd/MM/yyyy' // dd/MM/Y h:mm aa - dd/MM/Y - MM/Y
                                                    dateFormatOculto='y-m-d h:i:s' // y-m-d h:i:s
                                                    inicial={watch('fecha')}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column sm="4"> Factura: </Form.Label>
                                            <Col sm="8">
                                                <Form.Control
                                                    name="numero_factura"
                                                    type="number"
                                                    size="sm"
                                                    // disabled
                                                    ref={register({
                                                        required: "Por favor, ingrese el número de la factura",
                                                    })}
                                                    isInvalid={errors.numero_factura}
                                                />
                                                <ErrorMessage errors={errors} name="numero_factura" as="div" className="invalid-feedback" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column sm="4"> Cheque: </Form.Label>
                                            <Col sm="8">
                                                <Form.Control
                                                    name="numero_cheque"
                                                    type="number"
                                                    placeholder=""
                                                    size="sm"
                                                    ref={register({
                                                        // required: "Por favor, ingrese el campo",
                                                    })}
                                                    // isInvalid={errors.Cheque}
                                                />
                                                {/* <ErrorMessage errors={errors} name="Cheque" as="div" className="invalid-feedback" /> */}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={12} style={{ paddingTop: '10px'}}>
                                <Card>
                                    <Card.Header>Proveedor o Cliente</Card.Header>
                                    <Card.Body style={{padding:'5px', fontSize:'13px'}}>
                                        <Row style={{marginBottom: '5px'}}>
                                            <Col sm="12">
                                                <SelectorEntidades3
                                                    id='1'
                                                    name="entidad_id"
                                                    register={register}
                                                    errors={errors}
                                                    funcionparaactualizar={setValue}
                                                    inicial={watch('entidad_id')}
                                                    requerido={false}
                                                    limpiar={limpiarSelectores}
                                                    retornar={returnSelectorEntidad}
                                                    clickBotonAgregar={clickBotonAgregar}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column sm="5">Rut: </Form.Label>
                                            <Col sm="7">
                                                <Form.Control
                                                    name="rut"
                                                    type="text"
                                                    placeholder=""
                                                    size="sm"
                                                    ref={register({})}
                                                    disabled/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column sm="5">Razón social: </Form.Label>
                                            <Col sm="7">
                                                <Form.Control
                                                    name="razon_social"
                                                    type="text"
                                                    placeholder=""
                                                    size="sm"
                                                    ref={register({})}
                                                    disabled/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column sm="5">Dirección: </Form.Label>
                                            <Col sm="7">
                                                <Form.Control
                                                    name="direccion"
                                                    type="text"
                                                    placeholder=""
                                                    size="sm"
                                                    ref={register({})}
                                                    disabled/>
                                            </Col>
                                        </Row>
                                        <Row as={Row} >
                                            <Form.Label column sm="5">Comuna: </Form.Label>
                                            <Col sm="7">
                                                <Form.Control
                                                    name="comuna"
                                                    type="text"
                                                    placeholder=""
                                                    size="sm"
                                                    ref={register({})}
                                                    disabled/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column sm="5">Giro: </Form.Label>
                                            <Col sm="7">
                                                <Form.Control
                                                    name="giro"
                                                    type="text"
                                                    placeholder=""
                                                    size="sm"
                                                    ref={register({})}
                                                    disabled/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Label column sm="5">Contacto: </Form.Label>
                                            <Col sm="7">
                                                <Form.Control
                                                    name="contacto"
                                                    type="text"
                                                    placeholder=""
                                                    size="sm"
                                                    ref={register({})}
                                                    disabled/>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={9}>
                        <Card>
                            {tienePermisos('editar',contexto.predio.perfil) &&
                                <Card.Header>Movimientos<Button onClick={modalMovimiento} variant="success" size="sm" style={{ float: 'right', padding: '2px 10px' }}><FaPlus/> Agregar movimiento</Button></Card.Header>
                            }
                            <Card.Body style={{padding:'0px'}}>
                                <Row>
                                    <Col>
                                        <div>
                                            <BaseGrid
                                                columns={columns}
                                                rowGetter={i => documento.movimientos[i]}
                                                rowsCount={documento.movimientos.length}
                                                minHeight={435}
                                                contextMenu={
                                                    <GridMenu
                                                        id="pac_1"
                                                        options={ [
                                                            {texto:"Editar",
                                                            funcion: (e, { rowIdx }) => editarMovimiento(rowIdx)},
                                                            {texto:"Eliminar",
                                                            funcion: (e, { rowIdx }) => eliminarMovimiento(rowIdx)}
                                                        ]}
                                                    />
                                                }
                                                RowsContainer={ContextMenuTrigger}
                                                // onGridSort={(sortColumn, sortDirection) =>
                                                //     setRows(sortRows(documento.movimientos, sortColumn, sortDirection))
                                                // }
                                                emptyRowsView={EmptyRowsView}/>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        {tienePermisos('editar',contexto.predio.perfil) &&
                            <Button type="submit" variant="success" size="sm" style={{float:'right', marginTop:'10px'}}><FaSave/> Guardar</Button>
                        }
                    </Col>
                </Row>
                </Form>
            </Layout>
            <FormMovimiento
                show={activarModalMovimiento}
                onHide={cerrarModalAgregaMovimiento}
                movimiento={movimiento}
                size="lg"/>
            <ModalEliminar titulo="Eliminar" show={showEliminarMovimiento} setShow={setShowEliminarMovimiento} confirmar={retornoEliminarMovimiento}>
                {msgEliminar}
            </ModalEliminar>
            <ModalAccion titulo="¿ Qué desea hacer ?" show={showModalAccion} setShow={setShowModalAccion} confirmar={retornoModalAccion} linkGrilla={'/contabilidad/documentos'}/>
            <FormEntidad
                show={activarModalAgregar}
                onHide={() => setActivarModalAgregar(false)}
                entidad={entidadForm}
                funcionRetorno={agregarEntidad} />
        </>
    );
}

export default FormDocumento;