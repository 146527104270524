import React, {useState,useEffect} from 'react';
import { Form, Button, Col, Modal, Row } from 'react-bootstrap';
import { useForm, ErrorMessage } from 'react-hook-form'
import { useAlert } from 'react-alert';
import { Api, ObjectToFormdata } from '../../utils/api';
import { FaSave, FaWindowClose } from 'react-icons/fa';
import SelectorPerfil from '../../componentes/SelectorTipoCategoriaActividad'

function inputContrasena(register, errors, watch) {
    return (
        <>
            <Form.Group as={Col} md="6" controlId="validationCustom04">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control type="password" placeholder="Contraseña..." name="password"
                    ref={register({
                        required: {
                            value: function () {
                                if (watch('id') === "") { // si no hay id de usuario, la contraseña es obligatoria
                                    return true
                                }
                                else { return false }
                            }, message: "Por favor, ingrese su contraseña"
                        },
                        minLength: { value: 6, message: "Largo mínimo 6 caracteres" }
                    })}
                    isInvalid={errors.password} size="sm" />
                <ErrorMessage errors={errors} name="password" as="div" className="invalid-feedback" />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom05">
                <Form.Label>Confirmar Contraseña</Form.Label>
                <Form.Control type="password" placeholder="Contraseña..." name="confirm_password"
                    ref={register({
                        required: {
                            value: function () {
                                if (watch('id') === "") { // si no hay id de usuario, la contraseña es obligatoria
                                    return true
                                }
                                else { return false }
                            }, message: "Por favor, ingrese su contraseña"
                        },
                        minLength: { value: 6, message: "Largo mínimo 6 caracteres" },
                        validate: (value) => value === watch('password') || "La contraseña no coincide"
                    })}
                    isInvalid={errors.confirm_password} size="sm" />
                <ErrorMessage errors={errors} name="confirm_password" as="div" className="invalid-feedback" />
            </Form.Group>
        </>
    )
}
export function FormularioPermisos(props){
    const { register, handleSubmit, watch, errors } = useForm()
    const alert = useAlert();           // Para las notificaciones
    const { usuario, funcionRetorno, ...rest } = props;
    const onSubmit = data => {
        editarGrilla(data);
    }

    function editarGrilla(data) {
        let encuentra = false
        let iterador = 0
        while (encuentra == false && iterador < props.rows.length){
            if (props.rows[iterador].usuario_id == data.id){
                encuentra = true
            }else{
                iterador++;
            }
        }
        if (encuentra){
            if (data.perfil === 'Visualizador'){
                props.rows[iterador].perfil = 'Visualizador'
            }else{
                props.rows[iterador].perfil = 'Administrador'
            }
            alert.show("Perfil editado con éxito, debe guardar para confirmar los cambios", { type: 'success' });
        }else{
            alert.show("Error al editar el perfil", { type: 'error' });
        }
        props.funcionRetorno()        
    }

    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Editar Permisos
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Col sm="12">
                        <Form.Row>
                            <Form.Control type="hidden" name="id" defaultValue={usuario.id} ref={register({})}></Form.Control>
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Form.Label>Nombre Completo</Form.Label>
                                <Form.Control type="text" size="sm" placeholder="Nombre..." name="nombre_completo" defaultValue={usuario.nombre_completo} disabled
                                    ref={register}
                                     />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                                <Form.Label>Usuario</Form.Label>
                                <Form.Control type="text" size="sm" placeholder="Usuario..." name="username" defaultValue={usuario.usuario} disabled
                                    ref={register}/>
                                <ErrorMessage errors={errors} name="username" as="div" className="invalid-feedback" />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom04">
                                <Form.Label>Perfil</Form.Label>
                                <Form.Control name="perfil" ref={register({})} as="select" size="sm" defaultValue={usuario.perfil}>
                                    <option key={"administrador"} value={"Administrador"}>
                                        Administrador
                                    </option>
                                        <option key={"visualizador"} value={"Visualizador"}>
                                            Vizualizador
                                    </option>
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={props.onHide}><FaWindowClose /> Cerrar</Button>
                    <Button type="submit" size="sm" variant="success"><FaSave /> Guardar</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
export function FormUsuario(props) {

    const { register, handleSubmit, watch, errors } = useForm()
    const alert = useAlert();           // Para las notificaciones
    const { usuario, funcionRetorno, ...rest } = props;
    const usuarioLogueado = JSON.parse(localStorage.getItem('usuario'));

    const onSubmit = data => {
        enviarFormulario(data);
    }

    async function enviarFormulario(data) {
        data = ObjectToFormdata(data);
        let resultado = await Api('api/administracion/usuarios/guardar', data, { 'Content-Type': 'application/json' }, true, 'post');
        if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            props.funcionRetorno(data);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            //props.funcionRetorno(data);
        }
    }

    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Agregar usuario
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Col sm="12">
                        <Form.Row>
                            <Form.Control type="hidden" name="id" defaultValue={usuario.id} ref={register({})}></Form.Control>
                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                <Form.Label>Nombre Completo</Form.Label>
                                <Form.Control type="text" size="sm" placeholder="Nombre..." name="nombre_completo" defaultValue={usuario.nombre_completo}
                                    ref={register({
                                        required: "Por favor, ingrese su nombre completo",
                                        minLength: { value: 10, message: "Largo mínimo 10 caracteres" }
                                    })}
                                    isInvalid={errors.nombre_completo} />
                                <ErrorMessage errors={errors} name="nombre_completo" as="div" className="invalid-feedback" />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom02">
                                <Form.Label>Correo</Form.Label>
                                <Form.Control type="mail" size="sm" placeholder="Correo..." name="correo"
                                    defaultValue={usuario.correo}
                                    ref={register({
                                        /*required: "Por favor, ingrese su correo electrónico", */
                                        pattern: { value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, message: "Ingrese un correo correcto" }
                                    })}
                                    isInvalid={errors.correo} />
                                <ErrorMessage errors={errors} name="correo" as="div" className="invalid-feedback" />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                                <Form.Label>Usuario</Form.Label>
                                <Form.Control type="text" size="sm" placeholder="Usuario..." name="username" defaultValue={usuario.username}
                                    ref={register({
                                        required: "Por favor, ingrese su nombre de usuario",
                                        minLength: { value: 3, message: "Largo mínimo 3 caracteres" }
                                    })}
                                    isInvalid={errors.username} />
                                <ErrorMessage errors={errors} name="username" as="div" className="invalid-feedback" />
                            </Form.Group>
                            {usuario.nuevo === true &&
                                inputContrasena(register, errors, watch)
                            }
                        </Form.Row>

                        {usuarioLogueado.admin_sistema === true &&
                            <Form.Group>
                                <Form.Check
                                    label="Super usuario de sistema"
                                    name="admin_sistema"
                                    defaultChecked={usuario.admin_sistema}
                                    value={true}
                                    size="sm"
                                    ref={register()}
                                />
                            </Form.Group>
                        }
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={props.onHide}><FaWindowClose /> Cerrar</Button>
                    <Button type="submit" size="sm" variant="success"><FaSave /> Guardar</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export async function getUsuario(usuarioId) {
    let resultado = await Api('api/administracion/usuarios/' + usuarioId);
    return resultado.data.usuario;
}

export function FormEliminarUsuario(props) {

    const alert = useAlert();           // Para las notificaciones
    const { usuario, funcionRetorno, ...rest } = props;


    async function eliminarUsuario(data) {
        //data = ObjectToFormdata(data);
        let resultado = await Api('api/administracion/usuarios/eliminar/' + data.id, null, {}, true, 'delete');
        if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            props.funcionRetorno(data);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            //props.funcionRetorno(data);
        }
    }

    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Eliminar usuario
            </Modal.Header>
            <Modal.Body>
                <Col sm="12">
                    ¿Está seguro de eliminar al usuario {usuario.nombre_completo}?
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.onHide}>
                    Cerrar
                    </Button>
                <Button variant="danger" size="sm"
                    onClick={(e) => eliminarUsuario(usuario)}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export function FormCambiarPassword(props) {
    const { register, handleSubmit, watch, errors } = useForm()
    const { usuario, funcionRetorno, ...rest } = props;
    const alert = useAlert();           // Para las notificaciones

    const onSubmit = data => {
        enviarFormulario(data);
    }

    async function enviarFormulario(data) {
        data = ObjectToFormdata(data);
        let resultado = await Api('api/administracion/usuarios/guardar', data, { 'Content-Type': 'application/json' }, true, 'post');
        if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            props.funcionRetorno(data);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            //props.funcionRetorno(data);
        }
    }

    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Cambiar contraseña
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Col sm="12">
                        <Form.Row>
                            <Form.Control type="hidden" name="id"
                                size="sm" defaultValue={usuario.id} ref={register({})}></Form.Control>
                            {inputContrasena(register, errors, watch)}
                        </Form.Row>
                    </Col>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={props.onHide}><FaWindowClose /> Cerrar</Button>
                    <Button type="submit" size="sm" variant="success"><FaSave /> Guardar</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export function FormCrearToken(props) {

    const alert = useAlert();           // Para las notificaciones
    const {usuario, funcionRetorno, ...rest} = props;
    const [token, setToken] = useState(null);

    useEffect(
        () => {
            setToken(usuario.api_token)
        },
        [props]
    )

    async function crearToken(data){
        //data = ObjectToFormdata(data);
        let resultado = await Api('api/crea_token/'+ data.id,null,{},true,'get');
		if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            setToken(resultado.data.token);
		} else {
            alert.show(resultado.data.msg, { type: 'error' });
            //props.funcionRetorno(data);
		}
    }

    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Crear Token
            </Modal.Header>
            <Modal.Body>
                <Col sm="12">
                ¿Está seguro de crear un token de acceso al usuario {usuario.nombre_completo}?
                </Col>
                <Col sm="12">
                    <label>Token:</label>
                    <Form.Control type="text" size="sm" readOnly value={token}></Form.Control>
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={() => {
                        setToken('')
                        props.onHide()
                    }}>
                    Cerrar
                </Button>
                    <Button variant="danger" size="sm" 
                        onClick={(e) => crearToken(usuario)}>
                        Crear
                </Button>
            </Modal.Footer>
        </Modal>
    )
}