import React from 'react';
import {BaseStylesPdf} from '../../../../componentes/pdf/BaseStylesPdf';
import {BaseLinkPdf} from '../../../../componentes/pdf/BaseLinkPdf';
import {Table, TableHeader, TableBody, TableCell, DataTableCell} from '@david.kucsai/react-pdf-table';
import { separadorDeMiles, separadorDeMilesOrVacio} from '../../../../componentes/grid/utils';

export function MovimientoDeCajaPdf(props) {
    const styles = BaseStylesPdf();
    var tablaPresupuestoActividad = '';

    try {
        tablaPresupuestoActividad =(
            <>
            <Table data={props.rows}>
                <TableHeader textAlign={"center"}>
                    {/* el "weighting" idealmente debe sumar 1 para que quede todo ordenado */}
                    { props.columns.map((data, index) => {
                        return data.name === 'Cuenta' ?
                                <TableCell key={index} tyle={styles.tableHeader} weighting={0.214}>{data.name}</TableCell>
                            :
                                <TableCell key={index}  style={styles.tableHeader} weighting={0.06}>{data.name}</TableCell>
                    })}
                </TableHeader>
                <TableBody>
                    {props.columns.map((data, index) => {
                        return data.name === 'Cuenta' ?
                            <DataTableCell key={index} style={{ textAlign: "left", fontSize: 10, fontWeight:700 }} weighting={0.2} getContent={(r) => r.nombre_cuenta} />
                        :
                            <DataTableCell key={index} style={{ textAlign: "right", fontSize: 9 }} weighting={0.06} getContent={(r) => separadorDeMilesOrVacio(r[props.columns[index].key])} />
                    })}
                </TableBody>
            </Table>
            <Table data={props.rowsTotales}>
                <TableHeader textAlign={"center"}>
                    {/* el "weighting" idealmente debe sumar 1 para que quede todo ordenado */}
                    {props.columns.map((data, index) => {
                        return data.name === 'Cuenta' ?
                            <TableCell key={index} tyle={styles.tableHeader} weighting={0.214}>{data.name}</TableCell>
                            :
                            <TableCell key={index} style={styles.tableHeader} weighting={0.06}>{data.name}</TableCell>
                    })}
                </TableHeader>
                <TableBody>
                    {props.columns.map((data, index) => {
                        return data.name === 'Cuenta' ?
                            <DataTableCell key={index} style={{ textAlign: "right" }} weighting={0.2} getContent={(r) => r.titulo} />
                        :
                            <DataTableCell key={index} style={{ textAlign: "right", fontSize: 8 }} weighting={0.06} getContent={(r) => separadorDeMiles(r[props.columns[index].key])} />
                    })}
                </TableBody>
            </Table>
            </>)
    } catch (error) {
        console.log("Error MovimientoDeCajaPdf", error);
    }

    let blob = BaseLinkPdf({...props, body:tablaPresupuestoActividad, orientation:"landscape"})

    return blob
}