import React from 'react';
import MenuConga from './menu/menu.js';
import {Container, Row, Col, Breadcrumb} from 'react-bootstrap';
import {version} from '../../package.json';

function Layout(props) {
    // useEffect(
    //     () => {
    //         current_path();
    //     },
    //     [current_path]
    // )

    // function current_path() {
    //     console.log(window.location.pathname);
    // }
    return (
        <Container fluid={true}>
            <Row>
                <Col>
                    <MenuConga />
                </Col>
            </Row>
            <Row style={{paddingTop:'10px'}}>
                <Col>
                    <Breadcrumb>
                        {/* <Breadcrumb.Item as={Link} to='/'>Home</Breadcrumb.Item> */}
                        <Breadcrumb.Item active>{props.title}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>{props.children}</Col>
            </Row>
            <footer>
                <hr />
                <a href="/">CONGA: Sistema de contabilidad y gestión Agropecuaria. Desarrollado por AltaVenta SpA <span style={{float:"right"}}>V {version}</span></a>
            </footer>
        </Container>
    );
}

export default Layout;