import React, {useEffect}  from 'react';
import { Button, Modal } from 'react-bootstrap';
// import { useAlert} from 'react-alert';
import BaseGrid from '../componentes/grid/BaseGrid';
import { LongTextFormatter } from '../componentes/grid/utils';
import {FaTimes, FaInfo } from 'react-icons/fa';

const ModalErrores = (props) => {

    const columns = [
        { key: 'columna', name: 'Columna', formatter: LongTextFormatter, width: 130 },
        { key: 'contenido', name: 'Contenido', formatter: LongTextFormatter, width: 100 },
        { key: 'fila', name: 'Fila', formatter: LongTextFormatter, width: 80 },
        { key: 'mensaje', name: 'Mensaje de error', formatter: LongTextFormatter, width: 440 },
    ];

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.close}
                size='lg'
            >
                <Modal.Header closeButton><FaInfo />Error al cargar el archivo, a continuación listamos todos los errores:</Modal.Header>
                <Modal.Body>
                    <BaseGrid
                        columns={columns}
                        rowGetter={i => props.errores[i]}
                        rowsCount={props.errores.length}
                        minHeight={600}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button color="light" onClick={props.close}>
                        <FaTimes /> Cerrar</Button>
                </Modal.Footer>

            </Modal>
        </>
    )
}

export default ModalErrores