import React, {useState, useContext, useEffect} from 'react';
import BaseGrid from '../../componentes/grid/BaseGrid';
import UserContext from '../../UserContext';
import Layout from '../../componentes/Layout.js';
import {NumberFormatter, codigoActividad} from '../../componentes/grid/utils';
import DefaultButtons from '../../componentes/grid/DefaultButtons'
import {Button, Row, Col, ButtonGroup, Form} from 'react-bootstrap';
import {Api} from '../../utils/api';
import ChartResultadoGlobal from './ChartResultadoGlobal';
import { exportar, downloadBlob, getDMY, fechaHoy } from '../../utils/Functions';
import {ResultadoGlobalPdf} from './pdf/ResultadoGlobalPdf';
import { useAlert } from 'react-alert';

function ResultadoGlobal(props) {
    const alert = useAlert();			// Para las notificaciones
    const contexto = useContext(UserContext);
    const [rows, setRows] = useState([]);
    const [rowsResultado, setRowsResultado] = useState([]);
    const [showChart, setShowChart] = useState(false);
    const [fechaExportar] = useState(fechaHoy());

    const columns = [
        {key: 'codigo', name: 'Actividad', formatter: codigoActividad},
        {key: 'nombre', name: 'Descripción',  width: 400},
        { key: 'cantidad', name: 'Tamaño', width: 300, formatter: NumberFormatter},
        {key: 'total', name: 'Total', formatter: NumberFormatter},
        {key: 'total_unidad', name: 'Total / Unidad', formatter: NumberFormatter},
     ];

     const columnsResultado = [
        {key: 'codigo1', name: ''},
        {key: 'codigo2', name: '',  width: 400},
        {key: 'nombre', name: '',  width: 300, formatter: NumberFormatter},
        {key: 'total', name: 'Total', formatter: NumberFormatter},
        {key: 'total_unidad', name: 'Total / Unidad', formatter: NumberFormatter},
     ];

    async function getReporte(predio) {
        let resultado = await Api(`api/reportes/presupuesto/resultado_global/${predio}/${contexto.predio.periodo.id}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.presupuestos);
            setRowsResultado(resultado.data.resultado);
        } else {
            setRows([]);
            setRowsResultado([]);
            console.log('viene un error');
        }
    }


    function chart() {
        setShowChart(true);
    }

    useEffect(
        () => {
            if (contexto.predio.predio_id, contexto.predio.periodo) {
                getReporte(contexto.predio.predio_id);
            }
        },
        [contexto]
    )

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        exportar(`api/exportar/reportes/presupuesto/resultado_global/${contexto.predio.predio_id}/${contexto.predio.periodo.id}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf(){
        let props = {
            columns:columns,
            rows:rows,
            columnsTotales: columnsResultado,
            rowsTotales: rowsResultado,
            titulo:"Resultado Global de la Explotación",
            bajada:contexto.predio.nombre_predio
        }
        alert.show('La descarga comenzará en breve', { type: 'success' });
        new Promise(function(resolve, reject){
            ResultadoGlobalPdf(props).then(
                function(blob){
                    downloadBlob(blob,
                        `Resultado_Global_${contexto.predio.nombre_predio.replace(/ /g, '_')}_${getDMY(fechaExportar)}.pdf`);
                },function(blob){
                    alert.show('Error al exportar', { type: 'error' })
            })
        })
    }

    return (
        <Layout title="Resultado Global de la Explotación">
            <Form>
                <Form.Row style={{paddingBottom:'10px'}}>
                    <Col md={8}>
                        <Form.Group as={Row} controlId="select_periodo">

                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <ButtonGroup className="float-right">
                            <Button
                                variant="info"
                                size="sm"
                                onClick={chart}>Gráfico</Button>
                            <DefaultButtons exportar={exportarExcel}/>
                            <DefaultButtons imprimir={exportarPdf}/>
                        </ButtonGroup>
                    </Col>
                </Form.Row>
            </Form>
            <div>
                <BaseGrid
                    columns={columns}
                    rowGetter={i => rows[i]}
                    rowsCount={rows.length}
                    minHeight={250}
                    showCountRows={false} />
            </div>
            <div>
                <BaseGrid
                    columns={columnsResultado}
                    rowGetter={i => rowsResultado[i]}
                    rowsCount={rowsResultado.length}
                    minHeight={142}
                    showCountRows={false} />
            </div>
            <ChartResultadoGlobal
                show={showChart}
                onHide={() => setShowChart(false)} />
        </Layout>
    );
}

export default ResultadoGlobal;