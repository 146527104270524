import React, { useState, useEffect, useContext } from 'react';
import { useAlert } from 'react-alert';
import BaseGrid from '../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import { NumberFormatter, sortRows, EmptyRowsView } from '../../componentes/grid/utils';
import { ApiBDL } from '../../utils/api';
import { Row, Col, Form, ButtonGroup } from 'react-bootstrap';
import Fecha from '../../componentes/Fecha';
import { getYMD } from '../../utils/Functions';
import { exportar, downloadBlob } from '../../utils/Functions';

function ResumenDeMargenes(props) {
    const { predio, periodoId, ...rest } = props
    const { ContextMenuTrigger } = Menu
    const alert = useAlert();
    const [rows, setRows] = useState([]);
    const [rowsTotales, setRowsTotales] = useState([]);
    const columns = [
        { key: 'titulo', name: 'Actividad'},
        { key: 'tamano', name: 'Tamaño', width: 100 },
        { key: 'presupuesto_margen', name: 'Margen', width: 200, formatter: NumberFormatter},
        { key: 'presupuesto_margen_unitario', name: 'Margen Unitario', width: 130, formatter: NumberFormatter },
        { key: 'operacion_gastos', name: 'Gastos', width: 100, formatter: NumberFormatter  },
        { key: 'operacion_ingresos', name: 'Ingresos', width: 100, formatter: NumberFormatter },
        { key: 'operacion_margen', name: 'Margen', width: 200, formatter: NumberFormatter  },
        { key: 'operacion_margen_unitario', name: 'Margen Unitario', width: 130, formatter: NumberFormatter },
        { key: 'desviacion', name: 'Des(%)', width: 100, formatter: NumberFormatter }
    ];
    const titulos = [
        { key: 'titulo2', name: '' },
        { key: 'titulo3', name: '', width: 100  },
        { key: 'unidad', name: 'PRESUPUESTO', width: 330 },
        { key: 'cantidad', name: 'OPERACIONES', width: 630 },
    ];
    const columnsTotales = [
        { key: 'titulo', name: '' },
        { key: 'margen_presupuestado', name: 'Margen Presupuestado', width: 200, formatter: NumberFormatter },
        { key: 'vacio2', name: '', width: 130 },
        { key: 'vacio3', name: '', width: 100 },
        { key: 'vacio4', name: '', width: 100 },
        { key: 'margen_operacional', name: 'Margen Operacional', width: 200, formatter: NumberFormatter },
        { key: 'vacio5', name: '', width: 130 },
        { key: 'desviacion', name: 'Des(%)', width: 100, formatter: NumberFormatter }
    ];
    const [fecha, setFecha] = useState('');


    useEffect(
        () => {
            if (predio && predio.predio_id && predio.periodo.cierre && periodoId) {
                if (predio.periodo.cierre === fecha) {
                    obtenerDatos();
                }
                setFecha(predio.periodo.cierre);
            } else {
                setRows([]);
                setRowsTotales([]);
            }
        },
        [predio, periodoId]
    )

    useEffect(
        () => {
            if (fecha) {
                obtenerDatos();
            }
        },
        [fecha]
    )

    /**
     * Cargar información de la grilla
     */
    async function obtenerDatos() {
        let resultado = await ApiBDL(`api/bdl/contabilidad/resumen-de-margenes?predio_id=${predio.predio_id}&periodo_id=${periodoId}&fecha_cierre=${fecha}`, null, {}, true, 'get', props.token);
        if (resultado && resultado.status === 200) {
            setRows(tituloColumnas(resultado.data.datos));
            setRowsTotales(resultado.data.resultados);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            setRows([]);
            setRowsTotales([]);
        }
    }

    function tituloColumnas(info) {
        info.forEach(row => {
            row.titulo = `${row.codigo}: ${row.nombre}`;
            row.tamano = `${row.presupuesto_cantidad} ${row.presupuesto_unidad}`;
        });
        return info;
    }

    /**
     * Cuando cambian la fecha
     *
     * @param {date} e
     */
    function onChangeInicio(e) {
        let fecha = getYMD(e);
        setFecha(fecha);
    }

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        exportar(`api/exportar/contabilidad/resumen-de-margenes?predio_id=${predio.predio_id}&fecha_cierre=${fecha}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf() {
        // let props = {
        //     columns: columns,
        //     rows: rows,
        //     rowsTotales: rowsTotales,
        //     titulo: 'Resumen de Márgenes',
        //     bajada: predio.nombre_predio,
        //     subtitulo: 'Fecha de cierre: ' + getDMY(fecha)
        // }
        // alert.show('La descarga comenzará en breve', { type: 'success' });
        // new Promise(function(resolve, reject){
        //     ResumenDeMargenesPdf(props).then(
        //         function(blob){
        //             downloadBlob(blob,`Resumen_de_margenes_${predio.nombre_predio.replace(/ /g, '_')}.pdf`);
        //         },function(blob){
        //             alert.show('Error al exportar', { type: 'error' })
        //     })
        // })
    }

    return (
        <>
            <Row style={{ marginBottom: '10px' }}>
                <Col sm={6} md={4} lg={3} id="cont-fecha-desde">
                    <Form.Label>Fecha de Cierre</Form.Label>
                    <Fecha
                        name='inicio'
                        dateFormat='dd/MM/yyyy' // dd/MM/Y h:mm aa - dd/MM/Y - MM/Y
                        dateFormatOculto='y-m-d' // y-m-d h:i:s
                        inicial={fecha}
                        onChange={onChangeInicio}
                    />
                </Col>
                {/* <Col sm={6} md={8} lg={9} style={{ paddingTop: '32px', textAlign: 'right' }}>
                    <ButtonGroup className="float-right">
                        <DefaultButtons exportar={exportarExcel} />
                        <DefaultButtons imprimir={exportarPdf} />
                    </ButtonGroup>
                </Col> */}
            </Row>
            <Row>
                <Col>
                    <div>
                        <BaseGrid
                            columns={titulos}
                            rowGetter={i => rowsTotales[i]}
                            rowsCount={1}
                            minHeight={35}
                            RowsContainer={ContextMenuTrigger}
                            emptyRowsView={EmptyRowsView}
                            showCountRows={false} />
                    </div>
                    <div>
                        <BaseGrid
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            minHeight={200}
                            RowsContainer={ContextMenuTrigger}
                            onGridSort={(sortColumn, sortDirection) =>
                                setRows(sortRows(rows, sortColumn, sortDirection))
                            }
                            emptyRowsView={EmptyRowsView}
                            showCountRows={false} />
                    </div>
                    <div>
                        <BaseGrid
                            columns={columnsTotales}
                            rowGetter={i => rowsTotales[i]}
                            rowsCount={rowsTotales.length}
                            minHeight={142}
                            showCountRows={false} />
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default ResumenDeMargenes;