import React, { useState, useEffect, useContext } from 'react';
import { useAlert } from 'react-alert';
import BaseGrid from '../../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import Layout from '../../../componentes/Layout';
import { NumberFormatter, sortRows, EmptyRowsView } from '../../../componentes/grid/utils';
import { Api } from '../../../utils/api';
import UserContext from '../../../UserContext';
import { Row, Col, Form, ButtonGroup, Button } from 'react-bootstrap';
import { exportar, downloadBlob, getDMY, fechaHoy } from '../../../utils/Functions';
import DefaultButtons from '../../../componentes/grid/DefaultButtons';
import { CostoLitroDeLechePdf } from './pdf/CostoLitroDeLechePdf';
import ChartCostoLitroDeLeche from './ChartCostoLitroDeLeche'

function CostoLitroDeLeche(props) {
    const contexto = useContext(UserContext);
    const { ContextMenuTrigger } = Menu
    const alert = useAlert();
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [costoOportunidad, setCostoOportunidad] = useState(6);
    const [meses, setMeses] = useState(12);
    const [fechaExportar] = useState(fechaHoy());
    const [showChart, setShowChart] = useState(false);
    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                obtenerDatos();
            }
        },
        [contexto, costoOportunidad, meses]
    )
    function chart() {
        setShowChart(true);
    }
    function configurarMeses(orden) {
        var columns = [{ key: 'nombre_cuenta', name: 'Cuenta', frozen: true }];
        for (var i = 0; i < orden.length; i++) {
            columns.push({ key: orden[i].key, name: orden[i].name, width: 90, formatter: NumberFormatter });
        }
        columns.push({ key: 'totales', name: 'Totales', width: 100, formatter: NumberFormatter })
        columns.push({ key: 'promedio', name: 'Promedio', width: 90, formatter: NumberFormatter })
        return columns;
    }

    /**
     * Cargar información de la grilla
     */
    async function obtenerDatos() {
        let resultado = await Api(`api/contabilidad/costo-litro-de-leche?predio_id=${contexto.predio.predio_id}&costo_oportunidad=${costoOportunidad}&meses=${meses}`);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.detalle);
            setColumns(configurarMeses(resultado.data.meses));
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            setRows([]);
        }
    }

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        exportar(`api/exportar/contabilidad/costo-litro-de-leche?predio_id=${contexto.predio.predio_id}&costo_oportunidad=${costoOportunidad}&meses=${meses}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf() {
        let props = {
            columns: columns,
            rows: rows,
            titulo: 'Costo litro de leche',
            bajada: contexto.predio.nombre_predio,
            subtitulo: `Costo Oportunidad (%): ${costoOportunidad}, Meses: ${meses}`
        }
        alert.show('La descarga comenzará en breve', { type: 'success' });
        const sub_formated = `coportunidad_${costoOportunidad}_meses_${meses}`
        new Promise(function (resolve, reject) {
            CostoLitroDeLechePdf(props).then(
                function (blob) {
                    downloadBlob(blob, `Costo_litro_de_leche_${contexto.predio.nombre_predio.replace(/ /g, '_')}_${sub_formated}_${getDMY(fechaExportar)}.pdf`);
                }, function (blob) {
                    alert.show('Error al exportar', { type: 'error' })
                })
        })
    }

    return (
        <Layout title="Costo de Litro de Leche">
            <Row>
                <Col lg={12} style={{ marginBottom: '10px' }}>
                    <ButtonGroup className="float-right">
                        <Button
                            variant="info"
                            size="sm"
                            onClick={chart}>Gráfico</Button>
                        <DefaultButtons exportar={exportarExcel} />
                        <DefaultButtons imprimir={exportarPdf} />
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={6} md={4} lg={2}>
                    <Form.Label>Costo Oportunidad(%)</Form.Label>
                    <Form.Control
                        name="costo_oportunidad"
                        value={costoOportunidad}
                        onChange={(e) => {
                            setCostoOportunidad(e.target.value)
                        }}
                        type="number"
                        placeholder=""
                        size="sm" />
                </Col>
                <Col sm={6} md={4} lg={2}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Meses</Form.Label>
                        <Form.Control as="select" size="sm" onChange={(e) => {
                            setMeses(e.target.value)
                        }}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12" selected>12</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <BaseGrid
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            minHeight={400}
                            minColumnWidth={200}
                            RowsContainer={ContextMenuTrigger}
                            onGridSort={(sortColumn, sortDirection) =>
                                setRows(sortRows(rows, sortColumn, sortDirection))
                            }
                            rowStyle={[{
                                columna: 'nombre_cuenta',
                                valor: 'Precio Pagado',
                                clases: 'titulo-grilla',
                                css: { color: 'black', fontWeight: '500' }
                            }, {
                                columna: 'nombre_cuenta',
                                valor: 'Costo Directo',
                                clases: 'titulo-grilla',
                                css: { color: 'black', fontWeight: '500' }
                            }, {
                                columna: 'nombre_cuenta',
                                valor: 'Costo Indirecto',
                                clases: 'titulo-grilla',
                                css: { color: 'black', fontWeight: '500' }
                            }, {
                                columna: 'nombre_cuenta',
                                valor: 'Costo Total',
                                clases: 'titulo-grilla',
                                css: { color: 'black', fontWeight: '500' }
                            }, {
                                columna: 'nombre_cuenta',
                                valor: 'Gastos',
                                clases: 'titulo-grilla',
                                css: { color: 'black', fontWeight: '500' }
                            }]}
                            emptyRowsView={EmptyRowsView}
                            showCountRows={false} />
                    </div>
                </Col>
            </Row>
            <ChartCostoLitroDeLeche
                show={showChart}
                rows={rows}
                onHide={() => setShowChart(false)} />
        </Layout>
    );
}

export default CostoLitroDeLeche;