import React, {useState, useContext, useEffect} from 'react';
import {useAlert} from 'react-alert';
import BaseGrid from '../../componentes/grid/BaseGrid';
import UserContext from '../../UserContext';
import Layout from '../../componentes/Layout.js';
import {NumberFormatter} from '../../componentes/grid/utils';
import DefaultButtons from '../../componentes/grid/DefaultButtons'
import {Button, Row, Col, ButtonGroup, Form} from 'react-bootstrap';
import {Api} from '../../utils/api';
import ChartFlujoCaja from './ChartFlujoCaja';
import { exportar, downloadBlob, getDMY, fechaHoy } from '../../utils/Functions';
import {FlujoCajaPdf} from './pdf/FlujoCajaPdf';


function FlujoCaja(props) {
    const contexto = useContext(UserContext);
    const alert = useAlert();			// Para las notificaciones
    const [rows, setRows] = useState([]);
    const [rowsFlujos, setRowsFlujos] = useState([]);
    const [showChart, setShowChart] = useState(false);
    const [fechaExportar] = useState(fechaHoy());

    const [columns, setColumns] = useState([]);

    function configurarMeses(orden) {

        var columns = [{ key: 'nombre', name: 'Ítem', frozen: true, width: 300}];
        for (var i = 0; i < orden.length; i++) {
            columns.push({key: orden[i].key, name: orden[i].name, formatter: NumberFormatter});
        }
        return columns;
    }

    async function getReporte(predio) {
        setColumns([]);
        let resultado = await Api(`api/reportes/presupuesto/flujo/${predio}/${contexto.predio.periodo.id}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setColumns(configurarMeses(resultado.data.meses));
            setRows(resultado.data.detalle);
            setRowsFlujos(resultado.data.flujos);
        } else {
            setRows([])
            setRowsFlujos([])
            alert.show('Error al cargar reporte', { type: 'error' });
        }
    }


    function chart() {
        setShowChart(true);
    }

    useEffect(
        () => {
            if (contexto.predio.predio_id, contexto.predio.periodo) {
                getReporte(contexto.predio.predio_id);
            }
        },
        [contexto]
    )

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        exportar(`api/exportar/reportes/presupuesto/flujo/${contexto.predio.predio_id}/${contexto.predio.periodo.id}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf(){
        let props = {
            columns:columns,
            rows:rows,
            columnsTotales: columns,
            rowsTotales: rowsFlujos,
            titulo:"Flujo de Caja Presupuesto",
            bajada:contexto.predio.nombre_predio
        }
        alert.show('La descarga comenzará en breve', { type: 'success' });
        new Promise(function(resolve, reject){
            FlujoCajaPdf(props).then(
                function(blob){
                    downloadBlob(blob,
                        `Flujo_Caja_${contexto.predio.nombre_predio.replace(/ /g, '_')}_${getDMY(fechaExportar)}.pdf`);
                },function(blob){
                    alert.show('Error al exportar', { type: 'error' })
            })
        })
    }


    return (
        <Layout title="Flujo de Caja Presupuesto">
            <Form>
                <Form.Row style={{paddingBottom:'10px'}}>
                    <Col md={8}>
                        <Form.Group as={Row}>

                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <ButtonGroup className="float-right">
                            <Button
                                variant="info"
                                size="sm"
                                onClick={chart}>Gráfico</Button>
                            <DefaultButtons exportar={exportarExcel}/>
                            <DefaultButtons imprimir={exportarPdf}/>
                        </ButtonGroup>
                    </Col>
                </Form.Row>
            </Form>
            <div>
                <BaseGrid
                    columns={columns}
                    rowGetter={i => rows[i]}
                    rowsCount={rows.length}
                    minHeight={350}
                    showCountRows={false}
                    rowStyle={[{
                        columna: 'nombre',
                        valor: 'EGRESOS',
                        clases: 'titulo-grilla',
                        css: { color: 'black', fontWeight: '700' }
                    },{
                        columna: 'nombre',
                        valor: 'INGRESOS',
                        clases: 'titulo-grilla',
                        css: { color: 'black', fontWeight: '700' }
                    }]}/>
            </div>
            <div>
                <BaseGrid
                    columns={columns}
                    rowGetter={i => rowsFlujos[i]}
                    rowsCount={rowsFlujos.length}
                    minHeight={175}
                    showCountRows={false}/>
            </div>
            <ChartFlujoCaja
                show={showChart}
                onHide={() => setShowChart(false)} />
        </Layout>
    );
}

export default FlujoCaja;