import React, { useState, useContext, useEffect, useRef} from 'react';
import {Typeahead} from 'react-bootstrap-typeahead';
import { Button, InputGroup, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FaPlusCircle, FaTools} from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import {Api} from '../utils/api';
import UserContext from '../UserContext';
import FormularioActividad from '../modulos/configuracion/FormularioActividad';
import { ErrorMessage } from 'react-hook-form';
import { useAlert } from 'react-alert';
import { zeroFill } from './grid/utils';
import { tienePermisos } from '../utils/Functions';

function SelectorActividad(props) {
    const alert = useAlert();           // Para las notificaciones
    const refSelectorActividad = useRef();
    const contexto = useContext(UserContext);
    const [actividades, setActividades] = useState([]);
    const [oculto, setOculto] = useState('');
    const codigoMin = props.codigoMin || '';
    const codigoMax = props.codigoMax || '';
    const soloPredio = props.solo_predio || ''; // Si viene true solo traemos las actividades que tiene el predio
    const actividadesUtilizadas = props.actividadesUtilizadas || ''; // Para que no retornemos aquellas actividades que ya están siendo utilizadas por algún predio
    const [activarModalAgregar, setActivarModalAgregar] = useState(false);  // Modal para agregar registro

    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                obtenerActividades(contexto.predio.predio_id);
            }
        },
        [contexto]
    )

    useEffect(
        () => {
            if (props.inicial) {
                // console.log('valor combobox: ', refSelectorActividad.current.getInstance().state.selected.length);
                // console.log('valor: ', refSelectorActividad.current.getInstance()._input.value);
                // console.log('unidades.length: ', actividades.length);
                // console.log('props.inicial: ', props.inicial);
                if (actividades.length === 0) { // No hay actividades cargadas, tenemos que ir a buscar la seleccionada y seleccionar el combobox
                    obtenerActividad(props.inicial);
                } else { // Si hay actividades cargadas, solo seleccionamos la que esté
                    if (refSelectorActividad.current.getInstance().state.selected.length === 0) { // Solo cuando no hay un elemento seleccionado en el combobox, esto se da solo en el inicio, ref.current.getInstance()._input.value
                        actividades.forEach(actividad => {  // Buscamos dentro de las actividades la que tenemos que seleccionar
                            if (parseInt(actividad.codigo) === parseInt(props.inicial)) {
                                refSelectorActividad.current.getInstance()._updateSelected([actividad]); // Automáticamente llama cambiarSelector
                            }
                            // console.log(actividad);
                        });
                    }
                }
            }
        },
        [props.inicial]
    )

    useEffect(
        () => {
            // Solo si queremos que el elemento seleccionado sea el primero que encuentre
            // Recordar que si se actualiza actividades se volvera a ejecutar esta acción siempre y cuando inicial_primero venga como props
            if (actividades.length!== 0 && props.inicial_primero) {
                console.log('actividades.length: ', actividades.length);
                refSelectorActividad.current.getInstance()._updateSelected([actividades[0]]);
            } else if (actividades.length === 0 && props.inicial_primero) {
                refSelectorActividad.current.getInstance().clear(); // solo limpia la casila de la unidad, no hace nada más
                refSelectorActividad.current.getInstance()._updateSelected([]); // Este limpia la casilla y activa cambiarSelector
            }
        },
        [actividades] // Solo se ejecutará cuando tengamos valores en actividades
    )

    /**
     * Buscamos una actividad y actualizamos el valor por defecto del combobox
     *
     * @param {integer} predio
     */
    async function obtenerActividad(codigo) {
        let resultado = await Api(`api/actividad/plan/${contexto.predio.predio_id}?codigo=${codigo}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            if (resultado.data.datos.length > 0) {
                refSelectorActividad.current.getInstance()._updateSelected([resultado.data.datos[0]]); // Automáticamente llama cambiaSelectorActividad
            } else {
                alert.show('Error al actualizar combobox actividades', { type: 'error' });
            }
        } else {
            alert.show('Error al actualizar combobox actividades', { type: 'error' });
        }
    }

    /**
     * Cargamos las actividades en el combobox
     *
     * @param {integer} predio
     */
    async function obtenerActividades(predio) {
        let resultado = '';
        if (soloPredio) {
            resultado = await Api(`api/actividad?predio_id=${contexto.predio.predio_id}&codigo_min=${codigoMin}&codigo_max=${codigoMax}`, null, {}, true);
        } else {
            resultado = await Api(`api/actividad/plan/${predio}?codigo_min=${codigoMin}&codigo_max=${codigoMax}`, null, {}, true); //&actividades_utilizadas=${actividadesUtilizadas}
        }
        if (resultado && resultado.status === 200) {
            setActividades(resultado.data.datos);
        } else {
            setActividades([]);
        }
    }


    /**
     * Cada vez que cambia el selector actividad
     *
     * @param {array} selected
     */
    function cambiaSelectorActividad(selected) {
        if (props.returnSelectorActividad) {
            props.returnSelectorActividad(selected);
        }
        if (selected[0] !== undefined) {
            setOculto(selected[0].codigo);
        } else {
            setOculto('');
        }
    }

    /**
     * Evento para cerrar la modal agregar actividad, si viene cuentaActividad, es porque se creo uno
     *
     * @param {Object} cuentaActividad
     */
    function cerrarModalAgregar(cuentaActividad) {
        if (cuentaActividad) {
            let data = Object.assign([], actividades);
            data.push(cuentaActividad);
            setActividades(data);
            refSelectorActividad.current.getInstance()._updateSelected([cuentaActividad]); // Automáticamente llama cambiaSelectorCuenta
        }
        setActivarModalAgregar(false);
    }

    return (
        <>
        <InputGroup size="sm">
            <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1"><FaTools /></InputGroup.Text>
            </InputGroup.Prepend>
            <div style={{ width: 'calc(100% - 96px)' }}>
                { props.register ?
                    <Typeahead
                        { ...props}
                        bsSize='small'
                        maxResults={400}
                        options={actividades}
                        highlightOnlyResult={true}
                        selectHintOnEnter={true}
                        flip={true}
                        ref={refSelectorActividad}
                        isInvalid={props.errors[props.name] ? true : false}
                        onChange={cambiaSelectorActividad}
                        labelKey={(option) => `${zeroFill(option.codigo, 4)} ${option.nombre}`}
                    />
                :
                    <Typeahead
                        { ...props}
                        bsSize='small'
                        options={actividades}
                        highlightOnlyResult={true}
                        selectHintOnEnter={true}
                        flip={true}
                        ref={refSelectorActividad}
                        onChange={cambiaSelectorActividad}
                        labelKey={(option) => `${zeroFill(option.codigo, 4)} ${option.nombre}`}
                    />
                }
            </div>
            <InputGroup.Append>
                    <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Limpiar opción</Tooltip>}>
                        <Button
                            variant="outline-secondary"
                            disabled={props.desactivarBotonBorrar ? true : false}
                            onClick={() => {
                                refSelectorActividad.current.getInstance().clear();
                                setOculto('');
                                if (props.returnSelectorActividad) {
                                    props.returnSelectorActividad([]);
                                }
                            }}>
                            <IoMdClose />
                        </Button>
                    </OverlayTrigger>
                    {tienePermisos('editar',contexto.predio.perfil) &&
                        <OverlayTrigger
                            placement='top'
                            overlay={<Tooltip>Agregar</Tooltip>}>
                            <Button
                                hidden={props.ocultarBotonAgregar ? true : false}
                                onClick={() => setActivarModalAgregar(true)}
                                disabled={props.desactivarBotonAgregar ? true : false}
                                variant="outline-secondary"><FaPlusCircle />
                            </Button>
                        </OverlayTrigger>
                    }
            </InputGroup.Append>
        </InputGroup>
        {/* INPUT INVISIBLE */}
        {props.register &&
            <Form.Control
                type="text"
                name={props.name}
                value={oculto}
                hidden
                onChange={ // Este onChange no se ocupa, ya que nunca modificamos este valor directamente, solo está para que no lance error reactjs
                    (e) => {
                        // setOculto(e.target.value)
                    }
                }
                ref={props.register({
                    required: "Seleccione una opción"
                })}
                isInvalid={props.errors[props.name]}
            />
        }

        {props.errors &&
            <ErrorMessage errors={props.errors} name={props.name} as="div" className="invalid-feedback" />
        }
        <FormularioActividad
            show={activarModalAgregar}
            onHide={cerrarModalAgregar}
            actividad={''}
            size="lg"/>
        </>
    );
}

export default SelectorActividad;