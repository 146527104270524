import React, { useEffect, useState, useContext, useRef } from 'react';
import UserContext from '../../UserContext';
import { FormularioPredio, getPredio, FormularioPermisosPredio, FormularioRolesPredio } from '../../modulos/administracion/FormularioPredio';
import { FormularioCooperativa } from '../../modulos/administracion/FormularioCooperativa';
import Layout from '../../componentes/Layout';
import { Button, Col, Tabs, Tab } from 'react-bootstrap';
import { FaSave } from 'react-icons/fa';
import { capitalize, tienePermisos } from '../../utils/Functions';

function PermisosAcceso(props) {
    const [predioForm, setPredioForm] = useState(
        {
            "id": null,
            "nombre": "",
            "superficie_util": 0,
            "mano_obra_permanente": 0,
            "rol_unico_pecuario": "",
            "mes_inicio": "",
            "nombre_productor": "",
            "cooperativa": false,
            "total_cuotas": 0
        });
    const contexto = useContext(UserContext);
    const refSubmitButton = useRef();
    const [tabSeleccionado, setTabSeleccionado] = useState('formulario_predio');
    const [tipo, setTipo] = useState("")

    useEffect(
        () => {
            if (contexto.predio.predio_id !== null) {
                getPredio(contexto.predio.predio_id).then(function (predio) {
                    setPredioForm({
                        "id": predio.id,
                        "nombre": predio.nombre,
                        "superficie_util": predio.superficie_util,
                        "mano_obra_permanente": predio.mano_obra_permanente,
                        "rol_unico_pecuario": predio.rol_unico_pecuario,
                        "mes_inicio": predio.mes_inicio,
                        "nombre_productor": predio.nombre_productor,
                        "cooperativa": predio.cooperativa,
                        "total_cuotas": predio.total_cuotas
                    });
                    if (predio.cooperativa === true) {
                        setTipo("cooperativas");
                    }
                    else {
                        setTipo("predios");
                    }
                })
            }
        },
        [contexto.predio]
    )

    function funcionRetorno() {
        contexto.setPredio(contexto.predio)
    }

    /**
     * Luego de presionar un tab
     *
     * @param {*} e
     */
    function onSelect(e) {
        setTabSeleccionado(e);
    }

    return (
        <Layout title={`Configuración ${capitalize(tipo).slice(0, -1)}`}>
            <Tabs defaultActiveKey="formulario_predio" id="predio_tabs" onSelect={onSelect}>
                <Tab eventKey="formulario_predio" title={`Formulario ${capitalize(tipo).slice(0, -1)}`}>
                    <br></br>
                    {predioForm.cooperativa === true &&
                        <FormularioCooperativa
                            predio={predioForm}
                            fordwardRef={refSubmitButton}
                            funcionRetorno={funcionRetorno}
                        />
                    }
                    {predioForm.cooperativa === false &&
                        <FormularioPredio
                            predio={predioForm}
                            fordwardRef={refSubmitButton}
                            funcionRetorno={funcionRetorno}
                        />
                    }

                    <Col md={{ span: 2, offset: 10 }}>
                        {tienePermisos('editar',contexto.predio.perfil) &&
                            <Button onClick={(e) => refSubmitButton.current.click()} size="sm"
                                variant="success"><FaSave /> Guardar</Button>
                        }

                    </Col>
                </Tab>
                <Tab eventKey="permisos_acceso" title="Permisos de Acceso">
                    <br></br>
                    <FormularioPermisosPredio
                        predio={predioForm}
                        show={true}
                        tabSeleccionado={tabSeleccionado}
                    />
                </Tab>
                <Tab eventKey="roles_predio" title="Roles del Predio">
                    <br></br>
                    <FormularioRolesPredio
                        predio={predioForm}
                        show={true}
                        tabSeleccionado={tabSeleccionado}
                    />
                </Tab>
            </Tabs>
        </Layout>
    )
}

export default PermisosAcceso;