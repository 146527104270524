import React, {useState, useEffect, useContext} from 'react';
import {Modal, Button, Row, Col, Form} from 'react-bootstrap';
import { useForm, ErrorMessage } from 'react-hook-form'
import SelectorCuenta from '../../componentes/SelectorCuenta';
import {Api, ObjectToFormdata} from '../../utils/api';
import SelectorMeses from './SelectorMeses';
import UserContext from '../../UserContext';
import { useAlert } from 'react-alert';
import { FaSave, FaWindowClose } from 'react-icons/fa';
import {tienePermisos} from '../../utils/Functions'
import SelectorPeriodo from '../../componentes/SelectorPeriodo'

export function FormPresupuestoCaja(props) {
    const alert = useAlert();           // Para las notificaciones
    const { ...rest} = props
    const contexto = useContext(UserContext);
    const initState = {
        id: null
    };
    const [dato, setDato] = useState(initState);
    const [finalizar, setFinalizar] = useState(false); // Estado para SelectorMeses para evitar request al cerrar modal

    // para la validacion y envio de formulario - react hook form
    const { register, handleSubmit, watch, errors, setValue } = useForm({
        defaultValues: {
            monto_total: '',
            codigo_fecha: '',
            codigo_cuenta: '',
            tipo: 'C',
            iva: 'True',
            comentario: ''
        }
    })

    const onSubmit = data => {
        data.predio_id = contexto.predio.predio_id;
        enviarFormulario(data);
    }

    async function enviarFormulario(data){
        data = ObjectToFormdata(data);
        let resultado = await Api('api/presupuestos/caja/guardar', data, { 'Content-Type': 'application/json' }, true, 'post');
		if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            cerrarModal();
		} else {
            alert.show(resultado.data.msg, { type: 'error' });
		}
    }

    function initForm() {
        setFinalizar(false);
        if (props.presupuesto!=null){
            setValue([
                { monto_total: props.presupuesto.monto_total },
                { codigo_fecha: props.presupuesto.codigo_fecha },
                { codigo_cuenta: props.presupuesto.codigo_cuenta },
                { tipo: props.presupuesto.tipo },
                { periodo_id: props.presupuesto.periodo_id},
                { iva: props.presupuesto.iva ? 'True': 'False' },
                { comentario: props.presupuesto.comentario }
            ]);
            setDato(props.presupuesto)
        }
    }

    function closeForm() {
        setDato(initState);
    }

    function cerrarModal() {
        setDato(initState);
        setFinalizar(true);
        props.onHide();
    }

    return (
        <Modal { ...rest} onShow={initForm} onHide={closeForm}  backdrop="static" keyboard={false}>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header>
                    <Modal.Title>Presupuesto Caja</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="hidden" name="id" defaultValue={dato.id} ref={register({})}></Form.Control>
                    <Form.Group as={Row} controlId="1">
                        <Form.Label column sm="2">
                            Cuenta:
                        </Form.Label>
                        <Col sm="10">
                            <SelectorCuenta
                                id='1'
                                codigoMin={"100100"}
                                codigoMax={"599999"}
                                name="codigo_cuenta"
                                register={register}
                                errors={errors}
                                inicial={watch('codigo_cuenta')}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="selectorPeriodo">
                            <Form.Label column sm="2"> Periodo: </Form.Label>
                            <Col sm="10">
                                <SelectorPeriodo
                                    id='1'
                                    name="periodo_id"
                                    register={register}
                                    errors={errors}
                                    inicial={props.presupuestoid ? watch('periodo_id') : (contexto.predio ? (contexto.predio.periodo? contexto.predio.periodo.id:""): "")}
                                    placeholder="Seleccione el periodo"
                                    // returnSelectorActividad={returnSelectorActividad}
                                    actividadesUtilizadas={true}
                                    desactivarBotonBorrar={props.presupuestoid ? true : false}
                                />
                            </Col>
                        </Form.Group>
                    <Form.Group as={Row} controlId="2">
                        <Form.Label column sm="2">
                            Tipo:
                        </Form.Label>
                        <Col sm="10">
                            <Form.Check name='tipo' inline type='radio' label={`Ingreso`} id={`ingreso_id`}
                                value="C" ref={register({ required: true})}/>
                            <Form.Check name='tipo' inline type='radio' label={`Egreso`} id={`egreso_id`}
                                value="D" ref={register({ required: true})}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="3">
                        <Form.Label column sm="2">
                            Mes:
                        </Form.Label>
                        <Col sm="10">
                            <SelectorMeses
                                id="selectormeses"
                                name={"codigo_fecha"}
                                register={register}
                                errors={errors}
                                inicial={watch('codigo_fecha')}
                                finalizar={finalizar} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="4">
                        <Form.Label column sm="2">IVA:</Form.Label>
                        <Col sm="10">
                            <Form.Check name='iva' inline type='radio' label={`Sí`} id={`si_id`}
                                value={"True"} ref={register({ required: true})}/>
                            <Form.Check name='iva' inline type='radio' label={`No`} id={`no_id`}
                                value={"False"} ref={register({ required: true})}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="5">
                        <Form.Label column sm="2">Monto:</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                name="monto_total"
                                type="number"
                                size="sm"
                                placeholder="Ingrese el monto ($) Total"
                                ref={register({
                                    required: "Por favor, ingrese el monto total",
                                    min:{value:5,message:"mínimo 5"}
                                })}
                                isInvalid={errors.monto_total} />
                            <ErrorMessage errors={errors} name="monto_total" as="div" className="invalid-feedback" />
                        </Col>
                    </Form.Group>
                    <Form.Group controlId="textarea">
                        <Form.Label>Comentario:</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="comentario"
                            rows="2"
                            ref={register({})}/>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cerrarModal} size="sm"><FaWindowClose/> Cerrar</Button>
                    {tienePermisos('editar',contexto.predio.perfil) &&
                        <Button variant="success" type="submit" size="sm"><FaSave/> Guardar</Button>
                    }
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export async function GetPresupuestoCaja(predioId,presupuestoId){
    let resultado = await Api(`api/presupuestos/caja/${predioId}/${presupuestoId}`, null, {}, true);
    return resultado.data.dato;
}

export function FormularioEliminarPresupuestoCaja(props) {
    const alert = useAlert();           // Para las notificaciones
    const {presupuesto, funcionRetorno, ...rest} = props;

    async function eliminarPresupuesto(data){
        let resultado = await Api('api/presupuestos/caja/eliminar/'+data.id,null,{},true,'delete');
		if (resultado && resultado.status === 200) {
            alert.show(resultado.data.msg, { type: 'success' });
            props.funcionRetorno(data);
		} else {
            alert.show(resultado.data.msg, { type: 'error' });
		}
    }

    return (
        <Modal {...rest} size={props.size}>
            <Modal.Header closeButton>
                Eliminar presupuesto
            </Modal.Header>
            <Modal.Body>
                <Col sm="12">
                ¿Está seguro de eliminar el presupuesto {presupuesto?presupuesto.descripcion:""}?
                </Col>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={props.onHide}>
                    Cerrar
                    </Button>
                    <Button variant="danger" size="sm"
                        onClick={(e) => eliminarPresupuesto(presupuesto)}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}