import React, { useState, useContext, useEffect, useRef, Fragment} from 'react';
import { Button, InputGroup, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { FaSearch, FaPlusCircle} from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import {Api} from '../utils/api';
import UserContext from '../UserContext';
import { ErrorMessage } from 'react-hook-form';
import { useAlert } from 'react-alert';
import { completaRut } from './grid/utils';
import { Highlighter, Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import { groupBy } from 'lodash';
import { tienePermisos } from '../utils/Functions';

function SelectorEntidades3(props) {
    const alert = useAlert();           // Para las notificaciones
    const refSelectorEntidades = useRef();
    const contexto = useContext(UserContext);
    const [entidades, setEntidades] = useState([]);
    const [oculto, setOculto] = useState('');
    const propsData = {};
    const entidadDefault = {
        "rut": "",
        "razon_social": "",
        "giro": "",
        "comuna": "",
        "relacion": "C",
        "habilitada": true,
        "contacto": ""
    }
    const [valor, setValor] = useState([])

    propsData.renderMenu = (results, menuProps, state) => {
        let index = 0;
        const regions = groupBy(results, 'region');
        const items = Object.keys(regions).sort().map((region, keyIndex) => (
            <Fragment key={Math.random()}>
                {index !== 0 && <Menu.Divider />}
                <Menu.Header>{region}</Menu.Header>
                {regions[region].map((i) => {
                    const item =
                        <MenuItem key={(i.rut).toString() + 1} option={i} position={index}>
                            <Highlighter key={(i.rut).toString()} search=''>
                                {(completaRut(i.rut)).toString() + ' ' + i.razon_social}
                            </Highlighter>
                        </MenuItem>;

                    index += 1;
                    return item;
                })}
            </Fragment>
        ));
        return <Menu {...menuProps}>{items}</Menu>;
    };

    // Se vuelve a ejecutar cuando actualizamos entidades o props.inicial
    useEffect(
        () => {
            // console.log('1) useEffect inicial:', props.inicial)
            // console.log('1) useEffect entidades: ', entidades.length)
            if (entidades.length > 0 && (props.inicial)) {
                // console.log('2) useEffect inicial:', props.inicial)
                // Search worker default in entidades
                let workerSelectedArray = [];
                let existe = false;
                entidades.forEach(element => {
                    if (parseInt(element.id) === parseInt(props.inicial)) {
                        workerSelectedArray.push({
                            rut: element.rut,
                            razon_social: element.razon_social,
                            id: element.id,
                            direccion: element.direccion,
                            comuna: element.comuna,
                            giro: element.giro,
                            entidad_id: element.entidad_id,
                            contacto: element.contacto
                        });
                        existe = true;
                    }
                });

                // Cuando el valor seleccionado está en el array de opciones
                if (workerSelectedArray.length > 0) {
                    // console.log('3) useEffect - existe en valor en el array', workerSelectedArray)
                    setValor(workerSelectedArray)
                    setOculto(workerSelectedArray[0].id);
                    props.retornar(workerSelectedArray);
                } else { // Cuando no existe el valor seleccionado en el array, llamamos al endpoint y lo cargamos
                    // console.log('4) useEffect - NO existe en valor en el ARRAY, obtenerEntidad')
                    obtenerEntidad(props.inicial)
                }
            }
        },
        [entidades, props.inicial]
    )

    useEffect(
        () => {
            // console.log('inicial1', props.inicial)
            async function loadWorkers() {
                let resultado = await Api(`api/entidad?predio_id=${contexto.predio.predio_id}`); //&entidades_utilizadas=${entidadesUtilizadas}
                if (resultado && resultado.status === 200) {
                    // console.log('llegaron obtenerEntidades', props.inicial)
                    let entidadesData = [];
                    resultado.data.datos.forEach(dato => {
                        // console.log(dato)
                        dato.region = 'Del predio';
                        entidadesData.push(dato);
                    });
                    resultado.data.otros_predios.forEach(dato => {
                        // console.log(dato)
                        dato.region = 'Otros predios';
                        entidadesData.push(dato);
                    });
                    setEntidades(entidadesData);
                } else {
                    setEntidades([]);
                }

            }
            // search entidades only when have branch office selected
            if (contexto.predio.predio_id) {
                if (entidades.length === 0) {
                    // console.log('useEffect loadWorkers ********');
                    loadWorkers();
                }
            }
        },
        [props.inicial, contexto.predio.predio_id, entidades]
    )

    /**
     * Buscamos una entidad y actualizamos el valor por defecto del combobox
     *
     * @param {integer} predio
     */
    async function obtenerEntidad(entidadId) {
        // console.log('1) obtenerEntidad', entidadId)
        let resultado = await Api(`api/entidad/${entidadId}`);
        if (resultado && resultado.status === 200) {
            // console.log('2) obtenerEntidad')
            let existe = false;
            entidades.forEach(dato => {
                if (parseInt(dato.id) === parseInt(resultado.data.entidad)) {
                    existe = true;
                }
            });
            // console.log('3) obtenerEntidad')
            if (!existe) {
                // console.log('4) obtenerEntidad')
                resultado.data.entidad.region = 'Del predio';
                // console.log('5) obtenerEntidad')
                let entidades2 = Object.assign([], entidades);
                // console.log('6) obtenerEntidad')
                entidades2.push(resultado.data.entidad)
                // console.log('7) obtenerEntidad')
                setEntidades(entidades2);
                // console.log('8) obtenerEntidad', entidades2)
                // console.log('Se deberia volver a ejecutar useEffect, ', props.inicial)
            } else {
                // console.log('2) obtenerEntidad')
                // console.log('NO deberia volver a ejecutar useEffect', props.inicial)
                // setValor(resultado.data.entidad)
                // setOculto(resultado.data.entidad.id);
                // props.retornar([resultado.data.entidad]);
            }
        } else {
            alert.show('Error al actualizar combobox entidades', { type: 'error' });
        }
    }

    /**
     * Cada vez que cambia el selector
     *
     * @param {array} selected
     */
    function cambiaSelector(selected) {
        // console.log('cambiaSelector------', selected)
        setValor(selected)
        if (selected[0] !== undefined) {
            if (props.retornar) {
                props.retornar(selected);
            }
            setOculto(selected[0].id);
        } else {
            if (props.retornar) {
                props.retornar('');
            }
            setOculto('');
        }
    }

    return (
        <>
        <InputGroup size="sm">
            <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1"><FaSearch /></InputGroup.Text>
            </InputGroup.Prepend>
            <div style={{ width: 'calc(100% - 93px)' }}>
                { props.register ?
                    <Typeahead
                        { ...props}
                        {...propsData}
                        bsSize='small'
                        maxResults={400}
                        selected={valor}
                        options={entidades}
                        highlightOnlyResult={true}
                        selectHintOnEnter={true}
                        flip={true}
                        ref={refSelectorEntidades}
                        isInvalid={props.errors[props.name] ? true : false}
                        onChange={cambiaSelector}
                        labelKey={(option) => `${completaRut(option.rut)} ${option.razon_social}`
                        }
                        placeholder={props.placeholder ? props.placeholder : 'Buscar por nombre o rut'}
                    />
                :
                    <Typeahead
                        { ...props}
                        bsSize='small'
                        options={entidades}
                        selected={valor}
                        highlightOnlyResult={true}
                        selectHintOnEnter={true}
                        flip={true}
                        ref={refSelectorEntidades}
                        onChange={cambiaSelector}
                        labelKey={(option) => `${completaRut(option.rut)} ${option.razon_social}`}
                        placeholder={props.placeholder ? props.placeholder : 'Buscar por nombre o rut'}
                    />
                }
            </div>
            <InputGroup.Append>
                <OverlayTrigger
                    placement='top'
                    overlay={<Tooltip>Limpiar opción</Tooltip>}>
                    <Button
                        variant="outline-secondary"
                        disabled={props.desactivarBotonBorrar ? true : false}
                        onClick={() => {
                            refSelectorEntidades.current.clear(); // solo limpia la casila de la unidad, no hace nada más
                            setValor([])
                            setOculto('')
                            if (props.retornar) {
                                props.retornar('');
                            }
                        }}>
                        <IoMdClose />
                    </Button>
                </OverlayTrigger>
                {/* El formulario botón agregar entidad debe estar fuera del react-hook-form que tiene este combobox,
                 si no cuando presionemos aceptar en el formulario, se activará el otro formulario(no se puede tener un
                 formulario dentro de otro formulario, genera problemas)*/}
                {tienePermisos('editar',contexto.predio.perfil) &&
                    <OverlayTrigger
                        placement='top'
                        overlay={<Tooltip>Agregar</Tooltip>}>
                        <Button
                            // hidden={props.ocultarBotonAgregar ? true : false}
                            onClick={() => props.clickBotonAgregar()}
                            // disabled={props.desactivarBotonAgregar ? true : false}
                            variant="outline-secondary"><FaPlusCircle />
                        </Button>
                    </OverlayTrigger>
                }
            </InputGroup.Append>
        </InputGroup>
        {/* INPUT INVISIBLE */}
        {props.register &&
            <Form.Control
                type="text"
                name={props.name}
                value={oculto}
                hidden
                onChange={ // Este onChange no se ocupa, ya que nunca modificamos este valor directamente, solo está para que no lance error reactjs
                    (e) => {
                        // setOculto(e.target.value)
                    }
                }
                ref={props.register({
                    required: (props.requerido ? "Seleccione una opción" : '')
                })}
                isInvalid={props.errors[props.name]}
            />
        }

        {props.errors &&
            <ErrorMessage errors={props.errors} name={props.name} as="div" className="invalid-feedback" />
        }
        </>
    );
}

export default SelectorEntidades3;