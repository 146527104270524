import React from 'react';
import {BaseStylesPdf} from '../../../componentes/pdf/BaseStylesPdf';
import {BaseLinkPdf} from '../../../componentes/pdf/BaseLinkPdf';
import {Table, TableHeader, TableBody, TableCell, DataTableCell} from '@david.kucsai/react-pdf-table';
import {completaRut, RelFormatter, VigFormatter } from '../../../componentes/grid/utils';

export function EntidadesPdf(props) {
    const styles = BaseStylesPdf();
    const tablaEntidades =(
        <Table data={props.rows}>
            <TableHeader textAlign={"center"}>
                {/* el "weighting" idealmente debe sumar 1 para que quede todo ordenado */}
                <TableCell style={styles.tableHeader} weighting={0.15}>Rut</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.25}>Razón Social</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.15}>Giro</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.15}>Comuna</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.1}>Relación</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.1}>Vigencia</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.1}>Contacto</TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell style={{textAlign:"center", fontSize:10}} weighting={0.148} getContent={(r) => completaRut(r.rut)}/>
                <DataTableCell style={{textAlign:"center", fontSize:10}} weighting={0.243} getContent={(r) => r.razon_social}/>
                <DataTableCell style={{textAlign:"center", fontSize:10}} weighting={0.148} getContent={(r) => r.giro}/>
                <DataTableCell style={{textAlign:"center", fontSize:10}} weighting={0.148} getContent={(r) => r.comuna}/>
                <DataTableCell style={{textAlign:"center", fontSize:10}} weighting={0.1} getContent={(r) => RelFormatter({value: r.relacion})}/>
                <DataTableCell style={{textAlign:"center", fontSize:10}} weighting={0.1} getContent={(r) => VigFormatter({value:r.habilitada})}/>
                <DataTableCell style={{textAlign:"center", fontSize:10}} weighting={0.1} getContent={(r) => r.contacto}/>
            </TableBody>
        </Table>)

    let blob = BaseLinkPdf({...props,body:tablaEntidades})
        
    return blob
}