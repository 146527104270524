import React, {useState, useContext, useEffect} from 'react';
import {Api} from '../../utils/api';
import UserContext from '../../UserContext';
import {Modal, Col, Tabs, Tab} from 'react-bootstrap';
import BaseGrid from '../../componentes/grid/BaseGrid';
import {NumberFormatter, DecimalFormatter, CheckFormatter} from '../../componentes/grid/utils';


function InventarioBalance(props) {

    const { cuenta, showInventarioBalance, hide, logout,  ...rest} = props
    const contexto = useContext(UserContext)
    const [rows, setRows] = useState([]);

    const columns = [
        {key: 'nombre_cuenta', name: 'Cuenta', sortable: true},
        {key: 'item', name: 'Item', width: 250},
        {key: 'fabricacion', name: 'Fabricacion', width: 70},
        {key: 'cantidad', name: 'Ctd.', width: 50, formatter: DecimalFormatter},
        {key: 'unidad_abreviacion', name: 'Un.', width: 50, formatter: NumberFormatter},
        {key: 'valor_unitario', name: 'Valor Un.', formatter: NumberFormatter},
        {key: 'valor_total', name: 'Valor total', formatter: NumberFormatter},
        {key: 'depreciacion', name: 'Depreciacion', formatter: CheckFormatter},
        {key: 'tiempo_depreciacion', name: 'Años Dep.', width: 70},
        {key: 'valor_residual', name: 'Valor Residual', width: 70, formatter: NumberFormatter},
     ];

    async function getReporte() {
        var periodo = contexto.predio.periodo.id;
        let resultado = await Api(`api/inventario/${periodo}/categoria/${cuenta.codigo_cuenta}/${cuenta.codigo_maximo}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
        } else {
            console.log('viene un error');
            setRows([]);
        }
    }

    useEffect(
        () => {
            if (contexto.predio.predio_id && showInventarioBalance) {
                getReporte();
            }
        },
        [contexto, cuenta]
    )

    return (
        <Modal {...rest} size="lg" onShow={getReporte}>
            <Modal.Header closeButton>
                <Modal.Title>Inventario en {cuenta.codigo_cuenta} {cuenta.nombre_cuenta} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Tabs defaultActiveKey="grilla" id="grilla">
                <Tab eventKey="grilla" title="Inventario">
                    <Col sm="12">
                    <div><br/>
                        <BaseGrid
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            minHeight={400}/>
                    </div>
                    </Col>
                </Tab>
            </Tabs>
            </Modal.Body>
        </Modal>
    );
}
export default InventarioBalance;