import React from 'react';
import { BaseStylesPdf } from '../../../componentes/pdf/BaseStylesPdf';
import { BaseLinkPdf } from '../../../componentes/pdf/BaseLinkPdf';
import { Table, TableHeader, TableBody, TableCell, DataTableCell } from '@david.kucsai/react-pdf-table';
import { separadorDeMiles } from '../../../componentes/grid/utils';

export function BalanceInicialPdf(props) {
    const styles = BaseStylesPdf();
    const tablaBalanceInicial = (
        <>
        <Table data={props.rows}>
            <TableHeader textAlign={"center"}>
                {/* el "weighting" idealmente debe sumar 1 para que quede todo ordenado */}
                <TableCell style={styles.tableHeader} weighting={0.08}>Cuenta</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.2}>Descripcion</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.13}>Débito</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.13}>Crédito</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.08}>Cantidad</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.08}>Unidad</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.17}>Inventario</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.15}>Comentario</TableCell>
            </TableHeader>
            <TableBody>
                <DataTableCell style={styles.textCenter} weighting={0.08} getContent={(r) => r.codigo_cuenta}/>
                <DataTableCell style={styles.textLeft}weighting={0.2} getContent={(r) => r.nombre_cuenta}/>
                <DataTableCell style={styles.textRigh} weighting={0.13} getContent={(r) => separadorDeMiles(r.debito)}/>
                <DataTableCell style={styles.textRigh} weighting={0.13} getContent={(r) => separadorDeMiles(r.credito)}/>
                <DataTableCell style={styles.textRigh} weighting={0.08} getContent={(r) => r.cantidad}/>
                <DataTableCell style={styles.textCenter} weighting={0.08} getContent={(r) => r.unidad}/>
                <DataTableCell style={styles.textLeft} weighting={0.17} getContent={(r) => r.inventario}/>
                <DataTableCell style={styles.textLeft} weighting={0.15} getContent={(r) => r.comentario}/>
            </TableBody>
        </Table>
        <Table data={props.rowsTotales}>
        <TableHeader textAlign={"center"}>
                <TableCell style={styles.tableHeader} weighting={0.08}></TableCell>
                <TableCell style={styles.tableHeader} weighting={0.2}>Capital Propio</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.13}>Total Débito</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.13}>Total Crédito</TableCell>
                <TableCell style={styles.tableHeader} weighting={0.08}></TableCell>
                <TableCell style={styles.tableHeader} weighting={0.08}></TableCell>
                <TableCell style={styles.tableHeader} weighting={0.17}></TableCell>
                <TableCell style={styles.tableHeader} weighting={0.15}></TableCell>
        </TableHeader>
        <TableBody>
                <DataTableCell style={styles.textCenter} weighting={0.08} getContent={(r) => r.c1}/>
                <DataTableCell style={styles.textLeft}weighting={0.2} getContent={(r) => separadorDeMiles(r.capital_propio_cd)}/>
                <DataTableCell style={styles.textRigh} weighting={0.13} getContent={(r) => separadorDeMiles(r.total_debito)}/>
                <DataTableCell style={styles.textRigh} weighting={0.13} getContent={(r) => separadorDeMiles(r.total_credito)}/>
                <DataTableCell style={styles.textRigh} weighting={0.08} getContent={(r) => (r.c2)}/>
                <DataTableCell style={styles.textCenter} weighting={0.08} getContent={(r) => r.c3}/>
                <DataTableCell style={styles.textLeft} weighting={0.17} getContent={(r) => r.c4}/>
                <DataTableCell style={styles.textLeft} weighting={0.15} getContent={(r) => r.c5}/>
        </TableBody>
    </Table>
    </>)

    let blob = BaseLinkPdf({ ...props, body: tablaBalanceInicial, orientation: "landscape" })

    return blob
}