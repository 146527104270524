import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../../UserContext';
import { Form, Button, Col, Modal, Row, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useForm, ErrorMessage } from 'react-hook-form'
import { useAlert } from 'react-alert';
import { Api } from '../../utils/api';
import { tienePermisos } from '../../utils/Functions'
import { FaSave, FaWindowClose } from 'react-icons/fa';
import SelectorCategoriaCuenta from '../../componentes/SelectorCategoriaCuenta';

function FormularioCuenta(props) {
    const contexto = useContext(UserContext);
    const alert = useAlert();           // Para las notificaciones
    const { cuenta, ...rest } = props;
    const { register, handleSubmit, watch, errors, setValue } = useForm({
        defaultValues: {
            categoria_cuenta_id: '',
            nombre: '',
            codigo: '',
            habilitada: 'SI',
            depreciacion: ''
        }
    });
    const inicial = {
        codigo_minimo: '',
        codigo_maximo: ''
    }
    const [global, setGlobal] = useState(inicial);
    const [cuentasUtilizadas, setCuentasUtilizadas] = useState([]);
    const [cuentaBaseSeleccionada, setCuentaBaseSeleccionada] = useState([]);
    const [cuentasBaseDisponibles, setCuentasBaseDisponibles] = useState([]);

    useEffect(
        () => {
            if (cuenta) {
                setValue([
                    { categoria_cuenta_id: cuenta.categoria_id },
                    { nombre: cuenta.nombre },
                    { codigo: cuenta.codigo },
                    { habilitada: (cuenta.habilitada ? 'SI' : 'NO') },
                    { depreciacion: cuenta.depreciacion }
                ]);
            } else {
                setGlobal(inicial);
            }
        },
        [cuenta]
    )
    /**
     * Cuando el formulario está validado
     *
     * @param {*} data
     * @returns
     */
    async function guardar(data) {
        data.predio_id = contexto.predio.predio_id;
        if (cuenta === '') { // Crear
            let resultado = await Api('api/cuenta', data, { 'Content-Type': 'application/json' }, true, 'post');
            if (resultado && resultado.status === 200) { // Presupuesto creado
                alert.show(resultado.data.msg, { type: 'success' });
                cerrarModal(resultado.data.datos);
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
            }
        } else { // Actualizar
            let resultado = await Api(`api/cuenta/${cuenta.id}`, data, { 'Content-Type': 'application/json' }, true, 'put');
            if (resultado && resultado.status === 200) { // Presupuesto creado
                alert.show(resultado.data.msg, { type: 'success' });
            } else {
                alert.show(resultado.data.msg, { type: 'error' });
            }
        }
    }

    /**
     * Cargar las cuentas utilizadas y mostramos en el formulario
     *
     * @param {*} data
     * @returns
     */
    async function getCuentasUtilizadas(categoriaId) {
        let resultado = await Api(`api/predio/${contexto.predio.predio_id}/cuentas-utilizadas?categoria_id=${categoriaId}`, '', { 'Content-Type': 'application/json' }, true, 'get');
        if (resultado && resultado.status === 200) {
            setCuentasUtilizadas(resultado.data.datos);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
        }
    }

    /**
     * Cargar las cuentas base disponibles
     *
     * @param {*} data
     * @returns
     */
    async function getCuentasBaseDisponible(categoriaId) {
        let resultado = await Api(`api/cuenta/base?categoria_id=${categoriaId}&no_utilizadas=true&predio_id=${contexto.predio.predio_id}`, '', { 'Content-Type': 'application/json' }, true, 'get');
        if (resultado && resultado.status === 200) {
            setCuentasBaseDisponibles(resultado.data.datos);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
        }
    }

    /**
     * Retorna la categoria cuenta seleccionada, actualizamos codigo_minimo y codigo_maximo
     *
     * @param {Object} data
     */
    function returnSelectorCategoriaCuenta(categoria) {
        if (categoria.length > 0) {
            getCuentasUtilizadas(categoria[0].id);
            getCuentasBaseDisponible(categoria[0].id);
            setGlobal({
                ...global,
                codigo_minimo: categoria[0].codigo_minimo,
                codigo_maximo: categoria[0].codigo_maximo
            });
        } else {
            setCuentasUtilizadas([]);
            setCuentasBaseDisponibles([]);
            setGlobal({
                ...global,
                codigo_minimo: '',
                codigo_maximo: ''
            })
        }
    }

    /**
     * El callback cerrar modal le pasamos el elemento creado, si no vacio
     *
     * @param {*} data
     */
    function cerrarModal(data) {
        setGlobal({
            ...global,
            codigo_minimo: '',
            codigo_maximo: ''
        });
        setCuentasUtilizadas([]);
        setCuentaBaseSeleccionada('');
        setCuentasBaseDisponibles([]);
        props.onHide(data);
    }

    /**
     * Cuando quiere rellenar el formulario desde una cuenta base disponible
     *
     * @param {integer} cuentaBaseId
     */
    async function agregarCuentaBase(cuentaBaseId) {
        let resultado = await Api(`api/cuenta/base?id=${cuentaBaseId}`, '', { 'Content-Type': 'application/json' }, true, 'get');
        if (resultado && resultado.status === 200) {
            if (resultado.data.datos.length === 1) {
                let cuentaBase = resultado.data.datos[0];
                setCuentaBaseSeleccionada(cuentaBase);
                setValue([
                    { nombre: cuentaBase.nombre },
                    { codigo: cuentaBase.codigo },
                    { depreciacion: cuentaBase.depreciacion }
                ]);
            } else {
                alert.show('Información no disponible', { type: 'error' });
            }
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
        }
    }

    return (
        <Modal {...rest} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>{cuenta ? 'Editar' : 'Agregar'} Cuenta</Modal.Title>
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit(guardar)}>
                <Modal.Body>
                    <Row>
                        <Form.Label column sm="4"> Categoría: </Form.Label>
                        <Col sm="8">
                            <SelectorCategoriaCuenta
                                id='selectorCategoriaCuenta'
                                name="categoria_cuenta_id"
                                register={register}
                                errors={errors}
                                inicial={watch('categoria_cuenta_id')}
                                funcionRetorno={returnSelectorCategoriaCuenta}
                                actividadesUtilizadas={true}
                                placeholder=""
                                disabled={cuenta ? true : false}
                                desactivarBotonBorrar={cuenta ? true : false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="4">Rangos:</Form.Label>
                        <Col sm="8">
                            {global.codigo_minimo} - {global.codigo_maximo}
                        </Col>
                    </Row>
                    {!cuenta &&
                        <>
                            {cuentasBaseDisponibles.length > 0 &&
                                <Row style={{ marginBottom: '5px' }}>
                                    <Form.Label column sm="4">Cuentas base disponibles:</Form.Label>
                                    <Col sm="8">
                                        <label>Puedes utilizar una cuenta del plan base haciendo click en ella</label>
                                        {cuentasBaseDisponibles.map((p) =>
                                            <OverlayTrigger
                                                key={'top' + p.id}
                                                placement={'top'}
                                                overlay={
                                                    <Tooltip>
                                                        Presione para utilizar
                                                </Tooltip>
                                                }>
                                                <Button key={p.id} variant={cuentaBaseSeleccionada.id === p.id ? 'success' : 'info'} size="sm" onClick={(e) => agregarCuentaBase(p.id)} style={{ marginRight: '2px', padding: '0px 5px' }}>{p.codigo + ': ' + p.nombre}</Button>
                                            </OverlayTrigger>
                                        )}
                                    </Col>
                                </Row>
                            }
                            {cuentasUtilizadas.length > 0 &&
                                <Row style={{ marginBottom: '10px' }}>
                                    <Form.Label column sm="4">Cuentas utilizadas:</Form.Label>
                                    <Col sm="8">
                                        {cuentasUtilizadas.map((p) =>
                                            <Badge key={p.id} variant="light" style={{ marginRight: '2px', border: '1px solid #212529' }}>{p.codigo + ': ' + p.nombre}</Badge>
                                        )}
                                    </Col>
                                </Row>
                            }
                        </>
                    }
                    <Row>
                        <Form.Label column sm="4">Nombre:</Form.Label>
                        <Col sm="8">
                            <Form.Control
                                name="nombre"
                                type="text"
                                placeholder=""
                                size="sm"
                                ref={register({
                                    required: "Por favor, ingrese el nombre"
                                })}
                                isInvalid={errors.nombre} />
                            <ErrorMessage errors={errors} name="nombre" as="div" className="invalid-feedback" />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="4">Código:</Form.Label>
                        <Col sm="8">
                            <Form.Control
                                name="codigo"
                                type="number"
                                placeholder=""
                                size="sm"
                                ref={register({
                                    required: "Por favor, ingrese el código"
                                })}
                                // disabled={cuenta ? true : false}
                                isInvalid={errors.codigo} />
                            <ErrorMessage errors={errors} name="codigo" as="div" className="invalid-feedback" />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="4">Habilitada:</Form.Label>
                        <Col sm="8">
                            <Form.Check name='habilitada' inline type='radio' label={`Sí`} id={`habilitada_si`}
                                value={'SI'} ref={register({ required: true })} />
                            <Form.Check name='habilitada' inline type='radio' label={`No`} id={`habilitada_no`}
                                value={'NO'} ref={register({ required: true })} />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label column sm="4">Depreciación:</Form.Label>
                        <Col sm="8">
                            <Form.Control
                                name="depreciacion"
                                type="number"
                                placeholder=""
                                size="sm"
                                ref={register({
                                    required: "Por favor, ingrese la depreciación"
                                })}
                                isInvalid={errors.depreciacion} />
                            <ErrorMessage errors={errors} name="depreciacion" as="div" className="invalid-feedback" />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={(e) => cerrarModal('')}><FaWindowClose /> Cerrar</Button>
                    {tienePermisos('editar',contexto.predio.perfil) &&
                        <Button type="submit" size="sm" variant="success"><FaSave /> guardar</Button>
                    }

                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default FormularioCuenta;