import React, { useState, useEffect, useContext } from 'react';
import { useAlert } from 'react-alert';
import BaseGrid from '../../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import Layout from '../../../componentes/Layout';
import { NumberFormatter, sortRows, EmptyRowsView } from '../../../componentes/grid/utils';
import { Api } from '../../../utils/api';
import UserContext from '../../../UserContext';
import { Row, Col, ButtonGroup,Button } from 'react-bootstrap';
import { exportar, downloadBlob } from '../../../utils/Functions';
import DefaultButtons from '../../../componentes/grid/DefaultButtons';
import { MovimientoDeCajaPdf } from './pdf/MovimientoDeCajaPdf';
import ChartMovimientosDeCaja from './ChartMovimientosDeCaja'
import { fechaHoy, getDMY } from '../../../utils/Functions';

function MovimientosDeCaja(props) {
    const [showChart, setShowChart] = useState(false);
    const contexto = useContext(UserContext);
    const { ContextMenuTrigger } = Menu
    const alert = useAlert();
    const [rows, setRows] = useState([]);
    const [rowsTotales, setRowsTotales] = useState([]);
    const [columns, setColumns] = useState([]);
    const [meses, setMeses] = useState([]);
    const [columnsTotales, setColumnsTotales] = useState([]);
    const [fechaExportar] = useState(fechaHoy());

    function chart() {
        setShowChart(true);
    }

    useEffect(
        () => {
            if (contexto.predio.predio_id, contexto.predio.periodo) {
                obtenerDatos();
            }
        },
        [contexto]
    )

    function configurarMeses(orden) {
        var columns = [{ key: 'nombre_cuenta', name: 'Cuenta', frozen: true }];
        for (var i = 0; i < orden.length; i++) {
            columns.push({ key: orden[i].key, name: orden[i].name, width: 85, formatter: NumberFormatter });
        }
        return columns;
    }

    function configurarMesesTotales(orden) {
        var columns = [{ key: 'titulo', name: '', frozen: true }];
        for (var i = 0; i < orden.length; i++) {
            columns.push({ key: orden[i].key, name: orden[i].name, width: 85, formatter: NumberFormatter });
        }
        return columns;
    }

    /**
     * Cargar información de la grilla
     */
    async function obtenerDatos() {
        let resultado = await Api(`api/contabilidad/movimientos-de-caja?predio_id=${contexto.predio.predio_id}&periodo_id=${contexto.predio.periodo.id}`);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.detalle);
            setRowsTotales(resultado.data.resultados);
            setColumns(configurarMeses(resultado.data.meses));
            setColumnsTotales(configurarMesesTotales(resultado.data.meses));
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
            setRows([]);
            setRowsTotales([]);
        }
    }

    /**
     * Permite exportar la información de la grilla a excel
     */
    function exportarExcel() {
        exportar(`api/exportar/contabilidad/movimientos-de-caja?predio_id=${contexto.predio.predio_id}&periodo_id=${contexto.predio.periodo.id}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function exportarPdf() {
        let props = {
            columns: columns,
            rows: rows,
            rowsTotales: rowsTotales,
            titulo: 'Movimiento de Caja',
            bajada: contexto.predio.nombre_predio
        }
        alert.show('La descarga comenzará en breve', { type: 'success' });
        new Promise(function (resolve, reject) {
            MovimientoDeCajaPdf(props).then(
                function (blob) {
                    downloadBlob(blob, `Movimiento_de_caja_${contexto.predio.nombre_predio.replace(/ /g, '_')}_${getDMY(fechaExportar)}.pdf`);
                }, function (blob) {
                    alert.show('Error al exportar', { type: 'error' })
                })
        })
    }

    return (
        <Layout title="Movimientos de Caja">
            <Row>
                <Col lg={12} style={{marginBottom: '10px'}}>
                    <ButtonGroup className="float-right">
                        <Button
                            variant="info"
                            size="sm"
                            onClick={chart}>Gráfico</Button>
                        <DefaultButtons exportar={exportarExcel} />
                        <DefaultButtons imprimir={exportarPdf} />
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <BaseGrid
                            columns={columns}
                            rowGetter={i => rows[i]}
                            rowsCount={rows.length}
                            minHeight={400}
                            RowsContainer={ContextMenuTrigger}
                            onGridSort={(sortColumn, sortDirection) =>
                                setRows(sortRows(rows, sortColumn, sortDirection))
                            }
                            rowStyle={[{
                                columna: 'nombre_cuenta',
                                valor: 'EGRESOS',
                                clases: 'titulo-grilla',
                                css: { color: 'black', fontWeight: '700' }
                            }, {
                                columna: 'nombre_cuenta',
                                valor: 'INGRESOS',
                                clases: 'titulo-grilla',
                                css: { color: 'black', fontWeight: '700' }
                            }]}
                            emptyRowsView={EmptyRowsView}
                            showCountRows={false} />
                    </div>
                    <div>
                        <BaseGrid
                            columns={columnsTotales}
                            rowGetter={i => rowsTotales[i]}
                            rowsCount={rowsTotales.length}
                            minHeight={175}
                            RowsContainer={ContextMenuTrigger}
                            emptyRowsView={EmptyRowsView}
                            showCountRows={false} />
                    </div>
                </Col>
            </Row>
            <ChartMovimientosDeCaja
                show={showChart}
                onHide={() => setShowChart(false)} />
        </Layout>
    );
}

export default MovimientosDeCaja;