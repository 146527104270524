import React, {useState, useEffect, useContext} from 'react';
import {useAlert} from 'react-alert';
import BaseGrid from '../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import Layout from '../../componentes/Layout';
import {NumberFormatter, LongTextFormatter, sortRows, EmptyRowsView, RutFormatter, DateFormatter} from '../../componentes/grid/utils';
import {Api} from '../../utils/api';
import UserContext from '../../UserContext';
import {Button, Row, Col, ButtonGroup} from 'react-bootstrap';
import DefaultButtons from '../../componentes/grid/DefaultButtons'
import Buscador from '../../componentes/Buscador';
import GridMenu from '../../componentes/grid/GridMenu';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import ModalEliminar from '../../componentes/ModalEliminar';
import { exportar, downloadBlob, tienePermisos, fechaHoy, getDMY} from '../../utils/Functions';
import { DocumentosPdf2 } from './pdf/DocumentosPdf2';
import FormExcel from './FormExcel';

function Documentos(props) {

    const {ContextMenuTrigger} = Menu
    const [textoBuscar, setTextoBuscar] = useState('');
    const alert = useAlert();			// Para las notificaciones
    const [fechaExportar] = useState(fechaHoy());
    const [cargaExcel, setCargaExcel] = useState(false);


    const columns = [
        {key: 'fecha', name: 'Fecha', formatter: DateFormatter, sortable: true},
        {key: 'folio', name: 'Folio', formatter: NumberFormatter, sortable: true},
        {key: 'tipo_desc', name: 'Tipo', formatter: LongTextFormatter, sortable: true},
        {key: 'rut_entidad', name: 'Rut', width: 130, formatter: RutFormatter, sortable: true},
        {key: 'razon_social', name: 'Razón Social', width: 300, minColumnWidth: 300, sortable: true},
        {key: 'total_neto', name: 'Total Neto', formatter: NumberFormatter, sortable: true},
        {key: 'total_impuesto', name: 'Impuestos', formatter: NumberFormatter, sortable: true},
        {key: 'total_bruto', name: 'Total Bruto', formatter: NumberFormatter, sortable: true},
     ];

     const columns2 = [
        {key: 'fecha_documento', name: 'Fecha', formatter: DateFormatter, sortable: true},
        {key: 'documento_folio', name: 'Folio', formatter: NumberFormatter, sortable: true},
        {key: 'numero_cheque', name: 'Numero Cheque', formatter: LongTextFormatter},
        {key: 'unidad', name: 'Unidad', formatter: LongTextFormatter},
        {key: 'cantidad', name: 'Cantidad', formatter: LongTextFormatter},
        {key: 'actividad_codigo', name: 'Código', width: 30, formatter: NumberFormatter},
        {key: 'nombre_actividad', name: 'Actividad', width: 130, formatter: LongTextFormatter},
        {key: 'glosa', name: 'Glosa', width: 300, minColumnWidth: 300},
        {key: 'nombre_cuenta_provee', name: 'Cuenta Provee', formatter: NumberFormatter},
        {key: 'nombre_cuenta_recibe', name: 'Cuenta Recibe', formatter: NumberFormatter},
        {key: 'monto_total_bruto', name: 'Monto', formatter: NumberFormatter},
        {key: 'especifico_recuperable', name: 'Esp.Recuperable', formatter: NumberFormatter},
        {key: 'especifico_no_recuperable', name: 'Esp.No Recuperable', formatter: NumberFormatter},
     ];
    const [rows, setRows] = useState([]);
    const [rowsPDF, setRowsPDF] = useState([]);
    const [descarga, setDescarga] = useState(false);
    const contexto = useContext(UserContext);
    // Modal eliminar
    const [showEliminarDocumento, setShowEliminarDocumento] = useState(false);
    const [msgEliminar, setMsgEliminar] = useState('');
    const [documentoSeleccionado, setDocumentoSeleccionado] = useState('');

    useEffect(
        () => {
            if (contexto.predio.predio_id) {
                obtenerDatos(contexto.predio.predio_id);
            }
        },
        [contexto, cargaExcel]
    )
    useEffect(
        () => {
            if(contexto.predio.predio_id && descarga){
                descargarPDF()
            }
        },
        [rowsPDF,descarga]
    )
    async function obtenerDatos(predio) {
        let resultado = await Api(`api/contabilidad/documentos?predio_id=${predio}&fecha_inicio=${contexto.predio.periodo.inicio}&fecha_cierre=${contexto.predio.periodo.cierre}`);
        if (resultado && resultado.status === 200) {
            setRows(resultado.data.datos);
        } else {
            alert.show('Error al cargar documentos', { type: 'error' });
            setRows([]);
        }
    }

    async function retornoBusqueda(texto) {
        setTextoBuscar(texto);
        let resultado = await Api(`api/contabilidad/documentos?predio_id=${contexto.predio.predio_id}&texto=${texto}&fecha_inicio=${contexto.predio.periodo.inicio}&fecha_cierre=${contexto.predio.periodo.cierre}`);
		if (resultado && resultado.status === 200) {
			setRows(resultado.data.datos);
		} else {
            setRows([])
			alert.show('Error al cargar documentos', { type: 'error' });
		}
	}

    const editRow = rowIdx => {
        props.history.push('/contabilidad/documento/' + rows[rowIdx].id );
    };

    /**
     * Cuando queremos eliminar un documento
     *
     * @param {integer} fila
     */
    async function deleteRow(rowIdx) {
        let resultado = await Api(`api/contabilidad/documento/${rows[rowIdx].id}?movimientos=true`);
        if (resultado && resultado.status === 200) {
            let doc = resultado.data.datos[0];
            setDocumentoSeleccionado(doc);
            setMsgEliminar(`¿Estás seguro de eliminar?<br><br><b>Folio:</b>
                         ${doc.folio} </br>
                         <b>Razón social:</b> ${doc.razon_social}<br>
                         <b>Movimientos:</b> ${doc.movimientos.length}`);
            setShowEliminarDocumento(true);
        } else {
            alert.show('Error al cargar documento', { type: 'error' });
        }
    }

    /**
     * Confirmar eliminar documento
     */
    async function retornoEliminarDocumento() {
        let resultado = await Api(`api/contabilidad/documento/${documentoSeleccionado.id}`, null, {}, true, 'delete');
        if (resultado && resultado.status === 200) {
            setShowEliminarDocumento(false);
            setDocumentoSeleccionado('');
            alert.show(resultado.data.msg, { type: 'success' });
            obtenerDatos(contexto.predio.predio_id);
        } else {
            alert.show(resultado.data.msg, { type: 'error' });
        }
    }

    /**
    * Permite exportar la información de la grilla a excel
    */
    function exportarExcel() {
        //exportar(`api/exportar/contabilidad/documentos?predio_id=${contexto.predio.predio_id}&texto=${textoBuscar}`, alert);
        exportar(`api/exportar/contabilidad/movimientos/${contexto.predio.predio_id}?fecha_inicio=${contexto.predio.periodo.inicio}&fecha_cierre=${contexto.predio.periodo.cierre}`, alert);
    }

    /**
     * Permite exportar la información de la grilla a PDF
     */
    function descargarPDF(){
        let props = {
            columns: columns2,
            rows: rowsPDF,
            titulo: "Documentos",
            bajada: contexto.predio.nombre_predio
        }
        alert.show('La descarga comenzará en breve', { type: 'success' });
        new Promise(function (resolve, reject) {
            DocumentosPdf2(props).then(
                function (blob) {
                    downloadBlob(blob,
                        `Movimientos_${contexto.predio.nombre_predio.replace(/ /g, '_')}_${getDMY(fechaExportar)}.pdf`);
                }, function (blob) {
                    alert.show('Error al exportar', { type: 'error' })
                })
        })
        setDescarga(false)
    }
    async function exportarPdf() {
        let resultado = await Api(`api/contabilidad/movimientos/${contexto.predio.predio_id}?fecha_inicio=${contexto.predio.periodo.inicio}&fecha_cierre=${contexto.predio.periodo.cierre}`)
        if (resultado && resultado.status === 200){
            setRowsPDF(resultado.data.datos)
        }
        else{
            setRowsPDF("")
        }
        setDescarga(true)
    }

    function nuevoExcel() {
        setCargaExcel(true)
    }

    return (
        <>
            <Layout title="Documentos Contables">
                <Row style={{paddingBottom:'10px'}}>
                    <Col>
                        <Buscador retornoBusqueda={retornoBusqueda} busqueda={textoBuscar} placeholder='Búsqueda de documentos...'/>
                    </Col>
                    <Col>
                        <ButtonGroup className="float-right">
                            <Button color="info" style={{float:'right',color:'white'}} onClick={nuevoExcel} size="sm" className="mr-1">
                                Importar desde excel
                            </Button>
                            {tienePermisos('editar',contexto.predio.perfil) &&
                                <Link to={'/contabilidad/documento/crear'}>
                                    <Button
                                        variant="success"
                                        size="sm">
                                        <FaPlus/> Agregar Documento
                                    </Button>
                                </Link>
                            }
                            <DefaultButtons exportar={exportarExcel}/>
                            <DefaultButtons imprimir={exportarPdf} />
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                            <BaseGrid
                                columns={columns}
                                rowGetter={i => rows[i]}
                                rowsCount={rows.length}
                                minHeight={400}
                                contextMenu={
                                    <GridMenu
                                        id="pac_1"
                                        options={ [
                                            {texto:"Editar",
                                            funcion: (e, { rowIdx }) => editRow(rowIdx)},
                                            {texto:"Eliminar",
                                            funcion: (e, { rowIdx }) => deleteRow(rowIdx)}
                                        ]}
                                    />
                                }
                                RowsContainer={ContextMenuTrigger}
                                onGridSort={(sortColumn, sortDirection) =>
                                    setRows(sortRows(rows, sortColumn, sortDirection))
                                }
                                emptyRowsView={EmptyRowsView}/>
                        </div>
                    </Col>
                </Row>
            </Layout>
            <ModalEliminar titulo="Eliminar" show={showEliminarDocumento} setShow={setShowEliminarDocumento} confirmar={retornoEliminarDocumento}>
                {msgEliminar}
            </ModalEliminar>
            <FormExcel
                show={cargaExcel}
                contexto={contexto}
                close={() => {
                    setCargaExcel(false)
                }}
            />
        </>
    );
}

export default Documentos;