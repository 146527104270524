import React, {useState, useEffect, useContext} from 'react';
import BaseGrid from '../../componentes/grid/BaseGrid'
import { Menu } from "react-data-grid-addons";
import GridMenu from '../../componentes/grid/GridMenu';
import LayoutAdmin from '../../componentes/LayoutAdmin';
import {LongTextFormatter, sortRows, EmptyRowsView, CheckFormatter} from '../../componentes/grid/utils';
import {Api} from '../../utils/api';
import UserContext from '../../UserContext';
import { Button, Row, Col, ButtonGroup } from 'react-bootstrap';
import DefaultButtons from '../../componentes/grid/DefaultButtons'
import Buscador from '../../componentes/Buscador';
import { useAlert } from 'react-alert';
import {FormUsuario, getUsuario, FormEliminarUsuario, FormCambiarPassword, FormCrearToken} from '../../modulos/administracion/FormularioUsuario';

function Usuarios(props) {

    const {ContextMenuTrigger} = Menu;
    
    const [textoBuscar, setTextoBuscar] = useState('');
	const alert = useAlert();			// Para las notificaciones


	const columns = [
		{ key: 'username', name: 'Usuario', sortable: true },
		{ key: 'nombre_completo', name: 'Nombre', formatter: LongTextFormatter, sortable: true},
		{ key: 'correo', name: 'Correo',formatter: LongTextFormatter},
		//{ key: 'ultimo_login', name: 'Último Login', width: 200, sortable: true },
		{ key: 'predios', name: 'Predios', formatter: LongTextFormatter, sortable: true, resizable:true, width:600, isExpanded:true },
		{ key: 'habilitado', name: 'Habilitado',  formatter: CheckFormatter, classname:'prueba_x', width:90 },
		{ key: 'admin_sistema', name: 'Admin', formatter: CheckFormatter,width:90 },
    ];
    
	const [rows, setRows] = useState([]);
	const contexto = useContext(UserContext);

	const [showFormUsuario, setShowFormUsuario] = useState(false);			// Modal para agregar usuario
	const [showEliminarUsuario, setShowEliminarUsuario] = useState(false);			// Modal para eliminar usuario
	const [showCambiarPassword, setShowCambiarPassword] = useState(false);			// Modal para cambiar contraseña
	const [showCrearToken, setShowCrearToken] = useState(false);
    const [usuarioForm, setUsuarioForm] = useState(
		{
			"id":"",
			"nombre_completo": "",
			"correo":"",
			"usuario":"",
			"admin_sistema":false,
		});

	useEffect(
		() => {
			cargarUsuarios();
		},
		[contexto]
	)

	/**
	 * Buscamos y cargamos todos los usuarios en la grilla
	 */
	async function cargarUsuarios() {
		let resultado = await Api('api/administracion/usuarios', null, {}, true);
		if (resultado && resultado.status === 200) {
			setRows(resultado.data.datos);
		} else {
			alert.show('Error al cargar usuarios', { type: 'error' });
			setRows([]);
		}
    }
    
    /**
     * Función para búsqueda específica de usuarios
     * 
     * @param {String} texto 
     */
    async function retornoBusqueda(texto) {
		let resultado = await Api(`api/administracion/usuarios?texto=${texto}`, null, {}, true);
		if (resultado && resultado.status === 200) {
			setRows(resultado.data.datos);
		} else {
            setRows([])
			alert.show('Error al cargar usuarios', { type: 'error' });
		}
	}

	/**
	 * Acción para desplegar modal agregar usuario
	 */
	function nuevoDato() {
		setUsuarioForm({
			"id":"",
			"nombre_completo": "",
			"correo":"",
			"usuario":"",
			"admin_sistema":false,
			"nuevo":true
		});
		setShowFormUsuario(true);
	}

	/**
	 * Evento para cerrar el modal agregar usuario
	 */
	function cerrarModalAgregar() {
		cargarUsuarios();
		setShowFormUsuario(false);
	}

	/**
	 * Evento para cerrar el modal eliminar usuario
	 */
	function cerrarModalEliminar() {
		cargarUsuarios();
		setShowEliminarUsuario(false);
	}

	/**
	 * Evento para cerrar el modal eliminar usuario
	 */
	function cerrarCambiarPassword() {
		cargarUsuarios();
		setShowCambiarPassword(false);
	}


	/**
	 * Permite enviar el formulario para crear un nuevo usuario
	 */
	async function agregarUsuario(data) {
		cerrarModalAgregar();
	}

	
	/**
	 * Cuando queremos editar un usuario, desde el context menú
	 *
	 * @param {integer} rowIdx
	 */
	const editRow = rowIdx => {
		new Promise(function(resolve, reject){
			getUsuario(rows[rowIdx].id).then(function(usuario){
				setUsuarioForm(usuario);
				setShowFormUsuario(true);
			})
		})

	};


	/**
	 * Cuando queremos elimininar un usuario, desde el context menú
	 *
	 * @param {*} rowIdx
	 */
	const deleteRow = rowIdx => {
		new Promise(function(resolve, reject){
			getUsuario(rows[rowIdx].id).then(function(usuario){
				setUsuarioForm(usuario);
				setShowEliminarUsuario(true);
			})
		})
	};

	const cambiarPassword = rowIdx => {
		new Promise(function(resolve, reject){
			getUsuario(rows[rowIdx].id).then(function(usuario){
				setUsuarioForm(usuario);
				setShowCambiarPassword(true);
			})
		})
	};


	function cerrarModalCrearToken() {
		cargarUsuarios();
		setShowCrearToken(false);
	}

	const crearToken = rowIdx => {
		new Promise(function(resolve, reject){
			getUsuario(rows[rowIdx].id).then(function(usuario){
				setUsuarioForm(usuario);
				setShowCrearToken(true);
			})
		})
	};

	return (
		<LayoutAdmin title="Usuarios">
			<Row style={{paddingBottom:'10px'}}>
                <Col>
                    <Buscador retornoBusqueda={retornoBusqueda} busqueda={textoBuscar} placeholder='Búsqueda de usuarios...'/>
                </Col>
				<Col>
					<ButtonGroup className="float-right">
						<Button variant="success" size="sm" onClick={nuevoDato}>Agregar Usuario</Button>
						<DefaultButtons />
					</ButtonGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<div>
						<BaseGrid
							columns={columns}
							rowGetter={i => rows[i]}
							rowsCount={rows.length}
							minHeight={400}
							minColumnWidth={200}
							rowHeight={50}
							contextMenu={
								<GridMenu
									id="pac_1"
									options={ [
										{texto:"Editar",
										funcion: (e, { rowIdx }) => editRow(rowIdx)},
										{texto:"Eliminar",
										funcion: (e, { rowIdx }) => deleteRow(rowIdx)},
										{texto:"Cambiar Contraseña",
										funcion: (e, { rowIdx }) => cambiarPassword(rowIdx)},
										{texto:"Crear Token",
										funcion: (e, { rowIdx }) => crearToken(rowIdx)},
									]}
								/>
							}
							RowsContainer={ContextMenuTrigger}
							onGridSort={(sortColumn, sortDirection) =>
								setRows(sortRows(rows, sortColumn, sortDirection))
							}
							emptyRowsView={EmptyRowsView}/>
					</div>
				</Col>
			</Row>
			<FormUsuario 
				show={showFormUsuario}
				onHide={cerrarModalAgregar} 
				usuario={usuarioForm} 
				funcionRetorno={function(data){agregarUsuario(data)}}
				size="md"
			/>
			<FormEliminarUsuario 
				show={showEliminarUsuario}
				onHide={cerrarModalEliminar} 
				usuario={usuarioForm} 
				funcionRetorno={function(data){cerrarModalEliminar()}}
				size="md"
			/>
			<FormCambiarPassword 
				show={showCambiarPassword}
				onHide={cerrarCambiarPassword} 
				usuario={usuarioForm} 
				funcionRetorno={function(data){cerrarCambiarPassword()}}
				size="md"
			/>
			<FormCrearToken
				show={showCrearToken}
				onHide={cerrarModalCrearToken} 
				usuario={usuarioForm} 
				funcionRetorno={function(data){cerrarModalEliminar()}}
				size="md"
			/>
		</LayoutAdmin>
	);
}

export default Usuarios;