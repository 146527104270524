import React, { useState, useContext, useEffect, useRef} from 'react';
import {Typeahead} from 'react-bootstrap-typeahead';
import { Button, InputGroup, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FaTools} from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import {Api} from '../utils/api';
import { ErrorMessage } from 'react-hook-form';
import { useAlert } from 'react-alert';

function SelectorCategoriaCuenta(props) {
    const alert = useAlert();           // Para las notificaciones
    const refSelectorCuenta = useRef();
    const [categoriasCuenta, setCategoriasCuenta] = useState([]);
    const [oculto, setOculto] = useState('');

    useEffect(
        () => {
            obtenerActividades();
        },
        []
    )

    useEffect(
        () => {
            if (props.inicial) {
                if (categoriasCuenta.length === 0) { // No hay categoriascuenta cargadas, tenemos que ir a buscar la seleccionada y seleccionar el combobox
                    obtenerActividad(props.inicial);
                } else { // Si hay categoriascuenta cargadas, solo seleccionamos la que esté
                    if (refSelectorCuenta.current.getInstance().state.selected.length === 0) { // Solo cuando no hay un elemento seleccionado en el combobox, esto se da solo en el inicio, ref.current.getInstance()._input.value
                        categoriasCuenta.forEach(actividad => {  // Buscamos dentro de las categoriascuenta la que tenemos que seleccionar
                            if (parseInt(actividad.id) === parseInt(props.id)) {
                                refSelectorCuenta.current.getInstance()._updateSelected([actividad]); // Automáticamente llama cambiarSelector
                            }
                        });
                    }
                }
            }
        },
        [props.inicial]
    )

    /**
     * Buscamos una categorias y actualizamos el valor por defecto del combobox
     *
     * @param {integer} predio
     */
    async function obtenerActividad(id) {
        let resultado = await Api(`api/cuenta/categoria?id=${id}`, null, {}, true);
        if (resultado && resultado.status === 200) {
            if (resultado.data.datos.length > 0) {
                refSelectorCuenta.current.getInstance()._updateSelected([resultado.data.datos[0]]); // Automáticamente llama cambiaSelector
            } else {
                alert.show('Error al actualizar combobox selector categoría cuenta', { type: 'error' });
            }
        } else {
            alert.show('Error al actualizar combobox selector categoría cuenta', { type: 'error' });
        }
    }

    /**
     * Cargamos las categorias en el combobox
     */
    async function obtenerActividades() {
        let resultado = await Api(`api/cuenta/categoria`, null, {}, true);
        if (resultado && resultado.status === 200) {
            setCategoriasCuenta(resultado.data.datos);
        } else {
            setCategoriasCuenta([]);
        }
    }


    /**
     * Cada vez que cambia el selector
     *
     * @param {array} selected
     */
    function cambiaSelector(selected) {
        if (selected[0] !== undefined) {
            setOculto(selected[0].id);
            if (props.funcionRetorno) {
                props.funcionRetorno(selected);
            }
        } else {
            setOculto('');
            if (props.funcionRetorno) {
                props.funcionRetorno([]);
            }
        }
    }

    return (
        <>
        <InputGroup size="sm">
            <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1"><FaTools /></InputGroup.Text>
            </InputGroup.Prepend>
            <div style={{ width: 'calc(100% - 67px)' }}>
                { props.register ?
                    <Typeahead
                        { ...props}
                        bsSize='small'
                        maxResults={400}
                        options={categoriasCuenta}
                        highlightOnlyResult={true}
                        selectHintOnEnter={true}
                        flip={true}
                        ref={refSelectorCuenta}
                        isInvalid={props.errors[props.name] ? true : false}
                        onChange={cambiaSelector}
                        labelKey={(option) => `${option.codigo_minimo} ${option.nombre}`}
                    />
                :
                    <Typeahead
                        { ...props}
                        bsSize='small'
                        options={categoriasCuenta}
                        highlightOnlyResult={true}
                        selectHintOnEnter={true}
                        flip={true}
                        ref={refSelectorCuenta}
                        onChange={cambiaSelector}
                        labelKey={(option) => `${option.codigo_minimo} ${option.nombre}`}
                    />
                }
            </div>
            <InputGroup.Append>
                <OverlayTrigger
                    placement='top'
                    overlay={<Tooltip>Limpiar opción</Tooltip>}>
                    <Button
                        variant="outline-secondary"
                        disabled={props.desactivarBotonBorrar ? true : false}
                        onClick={() => {
                            refSelectorCuenta.current.getInstance().clear(); // solo limpia la casila de la cuenta, no hace nada más
                            refSelectorCuenta.current.getInstance()._updateSelected([]); // Este limpia la casilla y activa cambiarSelector
                            // refSelectorCuenta.current.getInstance().clear();
                            // setOculto('');
                            // props.funcionRetorno([]);
                        }}>
                        <IoMdClose />
                    </Button>
                </OverlayTrigger>
            </InputGroup.Append>
        </InputGroup>
        {/* INPUT INVISIBLE */}
        {props.register &&
            <Form.Control
                type="text"
                name={props.name}
                value={oculto}
                hidden
                onChange={ // Este onChange no se ocupa, ya que nunca modificamos este valor directamente, solo está para que no lance error reactjs
                    (e) => {
                        // setOculto(e.target.value)
                    }
                }
                ref={props.register({
                    required: "Seleccione una opción"
                })}
                isInvalid={props.errors[props.name]}
            />
        }

        {props.errors &&
            <ErrorMessage errors={props.errors} name={props.name} as="div" className="invalid-feedback" />
        }
        </>
    );
}

export default SelectorCategoriaCuenta;