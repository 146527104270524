import React from 'react';
import { Card, Button, Container, Row, Col, Image, Nav, Navbar } from 'react-bootstrap';
import icono_conga from './images/Group.png'
import conga_analisis from './images/ilustracion.png'
import celular from './images/celular.png'
import tarjeta_1 from './images/01.png'
import tarjeta_2 from './images/02.png'
import tarjeta_3 from './images/03.png'
import tarjeta_4 from './images/04.png'
import tarjeta_5 from './images/05.png'
import tarjeta_6 from './images/06.png'
import logo_uach from './images/logo_uach.png'
import logo_gob from './images/logo_gob.png'
import CardExample from './background/CardExample'
import styles from './styles/inicio.module.css'
import { useForm } from 'react-hook-form'

function Inicio(props) {
    const { register, handleSubmit  } = useForm({});
    const style_header = {
        minHeight: 50,
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        background: 'linear-gradient(178.18deg, #20B099 18.81%, #1C8AB7 108.17%, #1A74C8 158.3%)',

    };

    function ingresar() {
        props.history.push('/login');
    }

    return (
        <>
            <Row className="justify-content-md-center row center" style={style_header} >
                <Navbar expand="lg">
                    <Navbar.Brand ><Image src={icono_conga} />
                        <span className={styles.style_titulo1}>  Conga  </span>
                    </Navbar.Brand>
                    <Nav className="mx-auto">
                        <span className={styles.style_titulo}>CONTABILIDAD Y GESTIÓN <br /> SILVO-AGROPECUARIA</span>
                    </Nav>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="#que-es" className={styles.style_titulos}>¿QUÉ ES CONGA?</Nav.Link>
                            <Nav.Link href="#como-funciona" className={styles.style_titulos}>¿CÓMO FUNCIONA?</Nav.Link>
                            <Nav.Link href="#solicitar-cuenta" className={styles.style_titulos}>SOLICITAR CUENTA</Nav.Link>
                            <Nav.Link href="#contacto" className={styles.style_titulos}>CONTACTO</Nav.Link>
                            <Nav>
                                <Button variant="" className={styles.style_boton} onClick={ingresar}>LOGIN</Button>
                            </Nav>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Row>
            <Container fluid>
                <Row className="justify-content-md-center row center" style={{ marginTop: 80 }}>
                    <Col lg='5'>
                        <Card className="border-0" >
                            <Card.Body >
                                <Card.Title className={styles.style_titulo_descriptivo}>Conga es una aplicación para la gestión empresarial silvo-agropecuaria.</Card.Title>
                                <Card.Text className={styles.style_texto}>
                                    Usted podrá planificar, controlar y evaluar permanentemente la empresa a partir de un presupuesto y de un control de gestión.
                                </Card.Text>
                                <div style={{ marginTop: 50 }}>
                                    <Button variant="" className={styles.style_boton2} onClick={ingresar}>Login</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg='5'>
                        <Card className="border-0" >
                            <Card.Body >
                                <Card.Img style={{ minWidth: 282 }} src={conga_analisis} alt="Card image" fluid />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="justify-content-md-center row center">
                    <Col xs lg="5">
                        <Card className="border-0" >
                            <Card.Body >
                                <Card.Img style={{ minWidth: 282 }} src={celular} alt="Card image" fluid />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs lg="5">
                        <Card id="que-es" className="border-0">
                            <Card.Body >
                                <Card.Title className={styles.style_titulo_descriptivo} style={{ marginTop: 30 }}>¿Qué es Conga?</Card.Title>

                                <Card.Text className={styles.style_texto_cards} style={{ marginTop: 33 }}>
                                    Es un sistema diseñado para planificar, controlar y evaluar permanentemente explotaciones silvoagropecuarias,
                                    a partir de un presupuesto y de un control de gestión.
                                </Card.Text>
                                <Button variant="" className={styles.style_boton2} onClick={ingresar}>Login</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Container id="como-funciona" style={{ width: '85%' }} className={styles.como_funciona} fluid>
                    <Row className="justify-content-md-center h2">
                        <Col xs lg="10" className={styles.style_titulo_descriptivo} style={{ 'textAlign': 'center' }}>
                            ¿Cómo funciona?
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center" lg="3" md="2" sm="1" xs="1">
                        <Col >
                            <Card className="border-0">
                                <Card.Body >
                                    <Card.Img style={{ maxWidth: 50 }} src={tarjeta_1} alt="Card image" />
                                    <Card.Title className={styles.style_titulo_tarjetas}>Balance</Card.Title>

                                    <Card.Text className={styles.style_texto_cards}>
                                        Se determina en primer lugar la situación financiera de cada explotación, elaborando
                                        e ingresando a la plataforma, el Inventario Inicial de sus bienes y deudas, calculándose
                                        automáticamente el Balance de Activos y Pasivos.
                                </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col >
                            <Card className="border-0" >
                                <Card.Body >
                                    <Card.Img style={{ maxWidth: 50 }} src={tarjeta_2} alt="Card image" />
                                    <Card.Title className={styles.style_titulo_tarjetas}>Presupuesto</Card.Title>
                                    <Card.Text className={styles.style_texto_cards}>
                                    A continuación, se elabora el presupuesto o plan de explotación, determinando si el productor/a continuará con los rubros o actividades
                                     desarrolladas hasta el momento, si algunas deberán cambiarse o introducir modificaciones en su proceso. El presupuesto permite conocer con
                                    antelación si el productor(a) dispondrá o no de ingresos suficientes para financiar el funcionamiento de su predio, siendo alrededor de esta 
                                    necesidad vital donde se centran los problemas decisivos de la mayoría de las explotaciones.
                                </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="border-0" >
                                <Card.Body >
                                    <Card.Img style={{ maxWidth: 50 }} src={tarjeta_3} alt="Card image" />
                                    <Card.Title className={styles.style_titulo_tarjetas}>Contabilidad</Card.Title>

                                    <Card.Text className={styles.style_texto_cards}>
                                    Durante el ejercicio o año agrícola, se ingresan periódicamente al sistema los movimientos  contables, obteniéndose, con la periodicidad que el 
                                    productor/a lo requiera, los informes relacionados con la contabilidad económica y física: compras,ventas, libro mayor, balance (evolución 
                                    experimentada por sus capitales) y control de los presupuestos por rubro.
                                </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col >
                            <Card className="border-0" >
                                <Card.Body >
                                    <Card.Img style={{ maxWidth: 50 }} src={tarjeta_4} alt="Card image" />
                                    <Card.Title className={styles.style_titulo_tarjetas}>Accesibilidad</Card.Title>

                                    <Card.Text className={styles.style_texto_cards}>
                                    La tecnología utilizada en el desarrollo de la plataforma de gestión permite que cada productor/a pueda acceder directamente a la información 
                                    presupuestaria y contable de sus predios, a través de sus teléfonos celulares.
                                </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card className="border-0" >
                                <Card.Body >
                                    <Card.Img style={{ maxWidth: 50 }} src={tarjeta_5} alt="Card image" />
                                    <Card.Title className={styles.style_titulo_tarjetas}>Reportes</Card.Title>

                                    <Card.Text className={styles.style_texto_cards}>
                                    La información a la que tiene acceso, le permite conocer los márgenes que obtieneen cada rubro (ingresos – costos), es decir, cuánto gana o
                                    pierde en cada uno de ellos,información necesaria para aumentar su competitividad.
                                </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col >
                            <Card className="border-0" >
                                <Card.Body >
                                    <Card.Img style={{ maxWidth: 50 }} src={tarjeta_6} alt="Card image" />
                                    <Card.Title className={styles.style_titulo_tarjetas}>Capacitación</Card.Title>

                                    <Card.Text className={styles.style_texto_cards}>
                                    Esta innovación en la forma de gestionar sus predios, deja de manifiesto la necesidad de capacitar al productor(a) en el uso de esta 
                                    herramienta tecnológica, que le permita establecer anualmente un presupuesto o plan de explotación y a prever su financiamiento.
                                </Card.Text>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <CardExample id="solicitar-cuenta" handleSubmit={handleSubmit} register={register}></CardExample>
                <footer id="contacto" style={{ marginBottom: 50 }}>
                    <div className="justify-content-md-center" style={{ textAlign: 'center', marginBottom: 50 }}>
                        <span className={styles.style_titulo_descriptivo}>Contacto</span>
                    </div>
                    <Row className="justify-content-md-center row " style={{ textAlign: 'center', marginBottom: 50 }} lg="3" md="1" sm="1" xs="1">
                        <Col className={styles.style_texto}>jlerdon@uach.cl</Col>
                        <Col className={styles.style_texto}>56-63-2221235</Col>
                        <Col className={styles.style_texto}>Universidad Austral de Chile</Col>
                    </Row>
                    <Container style={{borderBottom: '1px solid #BFBFBF'}}fluid>
                    </Container>
                    <div className="justify-content-md-center" style={{  textAlign: 'center',marginTop:21,marginBottom:38 }}>
                        <span className={styles.style_titulo_footer} >Aplicación creada por</span>
                    </div>
                    <Row className="justify-content-md-center " style={{ textAlign: 'center' }} lg="3" md="1" sm="1" xs="1">
                        <Col >
                            <Image src={logo_uach} className={styles.style_img_footer1}></Image>
                        </Col>
                        <Col >
                            <div className={styles.autor_intelectual}>
                                <p className={styles.titulo}>Autor de la creación intelectual:</p>
                                <p>Ing. Agr. Dr. Juan Ricardo Lerdon Ferrada</p>
                                <p>Facultad de Ciencias Agrarias y Alimentarias</p>
                                <p>Instituto de economía Agraria</p>
                            </div>
                        </Col>
                    </Row>
                </footer>
            </Container>
        </>
    );
}

export default Inicio;