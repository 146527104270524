import React, { useState, useEffect } from 'react';
import {Button, InputGroup, Form} from 'react-bootstrap';
import { FaCalendar} from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { ErrorMessage } from 'react-hook-form';
import { getYMD, getYMDHIS } from '../utils/Functions';
registerLocale('es', es)

function Fecha(props) {
    const [oculto, setOculto] = useState('');
    const [fecha, setFecha] = useState('');
    const [formatoHora, setFormatoHora] = useState(false);
    const [formatoMesAno, setFormatoMesAno] = useState(false);

    useEffect(
        () => {
            if (props.inicial) {
                let valorInicial = props.inicial;
                /* Viene como fecha de base de datos del estilo y-m-d - y-m-d h:i:s
                 * debemos cambiar los - por / si no tendremos perdida de un día */
                valorInicial = valorInicial.replace(/-/g, '/');
                valorInicial = new Date(valorInicial);

                // Ahora actualizaremos el campo oculto al formato que nosotros queremos
                if (props.dateFormatOculto === 'y-m-d') {
                    setOculto(valorInicial ? getYMD(valorInicial) : '');
                } else if (props.dateFormatOculto === 'y-m-d h:i:s') {
                    setOculto(valorInicial ? getYMDHIS(valorInicial) : '');
                }

                // Actualizamos la fecha en el datepicker
                setFecha(props.inicial ? valorInicial : '');
            }

            // Estos son algunos props del datepicker
            if (props.dateFormat === 'dd/MM/Y h:mm aa') { // Para agregar formato hora
                setFormatoHora(true);
            } else if (props.dateFormat === 'MM/yyyy') { // Para que solo sea mes y año
                setFormatoMesAno(true);
            }
            if (props.formatoMesAno === true) { // Para que solo sea mes y año
                setFormatoMesAno(true);
            }
        },
        [props.inicial]
    )


    /**
     * Cada vez que cambia la fecha
     *
     * @param {array} selected
     */
    function onChangeDate(fechaData) {
        if (props.dateFormatOculto === 'y-m-d') {
            setOculto(fechaData ? getYMD(fechaData) : '');
        } else if (props.dateFormatOculto ==='y-m-d h:i:s') {
            setOculto(fechaData ? getYMDHIS(fechaData) : '');
        }
        setFecha(fechaData);
        if (props.onChange) { // para ejecutar un onChange externo
            props.onChange(fechaData);
        }
    }

    return (
        <>
        <InputGroup size="sm">
            <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1"><FaCalendar /></InputGroup.Text>
            </InputGroup.Prepend>
            <div id="cont-fecha" style={{ width: 'calc(100% - 62px)' }} className={formatoHora ? 'formato-hora' : ''}>
                <DatePicker
                    className="form-control form-control-sm"
                    name="fecha"
                    // peekNextMonth
                    // showPopperArrow={false}
                    minDate={props.minDate ? new Date(props.minDate.replace(/-/g, '/')) : false} // Debe venir formato base de datos 2008-08-01
                    maxDate={props.maxDate ? new Date(props.maxDate.replace(/-/g, '/')) : false} // Debe venir formato base de datos 2008-08-01
                    // placeholderText="mes día año"
                    // isClearable
                    showTimeSelect={formatoHora}
                    timeCaption="Hora"
                    disabled={props.disabled ? true : false}

                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat={props.dateFormat ? props.dateFormat : 'dd/MM/yyyy'}
                    showMonthYearPicker={formatoMesAno}
                    locale="es"
                    selected={fecha ? fecha : ''}
                    onChange={(e) => onChangeDate(e)} />
            </div>
            <InputGroup.Append>
                <Button
                    variant="outline-secondary"
                    disabled={props.desactivarBotonBorrar ? true : false}
                    onClick={() => {
                        setOculto('');
                        setFecha('');
                    }}>
                    <IoMdClose />
                </Button>
            </InputGroup.Append>
        </InputGroup>
        {/* INPUT INVISIBLE */}
        {props.register &&
            <Form.Control
                type="text"
                name={props.name}
                value={oculto}
                hidden
                onChange={ // Este onChange no se ocupa, ya que nunca modificamos este valor directamente, solo está para que no lance error reactjs
                    (e) => {}
                }
                ref={props.vacio ? props.register({}) : props.register({required: "Seleccione la fecha"})}
                isInvalid={props.errors[props.name]}
            />
        }

        {props.errors &&
            <ErrorMessage errors={props.errors} name={props.name} as="div" className="invalid-feedback" />
        }
        </>
    );
}

export default Fecha;